import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import vajra from "../images/vajra.png";



const NavbarMain = ({handleToggle,toggle}) => {
  const navigate = useNavigate();


  return (
    <AppBar
      position="sticky"
      sx={{
        top: 0,
        zIndex: 999,
        bgcolor: "rgba(27, 37, 53, 1)",
        height: "60px",
      }}>
      <Toolbar>
        <Stack
          sx={{
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "row",
            px: {
              xs: '0px',  
              sm: '0px',   
              md: '70px', 
            },
            position:'relative',
            top:'5px',
          }}>
            
            <Link to="https://vajra.software/" style={{ textDecoration: 'none' ,  }}>
          <Box sx={{  height: "50px",
          display:'flex',
              alignItems: "center",
              cursor:'pointer',
              justifyContent: "center",

             }}            
             onClick={() => navigate("/")}>

          <Typography
            variant="h6"
            // component="div"
            sx={{
            
            
              alignItems: "center",
              justifyContent: "center",
             
            }}>
            {/* Vajra */}
            <img
                src={vajra}
                alt="vajra logo"
                style={{ maxWidth: "50%", maxHeight: "50%",  }}

              />
              
          </Typography>
          
          </Box>
          </Link>
          <Link to="https://vajra.software/" style={{ textDecoration: 'none', color:'white',position:'relative',top:'10px' }}>
          <Box
            // onClick={() => navigate("/")}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
              // padding: '8px',
              borderRadius: "8px",
            }}>
            <HomeIcon onClick={handleToggle} toggle={toggle}   />Home
          </Box>
          </Link>
        </Stack>
      </Toolbar>













    </AppBar>
  );
};

export default NavbarMain;
