import {
  COUNT_FAILURE,
  COUNT_REQUEST,
  COUNT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DEPARTMENT_ALREADY_SAVED_FAILURE,
  DEPARTMENT_ALREADY_SAVED_REQUEST,
  DEPARTMENT_ALREADY_SAVED_SUCCESS,
  DOWNLOAD_PDF_FAILURE,
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
  GET_DEPARTMENT_DATA_FAILURE,
  GET_DEPARTMENT_DATA_REQUEST,
  GET_DEPARTMENT_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  INCIDENT_DATA_FAILURE,
  INCIDENT_DATA_REQUEST,
  INCIDENT_DATA_SUCCESS,
  INCIDENT_DETAIL_FAILURE,
  INCIDENT_DETAIL_REQUEST,
  INCIDENT_DETAIL_SUCCESS,
  POST_DATA_FAILURE,
  POST_DATA_REQUEST,
  POST_DATA_SUCCESS,
  POST_DEPARTMENT_FAILURE,
  POST_DEPARTMENT_NAME_FAILURE,
  POST_DEPARTMENT_NAME_REQUEST,
  POST_DEPARTMENT_NAME_SUCCESS,
  POST_DEPARTMENT_REQUEST,
  POST_DEPARTMENT_SUCCESS,
  POST_USER_DATA_FAILURE,
  POST_USER_DATA_REQUEST,
  POST_USER_DATA_SUCCESS,
  RETRIEVE_ALL_ASSETS_REQUEST,
  RETRIEVE_DEPARTMENT_FAILURE,
  RETRIEVE_DEPARTMENT_REQUEST,
  RETRIEVE_DEPARTMENT_SUCCESS,
  UPDATE_DATA_FAILURE,
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  UPDATE_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_SUCCESS,
  UPLOADED_CERTIFICATE_FAILURE,
  UPLOADED_CERTIFICATE_REQUEST,
  UPLOADED_CERTIFICATE_SUCCESS,
  UPLOADED_IMAGE_FAILURE,
  UPLOADED_IMAGE_REQUEST,
  UPLOADED_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAILURE,
  CREATE_SERVICE_IMAGE_UPLOAD_REQUEST,
  CREATE_SERVICE_IMAGE_UPLOAD_SUCCESS,
  CREATE_SERVICE_IMAGE_UPLOAD_FAILURE,
  RETRIEVE_ALL_ASSETS_SUCCESS,
  RETRIEVE_ALL_ASSETS_FAILURE,
  CLOSED_SERVICE_REQUEST,
  CLOSED_SERVICE_SUCCESS,
  CLOSED_SERVICE_FAILURE,
  OPEN_SERVICE_REQUEST,
  OPEN_SERVICE_SUCCESS,
  OPEN_SERVICE_FAILURE,
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  SERVICE_LIST_FAILURE,
  SERVICE_DETAIL_REQUEST,
  SERVICE_DETAIL_SUCCESS,
  SERVICE_DETAIL_FAILURE,
  CLEAR_SERVICE_REQUEST,
  CLEAR_SERVICE_SUCCESS,
  CLEAR_SERVICE_FAILURE,
  SERVICE_ISSUE_REQUEST,
  SERVICE_ISSUE_SUCCESS,
  SERVICE_ISSUE_FAILURE,
  SERVICE_IMAGE_UPLOAD_REQUEST,
  SERVICE_IMAGE_UPLOAD_SUCCESS,
  SERVICE_IMAGE_UPLOAD_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  ADD_ASSET_FAILURE,
  ADD_ASSET_REQUEST,
  ADD_ASSET_SUCCESS,
  CLEAR_USER_DATA,
  CONTACT_US_FAILURE,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CREATE_QR_CODE_FAILURE,
  CREATE_QR_CODE_REQUEST,
  CREATE_QR_CODE_SUCCESS,
  DELETE_CERTIFICATE_FAILURE,
  DELETE_CERTIFICATE_REQUEST,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_IMAGE_FAILURE,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_REQUEST_HISTORY,
  DELETE_PROFILE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  EDIT_ASSET_FAILURE,
  EDIT_ASSET_REQUEST,
  EDIT_ASSET_SUCCESS,
  GET_ASSET_FAILURE,
  GET_ASSET_REQUEST,
  GET_ASSET_SUCCESS,
  GET_SINGLE_USER_FAILURE,
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
  UPLOAD_QR_CODE_IMAGE_FAILURE,
  UPLOAD_QR_CODE_IMAGE_REQUEST,
  UPLOAD_QR_CODE_IMAGE_SUCCESS,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  POST_REGISTER_FAILURE,
  POST_REGISTER_REQUEST,
  POST_REGISTER_SUCCESS,
  USER_MANUAL_REQUEST,
  USER_MANUAL_SUCCESS,
  USER_MANUAL_FAILURE,
  GET_NOTREQUIRED_FAILURE,
  GET_NOTREQUIRED_REQUEST,
  GET_NOTREQUIRED_SUCCESS,
  GET_NOTCALIBRATED_FAILURE,
  GET_NOTCALIBRATED_REQUEST,
  GET_NOTCALIBRATED_SUCCESS,
  GET_CALIBRATED_FAILURE,
  GET_CALIBRATED_REQUEST,
  GET_CALIBRATED_SUCCESS,
  CLEAR_INCIDENT_IMAGE_REQUEST,
  CLEAR_INCIDENT_IMAGE_SUCCESS,
  CLEAR_INCIDENT_IMAGE_FAILURE,
  CLEAR_INCIDENT_REQUEST,
  CLEAR_INCIDENT_SUCCESS,
  CLEAR_INCIDENT_FAILURE,
  CLEAR_INCIDENT_IMAGE_REMOVE_REQUEST,
  CLEAR_INCIDENT_IMAGE_REMOVE_FAILURE,
  CLEAR_INCIDENT_IMAGE_REMOVE_SUCCESS,
  SERVICE_REQUEST_IMAGE_REMOVAL_REQUEST,
  SERVICE_REQUEST_IMAGE_REMOVAL_SUCCESS,
  SERVICE_REQUEST_IMAGE_REMOVAL_FAILURE,
  ASSET_DETAILS_REQUEST,
  ASSET_DETAILS_SUCCESS,
  ASSET_DETAILS_FAILURE,
  RECORD_INCIDENT_IMAGE_UPLOAD_REQUEST,
  RECORD_INCIDENT_IMAGE_UPLOAD_SUCCESS,
  RECORD_INCIDENT_IMAGE_UPLOAD_FAILURE,
  RECORD_INCIDENT_IMAGE_DELETE_REQUEST,
  RECORD_INCIDENT_IMAGE_DELETE_SUCCESS,
  RECORD_INCIDENT_IMAGE_DELETE_FAILURE,
  CREATE_INCIDENT_REQUEST,
  CREATE_INCIDENT_SUCCESS,
  CREATE_INCIDENT_FAILURE,
  GET_INCIDENT_HISTORY_REQUEST,
  GET_INCIDENT_HISTORY_SUCCESS,
  GET_INCIDENT_HISTORY_FAILURE,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  DEPARTMENT_ASSET_DETAILS_REQUEST,
  DEPARTMENT_ASSET_DETAILS_SUCCESS,
  DEPARTMENT_ASSET_DETAILS_FAILURE,
  SERVICE_REPORT_REQUEST,
  SERVICE_REPORT_SUCCESS,
  SERVICE_REPORT_FAILURE,
  INCIDENT_REPORT_REQUEST,
  INCIDENT_REPORT_SUCCESS,
  INCIDENT_REPORT_FAILURE,
  CALIBRATION_REPORT_REQUEST,
  CALIBRATION_REPORT_SUCCESS,
  CALIBRATION_REPORT_FAILURE,
  GET_PM_ASSET_REQUEST,
  GET_PM_ASSET_SUCCESS,
  GET_PM_ASSET_FAILURE,
  POST_PM_CHECKLIST_REQUEST,
  POST_PM_CHECKLIST_SUCCESS,
  POST_PM_CHECKLIST_FAILURE,
  PM_CHECKLIST_PDF_REQUEST,
  PM_CHECKLIST_PDF_SUCCESS,
  PM_CHECKLIST_PDF_FAILURE,
  CLOSED_INCIDENT_REQUEST,
  CLOSED_INCIDENT_SUCCESS,
  CLOSED_INCIDENT_FAILURE,
  OPEN_INCIDENT_REQUEST,
  OPEN_INCIDENT_SUCCESS,
  OPEN_INCIDENT_FAILURE,
  INCIDENT_ISSUE_REQUEST,
  INCIDENT_ISSUE_SUCCESS,
  INCIDENT_ISSUE_FAILURE,
  WARRANTY_STATUS_REQUEST,
  WARRANTY_STATUS_SUCCESS,
  WARRANTY_STATUS_FAILURE,
  REQUEST_WARRANTY_REQUEST,
  REQUEST_WARRANTY_SUCCESS,
  REQUEST_WARRANTY_FAILURE,
  PROFILE_EDIT_REQUEST,
  PROFILE_EDIT_SUCCESS,
  PROFILE_EDIT_FAILURE,
  CLEAR_SERVICE_IMAGE_DELETE_REQUEST,
  CLEAR_SERVICE_IMAGE_DELETE_SUCCESS,
  CLEAR_SERVICE_IMAGE_DELETE_FAILURE,
  GET_INVALID_TOKEN_REQUEST,
  GET_INVALID_TOKEN_SUCCESS,
  GET_INVALID_TOKEN_FAILURE,
  GET_AUTHENTICATION_ERROR_SUCCESS,
  CLEAR_SERVICE_RECEIPT_UPLOAD_REQUEST,
  CLEAR_SERVICE_RECEIPT_UPLOAD_SUCCESS,
  CLEAR_SERVICE_RECEIPT_UPLOAD_FAILURE,
  CLEAR_SERVICE_RECEIPT_DELETE_REQUEST,
  CLEAR_SERVICE_RECEIPT_DELETE_SUCCESS,
  CLEAR_SERVICE_RECEIPT_DELETE_FAILURE,
  CONTACT_HOME_REQUEST,
  CONTACT_HOME_SUCCESS,
  CONTACT_HOME_FAILURE,
  BIOMEDICAL_SERVICE_REQUEST,
  BIOMEDICAL_SERVICE_SUCCESS,
  BIOMEDICAL_SERVICE_FAILURE,
  CSV_FILE_UPLOAD_REQUEST,
  CSV_FILE_UPLOAD_SUCCESS,
  CSV_FILE_UPLOAD_FAILURE,
  DELETE_CSV_FILE_UPLOAD_REQUEST,
  DELETE_CSV_FILE_UPLOAD_SUCCESS,
  DELETE_CSV_FILE_UPLOAD_FAILURE,
  CSV_FILE_UPLOAD_SUBMIT_REQUEST,
  CSV_FILE_UPLOAD_SUBMIT_SUCCESS,
  CSV_FILE_UPLOAD_SUBMIT_FAILURE,
  AMC_CMC_REQUEST,
  AMC_CMC_SUCCESS,
  AMC_CMC_FAILURE,
  AMC_REQUEST,
  AMC_SUCCESS,
  AMC_FAILURE,
  CMC_REQUEST,
  CMC_SUCCESS,
  CMC_FAILURE,
  ASSET_HISTORY_RETRIEVE_REQUEST,
  ASSET_HISTORY_RETRIEVE_SUCCESS,
  ASSET_HISTORY_RETRIEVE_FAILURE,
  ASSET_HISTORY_DROPDOWN_RETRIEVE_REQUEST,
  ASSET_HISTORY_DROPDOWN_RETRIEVE_SUCCESS,
  ASSET_HISTORY_DROPDOWN_RETRIEVE_FAILURE,
  ASSET_HISTORY_REQUEST,
  ASSET_HISTORY_SUCCESS,
  ASSET_HISTORY_FAILURE,
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE,
  POST_VENDOR_REQUEST,
  POST_VENDOR_SUCCESS,
  POST_VENDOR_FAILURE,
  GET_VENDOR_FAILURE,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_REQUEST,
  POST_VENDOR_SERVICE_REQUEST,
  POST_VENDOR_SERVICE_SUCCESS,
  POST_VENDOR_SERVICE_FAILURE,
  GET_VENDOR_SERVICE_REQUEST,
  GET_VENDOR_SERVICE_SUCCESS,
  GET_VENDOR_SERVICE_FAILURE,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  CHECKIN_REQUEST,
  CHECKIN_SUCCESS,
  CHECKIN_FAILURE,
  CHECKIN_CHECKOUT_REQUEST,
  CHECKIN_CHECKOUT_SUCCESS,
  CHECKIN_CHECKOUT_FAILURE,
  CHECKIN_CHECKOUT_DETAIL_REQUEST,
  CHECKIN_CHECKOUT_DETAIL_SUCCESS,
  CHECKIN_CHECKOUT_DETAIL_FAILURE,
  CALENDER_REQUEST,
  CALENDER_SUCCESS,
  CALENDER_FAILURE,
  CHECKIN_PDF_REQUEST,
  CHECKIN_PDF_SUCCESS,
  CHECKIN_PDF_FAILURE,
  AMC_CMC_REQ_REQUEST,
  AMC_CMC_REQ_SUCCESS,
  AMC_CMC_REQ_FAILURE,
  CALIBRATION_REQ_REQUEST,
  CALIBRATION_REQ_SUCCESS,
  CALIBRATION_REQ_FAILURE,
  ASSET_ADD_REPORT_REQUEST,
  ASSET_ADD_REPORT_SUCCESS,
  ASSET_ADD_REPORT_FAILURE,
  ASSET_DELETE_REPORT_REQUEST,
  ASSET_DELETE_REPORT_SUCCESS,
  ASSET_DELETE_REPORT_FAILURE,
  WARRANTY_REQUEST_REQUEST,
  WARRANTY_REQUEST_SUCCESS,
  WARRANTY_REQUEST_FAILURE,
  WARRANTY_TASK_COMPLETE_REQUEST,
  WARRANTY_TASK_COMPLETE_SUCCESS,
  WARRANTY_TASK_COMPLETE_FAILURE,
  AMC_CMC_TASK_COMPLETE_REQUEST,
  AMC_CMC_TASK_COMPLETE_SUCCESS,
  AMC_CMC_TASK_COMPLETE_FAILURE,
  CALIBRATION_TASK_COMPLETE_REQUEST,
  CALIBRATION_TASK_COMPLETE_SUCCESS,
  CALIBRATION_TASK_COMPLETE_FAILURE,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
  UPDATE_REPORT_FAILURE,
  CREATE_SUBUSER_REQUEST,
  CREATE_SUBUSER_SUCCESS,
  CREATE_SUBUSER_FAILURE,
  BULK_ASSET_DELETE_REQUEST,
  BULK_ASSET_DELETE_SUCCESS,
  BULK_ASSET_DELETE_FAILURE,
  BULK_DEPARTMENT_UPDATE_REQUEST,
  BULK_DEPARTMENT_UPDATE_SUCCESS,
  BULK_DEPARTMENT_UPDATE_FAILURE,
  RETRIEVE_ASSET_REQUEST,
  RETRIEVE_ASSET_SUCCESS,
  RETRIEVE_ASSET_FAILURE,
  RETRIEVE_SERVICE_ASSET_SUCCESS,
  RETRIEVE_SERVICE_ASSET_REQUEST,
  RETRIEVE_SERVICE_ASSET_FAILURE,
  USER_DETAIL_CHECK_REQUEST,
  USER_DETAIL_CHECK_SUCCESS,
  USER_DETAIL_CHECK_FAILURE,
  CREATE_SUPERADMIN_REQUEST,
  CREATE_SUPERADMIN_SUCCESS,
  CREATE_SUPERADMIN_FAILURE,
  ALL_USER_LIST_REQUEST,
  ALL_USER_LIST_SUCCESS,
  ALL_USER_LIST_FAILURE,
  CREATE_SUBUSER_TO_SUPERADMIN_REQUEST,
  CREATE_SUBUSER_TO_SUPERADMIN_SUCCESS,
  CREATE_SUBUSER_TO_SUPERADMIN_FAILURE,
  DEPARTMENT_USER_DETAILS_REQUEST,
  DEPARTMENT_USER_DETAILS_SUCCESS,
  DEPARTMENT_USER_DETAILS_FAILURE,
  USER_RETRIEVE_BY_BRANCH_REQUEST,
  USER_RETRIEVE_BY_BRANCH_SUCCESS,
  USER_RETRIEVE_BY_BRANCH_FAILURE,
  UPDATE_SUBUSER_TO_SUPERADMIN_REQUEST,
  UPDATE_SUBUSER_TO_SUPERADMIN_SUCCESS,
  UPDATE_SUBUSER_TO_SUPERADMIN_FAILURE,
  POST_NOTIFICATION_TOKEN_REQUEST,
  POST_NOTIFICATION_TOKEN_SUCCESS,
  POST_NOTIFICATION_TOKEN_FAILURE,
  REQUEST_LIST_REQUEST,
  REQUEST_LIST_SUCCESS,
  REQUEST_LIST_FAILURE,
  GET_NEW_ASSETS_REQUEST,
  GET_NEW_ASSETS_SUCCESS,
  GET_NEW_ASSETS_FAILURE,
  GET_DASHBOARD_LIST_REQUEST,
  GET_DASHBOARD_LIST_SUCCESS,
  GET_DASHBOARD_LIST_FAILURE,
  GET_DASHBOARD_LIST2_FAILURE,
  GET_DASHBOARD_LIST2_SUCCESS,
  GET_DASHBOARD_LIST2_REQUEST,
  GET_MONTHLY_REPORT_REQUEST,
  GET_MONTHLY_REPORT_SUCCESS,
  GET_MONTHLY_REPORT_FAILURE,
  ADD_ACCESSORY_UPLOAD_FILE,
  ADD_ACCESSORY_UPLOAD_FILE_HISTORY,
  UPLOAD_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE,
  ADD_ACCESSORY_UPDATE,
  ADD_ACCESSORY_UPDATE_HISTORY,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  ADD_ACCESSORY_UPDATE_HISTORY_SUCCESS,
  DOWNLOAD_PDF_REQUEST_HISTORY,
  DOWNLOAD_PDF_SUCCESS_HISTORY,
  DOWNLOAD_PDF_FAILURE_HISTORY,
  GET_REQUEST_DASHBOARD,
  GET_REQUEST_DASHBOARD_SUCCESS,
  GET_REQUEST_DASHBOARD_FAILURE,
  SELL_ONLINE_ASSET_FAILURE,
  SELL_ONLINE_ASSET_REQUEST,
  SELL_ONLINE_ASSET_SUCCESS,
  SET_NAVIGATION_SOURCE,
  GET_RETRIEVE_INCIDENT_ASSETS_REQUEST,
  GET_RETRIEVE_INCIDENT_ASSETS_SUCCESS,
  GET_RETRIEVE_INCIDENT_ASSETS_FAILURE,
  CHECKOUT_ASSET_REQUEST,
  CHECKOUT_ASSET_SUCCESS,
  CHECKOUT_ASSET_FAILURE,

  INCIDENT_EXPENDITURE_IMAGE_UPLOAD_FAILURE,
  INCIDENT_EXPENDITURE_IMAGE_UPLOAD_REQUEST,
  INCIDENT_EXPENDITURE_IMAGE_UPLOAD_SUCCESS,
  CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_FAILURE,
  CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_REQUEST,
  CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_SUCCESS,
  SERVICE_EXPENDITURE_IMAGE_UPLOAD_FAILURE,
  SERVICE_EXPENDITURE_IMAGE_UPLOAD_REQUEST,
  SERVICE_EXPENDITURE_IMAGE_UPLOAD_SUCCESS,
  CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_FAILURE,
  CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_SUCCESS,
  CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_REQUEST,
  NOT_WORKING_ASSETS_REQUEST,
  NOT_WORKING_ASSETS_SUCCESS,
  NOT_WORKING_ASSETS_FAILURE,
  DISCARDED_ASSETS_REQUEST,
  DISCARDED_ASSETS_SUCCESS,
  DISCARDED_ASSETS_FAILURE,

  
  
} from "./action";

const initState = {
  isLoading: false,
  isError: false,
  error: {},
  data: [],
  userData: [],
  addedUser: [],
  department: [],
  updateData: [],
  incidentData: [],
  incidentDetails: [],
  downloadPdf: [],
  addImage: [],
  addedImage: [],
  certificateImage: [],
  count: 0,
  departmentName: [],
  alreadySaved: [],
  editDepart: [],
  retrieveDetail: [],
  deleteDepart: [],
  incharge: [],
  allAssets: [],
  deleteAsset: [],
  createService: [],
  createServiceImageUpload: [],
  closeService: [],
  openService: [],
  serviceList: [],
  serviceDetail: [],
  clearService: [],
  serviceIssue: [],
  serviceImageUpload: [],
  logout: [],
  qrCodeImage: [],
  createQrCode: [],
  addAsset: [],
  editAsset: [],
  getAsset: [],
  singleUser: [],
  deleteImage: [],
  deleteCertificate: [],
  deleteUser: [],
  contact: [],
  profile: [],
  register: [],
  userManual: [],
  clearIncidentImageUpload: [],
  clearIncidentImageRemove: [],
  clearIncident: [],
  serviceRequestImageRemove: [],
  assetDetails: [],
  recordIncidentImage: [],
  recordIncidentImageDelete: [],
  createIncident: [],
  incidentHistory: [],
  calibratedList: [],
  calibrateNotRequired: [],
  notCalibrated: [],
  dashboard: [],
  departmentAssetDetails: [],
  serviceReport: [],
  incidentReport: [],
  calibrationReport: [],
  getPmAssetData: [],
  postPmCheckListData: [],
  closedService: [],
  pmChecklistPdf: [],
  closedIncident: [],
  openIncident: [],
  incidentIssue: [],
  warrantyStatus: [],
  requestWarranty: [],
  profileEdit: [],
  clearServiceImageDel: [],
  invalidToken: [],
  authenticationError: [],
  clearServiceReceipt: [],
  clearServiceReceiptDel: [],
  contactHome: [],
  bioMedicalServices: [],
  csvFileUpload: [],
  deleteCsvFile: [],
  csvFileUploadSubmit: [],
  AmcCmcData: [],
  amcData: [],
  cmcData: [],
  assetHistoryData: [],
  assetHistoryRetrieveData: [],
  assetHistoryDropdownRetrieveData:[],
  notificationToken: [],
  createdTask: [],
  getTask: [],
  postVendor: [],
  getVendor: [],
  postVendorService: [],
  getVendorService: [],
  checkoutData: [],
  checkInData: [],
  checkInOutData: [],
  datas: [],
  checkInOutDataDetail: [],
  calenderData: [],
  checkInPdfData: [],
  amcCmcReqData: [],
  calibrationReq: [],
  addAssetReport: [],
  deleteAssetReport: [],
  warrantyRequest: [],
  warrantyTaskComplete: [],
  AmcCmcTaskComplete: [],
  calibrationTaskComplete: [],
  updateReports: [],
  createSubUser: [],
  subuserToSuperAdmin: [],
  bulkAssetDelete: [],
  bulkDepartmentUpdate: [],
  retrieveAsset: [],
  retrieveServiceAsset:[],
  userDetailCheck: [],
  createSuperAdmin: [],
  usersList: [],
  departmentUserList: [],
  userRetrieveByBranch: [],
  updateSuperAdmin: [],
  request: [],
  dashboardList: [],
  dashboardList2: [],
  monthlyReport: [],
  allAssetsNew: {},
  accessories: {
    names: [],
    prices: [],
    quantities: [],
    imageIds: [],
  },
  data: {
    accessoryname: [],
    accessoryprice: [],
    accessoryquantity: [],
    accessoryimages: [],
  },
  dataHistory: [],
  accessoryHistoryExpenditure: 0,
  pdfImgHistory: null,
  requestDashboardData: [],
  sellOnlineData: [],
  fromPage: null,
  logoUrl:'',
  dropdownincidentasset:[],
  incidentExpenditureImageUpload:[],
  clearIncidentExpenditureImageRemove:[],
  serviceExpenditureImageUpload:[],
  clearServiceImageRemove:[],
  notworking:[],
  discardedassets:[],
};

export const dataReducer = (state = initState, action) => {
  switch (action.type) {
    case POST_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };

    case POST_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        count: action.payload,
      };

    case COUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_USER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userData: action.payload,
      };

    case GET_USER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case POST_USER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_USER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        addedUser: action.payload,
      };

    case POST_USER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_DEPARTMENT_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_DEPARTMENT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        department: action.payload,
      };

    case GET_DEPARTMENT_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case UPDATE_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPDATE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        updateData: action.payload,
      };

    case UPDATE_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case INCIDENT_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case INCIDENT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        incidentData: action.payload,
      };

    case INCIDENT_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case INCIDENT_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case INCIDENT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        incidentDetails: action.payload,
      };

    case INCIDENT_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case DOWNLOAD_PDF_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        downloadPdf: action.payload,
      };

    case DOWNLOAD_PDF_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        addImage: action.payload,
      };

    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case UPLOADED_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPLOADED_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        addedImage: action.payload,
      };

    case UPLOADED_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case UPLOADED_CERTIFICATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPLOADED_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        certificateImage: action.payload,
      };

    case UPLOADED_CERTIFICATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case POST_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        incharge: action.payload,
      };

    case UPLOAD_QR_CODE_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPLOAD_QR_CODE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        qrCodeImage: action.payload,
      };

    case UPLOAD_QR_CODE_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CREATE_QR_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_QR_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        createQrCode: action.payload,
      };

    case CREATE_QR_CODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case ADD_ASSET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ADD_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        addAsset: action.payload,
      };

    case ADD_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case EDIT_ASSET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case EDIT_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        editAsset: action.payload,
      };

    case EDIT_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_ASSET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        getAsset: action.payload,
      };

    case GET_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_SINGLE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_SINGLE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        singleUser: action.payload,
      };

    case GET_SINGLE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case DELETE_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_IMAGE_REQUEST_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_PROFILE_IMAGE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        deleteImage: action.payload,
      };

    case DELETE_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case DELETE_CERTIFICATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        deleteCertificate: action.payload,
      };

    case DELETE_CERTIFICATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case DELETE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        deleteUser: action.payload,
      };

    case DELETE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CLEAR_USER_DATA:
      return {
        isLoading: false,
        isError: false,
        addedUser: [],
      };

    case CONTACT_US_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CONTACT_US_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        contact: action.payload,
      };

    case CONTACT_US_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CONTACT_HOME_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CONTACT_HOME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        contactHome: action.payload,
      };

    case CONTACT_HOME_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case POST_DEPARTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case POST_DEPARTMENT_NAME_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_DEPARTMENT_NAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        departmentName: action.payload,
      };

    case POST_DEPARTMENT_NAME_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case DEPARTMENT_ALREADY_SAVED_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DEPARTMENT_ALREADY_SAVED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        alreadySaved: action.payload,
      };
    case DEPARTMENT_ALREADY_SAVED_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case UPDATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        editDepart: action.payload,
      };
    case UPDATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case RETRIEVE_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case RETRIEVE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        retrieveDetail: action.payload,
      };
    case RETRIEVE_DEPARTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        deleteDepart: action.payload,
      };

    case DELETE_DEPARTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    //All assets

    case RETRIEVE_ALL_ASSETS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case RETRIEVE_ALL_ASSETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        allAssets: action.payload,
      };

    case RETRIEVE_ALL_ASSETS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    //Delete Asset
    case DELETE_ASSET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DELETE_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        deleteAsset: action.payload,
      };
    case DELETE_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    //create service

    case CREATE_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        createService: action.payload,
      };
    case CREATE_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //createService Image upload

    case CREATE_SERVICE_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_SERVICE_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        createServiceImageUpload: action.payload,
      };
    case CREATE_SERVICE_IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //closed service

    case CLOSED_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLOSED_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        closedService: action.payload,
      };
    case CLOSED_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //open service

    case OPEN_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case OPEN_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        openService: action.payload,
      };
    case OPEN_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //servicelist
    case SERVICE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case SERVICE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        serviceList: action.payload,
      };
    case SERVICE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //serviceDetail
    case SERVICE_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        serviceDetail: action.payload,
      };
    case SERVICE_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //clear service
    case CLEAR_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLEAR_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        clearService: action.payload,
      };
    case CLEAR_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //service issue
    case SERVICE_ISSUE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case SERVICE_ISSUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        serviceIssue: action.payload,
      };
    case SERVICE_ISSUE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case INCIDENT_ISSUE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case INCIDENT_ISSUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        incidentIssue: action.payload,
      };
    case INCIDENT_ISSUE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //service image upload
    case SERVICE_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case SERVICE_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        serviceImageUpload: action.payload,
      };
    case SERVICE_IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };


      case SERVICE_EXPENDITURE_IMAGE_UPLOAD_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case SERVICE_EXPENDITURE_IMAGE_UPLOAD_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          serviceExpenditureImageUpload: action.payload,
        };
      case SERVICE_EXPENDITURE_IMAGE_UPLOAD_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };

      case INCIDENT_EXPENDITURE_IMAGE_UPLOAD_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case INCIDENT_EXPENDITURE_IMAGE_UPLOAD_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          incidentExpenditureImageUpload: action.payload,
        };
      case INCIDENT_EXPENDITURE_IMAGE_UPLOAD_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };
    //logout
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        logout: action.payload,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //profile
    case PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        profile: action.payload,
      };
    case PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case POST_REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        register: action.payload,
      };

    case POST_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    //user manual
    case USER_MANUAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case USER_MANUAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userManual: action.payload,
      };

    case USER_MANUAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    //clear incide image upload
    case CLEAR_INCIDENT_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLEAR_INCIDENT_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        clearIncidentImageUpload: action.payload,
      };

    case CLEAR_INCIDENT_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CLEAR_INCIDENT_IMAGE_REMOVE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLEAR_INCIDENT_IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        clearIncidentImageRemove: action.payload,
      };

    case CLEAR_INCIDENT_IMAGE_REMOVE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };


      case CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          clearServiceImageRemove: action.payload,
        };
  
      case CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };

      case CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          clearIncidentExpenditureImageRemove: action.payload,
        };
  
      case CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };

    case CLEAR_INCIDENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLEAR_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        clearIncident: action.payload,
      };

    case CLEAR_INCIDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case SERVICE_REQUEST_IMAGE_REMOVAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case SERVICE_REQUEST_IMAGE_REMOVAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        serviceRequestImageRemove: action.payload,
      };

    case SERVICE_REQUEST_IMAGE_REMOVAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case ASSET_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        assetDetails: action.payload,
      };

    case ASSET_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case RECORD_INCIDENT_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case RECORD_INCIDENT_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        recordIncidentImage: action.payload,
      };

    case RECORD_INCIDENT_IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case RECORD_INCIDENT_IMAGE_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case RECORD_INCIDENT_IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        recordIncidentImageDelete: action.payload,
      };

    case RECORD_INCIDENT_IMAGE_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CREATE_INCIDENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        createIncident: action.payload,
      };

    case CREATE_INCIDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_INCIDENT_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_INCIDENT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        incidentHistory: action.payload,
      };

    case GET_INCIDENT_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case GET_CALIBRATED_REQUEST:
      return { ...state, isLoading: true, isError: false };

    case GET_CALIBRATED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        calibratedList: action.payload,
      };

    case GET_CALIBRATED_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case GET_NOTCALIBRATED_REQUEST:
      return { ...state, isLoading: true, isError: false };

    case GET_NOTCALIBRATED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        notCalibrated: action.payload,
      };

    case GET_NOTCALIBRATED_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_NOTREQUIRED_REQUEST:
      return { ...state, isLoading: true, isError: false };

    case GET_NOTREQUIRED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        calibrateNotRequired: action.payload,
      };

    case GET_NOTREQUIRED_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_DASHBOARD_REQUEST:
      return { ...state, isLoading: true, isError: false };

    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        dashboard: action.payload,
      };

    case GET_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case DEPARTMENT_ASSET_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DEPARTMENT_ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        departmentAssetDetails: action.payload,
      };

    case DEPARTMENT_ASSET_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case DEPARTMENT_USER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DEPARTMENT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        departmentUserList: action.payload,
      };

    case DEPARTMENT_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case USER_RETRIEVE_BY_BRANCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case USER_RETRIEVE_BY_BRANCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userRetrieveByBranch: action.payload,
      };

    case USER_RETRIEVE_BY_BRANCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    //Report

    case SERVICE_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case SERVICE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        serviceReport: action.payload,
      };

    case SERVICE_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case REQUEST_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case REQUEST_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        request: action.payload,
      };

    case REQUEST_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case INCIDENT_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case INCIDENT_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        incidentReport: action.payload,
      };

    case INCIDENT_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CALIBRATION_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CALIBRATION_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        calibrationReport: action.payload,
      };

    case CALIBRATION_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case GET_PM_ASSET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_PM_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        getPmAssetData: action.payload,
      };

    case GET_PM_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case POST_PM_CHECKLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_PM_CHECKLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        postPmCheckListData: action.payload,
      };

    case POST_PM_CHECKLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case PM_CHECKLIST_PDF_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case PM_CHECKLIST_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        pmChecklistPdf: action.payload,
      };

    case PM_CHECKLIST_PDF_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CLOSED_INCIDENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLOSED_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        closedIncident: action.payload,
      };
    case CLOSED_INCIDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case OPEN_INCIDENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case OPEN_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        openIncident: action.payload,
      };
    case OPEN_INCIDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case WARRANTY_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case WARRANTY_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        warrantyStatus: action.payload,
      };
    case WARRANTY_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case REQUEST_WARRANTY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case REQUEST_WARRANTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        requestWarranty: action.payload,
      };
    case REQUEST_WARRANTY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case PROFILE_EDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case PROFILE_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        profileEdit: action.payload,
      };
    case PROFILE_EDIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CLEAR_SERVICE_IMAGE_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLEAR_SERVICE_IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        clearServiceImageDel: action.payload,
      };
    case CLEAR_SERVICE_IMAGE_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_INVALID_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_INVALID_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        invalidToken: action.payload,
      };
    case GET_INVALID_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CLEAR_SERVICE_RECEIPT_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLEAR_SERVICE_RECEIPT_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        clearServiceReceipt: action.payload,
      };
    case CLEAR_SERVICE_RECEIPT_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CLEAR_SERVICE_RECEIPT_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CLEAR_SERVICE_RECEIPT_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        clearServiceReceiptDel: action.payload,
      };
    case CLEAR_SERVICE_RECEIPT_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case BIOMEDICAL_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case BIOMEDICAL_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        bioMedicalServices: action.payload,
      };
    case BIOMEDICAL_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case GET_AUTHENTICATION_ERROR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        authenticationError: action.payload,
      };

    case CSV_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CSV_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        csvFileUpload: action.payload,
      };
    case CSV_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case DELETE_CSV_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_CSV_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        deleteCsvFile: action.payload,
      };
    case DELETE_CSV_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CSV_FILE_UPLOAD_SUBMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CSV_FILE_UPLOAD_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        csvFileUploadSubmit: action.payload,
      };
    case CSV_FILE_UPLOAD_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case AMC_CMC_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case AMC_CMC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        AmcCmcData: action.payload,
      };
    case AMC_CMC_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case AMC_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case AMC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        amcData: action.payload,
      };
    case AMC_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CMC_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CMC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        cmcData: action.payload,
      };
    case CMC_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case ASSET_HISTORY_RETRIEVE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ASSET_HISTORY_RETRIEVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        assetHistoryRetrieveData: action.payload,
      };
    case ASSET_HISTORY_RETRIEVE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
      case ASSET_HISTORY_DROPDOWN_RETRIEVE_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case ASSET_HISTORY_DROPDOWN_RETRIEVE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          assetHistoryDropdownRetrieveData: action.payload,
        };
      case ASSET_HISTORY_DROPDOWN_RETRIEVE_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };
    case ASSET_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ASSET_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        assetHistoryData: action.payload,
      };
    case ASSET_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CREATE_TASK_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        createdTask: action.payload,
      };

    case CREATE_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_TASK_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        getTask: action.payload,
      };

    case GET_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_VENDOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_VENDOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        getVendor: action.payload,
      };

    case GET_VENDOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case POST_VENDOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_VENDOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        postVendor: action.payload,
      };

    case POST_VENDOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case POST_VENDOR_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_VENDOR_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        postVendorService: action.payload,
      };

    case POST_VENDOR_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_VENDOR_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_VENDOR_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        getVendorService: action.payload,
      };

    case GET_VENDOR_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CHECKOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        checkoutData: action.payload,
      };

    case CHECKOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CHECKIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CHECKIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        checkInData: action.payload,
      };

    case CHECKIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CHECKIN_CHECKOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CHECKIN_CHECKOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        checkInOutData: action.payload,
      };

    case CHECKIN_CHECKOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CHECKIN_CHECKOUT_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CHECKIN_CHECKOUT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        checkInOutDataDetail: action.payload,
      };

    case CHECKIN_CHECKOUT_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    // case REFRESH_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     datas: action.data,
    //   };

    case CALENDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CALENDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        calenderData: action.payload,
      };

    case CALENDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CHECKIN_PDF_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CHECKIN_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        checkInPdfData: action.payload,
      };

    case CHECKIN_PDF_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case AMC_CMC_REQ_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case AMC_CMC_REQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        amcCmcReqData: action.payload,
      };

    case AMC_CMC_REQ_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CALIBRATION_REQ_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CALIBRATION_REQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        calibrationReq: action.payload,
      };

    case CALIBRATION_REQ_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case ASSET_ADD_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ASSET_ADD_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        addAssetReport: action.payload,
      };

    case ASSET_ADD_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case ASSET_DELETE_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ASSET_DELETE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        deleteAssetReport: action.payload,
      };

    case ASSET_DELETE_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case WARRANTY_REQUEST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case WARRANTY_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        warrantyRequest: action.payload,
      };

    case WARRANTY_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case WARRANTY_TASK_COMPLETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case WARRANTY_TASK_COMPLETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        warrantyTaskComplete: action.payload,
      };

    case WARRANTY_TASK_COMPLETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case AMC_CMC_TASK_COMPLETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case AMC_CMC_TASK_COMPLETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        AmcCmcTaskComplete: action.payload,
      };

    case AMC_CMC_TASK_COMPLETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CALIBRATION_TASK_COMPLETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CALIBRATION_TASK_COMPLETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        calibrationTaskComplete: action.payload,
      };

    case CALIBRATION_TASK_COMPLETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case UPDATE_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        updateReports: action.payload,
      };

    case UPDATE_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CREATE_SUBUSER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_SUBUSER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        createSubUser: action.payload,
      };

    case CREATE_SUBUSER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case BULK_ASSET_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case BULK_ASSET_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        bulkAssetDelete: action.payload,
      };

    case BULK_ASSET_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case BULK_DEPARTMENT_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case BULK_DEPARTMENT_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        bulkDepartmentUpdate: action.payload,
      };

    case BULK_DEPARTMENT_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case RETRIEVE_ASSET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case RETRIEVE_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        retrieveAsset: action.payload,
      };

    case RETRIEVE_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

      case RETRIEVE_SERVICE_ASSET_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case RETRIEVE_SERVICE_ASSET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          retrieveServiceAsset: action.payload,
        };
  
      case RETRIEVE_SERVICE_ASSET_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };


    case USER_DETAIL_CHECK_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case USER_DETAIL_CHECK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userDetailCheck: action.payload,
      };

    case USER_DETAIL_CHECK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case CREATE_SUPERADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_SUPERADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        createSuperAdmin: action.payload,
      };

    case CREATE_SUPERADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case ALL_USER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ALL_USER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        usersList: action.payload,
      };

    case ALL_USER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CREATE_SUBUSER_TO_SUPERADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_SUBUSER_TO_SUPERADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        createSuperAdmin: action.payload,
      };

    case CREATE_SUBUSER_TO_SUPERADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case UPDATE_SUBUSER_TO_SUPERADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPDATE_SUBUSER_TO_SUPERADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        updateSuperAdmin: action.payload,
      };

    case UPDATE_SUBUSER_TO_SUPERADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case POST_NOTIFICATION_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_NOTIFICATION_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        notificationToken: action.payload,
      };

    case POST_NOTIFICATION_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_NEW_ASSETS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_NEW_ASSETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        allAssetsNew: action.payload,
      };

    case GET_NEW_ASSETS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_DASHBOARD_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        dashboardList: action.payload,
      };

    case GET_DASHBOARD_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_DASHBOARD_LIST2_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_DASHBOARD_LIST2_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        dashboardList2: action.payload,
      };

    case GET_DASHBOARD_LIST2_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case GET_MONTHLY_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_MONTHLY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        monthlyReport: action.payload,
      };

    case GET_MONTHLY_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case ADD_ACCESSORY_UPLOAD_FILE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ADD_ACCESSORY_UPLOAD_FILE_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPLOAD_PROFILE_IMAGE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ADD_ACCESSORY_UPDATE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ADD_ACCESSORY_UPDATE_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ADD_ACCESSORY_UPDATE_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataHistory: action.payload.accessory_history || [],
      };

    case FETCH_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    // case FETCH_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     data: {
    //       ...state.data,
    //       ...action.payload,
    //       accessoryname: action.payload.accessoryname,
    //       accessoryprice: action.payload.accessoryprice,
    //       accessoryquantity: action.payload.accessoryquantity,
    //       accessoryimages: action.payload.accessoryimages,
    //     },
    //     dataHistory: action.payload.accessory_history || [],
    //     accessoryHistoryExpenditure:
    //       action.payload.accessory_history_expenditure || 0,
    //     downTime: action.payload.down_time || {},
    //     logoUrl:action.payload,
    //     error: "",
    //   };

    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...action.payload,
          accessoryname: [...action.payload.accessoryname],
          accessoryprice: [...action.payload.accessoryprice],
          accessoryquantity: [...action.payload.accessoryquantity],
          accessoryimages: [...action.payload.accessoryimages],
        },
        dataHistory: action.payload.accessory_history || [],
        accessoryHistoryExpenditure:
          action.payload.accessory_history_expenditure || 0,
        downTime: action.payload.down_time || {},
        logoUrl:action.payload,
        error: "",
      };

    case FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload,
      };

    case DOWNLOAD_PDF_REQUEST_HISTORY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DOWNLOAD_PDF_SUCCESS_HISTORY:
      return {
        ...state,
        loading: false,
        pdfImgHistory: action.payload || {},
      };
    case DOWNLOAD_PDF_FAILURE_HISTORY:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_REQUEST_DASHBOARD:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_REQUEST_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        requestDashboardData: action.payload,
      };

    case GET_REQUEST_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case SELL_ONLINE_ASSET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case SELL_ONLINE_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        sellOnlineData: action.payload,
      };

    case SELL_ONLINE_ASSET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case SET_NAVIGATION_SOURCE:
      return {
        ...state,
        fromPage: action.payload,
      };

      case GET_RETRIEVE_INCIDENT_ASSETS_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case GET_RETRIEVE_INCIDENT_ASSETS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          dropdownincidentasset: action.payload,
        };
  
      case GET_RETRIEVE_INCIDENT_ASSETS_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };

        case CHECKOUT_ASSET_REQUEST:
          return {
            ...state,
            isLoading: true,
            isError: false,
          };
    
        case CHECKOUT_ASSET_SUCCESS:
          return {
            ...state,
            isLoading: false,
            isError: false,
            checkasset: action.payload,
          };
    
        case CHECKOUT_ASSET_FAILURE:
          return {
            ...state,
            isLoading: false,
            isError: true,
            error: action.payload,
          };

          // not working

    case NOT_WORKING_ASSETS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case NOT_WORKING_ASSETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        notworking: action.payload,
      };

    case NOT_WORKING_ASSETS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

        //  Discarded

    case DISCARDED_ASSETS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DISCARDED_ASSETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        discardedassets: action.payload,
      };

    case DISCARDED_ASSETS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    

    default:
      return {
        ...state,
      };
  }
};
