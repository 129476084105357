// import React, { useEffect } from "react";
// import {
//   Button,
//   Typography,
//   Box,
//   TableContainer,
//   Table,
//   TableHead,
//   TableCell,
//   TableBody,
//   TableRow,
//   TextField,
//   Dialog,
//   MenuItem,
//   Select,
//   OutlinedInput,
//   styled,
//   FormHelperText,
//   useTheme,
//   useMediaQuery,
//   Menu,
// } from "@mui/material";

// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   // WarrantyRequest,
//   requestWarranty,
//   getUserData,
//   warrantyStatus,
//   departmentUserDetails,
// } from "../redux/nbmData/action";

// import CloseIcon from "@mui/icons-material/Close";
// import WarrantyModal from "./WarrantyModal";

// const headingCellStyles = {
//   color: "#1746A2",

//   whiteSpace: "nowrap",
// };

// const HelperText = styled(FormHelperText)(({ theme }) => ({
//   color: "red",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const WarrantyRequestModal = ({ open, setopen, data }) => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
//   let userIds =
//     JSON.parse(sessionStorage.getItem("userData")) ||
//     JSON.parse(localStorage.getItem("userData"));
//   let userBranch =
//     JSON.parse(sessionStorage.getItem("userBranch")) ||
//     JSON.parse(localStorage.getItem("userBranch"));
//   let userAccess =
//     JSON.parse(sessionStorage.getItem("user_access")) ||
//     JSON.parse(localStorage.getItem("user_access"));
//   const [currentUser, setCurrentUser] = useState("");
//   const theme = useTheme();
//   const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   let text = "Warranty requested";

//   const warrantyStatusData = useSelector(
//     (store) => store.nbmData?.requestWarranty
//   );

//   const userData = useSelector((store) => store.nbmData.departmentUserList);
//   const dispatch = useDispatch();
//   const [year, setYear] = useState("");
//   const [yearError, setYearError] = useState("");
//   const [toggle, setToggle] = useState(false);

//   const [openModal, setOpenModal] = React.useState(false);
//   const handleOpenModal = () => setOpenModal(true);
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleButtonClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleMenuItemClick = (user) => {
//     handleMenuClose();

//     setCurrentUser(user);
//   };
//   const handleCloseModal = () => {
//     setToggle(false);
//     setYear("");
//     setCurrentUser("");
//     handleClose();
//   };


//   const handleYear = (e) => {
//     const value = e.target.value;
//     const regex = /^[1-9]\d*$/;
//     setYear(value);
//     if (regex.test(value) || value === "") {
//       setYear(value);
//       setYearError("");
//     }
//     if (value === "") {
//       setYearError("Please fill the field");
//     }
//   };

//   const handleSelectChange = (e) => {
//     setCurrentUser(e.target.value);
//   };

//   const handleClick = () => {
//     if (year !== "" && yearError === "") {
//       let dataToSend = {
//         hospid: userIds.hospid,
//         userid: userIds.userid,
//         hospname: userIds.hospname,
//         hospcity: userIds.city,
//         req_id: currentUser === "" ? userIds.userid : currentUser?.userid,
//         reqname: currentUser === "" ? userIds.username : currentUser?.username,
//         reqemail: currentUser === "" ? userIds.email : currentUser?.email,
//         reqphone: currentUser === "" ? userIds.mobile : currentUser?.mobile,
//         assetid: data?.assetid,
//         assetname: data?.name,
//         brand: data?.brand,
//         depart: data?.department,
//         dop: data?.dop,
//         expdate: data?.exp_date,
//         req_years: year,
//         branchid: userBranch?.id,
//         branchname: userBranch.branch,
//         depart_id: data.department_id,
//         // depart_id: userAccess.departmentids,
//       };

//       dispatch(requestWarranty(dataToSend));

//       setYear("");
//       setCurrentUser("");

//       setToggle(true);
//     }
//     if (year === "") {
//       setYearError("Please fill the field");
//     }
//   };



//   useEffect(() => {
//     let data = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       branchid: userBranch?.id,
//       depart_ids: userAccess.departmentids,
//     };

//     dispatch(warrantyStatus(data));
//   }, [dispatch, toggle]);

//   const handleClose = () => {
//     setopen(false);
//     setYear("");
//     setCurrentUser("");
//     setYearError("");
//   };
//   useEffect(() => {
//     if (data && data.department_id) {
//       let datas = {
//         adminid: userIds.userid,
//         hosp_id: userIds.hospid,
//         branchid: userBranch.id,
//         departid: data.department_id,
//       };
//       dispatch(departmentUserDetails(datas));
//     }
//   }, [dispatch, data]);
//   return (
//     <Dialog
//       open={open}
//       onClose={handleClose}
//       maxWidth="md"
//       fullWidth
//       sx={{
//         borderRadius: "15px",
//         "& .MuiDialog-paper": {
//           borderRadius: "15px",
//         },
//       }}>
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           borderRadius: "15px 15px 0px 0px",
//           padding: "8px",
//           backgroundColor: "red",
//         }}>
//         <Typography
//           variant={isMobileScreen ? "" : "h4"}
//           sx={{
//             color: "white",
//             backgroundColor: "red",
//             width: "90%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}>
//           Warranty Status
//         </Typography>
//         {isMobile ? (
//           <Typography
//             onClick={handleClose}
//             variant="body2"
//             sx={{
//               color: "white",
//               backgroundColor: "red",
//               height: "100%",
//               cursor: "pointer",
//               paddingRight: "2%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}>
//             <CloseIcon />
//           </Typography>
//         ) : (
//           <Typography
//             onClick={handleClose}
//             variant="body2"
//             sx={{
//               color: "white",
//               backgroundColor: "red",
//               height: "100%",
//               cursor: "pointer",
//             }}>
//             Close
//           </Typography>
//         )}
//       </Box>
//       <Box>
//         <TableContainer
//           sx={{
//             width: "100%",

//             maxHeight: 540,
//           }}>
//           <Table sx={{ width: "100%" }} stickyHeader>
//             <TableHead>
//               <TableRow sx={{ bgcolor: "#EFF5FE" }}>
//                 <TableCell style={headingCellStyles}>Asset Name</TableCell>
//                 <TableCell style={headingCellStyles}>Asset Code</TableCell>
//                 <TableCell style={headingCellStyles}>Department</TableCell>
//                 <TableCell
//                   style={headingCellStyles}
//                   sx={{ textAlign: "center" }}>
//                   {" "}
//                   Duration{" "}
//                 </TableCell>
//                 <TableCell
//                   style={headingCellStyles}
//                   sx={{ textAlign: "center" }}>
//                   {" "}
//                   Assign{" "}
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               <TableRow key={"index"}>
//                 <TableCell>{data.name}</TableCell>
//                 <TableCell>{data.assetcode}</TableCell>
//                 <TableCell>{data.department}</TableCell>
//                 <TableCell>
//                   {data.warrantstatus !== "Requested" && (
//                     <TextField
//                       type="number"
//                       placeholder="Enter year"
//                       value={year}
//                       size="small"
//                       inputProps={{ min: 0, style: { textAlign: "center" } }}
//                       onChange={handleYear}
//                       style={{ width: "130px", textAlign: "center" }}
//                     />
//                   )}
//                   <HelperText>{yearError}</HelperText>
//                 </TableCell>

//                 <TableCell>
//                   <Button
//                     variant="outlined"
//                     size="small"
//                     onClick={handleButtonClick}
//                     style={{
//                       width: "150px",
//                       color: "#1746A2",
//                       border: "none",
//                       textDecoration: "underline",
//                       textTransform: "none",
//                     }}>
//                     {currentUser ? currentUser.username : "Select User"}
//                   </Button>

//                   <Menu
//                     anchorEl={anchorEl}
//                     open={Boolean(anchorEl)}
//                     onClose={handleMenuClose}>
//                     <MenuItem value="" disabled>
//                       <Typography>Select User</Typography>
//                     </MenuItem>

//                     {userData?.map((user) => (
//                       <MenuItem
//                         key={user.userid}
//                         onClick={() => handleMenuItemClick(user)}>
//                         {user.username}
//                       </MenuItem>
//                     ))}
//                   </Menu>
//                 </TableCell>

//                 <TableCell>
//                   {
//                     <Button
//                       onClick={handleClick}
//                       variant="contained"
//                       sx={{
//                         bgcolor:
//                           data.warrantstatus === "Requested"
//                             ? "green"
//                             : "#FF4B4B",
//                         textTransform: "none",
//                       }}>
//                       {data.warrantstatus === "Requested"
//                         ? "Requested"
//                         : "Request"}
//                     </Button>
//                   }
//                 </TableCell>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>

//       <WarrantyModal
//         openModal={openModal}
//         handleOpenModal={handleOpenModal}
//         handleCloseModal={handleCloseModal}
//         response={warrantyStatusData}
//         text={text}
//         toggle={toggle}
//         setClose={setOpenModal}
//       />
//     </Dialog>
//   );
// };

// export default WarrantyRequestModal;



import React, { useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Dialog,
  MenuItem,
  Select,
  styled,
  FormHelperText,
  useTheme,
  useMediaQuery,
  Menu,
  Stack,
  IconButton
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AmcCmc,
  AmcCmcReq,
  CalibrationReq,
  departmentUserDetails,
  getCalibrated,
    WarrantyRequest,
  requestWarranty,
  getUserData,
  warrantyStatus,
} from "../redux/nbmData/action";

import SuccessPopUp from "./SuccessPopUp";
import { useNavigate } from "react-router-dom";

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const WarrantyRequestModal = ({
  open,
  setopen,
  data,
  selectedOption,
  text,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const [currentUser, setCurrentUser] = useState("");
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const warrantyStatusData = useSelector(
    (store) => store.data?.warrantyRequest
  );

  const userData = useSelector((store) => store.data.departmentUserList);
  const dispatch = useDispatch();
  const [year, setYear] = useState("");
  const [yearError, setYearError] = useState("");
  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate()

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (user) => {
    handleMenuClose();

    setCurrentUser(user);
  };
  const handleCloseModal = () => {
    setToggle(false);
    setYear("");
    setCurrentUser("");
    setOpenModal(false)
    handleClose();
  };

  const handleYear = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setYear(value);
    if (regex.test(value) || value === "") {
      setYear(value);
      setYearError("");
    }
    if (value === "") {
      setYearError("Please fill the field");
    }
  };

  const handleSelectChange = (e) => {
    setCurrentUser(e.target.value);
  };

  // const handleClick = () => {
  //   if (year !== "" && yearError === "") {
  //     let dataToSend = {
  //       hospid: userIds.hospid,
  //       userid: userIds.userid,
  //       hospname: userIds.hospname,
  //       hospcity: userIds.city,
  //       req_id: currentUser === "" ? userIds.userid : currentUser?.userid,
  //       reqname: currentUser === "" ? userIds.username : currentUser?.username,
  //       reqemail: currentUser === "" ? userIds.email : currentUser?.email,
  //       reqphone: currentUser === "" ? userIds.mobile : currentUser?.mobile,
  //       assetid: data?.assetid,
  //       assetname: data?.name,
  //       brand: data?.brand,
  //       depart: data?.department,
  //       dop: data?.dop,
  //       expdate: data?.exp_date,
  //       req_years: year,
  //       branchid: userBranch?.id,
  //       branchname: userBranch.branch,
  //       depart_id: data.department_id,
  //       // depart_id: userAccess.departmentids,
  //     };
  //     {
  //       if (selectedOption === "calibration") {
  //         dispatch(CalibrationReq(dataToSend));
  //       } else if (selectedOption === "warranty") {
  //         dispatch(WarrantyRequest(dataToSend));
  //       } else if (selectedOption === "amc_cmc") {
  //         dispatch(AmcCmcReq(dataToSend));
  //       }

  //       setYear("");
  //       setCurrentUser("");

  //       setToggle(true);
  //     }
  //     if (year === "") {
  //       setYearError("Please fill the field");
  //     }
  //   }
  // };
  const handleClick = () => {
    if (year !== "" && yearError === "") {
      let dataToSend = {
        hospid: userIds.hospid,
        userid: userIds.userid,
        hospname: userIds.hospname,
        hospcity: userIds.city,
        req_id: currentUser === "" ? userIds.userid : currentUser?.userid,
        reqname: currentUser === "" ? userIds.username : currentUser?.username,
        reqemail: currentUser === "" ? userIds.email : currentUser?.email,
        reqphone: currentUser === "" ? userIds.mobile : currentUser?.mobile,
        assetid: data?.assetid,
        assetname: data?.name,
        brand: data?.brand,
        depart: data?.department,
        dop: data?.dop,
        expdate: data?.exp_date,
        req_years: year,
        branchid: userBranch?.id,
        branchname: userBranch.branch,
        depart_id: data.department_id,
      };

      if (selectedOption === "calibration") {
        dispatch(CalibrationReq(dataToSend))
          .then(() => {
            let datas = {
              hospid: userIds.hospid,
              userid: userIds.userid,
              branchid: userBranch?.id,
              depart_ids: userAccess.departmentids,
            };
            dispatch(getCalibrated(datas));
          })
          .catch((error) => {});
      } else if (selectedOption === "warranty") {
        dispatch(WarrantyRequest(dataToSend))
          .then(() => {
            let datas = {
              hospid: userIds.hospid,
              userid: userIds.userid,
              branchid: userBranch?.id,
              depart_ids: userAccess.departmentids,
            };
            dispatch(warrantyStatus(datas));
          })
          .catch((error) => {});
      } else if (selectedOption === "amc_cmc") {
        dispatch(AmcCmcReq(dataToSend))
          .then(() => {
            let datas = {
              hospid: userIds.hospid,
              userid: userIds.userid,
              branchid: userBranch?.id,
              depart_ids: userAccess.departmentids,
            };
            dispatch(AmcCmc(datas));
          })
          .catch((error) => {});
      }

      setYear("");
      setCurrentUser("");
      setToggle(true);
      setOpenModal(true);
    } else if (year === "") {
      setYearError("Please fill the field");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(warrantyStatus(data));
  }, [dispatch, toggle]);

  const handleClose = () => {
    setopen(false);
    setYear("");
    setCurrentUser("");
    setYearError("");
  };
  useEffect(() => {
    if (data && data.department_id) {
      let datas = {
        adminid: userIds.userid,
        hosp_id: userIds.hospid,
        branchid: userBranch.id,
        departid: data.department_id,
      };
      dispatch(departmentUserDetails(datas));
    }
  }, [dispatch, data]);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth sx={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          padding: "8px",
          backgroundColor: "rgba(27, 37, 53, 1)",
        }}>
        <Typography
          variant="h6"
          sx={{
            color: "white",
            backgroundColor: "rgba(27, 37, 53, 1)",
            width: "95%",
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            textAlign: "start",
          }}>
          {text}
        </Typography>
        {isMobile ? (
          <IconButton
            onClick={handleClose}
            variant="body2"
            sx={{
              color: "white",
              backgroundColor: "red",
              height: "100%",
              cursor: "pointer",
              paddingRight: "2%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={handleClose}
            variant="body2"
            sx={{
              color: "white",
              backgroundColor: "rgba(27, 37, 53, 1)",
              height: "100%",
              cursor: "pointer",
            }}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",

            maxHeight: 540,
          }}>
          <Table sx={{ width: "100%" }} stickyHeader>
            <TableHead>
              <TableRow sx={{ bgcolor: "#EFF5FE" }}>
                <TableHeading>Asset Name</TableHeading>
                <TableHeading>Asset Code</TableHeading>
                <TableHeading>Department</TableHeading>
                <TableHeading sx={{ textAlign: "center" }}>
                  {" "}
                  Duration{" "}
                </TableHeading>
                <TableHeading sx={{ textAlign: "center" }}>
                  {" "}
                  Assign{" "}
                </TableHeading>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={"index"}>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.assetcode}</TableCell>
                <TableCell>{data.department}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {data.warrantstatus !== "Requested" && (
                    <TextField
                      type="number"
                      placeholder="No of years"
                      value={year}
                      size="small"
                      sx={{
                        background: "rgba(244, 246, 246, 1)",
                        "& fieldset": { border: "none" },
                        height: "40px",
                        textAlign: "center",
                        input: {
                          textAlign: "center",
                          color: "rgba(70, 144, 255, 1)",
                        },
                      }}
                      inputProps={{
                        sx: {
                          "&::placeholder": {
                            color: "rgba(70, 144, 255, 1)",
                            opacity: 1,
                            fontSize: "small",
                          },
                        },
                      }}
                      onChange={handleYear}
                      style={{ width: "130px", textAlign: "center" }}
                    />
                  )}
                  <HelperText>{yearError}</HelperText>
                </TableCell>

                <TableCell>
                  <Button
                    endIcon={<KeyboardArrowDownIcon />}
                    variant="contained"
                    onClick={handleButtonClick}
                    style={{
                      width: "150px",
                      color: "rgba(70, 144, 255, 1)",
                      boxShadow: "none",
                      height: "40px",
                      textTransform: "none",
                      background: "rgba(244, 246, 246, 1)",
                    }}>
                    {currentUser ? currentUser.username : "Select User"}
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}>
                    <MenuItem value="" disabled>
                      <Typography>Select User</Typography>
                    </MenuItem>

                    {userData?.map((user) => (
                      <MenuItem
                        key={user.userid}
                        onClick={() => handleMenuItemClick(user)}>
                        {user.username}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
         
        </TableContainer>
        <Stack
            sx={{  alignItems: "flex-end", padding: "10px" }}>
            <Button
              onClick={handleClick}
              variant="contained"
              sx={{
                bgcolor: "rgba(70, 144, 255, 1)",

                textTransform: "none",
                width: "200px",
                borderRadius: "22px",
                marginRight: "20px",
              }}>
              Request
            </Button>
          </Stack>
      </Box>

      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"Your request has been successfully processed.   "}
        subText2={" "}
        toggle={toggle}
      />
    </Dialog>
  );
};

export default WarrantyRequestModal;
