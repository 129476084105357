import React from "react";
import { useLocation } from "react-router-dom";

import PublicRoute from "./PublicRoute";

import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../components/LoadingComponent";

import ChooseRoute from "./ChooseRoute";
import ErrorModal from "../components/ErrorModal";

import TimeOut from "../components/TimeOut";
import TimeOutModal from "../components/TimeOutModal";
import AccessCheckModal from "../components/AccessCheckModal";
import Notification2 from "../components/Notification2";
import MainSideBar from "../components/MainSideBar";
import MainSideBarNbm from "../components/MainSideBarNbm";

function MainRoutes() {
  const token = localStorage.getItem("userToken");
  const userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const out = sessionStorage.getItem("TimeOut");
  const location = useLocation();
  const path = location.pathname;

  const bmOrNbm =
    sessionStorage.getItem("SbmOrNbm") || localStorage.getItem("bmOrNbm");

  const loading = useSelector((store) => store.data.isLoading);
  const error = useSelector((store) => store.data.error);
  const nbmerror = useSelector((store) => store.nbmData.error);
  const nbmloading = useSelector((store) => store.nbmData.isLoading);

  let publicRoute = !token && <PublicRoute />;
  let pop = out && <TimeOutModal />;
  let Timeout = token && <TimeOut />;
  let Choose = token &&
    userData.city !== "NA" &&
    !bmOrNbm &&
    bmOrNbm !== "BM" &&
    bmOrNbm !== "NBM" && <ChooseRoute />;
  let Biomedical = token && userData.city !== "NA" && bmOrNbm === "BM" && (
    <MainSideBar />
  );
  let NonBiomedical = token && userData.city !== "NA" && bmOrNbm === "NBM" && (
    <MainSideBarNbm />
  );

  return (
    <>
      {loading && bmOrNbm == "BM" && <LoadingComponent />}
      {nbmloading && bmOrNbm == "NBM" && <LoadingComponent />}
      {Object.keys(error || {})?.length > 0 && bmOrNbm == "BM" && <ErrorModal />}
      {Object.keys(nbmerror || {})?.length > 0 && bmOrNbm == "NBM" && <ErrorModal />}
      {publicRoute}
      {Choose}
      {Biomedical}
      {NonBiomedical}
      {Timeout}
      {pop}
      <AccessCheckModal />
      {token && <Notification2 />}
    </>
  );
}

export default MainRoutes;
