import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const IncidentGraphComponent = ({ data, date, dataValue, handleData, onBarClick }) => {
  console.log(data)
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  // Transform data for incidents
  const transformedData = data.map((item, index) => ({
    name: item[0],  // Name (Day, Month, or Year)
    incident: parseInt(item[1]),  // Incident Count
    id: index  // ID
  }));

  const maxValue = Math.max(...transformedData.map((item) => item.incident));
  const totalPages = Math.ceil(transformedData.length / itemsPerPage);

  const paginatedData = transformedData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleBarClick = (item) => {
    const formattedValue = (item.id + 1).toString().padStart(2, '0'); // Convert to "01", "02", etc.
    if (onBarClick) {
      onBarClick(formattedValue);
    //   handleData(); // Pass the formattedValue to the parent handler
    }
    if (dataValue) {
      dataValue(item.incident);
    //   handleData(); // Call dataValue if provided
    }
  };

  const handlePrevClick = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  return (
    <div style={{ width: "100%", height: "100%", textAlign: "start" }}>
      <ResponsiveContainer width="100%">
        <BarChart data={paginatedData}>
          <XAxis
            dataKey="name"
            tick={{ fontSize: 12, fill: "#000000", fontWeight: 500 }}
          />
          <YAxis
            width={25}
            tick={{ fontSize: 12, fill: "#000000", fontWeight: 500 }}
          />
          <Tooltip cursor={{ fill: "transparent" }} />
          <Bar dataKey="incident" fill="#000000" barSize={20}>
            {paginatedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                cursor="pointer"
                fill={entry.incident === maxValue ? "#FF6B00" : "#000000"}
                onClick={() => handleBarClick(entry)}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <Box display="flex" justifyContent="center" alignItems="center" sx={{ margin: '-10px 0' }}>
        <ArrowBackIcon 
          onClick={handlePrevClick} 
          sx={{ color: currentPage === 0 ? '#cccccc' : '#000000', cursor: currentPage > 0 ? 'pointer' : 'default' }} 
        />
        {Array.from({ length: totalPages }).map((_, index) => (
          <React.Fragment key={index}>
            {currentPage === index ? (
              <HorizontalRuleIcon
                onClick={() => setCurrentPage(index)}
                style={{ color: "#000000" }}
              />
            ) : (
              <FiberManualRecordIcon
                onClick={() => setCurrentPage(index)}
                style={{ color: '#cccccc', fontSize: '10px', cursor: 'pointer' }}
              />
            )}
          </React.Fragment>
        ))}
        <ArrowForwardIcon 
          onClick={handleNextClick} 
          sx={{ color: currentPage === totalPages - 1 ? '#cccccc' : '#000000', cursor: currentPage < totalPages - 1 ? 'pointer' : 'default' }} 
        />
      </Box>
    </div>
  );
};

export default IncidentGraphComponent;
