import styled from "@emotion/styled";
import { useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  FormHelperText,
  IconButton,
  Input,
  Modal,
  Stack,
  TableFooter,
  TableHead,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EastIcon from "@mui/icons-material/East";
import pdfImage from "../images/pdfImage.png";
import jsPDF from "jspdf";
import React, { useEffect, useState, lazy } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import comingSoon from "../images/comingSoon.png";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment/moment";


import { useLocation } from "react-router-dom";

import {
  DownloadPDF,
  DownloadPDFHistory,
  addAccesoryUpdateHistory,
  addAccesoryUploadImageHistory,
  assetDetailsData,
  deleteAsset,
  deleteImageAddAccessoryHistory,
  fetchDataFromAPI,
} from "../redux/nbmData/action";

import UpdateReport from "./UpdateReport";
import ViewImageModal from "../components/ViewImageModal";
import { Icon } from "@react-pdf-viewer/core";
import CloseIconNew from "@mui/icons-material/Close";
import { useAddAccessory } from "../hooks/useAddAccesory";
import { v4 as uuid } from "uuid";
import {
  addAccesoryUploadImage,
  deleteImageAddAccessory,
  addAccesoryUpdate,
} from "../redux/nbmData/action";

const InnerImageBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",
  justifyContent: "space-evenly",
  margin: "10px",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
// const Label = styled(Typography)(({ theme }) => ({
//   color: "rgba(27, 37, 53, 1)",
//   fontWeight: 700,
// }));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "230px",
  height: "230px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "230px",
    height: "230px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",

  boxShadow: 24,
  p: 4,
  height: "300px",
}));

const InnerWarrantyImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //   border:'6px solid red',

  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "max-content",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "max-content",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "0",
}));

const WarrantyInnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //    border:'2px solid black',
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

const ImageCard = styled(Box)(({ theme }) => ({
  width: "159.84px",
  height: "155px",
}));

const WarrantyImageCard = styled(Box)(({ theme }) => ({
  width: "159px",
  height: "145px",
  //  border:"3px solid black"
}));

const QrBox = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "100px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80px",
    height: "80px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextBox = styled(Box)(({ theme }) => ({
  margin: "5px",
}));

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const SplitCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: "2",
  gap: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",

  gap: "15px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  background: "white",
  height: "50px",
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  background: "white",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidTop = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",

  background: "white",
  // height:'200px',
  padding: "10px",
  gap: "15px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(2,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidTopBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  background: "white",
  height: "auto",
  padding: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidBottom = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "10% 22% 22% 22% 22%",
  alignItems: "center",

  background: "white",
  // height:'50px',
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "20px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MiniQRset = styled(Box)(({ theme }) => ({
  width: "60px",
  height: "60px",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

function AssetDetailPage() {
  const updateReportResponse = useSelector(
    (store) => store.nbmData.updateReports
  );
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [downtime, setDowntime] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });

  const convertTime = () => {
    const { tot_years, tot_months, tot_days, tot_hrs, tot_mins, tot_secs } =
      downtime;
    const totalDuration = moment.duration({
      years: parseInt(tot_years),
      months: parseInt(tot_months),
      days: parseInt(tot_days),
      hours: parseInt(tot_hrs),
      minutes: parseInt(tot_mins),
      seconds: parseInt(tot_secs),
    });

    const parts = [];
    if (totalDuration.years() > 0)
      parts.push(
        `${totalDuration.years()} yr${totalDuration.years() > 1 ? "s" : ""}`
      );
    if (totalDuration.months() > 0)
      parts.push(
        `${totalDuration.months()} month${
          totalDuration.months() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.days() > 0)
      parts.push(
        `${totalDuration.days()} day${totalDuration.days() > 1 ? "s" : ""}`
      );
    if (totalDuration.hours() > 0)
      parts.push(
        `${totalDuration.hours()} hr${totalDuration.hours() > 1 ? "s" : ""}`
      );
    if (totalDuration.minutes() > 0)
      parts.push(
        `${totalDuration.minutes()} min${
          totalDuration.minutes() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.seconds() > 0)
      parts.push(
        `${totalDuration.seconds()} sec${
          totalDuration.seconds() > 1 ? "s" : ""
        }`
      );

    return parts.join(" ");
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenQr = () => {
    setOpen(true);
  };

  const handleCloseQr = () => {
    setOpen(false);
  };

  const [image, setImage] = useState([]);
  const [status, setStatus] = useState(false);
  const [reportImage, setReportImage] = useState([]);
  const [reportImageName, setReportImageName] = useState([]);

  const [zoomImageUrl, setZoomImageUrl] = useState("");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [hidden, setHidden] = React.useState(false);
  const [certificateImage, setCertificateImage] = useState([]);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const params = useParams();
  const dispatch = useDispatch();
  const detailData = useSelector((store) => store.nbmData.assetDetails);

  const [openImage, setOpenImage] = useState(false);

  const [isFormOpen, setIsFormOpen] = useState(false); 

  
  const handleSubmitAndClose = (index) => {
    handleSubmit(); 
    handleRemoveExpenditure(index);
    setIsFormOpen(false); 
  };

  const handleImageOpen = (url) => {
    setZoomImageUrl(url);
    setOpenImage(true);
  };

  const handleImageClose = () => {
    setOpenImage(false);
  };

  const [accessoriesAdded, setAccessoriesAdded] = useState(false);


  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const text = "Asset";
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handlePrevious = () => {
    setCount((prevCount) => {
      if (prevCount === 0) {
        return image?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNext = () => {
    setCount((prevCount) => {
      if (prevCount === image?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleReportPrevious = () => {
    setCount3((prevCount) => {
      if (prevCount === 0) {
        return reportImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleReportNext = () => {
    setCount3((prevCount) => {
      if (prevCount === reportImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handlePreviousCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === 0) {
        return certificateImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNextCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === certificateImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleDelete = () => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
    };

    dispatch(deleteAsset(data, token));
    navigate(-1);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch, updateReportResponse]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = "downloaded_image.jpg";
    link.click();
  };
  // const handleDownload = () => {
  //   // const link = document.createElement("a");
  //   // link.href = url;
  //   // // link.target = "_blank";
  //   // // link.download = "downloaded_image.jpg";
  //   // link.click();
  //   navigate("/m-report");
  // };

  // const handleDownloadHistory = (url) => {
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.target = "_blank";
  //   link.download = url.split("/").pop();
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleDownloadHistory = (fileUrl, title) => {
    dispatch(DownloadPDFHistory(fileUrl, title));
  };

  useEffect(() => {
    setImage("");
    setCertificateImage("");
    setReportImage("");
    setReportImageName("");
  }, []);

  useEffect(() => {
    if (detailData?.imgs) {
      setImage(detailData?.imgs);
      setCertificateImage(detailData?.wimg);
    }
    if (detailData?.reportimgs) {
      setReportImage(detailData?.reportimgs);
    }
    if (detailData?.reportnames) {
      setReportImageName(detailData?.reportnames);
    }
    // setReportImageName
  }, [detailData]);

  const handleClick = () => {
    localStorage.setItem("incidentId", detailData?.incidid);
  };

  const handleDownloadPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";

      const imageX = (pdfWidth - 30) / 2;

      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);

      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;

      pdf.save(`${name}.pdf`);
    };

    img.src = pdfData;
  };

  const handlePrintPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";
      const imageX = (pdfWidth - 30) / 2;
      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);
      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;
      pdf.text(name, pdfWidth / 2, nameY, { align: "center" });
      pdf.text(code, pdfWidth / 2, codeY, { align: "center" });

      const pdfBlob = pdf.output("blob");

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const newWindow = window.open(pdfUrl);

      newWindow.onload = function () {
        newWindow.print();

        URL.revokeObjectURL(pdfUrl);
      };
    };

    img.src = pdfData;
  };

  const handleDownloadPDF2 = (
    logoUrl,
    pdfData,
    name,
    code,
    brand,
    calibdate,
    calibduedate,
    calibmnth,
    calibreq,
    calibstatus,
    cmcamc,
    cmcamcdue,
    cmcamcstart,
    dept,
    dop,
    imgs,
    wimg,
    serialno,
    incidstatus,
    lifeyrs,
    modelno,
    price,
    servstatus,
    soldby,
    status
  ) => {
    if (!pdfData || !logoUrl) return;

    const pdf = new jsPDF();
    const img = new Image();
    img.src = logoUrl;
    img.onload = function () {
      const desiredHeight = 30;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const lineWidth = 0.9 * pageWidth;
      pdf.setFontSize(22);
      pdf.setTextColor("#FF731D");
      const xPosition = (pageWidth - lineWidth) / 2;
      const yPosition = 10;
      const logoYPosition = 10;

      let yTop = yPosition + 7;
      const logoLineY = yPosition + 22; // Adjust this value to move the line above the table
      pdf.setLineWidth(1);
      pdf.setDrawColor("1b76bc");
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY);
      pdf.text(userIds.hospname, lineWidth / 2.3, yTop);

      pdf.setFontSize(10);
      pdf.setTextColor("#1746A2");
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY);
      pdf.text(userIds.city, lineWidth / 2, yTop + 7);
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      const parameterData = [
        ["Name", name],
        ["Code", code],
        ["Brand", brand],
        ["Department", dept],
        ["Status", status],
        ["Calibration Date", calibdate],
        ["Calibration Status", calibstatus],
        ["Useful Life", lifeyrs],
        ["Model No", modelno],
        ["AMC/CMC", cmcamc],
        ["Purchase Date", dop],
        ["Price", price],
        ["Sold by", soldby],
        ["Incident Status", incidstatus],
        ["Service Status", servstatus],
        ["CMC/AMC Start", cmcamcstart],
        ["CMC/AMC Due", cmcamcdue],
        ["Calibration Month", calibmnth],
        ["Calibration Due Date", calibduedate],
        ["Calibration Request", calibreq],
      ];

      pdf.autoTable({
        body: parameterData,
        startY: 40,
        headStyles: {
          fillColor: [26, 188, 156],
          textColor: [255, 255, 255],
        },
      });

      const contentWidth = pdf.internal.pageSize.getWidth();
      const qrCodeX = contentWidth - 70;
      const qrCodeY = pdf.autoTable.previous.finalY + 10; // Position it below the table

      const assetImageX = contentWidth - 70;
      const assetImageY = qrCodeY + 40;

      pdf.addImage(pdfData, "JPEG", qrCodeX, qrCodeY, 25, 25);
      pdf.setTextColor("1b76bc");
      pdf.text("QR Code", qrCodeX, qrCodeY);
      let imageX = assetImageX;
      let imageY = assetImageY + 5;
      const imageWidth = 18;
      const imageHeight = 18;

      pdf.setFontSize(14);
      pdf.setLineWidth(0.5);
      pdf.setTextColor("1b76bc");
      pdf.text("Asset Image", assetImageX, assetImageY + 2);

      for (let i = 0; i < imgs.length; i++) {
        const { imgurl } = imgs[i];
        pdf.setDrawColor("1b76bc");
        pdf.setLineWidth(0.5);

        pdf.rect(imageX, imageY, imageWidth, imageHeight);
        pdf.addImage(imgurl, "JPEG", imageX, imageY, imageWidth, imageHeight);

        imageX += imageWidth + 3;
        if (imageX > contentWidth - imageWidth - 3) {
          imageX = assetImageX;
          imageY += imageHeight + 3;
        }
      }

      if (wimg !== "NA" && wimg.length > 0) {
        const warrantyImageWidth = 18;
        const warrantyImageHeight = 18;
        let warrantyImageX = 20;
        let warrantyImageY = pdf.autoTable.previous.finalY + 10;

        const warrantyImageHeadingX = 20;
        const warrantyImageHeadingY = pdf.autoTable.previous.finalY + 15;

        pdf.setFontSize(14);
        pdf.setLineWidth(0.5);
        pdf.setTextColor("1b76bc");
        pdf.text(
          "Warranty Images",
          warrantyImageHeadingX,
          warrantyImageHeadingY - 7
        );

        for (let i = 0; i < wimg.length; i++) {
          const { imgurl } = wimg[i];

          // Check if imgurl is a PDF
          if (imgurl.toLowerCase().includes(".pdf")) {
            // Handle PDF images if needed
          } else {
            pdf.setDrawColor("1b76bc");
            pdf.setLineWidth(0.5);
            pdf.rect(
              warrantyImageX,
              warrantyImageY,
              warrantyImageWidth,
              warrantyImageHeight
            );

            pdf.addImage(
              imgurl,
              "JPEG",
              warrantyImageX,
              warrantyImageY,
              warrantyImageWidth,
              warrantyImageHeight
            );
            warrantyImageY += warrantyImageHeight + 3;
          }
        }
      }

      pdf.save(`${name}.pdf`);
    };

    // Set the source of the image to the logo data
    img.src = logoUrl;
  };

  const shareViaWhatsApp = () => {
    const text = `*Asset Details*\n\n*Page URL:* https://vajra.software${window.location.pathname}\n\n*Name:* ${detailData.name}\n*Code:* ${detailData.code}\n*Serial No:* ${detailData.serialno}\n*Model No:* ${detailData.modelno}\n*Department:* ${detailData.dept}\n*Type:* ${detailData.type}\n*Status:* ${detailData.status}\n*Brand:* ${detailData.brand}\n*Sold By:* ${detailData.soldby}\n*Date of Purchase:* ${detailData.dop}\n*Price:* ${detailData.price}\n\n*Warranty Start Date:* ${detailData.wrntdate}\n*Warranty End Date:* ${detailData.wrntexpdate}\n\n*Calibration Status:* ${detailData.calibstatus}\n*Calibration Date:* ${detailData.calibdate}\n*Calibration Due Date:* ${detailData.calibduedate}\n\n*AMC/CMC:* ${detailData.cmcamc}\n*AMC/CMC Start Date:* ${detailData.cmcamcstart}\n*AMC/CMC End Date:* ${detailData.cmcamcdue}\n\n`;

    const whatsappLink = `https://wa.me?text=${encodeURIComponent(text)}`;
    window.open(whatsappLink, "_blank");
  };
  const AssetDetail = {
    Code: detailData.code,
    "Serial No.": detailData.serialno,
    Department: detailData.dept,
    Status: detailData.status,
  };
  const Specifications = {
    Type: detailData.type,
    "Model Number": detailData.modelno,
    "Useful life ": detailData.lifeyrs,
    "Warranty Expiry":
      detailData.wrntexpdate === "Invalid date" ? "NA" : detailData.wrntexpdate,
    "AMC/CMC": detailData.cmcamc,
    ...(detailData.cmcamc === "AMC" && {
      "AMC Start": detailData.cmcamcstart,
      "AMC Due": detailData.cmcamcdue,
    }),
    ...(detailData.cmcamc === "CMC" && {
      "CMC Start": detailData.cmcamcstart,
      "CMC Due": detailData.cmcamcdue,
    }),
    ...(detailData.cmcamc === "None" && {
      "AMC/CMC Start": detailData.cmcamcstart,
      "AMC/CMC Due": detailData.cmcamcdue,
    }),

    // Calibration: detailData.calibreq,
    Calibration: detailData.calibreq === "NA" ? "Yes" : detailData.calibreq,

    Date: detailData.calibdate,
    "Due Date": detailData.calibduedate,
  };
  const Purchase = {
    Price: detailData.price,
    Date: detailData.dop,
    "Seller Brand ": detailData.brand,
    Representative: detailData.soldby,
    "Seller Contact": detailData.sellermobile,
    "Seller Email ID ": detailData.selleremail,
  };

  const handleOpenService = () => {
    setStatus(true);
  };

  const handleCloseService = () => {
    setStatus(false);
  };

  const handleNavigate = (requested, assetId) => {
    navigate(`/service-request/${assetId}?requested=${requested}`);
  };

  const downloadImage = (imageUrl) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        // setImageSrc(url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "downloaded_image.png");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading the image:", error));
  };

  const printImage = (imageSrc) => {
    if (imageSrc) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<img src="${imageSrc}" onload="window.print();window.close()" />`
      );
      printWindow.document.close();
    } else {
      console.log("Image not downloaded yet.");
    }
  };

  const [totalAmountSpent, setTotalAmountSpent] = useState("");
  const [isTotalAmountSpentError, setIsTotalAmountSpentError] = useState("");
  const [description, setDescription] = useState("");
  const [isDescriptionError, setIsDescriptionError] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isQuantityError, setIsQuantityError] = useState("");
  const fileInputRef = useRef(null);
  const historyFileInputRef = useRef(null);

  const handleDeleteAccessory = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    // dispatch(clearServiceImgDel(data));
    // setFileUrlAccessory(fileUrl?.filter((el) => el?.imageid !== id));
  };

  const handleDescription = (e) => {
    const value = e.target.value.trim();
    const regex = /^[a-zA-Z0-9.,'"\s-]*$/;
    if (value === "") {
      setDescription(value);
      setIsDescriptionError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setDescription(value);
      setIsDescriptionError(null);
    } else {
      setIsDescriptionError("Enter a valid description");
    }
  };

  const handleQuantity = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setQuantity(value);
      setIsQuantityError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setQuantity(value);
      setIsQuantityError(null);
    } else {
      setIsQuantityError("Enter a valid quantity");
    }
  };

  const handleQuantityHistory = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setQuantityHistory(value);
      setIsQuantityErrorHistory("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setQuantityHistory(value);
      setIsQuantityErrorHistory(null);
    } else {
      setIsQuantityErrorHistory("Enter a valid quantity");
    }
  };

  const handleTotalAmountSpent = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError(null);
    } else {
      setIsTotalAmountSpentError("Enter a valid amount");
    }
  };



  const [expenditures, setExpenditures] = useState([]);
  const [newExpenditure, setNewExpenditure] = useState({
    description: "",
    totalAmountSpent: "",
    quanity: "",
    expImage: [{ image: "", imageId: "" }],
  });

  const handleAddExpenditure = () => {
    setExpenditures([...expenditures, { ...newExpenditure }]);
    setNewExpenditure({
      description: "",
      totalAmountSpent: "",
      quanity: "",
      expImage: [{ image: "", imageId: "" }],
    });
    setAssetName("");
    setPrice("");
    setQuantity("");
    setFileUrl([]);
    setIsFormOpen(true);
    setIsAddingAccessory(true);
    setAccessoriesAdded(true);
    setIsFormOpen(true);

  };

  const handleRemoveExpenditure = (index) => {
    const updatedExpenditures = expenditures.filter((_, i) => i !== index);
    setExpenditures(updatedExpenditures);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewExpenditure({ ...newExpenditure, [name]: value });
  };

  const handleTotalAmountSpentObject = (e, index) => {
    handleTotalAmountSpent(e);
    let value = e.target.value;
    let edit = [...expenditures];
    edit[index].totalAmountSpent = value;
    setExpenditures([...edit]);
  };

  const handleDescriptionObject = (e, index) => {
    handleDescription(e);
    let value = e.target.value;
    let edit = [...expenditures];
    edit[index].description = value;
    setExpenditures([...edit]);
  };

  const handleQuantityObject = (e, index) => {
    handleQuantity(e);
    let value = e.target.value;
    let edit = [...expenditures];
    edit[index].quanity = value;
    setExpenditures([...edit]);
  };

  const handleEdit = () => {
    navigate(`/edit-asset/${params.id}`);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const [isPhotoError, setIsPhotoError] = useState(null);
  const [fileUrl, setFileUrl] = useState([]);
  const [assetName, setAssetName] = useState("");
  const [isAssetError, setIsAssetError] = useState(null);
  const [price, setPrice] = useState("");
  const [isPriceError, setIsPriceError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [accessoryNames, setAccessoryNames] = useState([]);
  const [accessoryPrices, setAccessoryPrices] = useState([]);
  const [accessoryQuantities, setAccessoryQuantities] = useState([]);
  const [accessoryImages, setAccessoryImages] = useState([]);
  const [accessoryNamesHistory, setAccessoryNamesHistory] = useState([]);
  const [accessoryPricesHistory, setAccessoryPricesHistory] = useState([]);
  const [accessoryQuantitiesHistory, setAccessoryQuantitiesHistory] = useState(
    []
  );
  const [accessoryImagesHistory, setAccessoryImagesHistory] = useState([]);
  const [addHistory, setAddHistory] = useState(false);
  const [assetNameHistory, setAssetNameHistory] = useState("");
  const [priceHistory, setPriceHistory] = useState("");
  const [quantityHistory, setQuantityHistory] = useState("");
  const [isAssetErrorHistory, setIsAssetErrorHistory] = useState(null);
  const [isPriceErrorHistory, setIsPriceErrorHistory] = useState(null);
  const [isQuantityErrorHistory, setIsQuantityErrorHistory] = useState("");
  const [isPhotoErrorHistory, setIsPhotoErrorHistory] = useState(null);
  const [fileUrlHistory, setFileUrlHistory] = useState([]);
  const [isAddingAccessory, setIsAddingAccessory] = useState(false);

  const assetData = useSelector((store) => store?.nbmData?.data);
  const assetDataHistory = useSelector((store) => store?.nbmData?.dataHistory);

  const isCheckedOut = assetData.check_in_status === "checked_out";

  const assetExpenditure = useSelector(
    (store) => store?.nbmData?.accessoryHistoryExpenditure
  );
  console.log(assetData);

  console.log(assetDataHistory);

  let idSplit = params.id.substring(0, 4);

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    if (singlefile) {
      const fileType = singlefile.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize =
        fileType === "application/pdf" ? 6 * 1024 * 1024 : 4 * 1024 * 1024;

      if (allowedTypes.indexOf(singlefile.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return;
      }

      if (singlefile.size > allowedSize) {
        // setIsPhotoError("Greater than 2 MB can't be uploaded");
        setIsPhotoError(
          fileType === "application/pdf"
            ? "PDF file size cannot exceed 6 MB."
            : "Image file size cannot exceed 4 MB."
        );
        return;
      }

      if (
        singlefile.size > allowedSize &&
        singlefile.type.startsWith("image/")
      ) {
        compressImage(singlefile);
        return;
      } else {
        setIsPhotoError(null);

        if (singlefile.type === "application/pdf") {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            branchid: userBranch?.id,
            imageid: `${uuid()}.${fileType}`,
            fileName: singlefile.name,
            fileType: fileType,
            image: singlefile,
          };

          setFileUrl([sendData]);

          dispatch(addAccesoryUploadImage(sendData, token));
          fileInputRef.current.value = "";
        } else {
          const reader = new FileReader();

          reader.onloadend = () => {
            let sendData = {
              hospid: userIds?.hospid,
              userid: userIds?.userid,
              branchid: userBranch?.id,
              imageid: `${uuid()}.${fileType}`,
              fileName: singlefile.name,
              fileType: fileType,
              image: reader.result,
            };

            setFileUrl([sendData]);

            dispatch(addAccesoryUploadImage(sendData, token));
          };
          reader.readAsDataURL(singlefile);
        }
      }
    }
  };
  const areFieldsValid = () => {
    return (
      assetName.trim() !== "" &&
      price.trim() !== "" &&
      quantity.trim() !== "" &&
      fileUrl.length > 0
    );
  };

  // const handlePhotoUploadHistory = (e) => {
  //   const singlefile = e.target.files[0];

  //   if (singlefile) {
  //     const fileType = singlefile.type.split("/")[1];
  //     const allowedTypes = [
  //       "image/jpeg",
  //       "image/jpg",
  //       "image/png",
  //       "application/pdf",
  //     ];
  //     const allowedSize = 2 * 1024 * 1024;

  //     if (allowedTypes.indexOf(singlefile.type) === -1) {
  //       setIsPhotoErrorHistory(`Can't upload ${fileType} file.`);
  //       return;
  //     }

  //     if (
  //       singlefile.size > allowedSize &&
  //       singlefile.type.startsWith("image/")
  //     ) {
  //       compressImageHistory(singlefile);
  //       return;
  //     } else {
  //       setIsPhotoErrorHistory(null);

  //       if (singlefile.type === "application/pdf") {
  //         let sendData = {
  //           hospid: userIds?.hospid,
  //           userid: userIds?.userid,
  //           branchid: userBranch?.id,
  //           imageid: `${uuid()}.${fileType}`,
  //           fileName: singlefile.name,
  //           fileType: fileType,
  //           image: singlefile,
  //         };

  //         if (fileUrlHistory.length > 0) {
  //           setFileUrlHistory([sendData]);
  //         } else {
  //           setFileUrlHistory([...fileUrlHistory, sendData]);
  //         }

  //         dispatch(addAccesoryUploadImageHistory(sendData, token));
  //       } else {
  //         const reader = new FileReader();

  //         reader.onloadend = () => {
  //           let sendData = {
  //             hospid: userIds?.hospid,
  //             userid: userIds?.userid,
  //             branchid: userBranch?.id,
  //             imageid: `${uuid()}.${fileType}`,
  //             fileName: singlefile.name,
  //             fileType: fileType,
  //             image: reader.result,
  //           };

  //           if (fileUrlHistory.length > 0) {
  //             setFileUrlHistory([sendData]);
  //           } else {
  //             setFileUrlHistory([...fileUrl, sendData]);
  //           }

  //           dispatch(addAccesoryUploadImageHistory(sendData, token));
  //         };
  //         reader.readAsDataURL(singlefile);
  //       }
  //     }
  //   }
  // };

  const handlePhotoUploadHistory = (e) => {
    const singlefile = e.target.files[0];

    if (singlefile) {
      const fileType = singlefile.type;
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize =
        fileType === "application/pdf" ? 6 * 1024 * 1024 : 4 * 1024 * 1024;

      if (!allowedTypes.includes(fileType)) {
        setIsPhotoErrorHistory(`Can't upload ${fileType} file.`);
        return;
      }

      if (singlefile.size > allowedSize) {
        // setIsPhotoErrorHistory("Greater than 2 MB can't be uploaded");
        setIsPhotoErrorHistory(
          fileType === "application/pdf"
            ? "PDF file size cannot exceed 6 MB."
            : "Image file size cannot exceed 4 MB."
        );
        return;
      }

      setIsPhotoErrorHistory(null);

      const reader = new FileReader();

      reader.onloadend = () => {
        let sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          branchid: userBranch?.id,
          imageid: `${uuid()}.${fileType.split("/")[1]}`,
          fileName: singlefile.name,
          fileType: fileType,
          image: reader.result,
        };

        setFileUrlHistory([sendData]);

        dispatch(addAccesoryUploadImageHistory(sendData, token));

        historyFileInputRef.current.value = "";
      };

      reader.readAsDataURL(singlefile);
    }
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(addAccesoryUploadImage(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const compressImageHistory = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(addAccesoryUploadImageHistory(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      imageid: id,
    };

    dispatch(deleteImageAddAccessory(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };

  const removePhotoHistory = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      imageid: id,
    };

    dispatch(deleteImageAddAccessoryHistory(data));
    setFileUrlHistory(fileUrlHistory.filter((item) => item.imageid !== id));
  };

  const handleAssetName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    setAssetName(value);
    if (value === "" || regex.test(value)) {
      setAssetName(value);
      setIsAssetError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsAssetError("wrong asset Name");
    }
    if (value === "") {
      setIsAssetError("Please fill the field");
    }
  };

  const handleAssetNameHistory = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    setAssetNameHistory(value);
    if (value === "" || regex.test(value)) {
      setAssetNameHistory(value);
      setIsAssetErrorHistory(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsAssetErrorHistory("wrong asset Name");
    }
    if (value === "") {
      setIsAssetErrorHistory("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  const handlePriceHistory = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    setPriceHistory(value);
    if (value === "" || regex.test(value)) {
      setPriceHistory(value);
      setIsPriceErrorHistory(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceErrorHistory("Enter valid price");
    }
    if (value === "") {
      setIsPriceErrorHistory("Please fill the field");
    }
  };

  const handleSubmit = async () => {
    console.log("Submit button clicked");

    if (assetName === "") {
      setIsAssetError("Please fill the field");
      console.log("Asset name is empty");
    }
    if (price === "") {
      setIsPriceError("Please fill the field");
      console.log("Price is empty");
    }
    if (quantity === "") {
      setIsQuantityError("Please fill the field");
      console.log("Quantity is empty");
    }
    if (fileUrl.length === 0) {
      setIsPhotoError("Please add an image");
      console.log("No file uploaded");
    }

    if (
      assetName === "" ||
      fileUrl.length === 0 ||
      price === "" ||
      quantity === ""
    ) {
      setSnackbarMessage("Please fill all the mandatory fields.");
      console.log("Mandatory fields are missing");
      return;
    }

    console.log("All validations passed");

    const assetIndex = accessoryNames.indexOf(assetName.trim());

    let newAccessoryNames = [...accessoryNames];
    let newAccessoryPrices = [...accessoryPrices];
    let newAccessoryQuantities = [...accessoryQuantities];
    let newAccessoryImages = [...accessoryImages];

    if (assetIndex !== -1) {
      newAccessoryNames[assetIndex] = assetName.trim();
      newAccessoryPrices[assetIndex] = Number(price.trim());
      newAccessoryQuantities[assetIndex] = Number(quantity.trim());
      newAccessoryImages[assetIndex] = fileUrl.map((item) => item.imageid);
    } else {
      newAccessoryNames = [...accessoryNames, assetName.trim()];
      newAccessoryPrices = [...accessoryPrices, Number(price.trim())];
      newAccessoryQuantities = [
        ...accessoryQuantities,
        Number(quantity.trim()),
      ];
      newAccessoryImages = [
        ...accessoryImages,
        ...fileUrl.map((item) => item.imageid),
      ];
    }

    let data = {
      assetid: params.id,
      hospid: userIds.hospid,
      hospname: userIds.hospname,
      userid: userIds.userid,
      branchid: userBranch?.id,
      accessoryname: newAccessoryNames,
      accessoryquantity: newAccessoryQuantities,
      accessoryprice: newAccessoryPrices,
      accessoryimage_id: newAccessoryImages,
    };

    try {
      await dispatch(addAccesoryUpdate(data, token));
      console.log("API call successful");

      setAssetName("");
      setPrice("");
      setQuantity("");
      setFileUrl([]);

      setAccessoryNames(newAccessoryNames);
      setAccessoryPrices(newAccessoryPrices);
      setAccessoryQuantities(newAccessoryQuantities);
      setAccessoryImages(newAccessoryImages);

      const fetchDataRequestBody = {
        assetid: params.id,
        hospid: userIds.hospid,
        userid: userIds.userid,
        branchid: userBranch?.id,
      };

      await dispatch(fetchDataFromAPI(fetchDataRequestBody, token));
      setIsAddingAccessory(false);
      console.log("Fetched updated data from API");
    } catch (error) {
      console.error("Failed to submit or fetch data:", error);
    }
  };

  const handleSubmitHistory = async () => {
    console.log("Submit button clicked");

    if (assetNameHistory === "") {
      setIsAssetErrorHistory("Please fill the field");
      console.log("Asset name is empty");
    }
    if (priceHistory === "") {
      setIsPriceErrorHistory("Please fill the field");
      console.log("Price is empty");
    }
    if (quantityHistory === "") {
      setIsQuantityErrorHistory("Please fill the field");
      console.log("Quantity is empty");
    }
    if (fileUrlHistory.length === 0) {
      setIsPhotoErrorHistory("Please add an image");
      console.log("No file uploaded");
    }

    if (
      assetNameHistory === "" ||
      priceHistory === "" ||
      quantityHistory === "" ||
      fileUrlHistory.length === 0
    ) {
      setSnackbarMessage("Please fill all the mandatory fields.");
      console.log("Mandatory fields are missing");
      return;
    }

    console.log("All validations passed");

    const assetIndex = accessoryNamesHistory.indexOf(assetNameHistory.trim());

    let newAccessoryName = assetNameHistory.trim();
    let newAccessoryPrice = Number(priceHistory.trim());
    let newAccessoryQuantity = Number(quantityHistory.trim());
    let newAccessoryImage =
      fileUrlHistory.length > 0 ? fileUrlHistory[0].imageid : "";

    if (assetIndex !== -1) {
      accessoryNamesHistory[assetIndex] = newAccessoryName;
      accessoryPricesHistory[assetIndex] = newAccessoryPrice;
      accessoryQuantitiesHistory[assetIndex] = newAccessoryQuantity;
      accessoryImagesHistory[assetIndex] = newAccessoryImage;
    } else {
      setAccessoryNamesHistory([...accessoryNamesHistory, newAccessoryName]);
      setAccessoryPricesHistory([...accessoryPricesHistory, newAccessoryPrice]);
      setAccessoryQuantitiesHistory([
        ...accessoryQuantitiesHistory,
        newAccessoryQuantity,
      ]);
      setAccessoryImagesHistory([...accessoryImagesHistory, newAccessoryImage]);
    }

    let data = {
      assetid: params.id,
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      accessoryname: newAccessoryName,
      accessoryprice: newAccessoryPrice,
      accessoryquantity: newAccessoryQuantity,
      accessoryimage_id: newAccessoryImage,
    };

    try {
      await dispatch(addAccesoryUpdateHistory(data, token));
      console.log("API call successful");

      setAssetNameHistory("");
      setPriceHistory("");
      setQuantityHistory("");
      setFileUrlHistory([]);

      const fetchDataRequestBody = {
        assetid: params.id,
        hospid: userIds.hospid,
        userid: userIds.userid,
        branchid: userBranch?.id,
      };

      await dispatch(fetchDataFromAPI(fetchDataRequestBody, token));
      setAddHistory(false);
      console.log("Fetched updated data from API");
    } catch (error) {
      console.error("Failed to submit or fetch data:", error);
    }
  };

  useEffect(() => {
    const requestBody = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      assetid: params.id,
    };

    dispatch(fetchDataFromAPI(requestBody, token));
  }, [dispatch]);

  const downTimeData = useSelector((store) => store?.nbmData?.downTime);
  useEffect(() => {
    setDowntime({
      tot_years: downTimeData?.year,
      tot_months: downTimeData?.month,
      tot_days: downTimeData?.day,
      tot_hrs: downTimeData?.hour,
      tot_mins: downTimeData?.min,
      tot_secs: downTimeData?.sec,
    });
  }, [downTimeData]);

  console.log(downTimeData);

  const location = useLocation();
  const { fromScanner, scannedAssetId } = location.state || {};
  const assetList = useSelector((store) => store?.nbmData?.allAssets);
  const assetIds = assetList?.map((asset) => asset.assetid);
  const isAssetAvailable = assetIds.includes(scannedAssetId);

  if (fromScanner && !detailData?.name) {
    navigate("/scan", { state: { fromScan: true } });
    return null;
  }

  const isGuest = userAccess && userAccess.role === "Guest";

  return (
    <Box
      sx={{
        display: "flex",
        padding: "10px",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <TopContainer>
        <Label variant="subtitle1" sx={{ fontSize: "20px", fontWeight: 700 }}>
          {detailData?.dept}{" "}
        </Label>
      </TopContainer>

      <MidContainer>
        <MidTop>
          <MidTopBox>
            <InnerBorderBoxBox>
              <Box sx={{ width: "100%" }}>
                <Tooltip title={detailData?.name} placement="top" arrow>
                  <Typography
                    sx={{
                      color: "#4690FF",
                      fontWeight: 700,
                      textAlign: "left",
                      fontSize: "24px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: {
                        xs: "280px",
                        sm: "280px",
                        md: "350px",
                      },
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 3, // Limits to 2 lines
                    }}
                    variant="subtitle1"
                  >
                    {detailData?.name}
                  </Typography>
                </Tooltip>
              </Box>

              <InnerImageBox>
                <IconButton onClick={handlePrevious}>
                  <KeyboardBackspaceIcon
                    style={{ color: "#4690FF" }}
                    sx={{ width: "38px", height: "38px" }}
                  />
                </IconButton>
                <ImageCard
                  as="img"
                  src={`${image[count]?.imgurl}`}
                  alt="check"
                  loading="lazy"
                  onClick={() => handleImageOpen(image[count]?.imgurl)}
                />
                <IconButton onClick={handleNext}>
                  <EastIcon
                    style={{ color: "#4690FF" }}
                    sx={{ width: "35px", height: "35px" }}
                  />
                </IconButton>
              </InnerImageBox>
              <Stack
                sx={{ width: "100%" }}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack gap="10px" flexDirection={"row"}>
                  <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                    Asset Image
                  </Typography>
                  <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                    {image.length}/{count + 1}
                  </Typography>
                </Stack>

                <Tooltip title="Download">
                  <IconButton
                    onClick={() => handleDownload(image[count]?.imgurl)}
                  >
                    <DownloadIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </InnerBorderBoxBox>
          </MidTopBox>

          <MidTopBox>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Label
                  sx={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Working Status
                </Label>
                <Divider />
              </Box>

              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap="5px"
              >
                <Box sx={{ width: "100%" }}>
                  <Label sx={{ textAlign: "left", fontSize: "20px" }}>
                    Status
                  </Label>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color:
                        detailData?.status === "Working"
                          ? "#4690FF"
                          : "#FF6B00",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    {detailData?.status}
                  </Typography>
                </Box>

                {detailData?.status === "Working" ? (
                  <CheckIcon style={{ color: "#4690FF", fontSize: "40px" }} />
                ) : (
                  <WarningAmberIcon
                    CheckIcon
                    style={{ color: "#FF6B00", fontSize: "40px" }}
                  />
                )}
              </Stack>

              <Box sx={{ width: "100%" }}>
                <Label sx={{ textAlign: "left", fontSize: "20px" }}>
                  Downtime
                </Label>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "#FF6B00",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  {convertTime() === "" ? "No down time" : convertTime()}
                </Typography>
              </Box>

              {/* <Stack
                sx={{ width: "100%" }}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack gap="10px" flexDirection={"row"}>
                  <Typography sx={{ fontSize: "15px" }}>
                    Monthly Report
                  </Typography>
                </Stack>

                <Tooltip title="Download">
                  <IconButton onClick={() => handleDownload()}>
                    <DownloadIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </Tooltip>
              </Stack> */}
            </Box>
          </MidTopBox>

          <MidTopBox>
            <ImageBox as="img" src={comingSoon} alt="image-box" />
          </MidTopBox>
        </MidTop>

        <Divider />
        <MidBottom>
          <MiniQRset
            onClick={handleOpenQr}
            as="img"
            src={detailData?.qrimg}
          ></MiniQRset>

          <Box>
            <Button
              sx={{
                color: "black",
                borderRadius: "20px",
                border: "1px solid",
                fontSize: "18px",
                width: "auto",
              }}
              disabled={fromScanner && !isAssetAvailable }
              onClick={handleEdit}
              variant="outlined"
            >
              Edit Asset
            </Button>
          </Box>

          <Stack sx={{ width: "100%", alignItems: "center" }}>
            {detailData?.incidstatus !== "Open" && (
              <Button
                variant="contained"
                disabled={fromScanner && !isAssetAvailable || isCheckedOut}
                onClick={
                  fromScanner && !isAssetAvailable || isCheckedOut
                    ? null
                    : () => navigate(`/record-incident/${params.id}`)
                }
                sx={{
                  borderRadius: "22px ",
                  fontSize: "15px",
                  width: "max-content",
                  background:
                    fromScanner && !isAssetAvailable || isCheckedOut? "#e0e0e0" : "#FF6B00", // Disabled color
                  color: fromScanner && !isAssetAvailable || isCheckedOut ? "#b1abc0" : "#fff", // Disabled text color
                  "&:hover": {
                    background:
                      fromScanner && !isAssetAvailable || isCheckedOut ? "#e0e0e0" : "#FF6B00", // Disabled hover color
                    color:
                      fromScanner && !isAssetAvailable  || isCheckedOut? "#b1abc0" : "#fff", // Disabled hover text color
                  },
                }}
              >
                Add Incident
              </Button>
            )}

            {detailData?.incidstatus === "Open" && (
              <Button
                variant="contained"
                onClick={
                  fromScanner && !isAssetAvailable || isCheckedOut
                    ? null
                    : () => navigate(`/incident-list/${detailData?.incidid}`)
                }
                disabled={fromScanner && !isAssetAvailable || isCheckedOut}
                sx={{
                  borderRadius: "22px ",
                  fontSize: "15px",
                  width: "max-content",
                  background:
                    fromScanner && !isAssetAvailable || isCheckedOut ? "#e0e0e0" : "#FF6B00", // Disabled color
                  color: fromScanner && !isAssetAvailable  || isCheckedOut? "#b1abc0" : "#fff", // Disabled text color
                  "&:hover": {
                    background:
                      fromScanner && !isAssetAvailable || isCheckedOut ? "#e0e0e0" : "#FF6B00", // Disabled hover color
                    color:
                      fromScanner && !isAssetAvailable || isCheckedOut ? "#b1abc0" : "#fff", // Disabled hover text color
                  },
                }}
              >
                Clear Incident
              </Button>
            )}
          </Stack>

          <Stack sx={{ width: "100%", alignItems: "center" }}>
            {detailData?.servstatus === "NA" && (
              <Button
                onClick={handleOpenModal}
                variant="contained"
                disabled={fromScanner && !isAssetAvailable || isCheckedOut}
                sx={{
                  borderRadius: "22px ",
                  fontSize: "15px",
                  width: "max-content",
                  background: "#FF6B00",
                  color: "#fff",
                  "&:hover": {
                    background: "#FF6B00",
                    color: "#fff",
                  },
                }}
              >
                Add Service
              </Button>
            )}

            {detailData?.servstatus === "Open" && (
              <Button
                onClick={() => navigate(`/service-list/${detailData.servid}`)}
                variant="contained"
                disabled={fromScanner && !isAssetAvailable || isCheckedOut}
                sx={{
                  borderRadius: "22px ",
                  width: "auto",
                  fontSize: "14px",
                  background: "#FF6B00",
                  color: "#fff",
                  "&:hover": {
                    background: "#FF6B00",
                    color: "#fff",
                  },
                }}
              >
                Clear Service
              </Button>
            )}
          </Stack>
          <Box>
            <UpdateReport assetId={params.id} />
          </Box>
        </MidBottom>
      </MidContainer>

      <MainWrapper>
        <Stack sx={{ flex: "1", gap: "20px" }}>
          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",

              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",

                height: "200px",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Asset Details
              </Label>
              <Divider />
              <Box sx={{ width: "100%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {Object.entries(AssetDetail).map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontSize: "15px",
                              fontWeight: 400,
                            }}
                          >
                            {key}
                          </TableCells>

                          <TableCells
                            sx={{ fontSize: "15px", fontWeight: 400 }}
                          >
                            {value}
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
                marginTop: "20px",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Specifications
              </Label>
              <Divider />
              <Box sx={{ width: "100%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {Object.entries(Specifications).map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontSize: "15px",
                              fontWeight: 400,
                            }}
                          >
                            {key}
                          </TableCells>

                          <TableCells
                            sx={{ fontSize: "15px", fontWeight: 400 }}
                          >
                            {value}
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
                marginBottom: "20px",
                height: "280px",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Purchase
              </Label>
              <Divider />
              <Box sx={{ width: "100%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {Object.entries(Purchase).map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontSize: "15px",
                              fontWeight: 400,
                            }}
                          >
                            {key}
                          </TableCells>

                          <TableCells
                            sx={{ fontSize: "15px", fontWeight: 400 }}
                          >
                            {value}
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>

            <Stack
              sx={{
                flex: "1",

                padding: "10px",
                bgcolor: "white",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  gap: "10px",
                  textAlign: "start",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                    Accessories
                  </Label>
                  <Button
                    disabled={
                      expenditures?.length > 0 ||
                      (fromScanner && !isAssetAvailable)
                    }
                    onClick={handleAddExpenditure}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "20px",
                      color: "#1B2535",
                      fontSize: {
                        xs: "11px",
                        sm: "21px",
                        md: "21px",
                        lg: "21px",
                        xl: "21px",
                      },
                      width: "200px",
                      height: "44px",
                    }}
                    variant="outlined"
                  >
                    Add Accessory
                  </Button>
                </Box>

                <Divider />

                <Stack
                  sx={{
                    width: "100%",

                    gap: "10px",
                  }}
                >
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                  >
      {!accessoriesAdded && assetData?.accessoryname?.length === 0 ? (
                      <Stack
                        sx={{
                          height: "150px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No record available
                      </Stack>
                    ) : (
                      <Table sx={{ width: "100%" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                                fontSize: "15px",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                                fontSize: "15px",
                              }}
                            >
                              Quantity
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                                fontSize: "15px",
                              }}
                            >
                              Unit price
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assetData?.accessoryname?.map((name, index) => (
                            <TableRow key={index}>
                              <TableCells
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                }}
                              >
                                {name}
                              </TableCells>
                              <TableCells
                                sx={{ fontWeight: 400, fontSize: "15px" }}
                              >
                                {assetData?.accessoryquantity[index] + " nos" ||
                                  "N/A"}
                              </TableCells>
                              <TableCells
                                sx={{ fontWeight: 400, fontSize: "15px" }}
                              >
                                ₹{assetData?.accessoryprice[index] || "N/A"}
                              </TableCells>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>

                  {/* {detailData?.incident_history !== "No History" ? (
                    <TableContainer
                      style={{
                        overflowX: "auto",
                        maxWidth: "100%",
                        height: "200px",
                      }}
                    >
                      <Table sx={{ width: "100%" }}>
                        <TableHead>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 600,
                            }}
                          >
                            Name
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 600,
                            }}
                          >
                            Quantity
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Unit Price
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {detailData?.incident_history?.map((item) => (
                            <TableRow key={item.id}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 500,
                                }}
                              >
                                {item?.cleardate}
                              </TableCells>

                              <TableCells> Download PDf</TableCells>
                              <TableCells sx={{ textAlign: "center" }}>
                                <IconButton
                                  disabled={userAccess?.role === "Guest"}
                                >
                                  <DownloadIcon
                                    onClick={() =>
                                      dispatch(
                                        DownloadPDF(
                                          item?.reporturl,
                                          item?.assetname
                                        )
                                      )
                                    }
                                    sx={{
                                      cursor: "pointer",
                                      color:
                                        userAccess?.role === "Guest"
                                          ? "grey"
                                          : "black",
                                    }}
                                    color="warning"
                                  />
                                </IconButton>
                              </TableCells>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  
                  ) : (
                    <Stack
                      sx={{
                        height: "150px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No incident recorded
                    </Stack>
                  )} */}

                  <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                    {/* {detailData?.incidstatus !== "Open" && (
                    <Link to={`/record-incident/${params.id}`}>
                      <Button
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Add Incident
                      </Button>
                    </Link>
                  )} */}

                    {/* {detailData?.incidstatus === "Open" && (
                      <Link
                        // to={`/clear-incident/${params.id}`}
                        to={`/incident-list/${detailData?.incidid}`}
                      >
                        <Button
                          onClick={handleClick}
                          variant="contained"
                          sx={{ borderRadius: "22px ", width: "130px" }}
                        >
                          Clear Incident
                        </Button>
                      </Link>
                    )} */}
                  </Stack>
                </Stack>

                {/* --------------------------------------------------------------------------------------------------- */}

                {expenditures?.map((item, index) => (
                  <Stack
                    sx={{
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <Stack
                      sx={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: "30px",
                      }}
                    >
                      <Label variant="subtitle1">Add new accessory</Label>
                      <IconButton
                        onClick={() => handleRemoveExpenditure(index)}
                      >
                        <CancelIcon sx={{ color: "black" }} />
                      </IconButton>
                    </Stack>

                    <Stack spacing={4}>
                      <Stack
                        sx={{
                          justifyContent: "space-between",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                          gap: "30px",
                        }}
                      >
                        <Stack
                          sx={{
                            gap: "10px",
                            width: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={assetName}
                            placeholder="Accessory name"
                            onChange={handleAssetName}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                            }}
                          />
                          <HelperText variant="caption">
                            {isAssetError}
                          </HelperText>
                        </Stack>
                        <Stack
                          sx={{
                            gap: "10px",
                            width: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={price}
                            placeholder="Unit price"
                            onChange={handlePrice}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                            }}
                          />
                          <HelperText variant="caption">
                            {isPriceError}
                          </HelperText>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                          gap: "30px",
                        }}
                      >
                        <Stack
                          sx={{
                            width: { xs: "100%", md: "max-content" },
                            gap: "3px",
                            height: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={quantity}
                            placeholder="Quantity"
                            onChange={handleQuantity}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                              marginRight: {
                                md: "100px",
                              },
                            }}
                          />
                          <HelperText variant="caption">
                            {isQuantityError}
                          </HelperText>
                        </Stack>

                        <Stack>
                          <Typography>
                            Upload Receipt (Png, jpeg, or pdf)
                          </Typography>
                          <Box
                            sx={{
                              background: "#f6f6f6",
                              padding: "5px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "223px",
                                  lg: "100%",
                                },
                                justifyContent: "flex-end",
                              }}
                            >
                              <Input
                                type="file"
                                accept=".png,.jpeg,.jpg,.pdf"
                                onChange={handlePhotoUpload}
                                sx={{ display: "none" }}
                                id="file-input-1" // Updated ID
                                inputRef={fileInputRef}
                              />
                              {fileUrl.map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 2,
                                    position: "relative",
                                  }}
                                >
                                  <Tooltip title={item.fileName}>
                                    <Box
                                      sx={{
                                        backgroundColor:
                                          "rgba(27, 37, 53, 0.19)",
                                        color: "#1B2535",
                                        padding: "8px",
                                        borderRadius: "4px",
                                        fontWeight: "bold",
                                        width: {
                                          xs: "160px",
                                          sm: "315px",
                                          md: "165px",
                                        },
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item.fileName} (
                                      {item.fileType.toUpperCase()})
                                    </Box>
                                  </Tooltip>

                                  <label
                                    htmlFor="file-input-1"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <Box
                                      variant="contained"
                                      component="a"
                                      sx={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        fontSize: "13px",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Replace Document
                                    </Box>
                                  </label>
                                  <IconButton
                                    onClick={() => removePhoto(item?.imageid)}
                                    size="small"
                                    sx={{
                                      marginLeft: "10px",
                                      position: "absolute",
                                      top: "-22px",
                                      right: "0",
                                    }}
                                  >
                                    <CloseIcon sx={{ color: "#FF3A44" }} />
                                  </IconButton>
                                </Box>
                              ))}
                            </Box>

                            {fileUrl.length === 0 && (
                              <label htmlFor="file-input-1">
                                <Button
                                  component="span"
                                  sx={{
                                    background: "rgba(27, 37, 53, 0.19)",
                                    width: "57px",
                                    height: "57px",
                                    color: "#1B2535",
                                  }}
                                >
                                  PDF
                                </Button>
                              </label>
                            )}
                          </Box>
                          <HelperText variant="primary">
                            {isPhotoError}
                          </HelperText>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          justifyContent: "end",
                          flexDirection: "row",
                          gap: "30px",
                        }}
                      >
                        <Button
                          variant="contained"
                          disabled={isGuest || !areFieldsValid()}
                          onClick={() => handleSubmitAndClose(index)}
                          sx={{ borderRadius: "25px" }}
                        >
                          Add
                        </Button>
                      </Stack>
                    </Stack>

                    <Divider />
                  </Stack>
                ))}

                {/* ------------------------------------------------------------------------------------------------------------- */}
              </Stack>
            </Stack>

            <Stack sx={{ flex: "1", gap: "20px" }}>
              <Stack sx={{ height: "300px" }}>
                <>
                  <Stack
                    sx={{
                      flex: "1",

                      padding: "10px",
                      bgcolor: "white",
                    }}
                  >
                    <Stack
                      sx={{
                        width: "100%",
                        gap: "10px",
                        textAlign: "start",
                      }}
                    >
                      <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                        Warranty
                      </Label>
                      <Divider />
                      <Stack
                        sx={{
                          width: "100%",

                          gap: "10px",
                        }}
                      >
                        {detailData?.wimg !== "NA" ? (
                          <Stack sx={{ bgcolor: "white", height: "100%" }}>
                            <WarrantyInnerBorderBoxBox>
                              <InnerWarrantyImageBox>
                                <IconButton onClick={handlePreviousCertificate}>
                                  <KeyboardBackspaceIcon
                                    style={{ color: "#4690FF" }}
                                  />
                                </IconButton>

                                <WarrantyImageCard
                                  as="img"
                                  src={`${
                                    certificateImage[count2]?.imgid
                                      ?.split(".")
                                      ?.pop() === "pdf"
                                      ? pdfImage
                                      : certificateImage[count2]?.imgurl
                                  }`}
                                  alt="check"
                                  onClick={() =>
                                    handleImageOpen(
                                      certificateImage[count2]?.imgurl
                                    )
                                  }
                                />
                                <IconButton onClick={handleNextCertificate}>
                                  <EastIcon style={{ color: "#4690FF" }} />
                                </IconButton>
                              </InnerWarrantyImageBox>
                              <Stack
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  marginTop: "20px",
                                }}
                              >
                                <Typography>Warranty Certificate</Typography>
                                <IconButton
                                  onClick={() =>
                                    handleDownload(
                                      certificateImage[count2]?.imgurl
                                    )
                                  }
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Stack>
                            </WarrantyInnerBorderBoxBox>
                          </Stack>
                        ) : (
                          <Stack
                            sx={{
                              height: "150px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            No warranty
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ flex: "1", gap: "20px" }}>
          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Incident History -{" "}
                {Array.isArray(detailData?.incident_history)
                  ? detailData.incident_history.length
                  : 0}
              </Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}
              >
                {detailData?.incident_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "280px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {detailData?.incident_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              Incident Lable
                            </TableCells>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {detailData?.serialno}
                            </TableCells>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {item?.cleardate}
                            </TableCells>

                            {/* <TableCells
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >
                              {" "}
                              Download PDf
                            </TableCells> */}
                            <TableCells
                              sx={{
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No incident recorded
                  </Stack>
                )}
                <Divider />

                {/* <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                  {detailData?.incidstatus !== "Open" && (
                    <Link to={`/record-incident/${params.id}`}>
                      <Button
                      disabled={fromScanner && !isAssetAvailable}
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}
                      >
                        Add Incident
                      </Button>
                    </Link>
                  )}

                  {detailData?.incidstatus === "Open" && (
                    <Link
                      // to={`/clear-incident/${params.id}`}
                      to={`/incident-list/${detailData?.incidid}`}
                    >
                      <Button
                      disabled={fromScanner && !isAssetAvailable}
                        onClick={handleClick}
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}
                      >
                        Clear Incident
                      </Button>
                    </Link>
                  )}
                </Stack> */}
              </Stack>
            </Stack>
          </Stack>




          <Stack
            sx={{
              flex: "1",
              padding: "10px",
              bgcolor: "white",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Service History -{" "}
                {Array.isArray(detailData?.service_history)
                  ? detailData.service_history.length
                  : 0}
              </Label>

              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}
              >
                {detailData?.service_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "280px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {detailData?.service_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >
                              {" "}
                              Service Lable
                            </TableCells>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {/* {item?.servstatus} */}
                              {/* {item?.serialno} */}
                              {detailData?.serialno}
                            </TableCells>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {/* {item?.servstatus} */}
                              {item?.servdate}
                            </TableCells>

                            {/* <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {item?.servstatus}
                            </TableCells>
                           

                            <TableCells
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >
                              {" "}
                              Download PDf
                            </TableCells> */}
                            <TableCells
                              sx={{
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No service history
                  </Stack>
                )}
                {/* <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                  {detailData?.servstatus === "NA" && (
                    <Button
                    disabled={fromScanner && !isAssetAvailable}
                      onClick={handleOpenModal}
                      variant="contained"
                      sx={{ borderRadius: "22px ", width: "130px" }}
                    >
                      Add Service
                    </Button>
                  )}

                  {detailData?.servstatus === "Open" && (
                    <Button
                    disabled={fromScanner && !isAssetAvailable}
                      onClick={() =>
                        navigate(`/service-list/${detailData.servid}`)
                      }
                      variant="contained"
                      sx={{ borderRadius: "22px ", width: "130px" }}
                    >
                      Clear Service
                    </Button>
                  )}
                </Stack> */}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                  Accessory History -{" "}
                  {assetDataHistory?.length > 0
                    ? `${assetDataHistory.length} `
                    : "0"}
                </Label>
                <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                  Expenditure:₹{assetExpenditure}
                </Label>
              </Stack>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}
              >
                {/* {reportImage?.length > 0 ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "150px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {reportImage?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {reportImageName[index]}
                            </TableCells>

                            <TableCells
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >
                              {" "}
                              Download PDf
                            </TableCells>
                            <TableCells
                              sx={{
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() => handleDownload(item?.imgurl)}
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No reports uploaded yet
                  </Stack>
                )} */}

                {assetDataHistory?.length > 0 ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "280px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {assetDataHistory?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {item?.accessoryname}
                            </TableCells>

                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {item?.accessoryquantity} nos
                            </TableCells>

                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              ₹{item?.accessoryprice}
                            </TableCells>

                            <TableCells
                              sx={{
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDFHistory(
                                        item?.receipt,
                                        item?.accessoryname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  !addHistory && (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Accessory History uploaded yet
                  </Stack>
                  )
                )}

                <Divider />

                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                  {/* <UpdateReport assetId={params.id} /> */}
                  <Button
                    sx={{
                      borderRadius: "20px",
                      width: "200px",
                      height: "44px",
                      fontSize: "18px",
                      fontWeight: 700,
                    }}
                    variant="outlined"
                    onClick={() => setAddHistory(true)}
                    disabled={
                      addHistory === true || (fromScanner && !isAssetAvailable)
                    }
                  >
                    Add History
                  </Button>
                </Stack>
                <Divider />
                {addHistory && (
                  <Stack
                    sx={{
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <Stack
                      sx={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: "30px",
                      }}
                    >
                      <Label variant="subtitle1">
                        Add new accessory history
                      </Label>
                      <IconButton onClick={() => setAddHistory(false)}>
                        <CancelIcon sx={{ color: "black" }} />
                      </IconButton>
                    </Stack>

                    <Stack spacing={4}>
                      <Stack
                        sx={{
                          justifyContent: "space-between",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                          gap: "30px",
                        }}
                      >
                        <Stack
                          sx={{
                            gap: "10px",
                            width: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={assetNameHistory}
                            placeholder="Accessory name"
                            onChange={handleAssetNameHistory}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                            }}
                          />
                          <HelperText variant="caption">
                            {isAssetErrorHistory}
                          </HelperText>
                        </Stack>
                        <Stack
                          sx={{
                            gap: "10px",
                            width: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={priceHistory}
                            placeholder="Unit price"
                            onChange={handlePriceHistory}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                            }}
                          />
                          <HelperText variant="caption">
                            {isPriceErrorHistory}
                          </HelperText>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                          gap: "30px",
                        }}
                      >
                        <Stack
                          sx={{
                            width: { xs: "100%", md: "max-content" },
                            gap: "3px",
                            height: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={quantityHistory}
                            placeholder="Quantity"
                            onChange={handleQuantityHistory}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                              marginRight: {
                                md: "100px",
                              },
                            }}
                          />
                          <HelperText variant="caption">
                            {isQuantityErrorHistory}
                          </HelperText>
                        </Stack>

                        <Stack>
                          <Typography>
                            Upload Receipt (Png, jpeg, or pdf)
                          </Typography>
                          <Box
                            sx={{
                              background: "#f6f6f6",
                              padding: "5px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "223px",
                                  lg: "100%",
                                },
                                justifyContent: "flex-end",
                              }}
                            >
                              <Input
                                type="file"
                                accept=".png,.jpeg,.jpg,.pdf"
                                onChange={handlePhotoUploadHistory}
                                sx={{ display: "none" }}
                                id="file-input"
                                inputRef={historyFileInputRef}
                              />
                              {fileUrlHistory.map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 2,
                                    position: "relative",
                                  }}
                                >
                                  <Tooltip title={item.fileName}>
                                    <Box
                                      sx={{
                                        backgroundColor:
                                          "rgba(27, 37, 53, 0.19)",
                                        color: "#1B2535",
                                        padding: "8px",
                                        borderRadius: "4px",
                                        fontWeight: "bold",
                                        width: {
                                          xs: "160px",
                                          sm: "315px",
                                          md: "165px",
                                        },
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item.fileName} (
                                      {item.fileType.toUpperCase()})
                                    </Box>
                                  </Tooltip>
                                  <label
                                    htmlFor="file-input"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <Box
                                      variant="contained"
                                      component="a"
                                      sx={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        fontSize: "13px",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Replace Document
                                    </Box>
                                  </label>
                                  <IconButton
                                    onClick={() =>
                                      removePhotoHistory(item?.imageid)
                                    }
                                    size="small"
                                    sx={{
                                      marginLeft: "10px",
                                      position: "absolute",
                                      top: "-22px",
                                      right: "0",
                                    }}
                                  >
                                    <CloseIcon sx={{ color: "#FF3A44" }} />
                                  </IconButton>
                                </Box>
                              ))}
                            </Box>

                            {fileUrlHistory.length === 0 && (
                              <label htmlFor="file-input">
                                <Button
                                  component="span"
                                  sx={{
                                    background: "rgba(27, 37, 53, 0.19)",
                                    width: "57px",
                                    height: "57px",
                                    color: "#1B2535",
                                  }}
                                >
                                  PDF
                                </Button>
                              </label>
                            )}
                          </Box>
                          <HelperText variant="primary">
                            {isPhotoErrorHistory}
                          </HelperText>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          justifyContent: "end",
                          flexDirection: "row",
                          gap: "30px",
                        }}
                      >
                        <Button
                          variant="contained"
                          disabled={isGuest}
                          onClick={handleSubmitHistory}
                          sx={{ borderRadius: "25px" }}
                        >
                          Add
                        </Button>
                      </Stack>
                    </Stack>

                    <Divider />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                  Report -{" "}
                  {Array.isArray(detailData?.reportnames)
                    ? detailData.reportnames.length
                    : 0}
                </Label>
                <Stack sx={{ width: "50%", alignItems: "flex-end" }}>
                  <UpdateReport assetId={params.id} />
                </Stack>
              </Stack>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}
              >
                {reportImage?.length > 0 ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "280px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {reportImage?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {reportImageName[index]}
                            </TableCells>

                            <TableCells
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >
                              {" "}
                              Download PDf
                            </TableCells>
                            <TableCells
                              sx={{
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() => handleDownload(item?.imgurl)}
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No reports uploaded yet
                  </Stack>
                )}
                <Divider />
                {/* <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                  <UpdateReport assetId={params.id} />
                </Stack> */}

                {/* {reportImage?.length === 0 && (
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",

                    bgcolor: "white",
                    display: "flex",
                    alignItems: "center",

                    borderRadius: "40px",
                    height: "50px",
                  }}>
                  <SubTitle>Report</SubTitle>
                  <UpdateReport assetId={params.id} />
                </Box>
              )} */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </MainWrapper>

      <div style={{}}>
        <Modal
          keepMounted
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <ModalBox sx={{ bgcolor: "background.paper" }}>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "end",
                paddingRight: "10px",
              }}
            >
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",

                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Label variant="h6">Request services</Label>

              <Stack sx={{ width: "90%" }}>
                <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
              </Stack>

              <Stack gap={"10px"} sx={{ width: "90%" }}>
                <Label textAlign={"start"}>Choose Service</Label>
                <Stack
                  flexDirection={"row"}
                  gap={"10px"}
                  textAlign={"start"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Vajra", params.id)}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                  >
                    Vajra
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Others", params.id)}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                  >
                    Others
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </ModalBox>
        </Modal>
      </div>



      <ViewImageModal
        imageUrl={detailData?.qrimg}
        assetId={detailData?.code}
        department={detailData?.dept}
        assestname={detailData?.name}
        handleClose={handleCloseQr}
        open={open}
      />
    </Box>
  );
}

export default AssetDetailPage;
