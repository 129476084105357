import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  Box,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { getPmAsset, postPmCheckList } from "../redux/nbmData/action";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import jsPDF from "jspdf";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const ParaBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  padding: "2px",
  marginBottom: 2,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RadioContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "20%",
}));

const ItemText = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  textTransform: "uppercase",
  fontSize: "18px",
  width: "80%",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  color: "#4690FF",
  fontSize: "22px",
  marginTop: "10px",
  marginBottom: "10px",
}));

const PdfButton = styled(Button)(({ theme }) => ({
  borderRadius: "35px",
  color: "#F78117",
  border: "1px solid #FF731D",
  textTransform: "none",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "7px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));



const FirstSet = styled(Box)(({ theme }) => ({
  width: "100%",
  display:'flex',
  justifyContent: "space-between",
  flexDirection: "row",

  marginTop: "20px",
  gap: "20px",
  height: "80%",
  alignItems: "center",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      flexDirection:'column',
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
}));



const OuterSet = styled(Box)(({ theme }) => ({
  width: "700px",
  display:'flex',
  flexDirection:'column',
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  gap: "10px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width:'98%',
    
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
}));

const InputSet = styled(Box)(({ theme }) => ({
     width: "50%",
     flexDirection:'column',
     height:'100%',
     gap:'10px',
     display:'flex',
   
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width:'100%',
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
}));





const departments = [
  { department: "Critical Care" },
  { department: "OT" },
  { department: "Laboratory" },
  { department: "Radiology" },
];

const MyComponent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [equipment, setEquipment] = useState("");

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [flag, setFlag] = useState(false);
  const token = localStorage.getItem("userToken");
  const pmChecklistData = useSelector(
    (store) => store.data.postPmCheckListData
  );
  const pdfUrl = useSelector((store) => store.data.pmChecklistPdf);
  const postPmCheckListDataC = useSelector(
    (store) => store.data.postPmCheckListData
  );
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const [department, setDepartment] = useState([]);
  const [iframeData, setIframeData] = useState({});
  const [singleDepartment, setSingleDepartment] = useState("");
  const [departmentId, setDepartmentId] = useState("");

  const [check, setCheck] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [assetName, setAssetName] = useState("");
  const [remark, setRemark] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [doneBy, setDoneBy] = useState("");
  const [isParameterError, setIsParameterError] = useState("");
  const [isDoneByError, setIsDoneByError] = useState("");
  const [isDateError, setIsDateError] = useState("");
  const [isSerialNumberError, setIsSerialNumberError] = useState("");
  const [isModelNumberError, setIsModelNumberError] = useState("");
  const [isRemarkError, setIsRemarkError] = useState("");
  const [isAssetNameError, setIsAssetNameError] = useState("");
  const [dropdown, setDropdown] = useState("");
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const dispatch = useDispatch();
  const [color, setColor] = useState("");

  const [assetId, setAssetId] = useState("");
  const [getAllData, setGetAllData] = useState([]);
  const assetData = useSelector((store) => store.data.getPmAssetData);
  const [submittedName, setSubmittedName] = useState("");
  const [submitDate, setSubmitDate] = useState(""); 



const handleSelectedDepartment = (event, newValue)=>{
 
    setSelectedDepartment(newValue)
    setDropdown("")
}



  let data2 = [

    {
      department: "Critical Care",
      equipment: [
        {
          name: "NST MACHINE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PAPPER NOTCH", status: "N/A" },
            { name: "TRANSDUCERS", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY WEIGHING SCALE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "WEIGHT TEST", status: "N/A" },
            { name: "0.5kg", status: "N/A" },
            { name: "1kg", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "SPO2 MONITOR",
          parameter: [
            { name: "BATTERY COVER", status: "N/A" },
            { name: "LED", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY RESUSCITATOR",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            {
              name: "Temperature sensor and sensor cover for prolonged resuscitation",
              status: "N/A",
            },
            { name: "Plastic bag or plastic wrap", status: "N/A" },
            { name: "Cleanliness", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "ABG ANALYSER",
          parameter: [
            { name: "CHARGER/MAIN", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "CATRIDGE AREA", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "THERMAL PAPER", status: "N/A" },
            { name: "MEMORY", status: "N/A" },
          ],
        },
        {
          name: "DEFIBRILLATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEY PAD", status: "N/A" },
            { name: "PADDLES", status: "N/A" },
            { name: "CLEANED", status: "N/A" },
            { name: "SPRINTER", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "PATTERN TEST", status: "N/A" },
            { name: "ALARM", status: "N/A" },
            { name: "VERIFICATION", status: "N/A" },
            { name: "HEART RATE TEST : 80", status: "N/A" },
            { name: "SET ENERGY", status: "N/A" },
            { name: "50J", status: "N/A" },
            { name: "70J", status: "N/A" },
            { name: "100J", status: "N/A" },
            { name: "150J", status: "N/A" },
            { name: "200J", status: "N/A" },
          ],
        },
        {
          name: "ECG MACHINE",
          parameter: [
            {
              name: "AC MAINS VOLTAGE /N to E=3v/N to L=230v/L to E=230v",
              status: "N/A",
            },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "CLEANESS OF MACHINE", status: "N/A" },
            { name: "PRINTER CONDITION", status: "N/A" },
            { name: "BATTERY TEST", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "CALIBRATION", status: "N/A" },
            { name: "VALUE SELECTED 30", status: "N/A" },
            { name: "VALUE SELECTED 60", status: "N/A" },
            { name: "VALUE SELECTED 120", status: "N/A" },
          ],
        },
        {
          name: "BP APPARATUS ",
          parameter: [
            {
              name: "CHECK THE CLEANLINESS,COMPLETENESS,DAMAGE OF THE UNIT",
              status: "N/A",
            },
            { name: "CHECK THE BULB WITH CONTROL VALVE", status: "N/A" },
            { name: "CHECK THE BP CUFF", status: "N/A" },
            { name: "CLEAN THE MERCURY", status: "N/A" },
            { name: "REPLACE THE RUBBER WASHER IF REQUIRED", status: "N/A" },
          ],
        },
        {
          name: "INCUBATOR",
          parameter: [
            { name: "Power indicator", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Equipment display", status: "N/A" },
            { name: "Keypad", status: "N/A" },
            { name: "Heater function", status: "N/A" },
            { name: "Temperature display", status: "N/A" },
            { name: "Door locking movement", status: "N/A" },
            { name: "Sensor function", status: "N/A" },
            { name: "Feather touch switches", status: "N/A" },
          ],
        },
        {
          name: "LIGHT SOURCE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Power ON Indicator", status: "N/A" },
            { name: "Fiber Optic Cable", status: "N/A" },
            { name: "Fan Function", status: "N/A" },
            { name: "Adapter for Light Cable", status: "N/A" },
          ],
        },
        {
          name: "MDICAL MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "REMOTE FUNCTION", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "PATIENT MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "ECG CABLE", status: "N/A" },
            { name: "NIBP CUFF", status: "N/A" },
            { name: "SPO2 PROBE", status: "N/A" },
          ],
        },

        {
          name: "TOURNIQUET",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Pressure bag and hose function", status: "N/A" },
            { name: "Timer Function", status: "N/A" },
            { name: "Pump Function", status: "N/A" },
            { name: "All Switches", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },

        {
          name: "VENTILATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BUTTONS", status: "N/A" },
            { name: "CIRCUIT ASSEMBLY", status: "N/A" },
            { name: "SENSORS", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "O2 AND AIR HOSE", status: "N/A" },
            { name: "CLENINESS", status: "N/A" },
          ],
        },
      ],
    },

    {
      department: "OT",
      equipment: [
        {
          name: "Cautery Machine or Diathermy",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "FOOT SWITCH", status: "N/A" },
            { name: "BIPLOAR& MONOPOLAR CABLE WORKING", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "SPO2 MONITOR",
          parameter: [
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "LED", status: "N/A" },
            { name: "BATTERY COVER", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "CAUTERY MACHINE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "FOOT SWITCH", status: "N/A" },
            { name: "BIPLOAR& MONOPOLAR CABLE WORKING", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY RESUSCITATOR",
          parameter: [
            { name: "KEYPAD ", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            {
              name: "Temperature sensor and sensor cover for prolonged resuscitation",
              status: "N/A",
            },
            { name: "Plastic bag or plastic wrap", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "Check for proper working of alarms", status: "N/A" },
            { name: "Cleanliness", status: "N/A" },
          ],
        },
        {
          name: "ANAESTHESIA MACHINE",
          parameter: [
            { name: "AC MAINS/POWEP CORD/BATTERY BACK UP", status: "N/A" },
            {
              name: "DISASSEMBLY OF BREATHING SYSTEMS AND BELLOWS ASSEMS ASSEMBLY FOR CLEANING",
              status: "N/A",
            },
            {
              name: "CLEANING OF FILTER /FLOW SENSORS FIT NEW SEALS BODOKS,RING",
              status: "N/A",
            },
            { name: "LEAKAGE  TEST WITHOUT VAPORIZER", status: "N/A" },
            { name: "LEAKAGE TEST WITH VAPORIZER", status: "N/A" },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY CYLINDER",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY PIPE LINE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF OXIGEN FAILURE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF CIRCLE ABSORBER",
              status: "N/A",
            },
            { name: "CHECK FOR PROPER MOVEMENTS OF WHEELS", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
            { name: "CALIBRATION OF OXIGEN SENSORS", status: "N/A" },
            {
              name: "FINAL TEST OF TIDAL VOLUME RATE AND I:E RATIO ,FIO2",
              status: "N/A",
            },
          ],
        },
        {
          name: "BP APPARATUS",
          parameter: [
            {
              name: "CHECK THE CLEANLINESS,COMPLETENESS,DAMAGE OF THE UNIT",
              status: "N/A",
            },
            { name: "CHECK THE BULB WITH CONTROL VALVE", status: "N/A" },
            { name: "CHECK THE BP CUFF", status: "N/A" },
            { name: "CLEAN THE MERCURY", status: "N/A" },
            { name: "REPLACE THE RUBBER WASHER IF REQUIRED", status: "N/A" },
          ],
        },
        {
          name: "DEFIBRILLATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEY PAD", status: "N/A" },
            { name: "PADDLES", status: "N/A" },
            { name: "CLEANED", status: "N/A" },
            { name: "SPRINTER", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "PATTERN TEST", status: "N/A" },
            { name: "ALARM", status: "N/A" },
            { name: "VERIFICATION", status: "N/A" },
            { name: "HEART RATE TEST : 80", status: "N/A" },
            { name: "SET ENERGY ", status: "N/A" },
          ],
        },
        {
          name: "ELECTRO SURGICAL UNIT",
          parameter: [
            { name: "KEY PAD FUNCTIONING", status: "N/A" },
            { name: "FOOTSWITH FUNCTIONING IN ALL MODES", status: "N/A" },
            { name: "MONOPOLAR  ND BIPOLAR FUNCTIONING", status: "N/A" },
            { name: "REM CONNECTION CHECK WITH ELECTRODE", status: "N/A" },
            { name: "ALARMS CHECK AND CLEANEESS", status: "N/A" },
            { name: "THE POWER SUPPLY", status: "N/A" },
          ],
          powerSupply: [
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+5V(RANGE0.15)AT  TP8",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE+/- 0.36)AT TP6",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE +/-0.36)AT TP5",
              observedValue: "",
            },
          ],
        },
        {
          name: "ETO STERLIZER",
          parameter: [
            { name: "DISPLAY AND KEYPAD", status: "N/A" },
            { name: "POWER INTEGRITY", status: "N/A" },
            { name: "FUSES", status: "N/A" },
            { name: "INPUT AIR REGULATOR", status: "N/A" },
            { name: "EXHAUST SYSTEM", status: "N/A" },
            { name: "DOOR LOCKING MECHANISM", status: "N/A" },
            { name: "GASKET", status: "N/A" },
            { name: "THERMAL SWITCH", status: "N/A" },
            { name: "LEAK TESTS", status: "N/A" },
            {
              name: "ETHYLENE OXIDE CARTRIDGE MOUNTING MACHINE",
              status: "N/A",
            },
          ],
        },
        {
          name: "FLASH AUTOCLAVE",
          parameter: [
            { name: "DISPLAY AND KEYPAD", status: "N/A" },
            { name: "GASKET CLEANING", status: "N/A" },
            { name: "DOOR LOCKING", status: "N/A" },
            { name: "TEMPERATURE INDICATION", status: "N/A" },
            { name: "WATR LEVEL INDICATION", status: "N/A" },
            { name: "DOOR LOCKING INDICAION", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
          ],
        },
        {
          name: "ANAESTHESIA MACHINE",
          parameter: [
            { name: "AC MAINS/POWEP CORD/BATTERY BACK UP", status: "N/A" },
            {
              name: "DISASSEMBLY OF BREATHING SYSTEMS AND BELLOWS ASSEMS ASSEMBLY FOR CLEANING",
              status: "N/A",
            },
            {
              name: "CLEANING OF FILTER /FLOW SENSORS FIT NEW SEALS BODOKS,RING",
              status: "N/A",
            },
            { name: "LEAKAGE  TEST WITHOUT VAPORIZER", status: "N/A" },
            { name: "LEAKAGE TEST WITH VAPORIZER", status: "N/A" },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY CYLINDER",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY PIPE LINE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF OXIGEN FAILURE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF CIRCLE ABSORBER",
              status: "N/A",
            },
            { name: "CHECK FOR PROPER MOVEMENTS OF WHEELS", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
            { name: "CALIBRATION OF OXIGEN SENSORS", status: "N/A" },
            {
              name: "FINAL TEST OF TIDAL VOLUME RATE AND I:E RATIO ,FIO2",
              status: "N/A",
            },
          ],
        },
        {
          name: "HARMONIC SCALPEL",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "All Switches Function", status: "N/A" },
            { name: "High Frequency Module Checking", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
          ],
          powerSupply: [
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+5V(RANGE0.15)AT  TP8",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE+/- 0.36)AT TP6",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE +/-0.36)AT TP5",
              observedValue: "",
            },
          ],
        },
        {
          name: "LIGHT SOURCE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Power ON Indicator", status: "N/A" },
            { name: "Fan Function", status: "N/A" },
            { name: "Adapter for Light Cable", status: "N/A" },
          ],
        },
        {
          name: "LITHOCLAST",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display Functions", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
          ],
        },
        {
          name: "MDICAL MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "REMOTE FUNCTION", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "ORTHO DRILL",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Motor Function", status: "N/A" },
            { name: "Hand Unit Function", status: "N/A" },
            { name: "Forward/Reverse Function", status: "N/A" },
            { name: "Speed Variation Function", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Indicators", status: "N/A" },
          ],
        },
        {
          name: "OT  LIGHT",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Optical Assembly", status: "N/A" },
            { name: "Different Positioning of Domes", status: "N/A" },
            { name: "Dome  Intensity", status: "N/A" },
            { name: "Battery Backup Function", status: "N/A" },
            { name: "Light Beam Focusing", status: "N/A" },
          ],
        },
        {
          name: "OT TABLE",
          parameter: [
            { name: "PHYSICAL APPERANCE OF THE TABLE", status: "N/A" },
            { name: "NO HYDRAULIC FLUID LEAKAGE", status: "N/A" },
            { name: "MOVEMENT OF TABLE ON CASTORS", status: "N/A" },
            { name: "FIXATION OF TABLE TO THE GROUND", status: "N/A" },
            { name: "CONNECTIVITY OF TABLE WITH MAINS CORD", status: "N/A" },
            { name: "LED INDICATION ON REMOTE/ CONTROL PANEL", status: "N/A" },
            {
              name: "OPERATION OF THE  TABLE  BY REMOTE CONTROL PANEL",
              status: "N/A",
            },
            {
              name: "OPERATION OF TABLE  BY OVERRIDE /EMERGENCY CONTROL PANEL",
              status: "N/A",
            },
            { name: "OPERATION OF TABLE  ON BACK UP BATTERIES", status: "N/A" },
            {
              name: "LOCKING SYSTEM AND FIXATION OF VARIOUS  PARTS (HEAD REST,LEG SUPPORTS EXTENSIONS ETC.)",
              status: "N/A",
            },
            { name: "FIXATION OF ACCESSORIES TO THE TABLE ", status: "N/A" },
            { name: "CORRECT POSITIONING OF CUSHIONS", status: "N/A" },
            { name: "BATTERY VOLTAGE  24+/-2 V DC", status: "N/A" },
          ],
        },
        {
          name: "STEEM STERILIZER",
          parameter: [
            { name: "POWER  SUPPLY", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SAFETY  VALVE,TEMPERATURE GUAGE", status: "N/A" },
            { name: "PRESSURE  GUAGE", status: "N/A" },
            { name: "HEATING COIL", status: "N/A" },
            { name: "LEAKAGE", status: "N/A" },
            { name: "OVER ALL CLEANING", status: "N/A" },
          ],
        },
        {
          name: "SUCTION MACHINE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Battery Voltage", status: "N/A" },
            { name: "Tubings", status: "N/A" },
            { name: "Suction Container", status: "N/A" },
            { name: "Pressure", status: "N/A" },
            { name: "Earth Resistance", status: "N/A" },
            { name: "Leakage Current", status: "N/A" },
          ],
        },
        {
          name: "SYRINGE PMP",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEY PAD&DISPLAY", status: "N/A" },
            { name: "ALARM FUNCTION", status: "N/A" },
            { name: "OCCULSION", status: "N/A" },
            { name: "NEARLY EMPTY", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SYRINGE DETECTION", status: "N/A" },
            { name: "10ML,20ML", status: "N/A" },
            { name: "50ML", status: "N/A" },
          ],
        },
        {
          name: "TOURNIQUET",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Pressure bag and hose function", status: "N/A" },
            { name: "Timer Function", status: "N/A" },
            { name: "Pump Function", status: "N/A" },
            { name: "All Switches", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },
      ],
    },

    {
      department: "Laboratory",
      equipment: [
        {
          name: "BIOCHEMISTRY ANALYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "SUCKING TUBE", status: "N/A" },
            { name: "MOVING ART", status: "N/A" },
            { name: "ELECTRICAL SAFEY", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING ", status: "N/A" },
          ],
        },
        {
          name: "BIOSAFETY CABINET",
          parameter: [
            { name: "Proper leveling and Stability", status: "N/A" },
            {
              name: "Proper flicker free operation of Florescent light",
              status: "N/A",
            },
            {
              name: "Proper operation of work chamber UV light",
              status: "N/A",
            },
            {
              name: "Proper operation of exhaust UV light through the check hole in the chamber,located above the gas cock",
              status: "N/A",
            },
            { name: "Manometer float lifting", status: "N/A" },
            {
              name: "Operation of all electrical switches on the control panel",
              status: "N/A",
            },
            {
              name: "Checked the gas leakage from the gas cock with soap solution",
              status: "N/A",
            },
            { name: "Checked the smooth door operation", status: "N/A" },
          ],
        },
        {
          name: "BLOOD CULTURE SYSTEM",
          parameter: [
            { name: "Power switch", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Key pad nad LCD display", status: "N/A" },
            { name: "Home Rotor Key/Rotor", status: "N/A" },
            { name: "Alaram Indicator", status: "N/A" },
            { name: "Barcode reader function ", status: "N/A" },
            { name: "Temperature verification", status: "N/A" },
            { name: "New positive indicator", status: "N/A" },
            { name: "Door interlock switch", status: "N/A" },
            { name: "Floppy Disk Drive", status: "N/A" },
            { name: "System start UP", status: "N/A" },
            { name: "Air Filter cleaning", status: "N/A" },
          ],
        },
        {
          name: "BLOOD MIXER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "ROTATING ARM", status: "N/A" },
            { name: "ROTATING SPEED", status: "N/A" },
            { name: "CUSION ON ROTATING ARM", status: "N/A" },
            { name: "TRAY", status: "N/A" },
            { name: "EARTHING", status: "N/A" },
          ],
        },
        {
          name: "CENTRIFUGE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "TUBES", status: "N/A" },
            { name: "CARBON BRUSHES", status: "N/A" },
            { name: "CLEANINESS", status: "N/A" },
            { name: "LUBRICANT", status: "N/A" },
            { name: "ELECTRICAL SAFETY", status: "N/A" },
          ],
        },
        {
          name: "COAGULOMETER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECTRICAL SAFETY", status: "N/A" },
            { name: "CHNNEL", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "PAPER", status: "N/A" },
            { name: "LAMP", status: "N/A" },
          ],
        },
        {
          name: "COBLATOR",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "All Switches Function", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },
        {
          name: "ELOCTOLYTE ANLYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SCREEN", status: "N/A" },
            { name: "BUTTONS", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "SAMPLE SUCKING", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "OVER ALL CLEANINESS", status: "N/A" },
          ],
        },
        {
          name: "ELISA READER",
          parameter: [
            { name: "Power integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Equipment filters", status: "N/A" },
            { name: "Equipment lamp", status: "N/A" },
            { name: "Measuring tray movement", status: "N/A" },
            { name: "Dispensing and aspiration", status: "N/A" },
            { name: "Switch function", status: "N/A" },
            { name: "Equipment display", status: "N/A" },
            { name: "Tubing", status: "N/A" },
            { name: "Earth resistance", status: "N/A" },
          ],
        },
        {
          name: "ENT MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "Lamps", status: "N/A" },
            { name: "Connectors", status: "N/A" },
            { name: "Objectives", status: "N/A" },
            { name: "Condenser", status: "N/A" },
            { name: "Mechanical Movements", status: "N/A" },
          ],
        },
        {
          name: "HEMOSTASIS  ANALYZER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "TOUCH SCREEN", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "SAMPLE  SUKING", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "OVER ALL CLEANINESS", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "SEMI AUTO MATIC ANALYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "SUCKING TUBE", status: "N/A" },
            { name: "SAMPLE AREA", status: "N/A" },
            { name: "ELECTRICAL SAFEY", status: "N/A" },
            { name: "TOUCH SCREEN", status: "N/A" },
            { name: "INTERNAL CLEANING ", status: "N/A" },
            { name: "LAMP", status: "N/A" },
            { name: "TUBINS", status: "N/A" },
          ],
        },
        {
          name: "VDRL SHAKER",
          parameter: [
            { name: "Power integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch functions", status: "N/A" },
            { name: "Mechanical movements", status: "N/A" },
            { name: "Leeds", status: "N/A" },
            { name: "Rollers", status: "N/A" },
            { name: "Earth resistance", status: "N/A" },
            { name: "Leakage current", status: "N/A" },
          ],
        },
      ],
    },

    {
      department: "Radiology",
      equipment: [
        {
          name: "ECHO MACHINE(HAND HELD)",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "ECHO PROBE", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "CR PRINTER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PHYSICAL APPERANCE", status: "N/A" },
            { name: "CONTRAST", status: "N/A" },
            { name: "PRINT QUALITY", status: "N/A" },
            { name: "SELF TEST SEQUENCE", status: "N/A" },
          ],
        },
        {
          name: "CR READER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PHYSICAL APPERANCE", status: "N/A" },
            { name: "LOADING SLOT", status: "N/A" },
            { name: "SCANNING TIME", status: "N/A" },
            { name: "EJECTION", status: "N/A" },
            { name: "CLEANESS", status: "N/A" },
          ],
        },
        {
          name: "PRESSURE INJECTOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "CONTROLS & SETTINGS", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "MECHANICAL ASSEMBLY CHECKING", status: "N/A" },
            { name: "PARAMETERS & SAMPLE  READINGS", status: "N/A" },
            { name: "CHECK FOR ALL SCREWS, KNOBS", status: "N/A" },
          ],
        },
        {
          name: "STEEM STERILIZER",
          parameter: [
            { name: "POWER  SUPPLY", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SAFETY  VALVE,TEMPERATURE GUAGE", status: "N/A" },
            { name: "PRESSURE  GUAGE", status: "N/A" },
            { name: "HEATING COIL", status: "N/A" },
            { name: "LEAKAGE", status: "N/A" },
            { name: "OVER ALL CLEANING", status: "N/A" },
          ],
        },
        {
          name: "ULTRASOUND",
          parameter: [
            { name: "SURFACE CLEANING/INTERNAL CLEANING", status: "N/A" },
            { name: "CHECKED ELECTRICAL CONNECTION AND CABLE", status: "N/A" },
            { name: "IMAGE QUALITY", status: "N/A" },
            { name: "TRANSDUCER CONNECTION", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
          ],
        },
      ],
    }
    
  ];

  const handleEquipment = (e, value) => {
    setDropdown(value);
    setBrand("");
    setAssetCode("");
    setAssetName("");
    setDoneBy("");
    setRemark("");
    setStartDate("");
    setEndDate("");
    setSingleDepartment("");
    setSerialNumber("");
    setModelNumber("");
    setCheck([]);

    let selectedEquipment = department?.equipment?.filter((name) => {
      return name?.name === value;
    });

    setCheck([...selectedEquipment]);
    setFlag(false);
  };

  const handleChangeStatus = (index, value) => {
    setSelectedOption(value);
    const updatedParameters = [...check];
    updatedParameters[0].parameter[index].status = value;
    setCheck(updatedParameters);
    setIsParameterError("");
    setFlag(false);
  };

  const handleChangeObservedValue = (e, index) => {
    const value = e.target.value;
    const updatedObservedValue = [...check];
    updatedObservedValue[0].powerSupply[index].observedValue = value;
    setCheck(updatedObservedValue);
    setIsParameterError("");
    setFlag(false);
  };

  const handleAssetName = (e, newValue) => {
    if (newValue !== null && newValue !== undefined) {
      setAssetName(newValue.label);
      filterDetails(newValue.value);
      setIsAssetNameError("");
      setFlag(false);
    } else {
      setIsAssetNameError("Please fill the field");
      setBrand("");
      setAssetCode("");
      setSingleDepartment("");
      setSerialNumber("");
      setModelNumber("");
    }
  };

  const hanldeStartDate = (date) => {
    setStartDate(date);
    handleDate(date, endDate);
    setFlag(false);
  };

  const hanldeEndDate = (date) => {
    setEndDate(date);
    handleDate(startDate, date);
    setFlag(false);
  };

  const handleDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    if (start > end) {
      setIsDateError("Invalid date range");
    } else if (start.getTime() === end.getTime()) {
      setIsDateError("Start date should be before end date");
    } else {
      setIsDateError("");
    }
  };

  const handleRemark = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;

    if (value === "" || regex.test(value)) {
      setRemark(value);
      setIsRemarkError("");
      setFlag(false);
    }
    if (value !== "" && !regex.test(value)) {
      setIsRemarkError("Invalid character");
    }
    if (value === "") {
      setIsRemarkError("Please fill the field");
    }
  };

  const handleDoneBy = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z]+$/;
    if (value === "" || regex.test(value)) {
      setDoneBy(value);
      setFlag(false);
      setIsDoneByError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsDoneByError("wrong Name");
    }
    if (value === "") {
      setIsDoneByError("Please fill the field");
    }
  };

  const filterDetails = (asset) => {
    let filterData = getAllData?.filter((item) => {
      return item.assetid === asset;
    });

    let singleData = filterData[0];
    setSerialNumber(singleData?.serialno);
    setModelNumber(singleData?.modelno);
    setBrand(singleData?.brand);
    setAssetCode(singleData?.assetcode);
    setSingleDepartment(singleData?.department);
    setAssetId(singleData?.assetid);
    setDepartmentId(singleData.department_id);
    setIframeData({...singleData})
    console.log("singleData",singleData)
  };


  console.log("Iframe Data", iframeData)

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(getPmAsset(data, token));
  }, [dispatch]);

  useEffect(() => {
    setColor(selectedDepartment);

    let value = selectedDepartment && selectedDepartment.department;

    let filterData = data2?.filter((item) => {
      return item.department === value;
    });

    setDepartment(...filterData);
  }, [selectedDepartment]);

  useEffect(() => {
    if (assetData && assetData?.length !== 0) {
      setGetAllData(assetData);
    }
  }, [assetData, equipment]);

  const hasEmptyStatus = check[0]?.parameter.some((item) => {
    return item.status !== "N/A";
  });
  const formattedStartDate = dayjs(startDate).format("DD-MM-YYYY");
  const formattedEndDate = dayjs(endDate).format("DD-MM-YYYY");

  const handleSubmit = () => {
    (endDate === "" || startDate === "") &&
      setIsDateError("Please fill the field");
    doneBy === "" && setIsDoneByError("Please fill the field");
    remark === "" && setIsRemarkError("Please fill the field");
    doneBy === "" && setIsDoneByError("Please fill the field");
    assetName === "" && setIsAssetNameError("Please fill the field");
    hasEmptyStatus === false &&
      setIsParameterError("Please fill the parameter");
      setSubmittedName(doneBy);
      setSubmitDate(dayjs().format("YYYY-MM-DD"));

    if (
      isAssetNameError === "" &&
      isDateError === "" &&
      isDoneByError === "" &&
      isModelNumberError === "" &&
      isRemarkError === "" &&
      isSerialNumberError === ""
    ) {
      if (
        assetName !== "" &&
        serialNumber !== "" &&
        modelNumber !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        remark !== "" &&
        doneBy !== "" &&
        hasEmptyStatus === true
      ) {
        let data = {
          userid: userIds?.userid,
          branchname: userBranch.branch,
          hospname: userIds?.hospname,
          username: userIds?.username,
          hospid: userIds?.hospid,
          hosp_name: userIds?.hospname,
          asset_name: assetName,
          asset_id: assetId,
          asset_code: assetCode,
          brand: brand,
          serial_no: serialNumber,
          model_no: modelNumber,
          department: singleDepartment,
          pm_done_date: formattedStartDate,
          pm_due_date: formattedEndDate,
          remarks: remark,
          done_by: doneBy,
          department_id: departmentId,
          parameter: check[0]?.parameter,
          branchid: userBranch.id,
          power_supply:
            check[0]?.powerSupply === undefined ? [] : check[0]?.powerSupply,
        };

        setFlag(true);
        setIsParameterError("");
        setIframeData(data)
        dispatch(postPmCheckList(data, token));
      } else {
      }
    } else {
    }
  };

  const handleDownload = () => {

console.log("check on test" ,check)

// console.log("parameter")




    const pdf = new jsPDF();
    pdf.setFillColor(255, 251, 244);
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(16);

    pdf.text(
      "DEPARTMENT OF BIOMEDICAL ENGINEERING",
      pdf.internal.pageSize.width / 2,
      15,
      "center"
    );

    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(16);

    pdf.text("PM CHECKLIST", pdf.internal.pageSize.width / 2, 25, "center");

    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(12);
    pdf.text(`Asset Name:`, 20, 40);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.asset_name}`, 70, 40);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Serial Number:`, 20, 50);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.serial_no}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Model Number:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.model_no}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.department}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Done Date:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.pm_done_date}`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Due Date:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.pm_due_date}`, 70, 90);

    if (iframeData.parameter.length > 0) {
      const parameterData = iframeData.parameter.map((param) => [
        param.name,
        param.status,
      ]);

      pdf.autoTable({
        head: [["Parameter Name", "Status"]],
        body: parameterData,
        startY: 100,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] },
      });
    }

    if (iframeData?.power_supply?.length > 0) {
      const powerSupplyData = iframeData?.power_supply.map((supply) => []);

      pdf.autoTable({
        head: [["Power Supply Details"]],
        body: powerSupplyData,
        startY: pdf.autoTable.previous.finalY + 10,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] },
      });
    }

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Remarks:`, 20, pdf.autoTable.previous.finalY + 10);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.remarks}`, 70, pdf.autoTable.previous.finalY + 10);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Done By:`, 20, pdf.autoTable.previous.finalY + 20);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.done_by}`, 70, pdf.autoTable.previous.finalY + 20);
    pdf.save("PMChecklist.pdf");
  };

//   useEffect(() => {
// if(check?.length>0){
//   setIframeData({...iframeData,...check[0]})
// }


    
//   }, [check]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const AssetData = {
    "Serial No.": serialNumber,
    Department: singleDepartment,
    "Model No.": modelNumber,
    Brand: brand,
  };
  return (
 
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // height: "100vh",
          
        }}>

        <OuterSet>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
          
              bgcolor: "white",

              padding: "15px",
            }}>
            <Label variant="h6" color="primary">
              Record PM Checklist
            </Label>
            {submittedName && (
          <Typography variant="h6" sx={{fontWeight:"bold"}}>By: {submittedName}</Typography>
        )}
        {submitDate && (
          <Typography variant="h6" sx={{fontWeight:"bold"}}>On: {submitDate}</Typography>
        )}
          </Stack>

          <Box sx={{ bgcolor: "white", padding: "20px", minHeight: "350px" }}>
            <Stack sx={{ width: "100%", height: "100%" }}>
              {/* <Stepper activeStep={activeStep} nonLinear>
                <Step key={0}>
                  <StepLabel>Step 1</StepLabel>
                </Step>
                <Step key={1}>
                  <StepLabel>Step 2</StepLabel>
                </Step>
                <Step key={2}>
                  <StepLabel>Step3</StepLabel>
                </Step>
              </Stepper> */}
              {activeStep === 0 && (
                <>
                  <FirstSet>
                    <InputSet>
                      <Autocomplete
                        options={departments}
                        getOptionLabel={(option) => option.department}
                        value={selectedDepartment}
                        onChange={handleSelectedDepartment}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            label="Choose Department"
                            variant="filled"
                            sx={{ width: "100%" }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              sx: {
                                "& input:focus": {
                                  backgroundColor: "transparent",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </InputSet>

                    <InputSet >
                      <Autocomplete
                        disabled={!color}
                        sx={{ bgcolor: "#EFF5FE" }}
                        value={color && dropdown}
                        options={
                          color &&
                          department?.equipment?.map((option) => option.name)
                        }
                        onChange={handleEquipment}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            label="Choose Equipment"
                            variant="filled"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              sx: {
                                "& input:focus": {
                                  backgroundColor: "transparent",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </InputSet>
                  </FirstSet>

                  <Stack
                    sx={{
                      alignItems: "flex-end",
                      padding: "20px"
                    }}>
                    <Button
                      variant="contained"
                      sx={{ width: "150px", borderRadius: "22px" }}
                      disabled={!selectedDepartment || !dropdown }
                      onClick={handleNext}>
                      Next
                    </Button>
                  </Stack>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <FirstSet>
                    <InputSet>
                      <Label sx={{textAlign:'left'}}>Asset Details</Label>
                      <Autocomplete
                        sx={{ bgcolor: "#EFF5FE" }}
                        id="free-solo-demo"
                        onChange={handleAssetName}
                        placeholder="e.g. ventilator"
                        options={getAllData?.map((option) => ({
                          label: `${option?.name} (${option?.assetcode})`,
                          value: option?.assetid,
                        }))}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Asset Name" />
                        )}
                      />
                      {assetName && (
                        <Box
                          sx={{
                            width: "100%",

                            bgcolor: "rgba(244, 246, 246, 1)",
                          }}>
                          <TableContainer
                            style={{ overflowX: "auto", maxWidth: "100%" }}
                            sx={{}}>
                            <Table sx={{ maxWidth: "100%" }}>
                              <TableBody sx={{padding:'10px'}}>
                                {Object.entries(AssetData).map(
                                  ([key, value]) => (
                                    <TableRow key={key}>
                                      <TableCells
                                        component="th"
                                        scope="row"
                                        sx={{ color: "rgba(27, 37, 53, 1)" }}>
                                        {key}
                                      </TableCells>

                                      <TableCells sx={{}}>{value}</TableCells>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )}
                    </InputSet>
                    <InputSet
                      sx={{ width: "50%", gap: "20px", textAlign: "start" }}>
                      <Label>Incharge and due date</Label>
                      <Stack>
                        <Field
                          label="Name"
                          variant="filled"
                          placeholder="e.g. Joseph"
                          onChange={handleDoneBy}
                          value={doneBy}
                          InputProps={{ disableUnderline: true }}
                        />
                        {<HelperText>{isDoneByError}</HelperText>}
                      </Stack>

                      <Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Start Date"
                            slotProps={{
                              textField: {
                                error: false,
                                variant: "filled",
                              },
                            }}
                            format="DD-MM-YYYY"
                            sx={{
                              border: "none",
                              backgroundColor: "rgba(244, 246, 246, 1)",
                              "& label.Mui-focused": {
                                color: "rgba(166, 166, 166, 1)",
                              },
                              "& .MuiFilledInput-root": {
                                backgroundColor: "rgba(244, 246, 246, 1)",
                              },
                              "&:hover .MuiFilledInput-root": {
                                backgroundColor: "rgba(244, 246, 246, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                            }}
                            value={startDate}
                            onChange={hanldeStartDate}
                            InputProps={{ disableUnderline: true }}
                          />
                        </LocalizationProvider>
                        {/* {<HelperText>{isDateError}</HelperText>} */}
                      </Stack>
                      <Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="End Date"
                            slotProps={{
                              textField: {
                                error: false,
                                variant: "filled",
                              },
                            }}
                            format="DD-MM-YYYY"
                            sx={{
                              border: "none",
                              backgroundColor: "rgba(244, 246, 246, 1)",
                              "& label.Mui-focused": {
                                color: "rgba(166, 166, 166, 1)",
                              },
                              "& .MuiFilledInput-root": {
                                backgroundColor: "rgba(244, 246, 246, 1)",
                              },
                              "&:hover .MuiFilledInput-root": {
                                backgroundColor: "rgba(244, 246, 246, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                            }}
                            value={endDate}
                            onChange={hanldeEndDate}
                            InputProps={{ disableUnderline: true }}
                          />
                        </LocalizationProvider>
                        {<HelperText>{isDateError}</HelperText>}
                      </Stack>
                    </InputSet>
                  </FirstSet>

                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "20px",
                      // width: "100%",
                      flexDirection: "row",
                    }}>
                    <KeyboardBackspaceIcon
                      size={20}
                      onClick={handleBack}
                      style={{ color: "#4690FF", cursor: "pointer" }}
                    />

                    <Button
                      variant="contained"
                      sx={{ width: "150px", borderRadius: "22px" }}
                      disabled={!selectedDepartment ||  !assetName || !startDate || !endDate ||
                        isDateError}
                      onClick={handleNext}>
                      Next
                    </Button>
                  </Stack>
                </>
              )}
              {activeStep === 2 && (
                <Stack sx={{ gap: "20px" }}>
                  {check?.length > 0 && (
                    <Stack sx={{ marginTop: "30px" }}>
                      <Label sx={{ textAlign: "start", padding: "10px" }}>
                        Parameters
                      </Label>
                      {check[0]?.parameter?.map((item, index) => (
                        <ParaBox key={index}>
                          <Typography
                            sx={{
                              bgcolor: "#F4F6F6",
                               width: "100%",
                              height: "100%",
                              textAlign: "start",
                              display: "flex",
                              alignItems: "center",
                              padding: "10px",
                            }}>
                            {item?.name}
                          </Typography>

                          <Stack
                            sx={{
                               width: "100%",
                              flexDirection: "row",
                              gap: "10px",

                              alignItems: "center",
                            }}>
                            <Button
                              variant={
                                item.status === "Pass"
                                  ? "contained"
                                  : "outlined"
                              }
                              sx={{
                                borderRadius: "22px",
                                bgcolor:
                                  item.status !== "Pass" ? "white" : "#4690FF",
                                width: "100px",
                              }}
                              onClick={() => handleChangeStatus(index, "Pass")}>
                              Pass
                            </Button>
                            <Button
                              sx={{
                                borderRadius: "22px",
                                bgcolor:
                                  item.status !== "Fail" ? "white" : "#4690FF",
                                width: "100px",
                              }}
                              variant={
                                item.status === "Fail"
                                  ? "contained"
                                  : "outlined"
                              }
                              onClick={() => handleChangeStatus(index, "Fail")}>
                              Fail
                            </Button>
                            <Button
                              sx={{
                                borderRadius: "22px",
                                bgcolor:
                                  item.status !== "N/A" ? "white" : "#4690FF",
                                width: "100px",
                              }}
                              variant={
                                item.status === "N/A" ? "contained" : "outlined"
                              }
                              onClick={() => handleChangeStatus(index, "N/A")}>
                              N/A
                            </Button>
                          </Stack>
                        </ParaBox>
                      ))}
                      {check[0]?.powerSupply !== undefined && (
                        <>
                          <SubTitle>Power Supply</SubTitle>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}>
                            <ItemText
                              sx={{
                                textTransform: "none",
                                color: "#4690FF",
                              }}>
                              Parameter
                            </ItemText>
                            <ItemText
                              sx={{
                                textTransform: "none",
                                color: "#4690FF",
                              }}>
                              Selected Value
                            </ItemText>

                            <RadioContainer sx={{ width: "40%" }}>
                              <ItemText
                                sx={{
                                  width: "100%",
                                  textTransform: "none",
                                  color: "#4690FF",
                                }}>
                                Observed Value
                              </ItemText>
                            </RadioContainer>
                          </Box>
                        </>
                      )}
                      {check[0]?.powerSupply !== undefined &&
                        check[0]?.powerSupply?.map((item, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}>
                            <ItemText>{item?.supplyParameter}</ItemText>
                            <ItemText>{item?.selectedValue}</ItemText>

                            <RadioContainer sx={{ width: "40%" }}>
                              <OutlinedInput
                                onChange={(e) =>
                                  handleChangeObservedValue(e, index)
                                }
                                value={item.observerdValue}
                                placeholder="value..."
                              />
                            </RadioContainer>
                          </Box>
                        ))}
                    </Stack>
                  )}

                  <Stack
                    sx={{
                      marginTop: "5px",

                      width: "100%",
                    }}>
                    <FormElement sx={{ textAlign: "start" }}>
                      <Label sx={{ padding: "10px" }}>Remark</Label>
                      <Field
                        multiline
                        rows={3}
                        sx={{}}
                        label="Remark"
                        value={remark}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        onChange={handleRemark}
                      />
                      {<HelperText>{isRemarkError}</HelperText>}
                    </FormElement>
                  </Stack>
                  {<HelperText>{isParameterError}</HelperText>}

                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}>
                    <KeyboardBackspaceIcon
                      size={20}
                      onClick={handleBack}
                      style={{ color: "#4690FF", cursor: "pointer" }}
                    />

{console.log("pmChecklistData",pmChecklistData)}

                    {pmChecklistData?.reportId !== undefined && flag ? (
                      <PdfButton
                        onClick={handleDownload}
                        sx={{
                          "&:hover": {
                            transform: "scale3d(1.05, 1.05, 2)",
                            cursor: "pointer",
                            color: "#F78117",
                          },
                        }}>
                        Download PDF
                      </PdfButton>
                    ) : (
                      <>
                        <Button
                          sx={{ borderRadius: "22px", width: "150px" }}
                          onClick={handleSubmit}
                          disabled={userAccess?.role === "Guest"}
                          variant="contained">
                          Submit
                        </Button>
                      </>
                    )}
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
        </OuterSet>
      </Stack>
   
  );
};

export default MyComponent;
