import React, { useEffect, useState } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { Routes } from "react-router-dom";
import Callibration from "../pages/Callibration";

import OpenServices from "../pages/OpenServices";

import Department from "../pages/Department";

import CalibrateNotRequired from "../pages/CalibrateNotRequired";

import DepartmentAssetDetails from "../pages/DepartmentAssetDetails";
import CloseService from "../pages/CloseService";
import ClosedIncident from "../pages/ClosedIncident";
import OpenIncident from "../pages/OpenIncident";

import { allUserList, logOut, retrieveAllAssets } from "../redux/data/action";
import { useDispatch, useSelector } from "react-redux";
import ReloginModal from "../components/ReloginModal";

import { BulkUploadDepartment } from "../pages/BulkUploadDepartment";
import Plans from "../pages/Plans";
import Amc from "../pages/Amc";
import Cmc from "../pages/Cmc";

import NotificationTab from "../pages/NotificationTab";
import PmChoose from "../pages/PmChoose";
import AccessCheckModal from "./../components/AccessCheckModal";
import DepartmentAssetDelete from "../pages/DepartmentAssetDelete";
import AllAsset from "../pages/AllAsset";
import DepartmentList from "../pages/DepartmentList";

import DepreciationCalculator from "../pages/DepreciationCalculator";
import VendorList from "../pages/VendorList";
import ServiceCompanyList from "../pages/ServiceCompanyList";
import ContactPage from "../pages/ContactPage";
import CheckInpage from "../pages/CheckInPage";
import UserManualList from "../pages/UserManualList";
import RequestPage from "../pages/RequestPage";
import IncidentListPage from "../pages/IncidentListPage";
import ServiceListPage from "../pages/ServiceListPage";
import UserListPage from "../pages/UserListPage";
import ReportPage from "../pages/ReportPage";
import EditProfile from "../pages/EditProfile";
import RecorderIncidentPage from "../pages/RecorderIncidentPage";
import ServiceDetail from "../pages/ServiceDetail";
import ServiceRequest from "../pages/ServiceRequest";
import QrCodeScanner from "../components/QrCodeScanner";
import AddUserPage from "../pages/AddUserPage";
import AssetDetailPage from "../pages/AssetDetailPage";
import IncidentDetailPage from "../pages/IncidentDetailPage";
import AddCheckOut from "../pages/AddCheckOut";
import AddAssetPage from "../pages/AddAssetPage";
import EditUserPage from "../pages/EditUserPage";
import Calendar from "../pages/Calendar";
import Calendar2 from "../pages/Calendar2";

import ClearIncident from "../pages/ClearIncident";
import ClearService from "../pages/ClearService";
import EditAsset from "../pages/EditAsset";
import DashboardPage from "../pages/DashboardPage";
import SellOnlinePage from "../pages/SellOnlinePage";
import NotCalibratedPage from "../pages/NotCalibratedPage";
import TaskListPage from "../pages/TaskListPage";
import AssetsMobile from "../pages/AssetsMobile";
import ProfilePage from "../pages/ProfilePage";
import AllAssetHistory from "../pages/AssetHistoryList";
import TaskTest from "../pages/TaskTest";
import IncidentMobile from "../pages/IncidentMobile";
import RequestMobile from "../pages/RequestMobile";
import UserMobile from "../pages/UserMobile";
import DashboardMobile from "../pages/DashboardMobile";
import Report from "../pages/Report";
import TestExp from "../pages/TestExp";
import CheckinOutDetailPage from "../pages/CheckinOutDetailPage";
import QrCodeError from "../components/QrCodeError";
import TestingComponent from "../components/TestingComponent";
import NotWorkingAssetPage from "../pages/NotWorkingAssetPage";
import Discarded from "../pages/Discarded";
import EditDepartment from "../pages/EditDepartment";

function PrivateRoute() {
  const [dataLendth, setDataLength] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const invalidToken = useSelector((store) => store?.data?.invalidToken);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let checkAccess = userAccess === null ? false : userAccess.role === "NA";

  const authenticationError = useSelector(
    (store) => store?.data?.authenticationError
  );
  const allAssets = useSelector((store) => store.data.allAssets);
  const userData = useSelector((store) => store.data.usersList);

  useEffect(() => {
    if (authenticationError === "AUTHENTICATION_ERROR" || !userBranch) {
      let data = {
        userid: userIds.userid,
      };
      dispatch(logOut(data, token));
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  }, [authenticationError, userBranch]);

  React.useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(allUserList(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(userData)?.length > 0) {
      setDataLength(userData.users);
    }
  }, [userData]);

  return (
    <>
      {invalidToken === "INVALID_TOKEN" ? (
        <ReloginModal status={invalidToken} />
      ) : (
        authenticationError !== "AUTHENTICATION_ERROR" &&
        (checkAccess ? (
          <AccessCheckModal status={checkAccess} text="Biomedical" />
        ) : (
          <Routes>
            <Route path="*" element={<DashboardPage />} />
            <Route path="/" element={<DashboardPage />} />
            <Route path="/home" element={<DashboardPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/profile" element={<ProfilePage />} />

            {(userIds.role === "Super_Admin" || userIds.role === "Owner") && (
              <Route path="/edit-profile" element={<EditProfile />} />
            )}

            <Route path="/report" element={<ReportPage />} />
            <Route path="/user" element={<UserListPage />} />
            <Route path="/user/:id" element={<EditUserPage />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard" ||
              (userIds.subs_plan === "Basic" && dataLendth?.length < 1)) && (
              <Route path="/adduser" element={<AddUserPage />} />
            )}
            <Route path="/incident-list" element={<IncidentListPage />} />
            <Route path="/incident-list/:id" element={<IncidentDetailPage />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard" ||
              (userIds.subs_plan === "Basic" && allAssets?.length <= 49)) && (
              <Route path="/add-asset" element={<AddAssetPage />} />
            )}

            <Route path="/asset-detail/:id" element={<AssetDetailPage />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard") && (
              <Route path="/user-manual" element={<UserManualList />} />
            )}

            <Route path="/calibration" element={<Callibration />} />
            <Route path="/not-calibrated" element={<NotCalibratedPage />} />
            <Route path="/editDepart/:id" element={<EditDepartment />} />
            <Route
              path="/calibration-not-required"
              element={<CalibrateNotRequired />}
            />
            <Route path="/add-department" element={<Department />} />
            <Route path="/all-assets" element={<AllAsset />} />
            <Route path="/service-request/:id" element={<ServiceRequest />} />
            {/* <Route path="/service-medpick/:id" element={<ServiceMedpick />} />
            <Route path="/service-others/:id" element={<ServiceOther />} /> */}
            <Route path="/closed-service" element={<CloseService />} />
            <Route path="/closed-incident" element={<ClosedIncident />} />
            <Route path="/open-incident" element={<OpenIncident />} />
            <Route path="/open-service" element={<OpenServices />} />
            <Route path="/service-list" element={<ServiceListPage />} />
            <Route path="/service-list/:id" element={<ServiceDetail />} />
            <Route path="/clear-service/:id/:id2" element={<ClearService />} />
            <Route path="/edit-asset/:id" element={<EditAsset />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/pm-checklist" element={<PmChoose />} />
            <Route path="/m-report" element={<Report />} />
            <Route path="/depreciation" element={<DepreciationCalculator />} />
            <Route
              path="/checkin-checkout-details/:id"
              element={<CheckinOutDetailPage />}
            />

            <Route path="/clear-incident/:id" element={<ClearIncident />} />
            <Route path="/test-exp" element={<TestExp />} />
            <Route
              path="/record-incident/:id"
              element={<RecorderIncidentPage />}
            />
            <Route
              path="/department-asset-details/:id"
              element={<DepartmentAssetDetails />}
            />
            <Route
              path="/department-asset-delete-details/:id"
              element={<DepartmentAssetDelete />}
            />

            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard") && (
              <Route
                path="/bulk-upload-department"
                element={<BulkUploadDepartment />}
              />
            )}

            <Route path="/amc" element={<Amc />} />
            <Route path="/cmc" element={<Cmc />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/task" element={<TaskListPage />} />
            <Route path="/task-test" element={<TaskTest />} />
            <Route path="/calendar" element={<Calendar2 />} />
            <Route path="/calendar2" element={<Calendar2 />} />
            <Route path="/notification" element={<NotificationTab />} />
            <Route path="/scan" element={<QrCodeScanner />} />
            <Route path="/department-list" element={<DepartmentList />} />
            <Route path="/vendor" element={<VendorList />} />
            <Route path="/service-company" element={<ServiceCompanyList />} />
            <Route path="/check-in" element={<CheckInpage />} />
            <Route path="/check-out" element={<AddCheckOut />} />
            <Route path="/request" element={<RequestPage />} />
            <Route path="/sell-online" element={<SellOnlinePage />} />

            <Route path="/asset-mobile" element={<AssetsMobile />} />
            <Route path="/request-mobile" element={<RequestMobile />} />
            <Route path="/user-mobile" element={<UserMobile />} />
            <Route path="/dashboard-mobile" element={<DashboardMobile />} />
            <Route path="/incident-mobile" element={<IncidentMobile />} />

            <Route path="/asset-history-list" element={<AllAssetHistory />} />
            <Route path="/qrcode-error" element={<QrCodeError />} />
            <Route path="/test" element={<TestingComponent />} />
            <Route
              path="/not-working-asset"
              element={<NotWorkingAssetPage />}
            />
            <Route path="/Discarded" element={<Discarded />} />
          </Routes>
        ))
      )}
    </>
  );
}

export default PrivateRoute;
