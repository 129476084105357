import React from 'react'
import { Modal,Stack,IconButton,Typography,Button,styled,Box } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  background: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  height: "200px",
  borderRadius: "6px",
}));

const QrCodeError = () => {

  const [openModal, setOpenModal] = React.useState(false);

  const navigate = useNavigate()
const handleCloseModal = () => {
  setOpenModal(false);
};

const handleOpenModal = () => setOpenModal(true);
  return (
<Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <ModalBox>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}
          >
            <IconButton onClick={handleCloseModal}>
              <CloseIcon sx={{ fill: "#000" }} />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
              position: "absolute",
              top: "50px",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              You are not authorized to access this asset.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "150px", borderRadius: "22px", fontWeight: 600 }}
              onClick={() => navigate("/home")}
            >
              Okay
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
  )
}

export default QrCodeError
