import React from "react";
import Dialog from "@mui/material/Dialog";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Box,
  DialogActions,
  DialogTitle,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import CalendarPic from "../images/CalendarPic.png";
const CalenderModal = ({ open, onClose, modalClose }) => {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dialogStyle = {
    width: isSmallScreen ? "90vw" : isTabletScreen ? "60vw" : "25vw",
    height: "350px",
    borderRadius: "8px",
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      PaperProps={{ style: isSmallScreen ? dialogStyle : dialogStyle }}>
      <DialogTitle id="dialog-title"></DialogTitle>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: 300,
        }}>
        <Box sx={{ width: "70%", height: 230 }}>
          <img
            src={CalendarPic}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Typography
          id="dialog-description"
          style={{ color: "#00A884", marginTop: "2%" }}>
          Request Submitted Successfully
        </Typography>
      </Box>
      <DialogActions
        sx={{
          display: "flex",

          justifyContent: "end",
          alignItems: "end",
        }}>
        <Button
          onClick={modalClose}
          sx={{
            color: "black",

            textTransform: "none",
            marginBottom: "2%",
          }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalenderModal;
