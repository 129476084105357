import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  Button,
  styled,
  Divider,
  FormHelperText,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";


import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import "jspdf-autotable";

import {
  contactUs,
  createSubuser,

  getDepartmentData,
  getUserData,

} from "../redux/nbmData/action";
import SuccessPopUp from "../components/SuccessPopUp";
import { useEffect } from "react";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddUserModal from "../components/AddUserModal";
import { createSuperAdmin, userDetailCheck } from "../redux/nbmData/action";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const Label2 = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 500,
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const allOption = {
  departid: "all",
  departname: "All",
};
const Box1 = styled(Stack)(({ theme }) => ({
  width: "600px",

  borderRadius: "8px",

  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));
const Box2 = styled(Stack)(({ theme }) => ({
  // width: "50%",
  // marginRight: "16px",
  gap: "15px",

  height: "100%",
  textAlign: "start",

  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "",
  },
}));
const UserBox = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "20px",

  gap: "10px",
  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column",
  },
}));

// const AddUserPage = () => {
//   const navigate = useNavigate();
//   const [show, setShow] = useState(false);
//   const dispatch = useDispatch();
//   const token = localStorage.getItem("userToken");
//   const userList = useSelector((store) => store.data.userData);
//   let userAccess =
//     JSON.parse(sessionStorage.getItem("user_access")) ||
//     JSON.parse(localStorage.getItem("user_access"));
//   let userBranch =
//     JSON.parse(sessionStorage.getItem("userBranch")) ||
//     JSON.parse(localStorage.getItem("userBranch"));

//   const departmentData = useSelector((store) => store.data.department) || [];
//   const addedStatus = useSelector((store) => store?.nbmData?.createSubuser) || [];
//   console.log(addedStatus)
//   const createSuperAdmins = useSelector((store) => store.data.createSuperAdmin);
//   let userData =
//     JSON.parse(sessionStorage.getItem("userData")) ||
//     JSON.parse(localStorage.getItem("userData"));

//   const [name, setName] = useState("");
//   const [toggle, setToggle] = useState(false);
//   const [email, setEmail] = useState("");

//   const [mobileNumber, setMobileNumber] = useState("");
//   const [designation, setDesignation] = useState("");

//   const [nameError, setNameError] = useState("");

//   const [designationError, setDesignationError] = useState("");
//   const [departmentError, setDepartmentError] = useState("");
//   const [userRollError, setUserRollError] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [phoneError, setPhoneError] = useState("");

//   const [openAddUser, setOpenAddUser] = React.useState(false);
//   const [data, setData] = useState([]);

//   const [selectedRole, setSelectedRole] = useState("");
//   const [selectedDepartments, setSelectedDepartments] = React.useState([]);

//   const response = useSelector((store) => store.data.userDetailCheck);

//   const [selectAll, setSelectAll] = React.useState(false);

//   const handleChange = (_, value) => {
//     if (value.some((dept) => dept.departid === "all")) {
//       setSelectAll(true);
//       setSelectedDepartments([allOption]);
//     } else {
//       setSelectAll(false);
//       setSelectedDepartments(value.filter((dept) => dept.departid !== "all"));
//     }
//   };

//   useEffect(() => {
//     if (userList && userList?.length > 0) {
//       setData(userList);
//     }
//   }, [userList]);

//   const validateEmail = (inputEmail) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(inputEmail);
//   };

//   const handleEmailChange = async (e) => {
//     const newEmail = e.target.value;
//     setEmail(newEmail);

//     if (newEmail.length === 0) {
//       setEmailError("");
//       setName("");
//       setMobileNumber("");
//       setDesignation("");
//       return;
//     }

//     try {
//       if (!validateEmail(newEmail)) {
//         setEmailError("Invalid email format");
//         setName("");
//         setMobileNumber("");
//         setDesignation("");
//         return;
//       }

//       let data = {
//         adminid: userData.userid,
//         emailid: newEmail,
//         hospid: userData.hospid,
//       };

//       setShow(true);
//       await dispatch(userDetailCheck(data));
//     } catch (error) {
//       console.error("Error checking email:", error);
//     }
//   };

//   const handleOpenUser = () => setOpenAddUser(true);
//   const handleCloseUser = () => {
//     setOpenAddUser(false);
//     setToggle(false);

//     if (addedStatus?.success === false) {
//     } else {
//       navigate("/user");
//     }
//   };

//   const handleDesignation = (e) => {
//     const value = e.target.value.trim();
//     const regex = /^[A-Za-z0-9\- ]+$/;
//     setDesignation(value);

//     if (value === "" || regex.test(value)) {
//       setDesignationError("");
//     } else {
//       setDesignationError("Wrong designation");
//     }

//     if (value === "") {
//       setDesignationError("Please fill the field");
//     }
//   };

//   const handlePhone = (e) => {
//     const value = e.target.value.trim();
//     const regex = /^\d{10}$/;
//     setMobileNumber(value);

//     if (value === "" || regex.test(value)) {
//       setPhoneError("");
//     } else {
//       setPhoneError("Invalid Phone Number");
//     }

//     if (value === "") {
//       setPhoneError("Please fill the field");
//     }
//   };

//   const handleName = (e) => {
//     const value = e.target.value;
//     const regex = /^[a-zA-Z ]+$/;
//     setName(value);

//     if (value === "") {
//       setNameError("Please fill the field");
//     } else if (!regex.test(value)) {
//       setNameError("Invalid name");
//     } else {
//       setNameError("");
//     }
//   };

//   const handleClick2 = () => {
//     let isValid = true;

//     if (name === "") {
//       setNameError("Please fill the field");
//       isValid = false;
//     } else {
//       setNameError("");
//     }

//     if (email === "") {
//       setEmailError("Please fill the field");
//       isValid = false;
//     } else {
//       setEmailError("");
//     }
//     if (designation === "") {
//       setDesignationError("Please fill the  field");
//       isValid = false;
//     } else {
//       setDesignationError("");
//     }

//     if (mobileNumber === "") {
//       setPhoneError("Please fill the  field");
//       isValid = false;
//     } else {
//       setPhoneError("");
//     }
//     if ((checked.Admin || checked.Guest) && selectedDepartments.length === 0) {
//       setDepartmentError("Please select at least one department");
//       isValid = false;
//     } else {
//       setDepartmentError("");
//     }

//     if (
//       !checked.Super_Admin &&
//       !checked.Branch_Admin &&
//       !checked.Admin &&
//       !checked.Guest
//     ) {
//       setUserRollError("Please select the user role");
//       isValid = false;
//     } else {
//       setUserRollError("");
//     }
//     if (isValid) {
//       const formattedDepartments =
//         selectedDepartments.length === 0 &&
//         (checked.Super_Admin || checked.Branch_Admin)
//           ? [
//               {
//                 depart_id: "all",
//                 depart_name: "All",
//               },
//             ]
//           : selectedDepartments.map((dept) => ({
//               depart_id: dept.departid,
//               depart_name: dept.departname,
//             }));

//       if (checked.Branch_Admin || checked.Admin || checked.Guest) {
//         let data = {
//           admin_id: userData.userid,
//           hosp_id: userData.hospid,
//           hosp_name: userData?.hospname,
//           user_name: name.trim(),
//           user_email: email.trim(),
//           user_mobile: mobileNumber.trim(),
//           user_role: "Sub_User",
//           user_desig: designation.trim(),
//           user_departs: "All",
//           user_departids: "all",
//           subscrb_plan: userData.subs_plan,
//           subscrb_date: moment(userData.subs_date).format("DD-MM-YYYY"),
//           subscrb_expdate: moment(userData.subs_expdate).format("DD-MM-YYYY"),
//           hospcity: userData.city,
//           dash_acc: "Yes",
//           addprod: "Yes",
//           deleteprod: "Yes",
//           editprod: "Yes",
//           adddepart: "Yes",
//           deldepart: "Yes",
//           editdepart: "Yes",
//           adduser: "Yes",
//           deleteuser: "No",
//           edituser: "Yes",
//           branchname: userBranch.branch,
//           branchid: userBranch.id,
//           branchdepart: {
//             branchId: userBranch.id,
//             branch: userBranch.branch,
//             role: selectedRole,
//             addprod:
//               checked.Branch_Admin || checked.Admin || checked.Guest
//                 ? "Yes"
//                 : "No",
//             delprod:
//               checked.Super_Admin || checked.Branch_Admin || checked.Admin
//                 ? "Yes"
//                 : "No",

//             editprod:
//               checked.Super_Admin || checked.Branch_Admin || checked.Admin
//                 ? "Yes"
//                 : "No",
//             adddepart:
//               checked.Super_Admin || checked.Branch_Admin
//                 ? "Yes"
//                 : checked.Admin &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : checked.Guest
//                 ? "No"
//                 : "Yes",
//             deldepart:
//               checked.Super_Admin || checked.Branch_Admin
//                 ? "Yes"
//                 : checked.Admin &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : checked.Guest
//                 ? "No"
//                 : "Yes",
//             editdepart:
//               checked.Super_Admin || checked.Branch_Admin
//                 ? "Yes"
//                 : checked.Admin &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : checked.Guest
//                 ? "No"
//                 : "Yes",
//             adduser:
//               checked.Super_Admin || checked.Branch_Admin
//                 ? "Yes"
//                 : checked.Admin &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : checked.Guest
//                 ? "No"
//                 : "Yes",
//             deluser:
//               checked.Super_Admin || checked.Branch_Admin
//                 ? "Yes"
//                 : checked.Admin &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : checked.Guest
//                 ? "No"
//                 : "Yes",
//             edituser:
//               checked.Super_Admin || checked.Branch_Admin
//                 ? "Yes"
//                 : checked.Admin &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : checked.Guest
//                 ? "No"
//                 : "Yes",
//             department: formattedDepartments,
//           },
//         };
//         dispatch(createSubuser(data));
   

//         setToggle(true);
//       } else if (selectedRole === "Super_Admin") {
//         let data = {
//           admin_id: userData.userid,
//           hosp_id: userData.hospid,
//           hosp_name: userData?.hospname,
//           user_name: name.trim(),
//           user_email: email.trim(),
//           user_mobile: mobileNumber.trim(),
//           user_desig: designation.trim(),
//         };
//         dispatch(createSuperAdmin(data));

//         setToggle(true);
//       }
//     }
//   };

//   useEffect(() => {
//     let data = {
//       hospid: userData.hospid,
//       userid: userData.userid,
//       branchid: userBranch.id,
//       depart_ids: userAccess?.departmentids,
//     };
//     dispatch(getDepartmentData(data, token));
//   }, [dispatch]);

//   //-------------------------------p-----------------------------------//

//   useEffect(() => {
//     if (addedStatus !== undefined && addedStatus?.length > 0) {
//       if (addedStatus?.success && name.length > 0) {
//         setOpenAddUser(true);
//       }
//       if (addedStatus?.success === false && name.length > 0) {
//         setOpenAddUser(true);
//       }
//     }
//   }, [addedStatus]);
//   useEffect(() => {
//     if (createSuperAdmins !== undefined && createSuperAdmins?.length > 0) {
//       if (createSuperAdmins?.success && name.length > 0) {
//         setOpenAddUser(true);
//       }
//       if (createSuperAdmins?.success === false && name.length > 0) {
//         setOpenAddUser(true);
//       }
//     }
//   }, [createSuperAdmins]);

//   useEffect(() => {
//     let data = {
//       userid: userData.userid,
//       hosp_id: userData.hospid,
//       branchid: userBranch.id,
//     };
//     dispatch(getUserData(data, token));
//   }, [dispatch]);

//   useEffect(() => {
//     if (Object.keys(response).length === 0) {
//       setEmailError("");

//       setName("");
//       setMobileNumber("");
//       setDesignation("");
//     } else {
//       if (response.role === "Owner" || response.role === "Super_Admin") {
//         if (show) {
//           setEmailError("User already exists");
//         }
//       } else if (response.role !== "Owner" || response.role !== "Super_Admin") {
//         if (response.role === "Sub_User") {
//           const branchIdFromLocalStorage = userBranch.id;
//           const branchIdsFromResponse = response.bm_branchids;

//           const branchIdMatch = branchIdsFromResponse.some(
//             (id) => id === branchIdFromLocalStorage
//           );

//           if (branchIdMatch) {
//             setEmailError("User already exists");
//           } else {
//             setEmailError("");

//             setName(response.username);
//             setMobileNumber(response.mobile || "");
//             setDesignation(response.designate || "");
//           }
//         } else {
//           setEmailError("");

//           setName(response.username || "");
//           setMobileNumber(response.mobile || "");
//           setDesignation(response.designate || "");
//         }
//       } else {
//         setEmailError("");
//       }
//     }
//   }, [response, userList]);

//   useEffect(() => {
//     setEmailError("");
//   }, []);
//   const [checked, setChecked] = useState({
//     Super_Admin: false,
//     Branch_Admin: false,
//     Admin: false,
//     Guest: false,
//   });
 
//   const [adminType, setAdminType] = useState("");
//   const [adminValue, setAdminValue] = useState("");

//   const handleCheckboxChange = (name) => (event) => {
//     setUserRollError("");
//     const isChecked = event.target.checked;

//     if (isChecked) {
//       setSelectedRole(name);
//     } else {
//       setSelectedRole("");
//     }

//     const updatedChecked = { ...checked, [name]: event.target.checked };

//     if (name === "Super_Admin" && event.target.checked) {
//       setChecked({
//         Super_Admin: true,
//         Branch_Admin: false,
//         Admin: false,
//         Guest: false,
//       });
//       setAdminType("");
//     } else if (name === "Branch_Admin" && event.target.checked) {
//       setChecked({
//         Super_Admin: false,
//         Branch_Admin: true,
//         Admin: false,
//         Guest: false,
//       });
//       setAdminType("");
//     } else if (name === "Admin" && event.target.checked) {
//       setChecked({
//         Super_Admin: false,
//         Branch_Admin: false,
//         Admin: true,
//         Guest: false,
//       });
//       setAdminType("Department Admin");
//     } else if (name === "Guest" && event.target.checked) {
//       setChecked({
//         Super_Admin: false,
//         Branch_Admin: false,
//         Admin: false,
//         Guest: true,
//       });
//       setAdminType("Guest");
//     } else {
//       setChecked({
//         Super_Admin: false,
//         Branch_Admin: false,
//         Admin: false,
//         Guest: false,
//       });
//       setAdminType("");
//       setAdminValue("");
//     }
//   };



//   const handleClick = () => {
//     let isValid = true;

//     if (name === "") {
//       setNameError("Please fill the field");
//       isValid = false;
//     } else {
//       setNameError("");
//     }

//     if (email === "") {
//       setEmailError("Please fill the field");
//       isValid = false;
//     } else {
//       setEmailError("");
//     }
//     if (designation === "") {
//       setDesignationError("Please fill the  field");
//       isValid = false;
//     } else {
//       setDesignationError("");
//     }

//     if (mobileNumber === "") {
//       setPhoneError("Please fill the  field");
//       isValid = false;
//     } else {
//       setPhoneError("");
//     }
//     if (
//       (selectedRole === "Admin" || selectedRole === "Guest") &&
//       selectedDepartments.length === 0
//     ) {
//       setDepartmentError("Please select at least one department");
//       isValid = false;
//     } else {
//       setDepartmentError("");
//     }
//     if (
//       !checked.Super_Admin &&
//       !checked.Branch_Admin &&
//       !checked.Admin &&
//       !checked.Guest
//     ) {
//       setUserRollError("Please select the user role");
//       isValid = false;
//     } else {
//       setUserRollError("");
//     }
//     if (isValid) {
//       const formattedDepartments =
//         selectedDepartments.length === 0 &&
//         (selectedRole === "Branch_Admin" || selectedRole === "Super_Admin")
//           ? [
//               {
//                 depart_id: "all",
//                 depart_name: "All",
//               },
//             ]
//           : selectedDepartments.map((dept) => ({
//               depart_id: dept.departid,
//               depart_name: dept.departname,
//             }));

//       if (
//         selectedRole === "Admin" ||
//         selectedRole === "Branch_Admin" ||
//         selectedRole === "Guest"
//       ) {
//         let data = {
//           admin_id: userData.userid,
//           hosp_id: userData.hospid,
//           hosp_name: userData?.hospname,
//           user_name: name.trim(),
//           user_email: email.trim(),
//           user_mobile: mobileNumber.trim(),
//           user_role: "Sub_User",
//           user_desig: designation.trim(),
//           user_departs: "All",
//           user_departids: "all",
//           subscrb_plan: userData.subs_plan,
//           subscrb_date: moment(userData.subs_date).format("DD-MM-YYYY"),
//           subscrb_expdate: moment(userData.subs_expdate).format("DD-MM-YYYY"),
//           hospcity: userData.city,
//           dash_acc: "Yes",
//           addprod: "Yes",
//           deleteprod: "Yes",
//           editprod: "Yes",
//           adddepart: "Yes",
//           deldepart: "Yes",
//           editdepart: "Yes",
//           adduser: "Yes",
//           deleteuser: "No",
//           edituser: "Yes",
//           branchname: userBranch.branch,
//           branchid: userBranch.id,
//           branchdepart: {
//             branchId: userBranch.id,
//             branch: userBranch.branch,
//             role: selectedRole,
//             addprod:
//               selectedRole === "Branch_Admin" ||
//               selectedRole === "Super_Admin" ||
//               selectedRole === "Admin"
//                 ? "Yes"
//                 : "No",
//             delprod:
//               selectedRole === "Branch_Admin" ||
//               selectedRole === "Super_Admin" ||
//               selectedRole === "Admin"
//                 ? "Yes"
//                 : "No",

//             editprod:
//               selectedRole === "Branch_Admin" ||
//               selectedRole === "Super_Admin" ||
//               selectedRole === "Admin"
//                 ? "Yes"
//                 : "No",
//             adddepart:
//               selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
//                 ? "Yes"
//                 : selectedRole === "Admin" &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : selectedRole === "Guest"
//                 ? "No"
//                 : "Yes",
//             deldepart:
//               selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
//                 ? "Yes"
//                 : selectedRole === "Admin" &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : selectedRole === "Guest"
//                 ? "No"
//                 : "Yes",
//             editdepart:
//               selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
//                 ? "Yes"
//                 : selectedRole === "Admin" &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : selectedRole === "Guest"
//                 ? "No"
//                 : "Yes",
//             adduser:
//               selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
//                 ? "Yes"
//                 : selectedRole === "Admin" &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : selectedRole === "Guest"
//                 ? "No"
//                 : "Yes",
//             deluser:
//               selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
//                 ? "Yes"
//                 : selectedRole === "Admin" &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : selectedRole === "Guest"
//                 ? "No"
//                 : "Yes",
//             edituser:
//               selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
//                 ? "Yes"
//                 : selectedRole === "Admin" &&
//                   selectedDepartments &&
//                   selectedDepartments.some((dept) => dept.departid !== "all")
//                 ? "No"
//                 : selectedRole === "Guest"
//                 ? "No"
//                 : "Yes",
//             department: formattedDepartments,
//           },
//         };
//         dispatch(createSubuser(data));
//         console.log("dataaaa", data);
//         setToggle(true);
//       } else if (selectedRole === "Super_Admin") {
//         let data = {
//           admin_id: userData.userid,
//           hosp_id: userData.hospid,
//           hosp_name: userData?.hospname,
//           user_name: name.trim(),
//           user_email: email.trim(),
//           user_mobile: mobileNumber.trim(),
//           user_desig: designation.trim(),
//         };
//         dispatch(createSuperAdmin(data));
//         console.log("dataaaaaaaaa", data);


//         setToggle(true);
//       }
//     }
//   };
//   return (
//     <Stack
//       sx={{
//         justifyContent: "center",
//         alignItems: "center",
//         width: "100%",
//         minHeight: "100vh",
//       }}>
//       <Box1 sx={{}}>
//         <Stack
//           sx={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             justifyContent: "space-between",

//             bgcolor: "white",

//             padding: "20px",
//           }}>
//           <Label variant="subtitle1" color="primary">
//             Add User
//           </Label>

//           <Label variant="subtitle1" color="primary">
//             {userBranch.branch}
//           </Label>
//         </Stack>

//         <Box sx={{ bgcolor: "white", padding: "20px" }}>
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               justifyContent: "space-between",
//               bgcolor: "white",
//             }}>
//             <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
//               New User
//             </Label>
//           </Box>
//           <Divider style={{ background: "rgba(70, 144, 255, 1)" }} />

//           <UserBox>
//             <Box2
//               sx={{
//                 width: "50%",
//                 marginRight: "16px",
//                 gap: "15px",

//                 height: "100%",
//                 textAlign: "start",
//               }}>
//               <Label>Personal Details</Label>
//               <Stack>
//                 <Field
//                   value={email}
//                   onChange={handleEmailChange}
//                   label="Email"
//                   variant="filled"
//                   sx={{ width: "100%" }}
//                   InputProps={{ disableUnderline: true }}
//                 />

//                 <HelperText variant="caption">{emailError}</HelperText>
//               </Stack>
//               <Stack>
//                 <Field
//                   value={name}
//                   onChange={handleName}
//                   label="User Name"
//                   variant="filled"
//                   sx={{ width: "100%" }}
//                   disabled={
//                     email.length === 0 ||
//                     !validateEmail(email) ||
//                     emailError !== ""
//                   }
//                   InputProps={{ disableUnderline: true }}
//                 />
//                 <HelperText variant="caption">{nameError}</HelperText>
//               </Stack>
//               <Stack>
//                 <Field
//                   value={mobileNumber}
//                   onChange={handlePhone}
//                   label="Mobile Number"
//                   variant="filled"
//                   sx={{ width: "100%" }}
//                   disabled={
//                     email.length === 0 ||
//                     !validateEmail(email) ||
//                     emailError !== ""
//                   }
//                   InputProps={{ disableUnderline: true }}
//                 />
//                 <HelperText variant="caption">{phoneError}</HelperText>
//               </Stack>
//               <Stack>
//                 <Field
//                   value={designation}
//                   onChange={handleDesignation}
//                   label="Designation"
//                   variant="filled"
//                   sx={{ width: "100%" }}
//                   disabled={
//                     email.length === 0 ||
//                     !validateEmail(email) ||
//                     emailError !== ""
//                   }
//                   InputProps={{ disableUnderline: true }}
//                 />
//                 <HelperText variant="caption">{designationError}</HelperText>
//               </Stack>
//             </Box2>
//             <Box2
//               sx={{
//                 width: "50%",
//                 height: "100%",

//                 gap: "15px",
//                 textAlign: "start",
//               }}>
//               <Label>User Access</Label>
//               <Stack sx={{ height: "100%" }}>
//                 <Stack
//                   sx={{
//                     gap: "20px",
//                   }}>
//                   <FormGroup
//                     sx={{
//                       padding: "20px",
//                       bgcolor: "#F4F6F6",
//                     }}>
//                     <FormControlLabel
//                       disabled={
//                         email.length === 0 ||
//                         !validateEmail(email) ||
//                         emailError !== ""
//                       }
//                       control={
//                         <Checkbox
//                           checked={checked.Super_Admin}
//                           onChange={handleCheckboxChange("Super_Admin")}
//                         />
//                       }
//                       label="Super Admin"
//                     />
//                     <FormControlLabel
//                       disabled={
//                         email.length === 0 ||
//                         !validateEmail(email) ||
//                         emailError !== ""
//                       }
//                       control={
//                         <Checkbox
//                           checked={checked.Branch_Admin}
//                           onChange={handleCheckboxChange("Branch_Admin")}
//                         />
//                       }
//                       label="Branch Admin"
//                     />
//                     <FormControlLabel
//                       disabled={
//                         email.length === 0 ||
//                         !validateEmail(email) ||
//                         emailError !== ""
//                       }
//                       control={
//                         <Checkbox
//                           checked={checked.Admin}
//                           onChange={handleCheckboxChange("Admin")}
//                         />
//                       }
//                       label="Department Admin"
//                     />
//                     <FormControlLabel
//                       disabled={
//                         email.length === 0 ||
//                         !validateEmail(email) ||
//                         emailError !== ""
//                       }
//                       control={
//                         <Checkbox
//                           checked={checked.Guest}
//                           onChange={handleCheckboxChange("Guest")}
//                         />
//                       }
//                       label="Guest"
//                     />

//                     <HelperText>{userRollError}</HelperText>
//                   </FormGroup>
//                   {adminType && (
//                     <Stack>
//                       <Autocomplete
//                         multiple
//                         id="checkboxes-tags-demo"
//                         options={[allOption, ...departmentData]}
//                         disableCloseOnSelect
//                         style={{
//                           textAlign: "left",
//                           background: "#F4F6F6",
//                         }}
//                         getOptionLabel={(option) => option.departname}
//                         onChange={handleChange}
//                         value={selectedDepartments}
//                         renderOption={(props, option, { selected }) => (
//                           <li {...props}>
//                             <Checkbox
//                               icon={icon}
//                               checkedIcon={checkedIcon}
//                               style={{ marginRight: 8 }}
//                               checked={selected}
//                               disabled={selectAll && option.departid !== "all"}
//                             />
//                             {option.departname}
//                           </li>
//                         )}
//                         renderInput={(params) => (
//                           <Field
//                             placeholder="Select department"
//                             variant="outlined"
//                             sx={{ width: "100%" }}
//                             InputProps={{ disableUnderline: true }} // This line removes the bottom underline
//                             {...params}
//                           />
//                         )}
//                       />
//                       {selectedDepartments?.length === 0 && (
//                         <HelperText>Select at least one department</HelperText>
//                       )}
//                     </Stack>
//                   )}
//                 </Stack>
//               </Stack>
//             </Box2>
//           </UserBox>

//           <>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "row",
//                 marginTop: "16px",
//               }}>
//               <Stack
//                 sx={{
//                   flexShrink: 1,
//                   width: "100%",
//                   alignItems: "flex-end",
//                   justifyContent: "center",
//                 }}>
//                 <Button
//                   //   onClick={handleSubmit}
//                   onClick={handleClick}
//                   disabled={userAccess?.adduser !== "Yes"}
//                   variant="contained"
//                   sx={{
//                     width: "154px",
//                     borderRadius: "22px",
//                     bgcolor: "rgba(70, 144, 255, 1)",
//                   }}>
//                   Submit
//                 </Button>
//               </Stack>
//             </Box>
//           </>
//         </Box>
//       </Box1>

//       <AddUserModal
//         openAddUser={openAddUser}
//         handleOpenUser={handleOpenUser}
//         handleCloseUser={handleCloseUser}
//         addedStatus={addedStatus}
//         toggle={toggle}
//       />
//       <AddUserModal
//         openAddUser={openAddUser}
//         handleOpenUser={handleOpenUser}
//         handleCloseUser={handleCloseUser}
//         addedStatus={createSuperAdmins}
//         toggle={toggle}
//       />
//     </Stack>
//   );
// };

const AddUserPage = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("userToken");
  const userList = useSelector((store) => store.nbmData.userData);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const departmentData = useSelector((store) => store.nbmData.department) || [];
  const addedStatus = useSelector((store) => store.nbmData.createSubUser) || [];
  console.log(addedStatus)

  const createSuperAdmins = useSelector((store) => store.nbmData.createSuperAdmin);
  let userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const [name, setName] = useState("");
  const [toggle, setToggle] = useState(false);
  const [email, setEmail] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");

  const [nameError, setNameError] = useState("");

  const [designationError, setDesignationError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [userRollError, setUserRollError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [data, setData] = useState([]);

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDepartments, setSelectedDepartments] = React.useState([]);

  const response = useSelector((store) => store.nbmData.userDetailCheck);

  const [selectAll, setSelectAll] = React.useState(false);

  const handleChange = (_, value) => {
    if (value.some((dept) => dept.departid === "all")) {
      setSelectAll(true);
      setSelectedDepartments([allOption]);
    } else {
      setSelectAll(false);
      setSelectedDepartments(value.filter((dept) => dept.departid !== "all"));
    }
  };

  useEffect(() => {
    if (userList && userList?.length > 0) {
      setData(userList);
    }
  }, [userList]);

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleEmailChange = async (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (newEmail.length === 0) {
      setEmailError("");
      setName("");
      setMobileNumber("");
      setDesignation("");
      return;
    }

    try {
      if (!validateEmail(newEmail)) {
        setEmailError("Invalid email format");
        setName("");
        setMobileNumber("");
        setDesignation("");
        return;
      }

      let data = {
        adminid: userData.userid,
        emailid: newEmail,
        hospid: userData.hospid,
      };

      setShow(true);
      await dispatch(userDetailCheck(data));
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  const handleOpenUser = () => setOpenAddUser(true);
  const handleCloseUser = () => {
    setOpenAddUser(false);
    setToggle(false);

    if (addedStatus?.success === false) {
    } else {
      navigate("/user");
    }
  };

  const handleDesignation = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z ]*$/;
  
    if (value === "" || regex.test(value)) {
      setDesignation(value);
      setDesignationError("");
    } else {
      setDesignationError("Invalid name: Only alphabets and spaces are allowed");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,10}$/;
  
    if (regex.test(value)) {
      setMobileNumber(value);
      setPhoneError(null);
    }
  
    if (value !== "" && !regex.test(value)) {
      setPhoneError("Enter a valid 10-digit number");
    }
  
    if (value === "") {
      setPhoneError("Please fill the field");
    }
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z ]*$/; 
  
    if (value === "" || regex.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid name: Only alphabets and spaces are allowed");
    }
  };

  const handleClick2 = () => {
    let isValid = true;

    if (name === "") {
      setNameError("Please fill the field");
      isValid = false;
    } else {
      setNameError("");
    }

    if (email === "") {
      setEmailError("Please fill the field");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (designation === "") {
      setDesignationError("Please fill the  field");
      isValid = false;
    } else {
      setDesignationError("");
    }

    if (mobileNumber === "") {
      setPhoneError("Please fill the  field");
      isValid = false;
    } else {
      setPhoneError("");
    }
    if ((checked.Admin || checked.Guest) && selectedDepartments.length === 0) {
      setDepartmentError("Please select at least one department");
      isValid = false;
    } else {
      setDepartmentError("");
    }

    if (
      !checked.Super_Admin &&
      !checked.Branch_Admin &&
      !checked.Admin &&
      !checked.Guest
    ) {
      setUserRollError("Please select the user role");
      isValid = false;
    } else {
      setUserRollError("");
    }
    if (isValid) {
      const formattedDepartments =
        selectedDepartments.length === 0 &&
        (checked.Super_Admin || checked.Branch_Admin)
          ? [
              {
                depart_id: "all",
                depart_name: "All",
              },
            ]
          : selectedDepartments.map((dept) => ({
              depart_id: dept.departid,
              depart_name: dept.departname,
            }));

      if (checked.Branch_Admin || checked.Admin || checked.Guest) {
        let data = {
          admin_id: userData.userid,
          hosp_id: userData.hospid,
          hosp_name: userData?.hospname,
          user_name: name.trim(),
          user_email: email.trim(),
          user_mobile: mobileNumber.trim(),
          user_role: "Sub_User",
          user_desig: designation.trim(),
          user_departs: "All",
          user_departids: "all",
          subscrb_plan: userData.subs_plan,
          subscrb_date: moment(userData.subs_date).format("DD-MM-YYYY"),
          subscrb_expdate: moment(userData.subs_expdate).format("DD-MM-YYYY"),
          hospcity: userData.city,
          dash_acc: "Yes",
          addprod: "Yes",
          deleteprod: "Yes",
          editprod: "Yes",
          adddepart: "Yes",
          deldepart: "Yes",
          editdepart: "Yes",
          adduser: "Yes",
          deleteuser: "No",
          edituser: "Yes",
          branchname: userBranch.branch,
          branchid: userBranch.id,
          branchdepart: {
            branchId: userBranch.id,
            branch: userBranch.branch,
            role: selectedRole,
            addprod:
              checked.Branch_Admin || checked.Admin || checked.Guest
                ? "Yes"
                : "No",
            delprod:
              checked.Super_Admin || checked.Branch_Admin || checked.Admin
                ? "Yes"
                : "No",

            editprod:
              checked.Super_Admin || checked.Branch_Admin || checked.Admin
                ? "Yes"
                : "No",
            adddepart:
              checked.Super_Admin || checked.Branch_Admin
                ? "Yes"
                : checked.Admin &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : checked.Guest
                ? "No"
                : "Yes",
            deldepart:
              checked.Super_Admin || checked.Branch_Admin
                ? "Yes"
                : checked.Admin &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : checked.Guest
                ? "No"
                : "Yes",
            editdepart:
              checked.Super_Admin || checked.Branch_Admin
                ? "Yes"
                : checked.Admin &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : checked.Guest
                ? "No"
                : "Yes",
            adduser:
              checked.Super_Admin || checked.Branch_Admin
                ? "Yes"
                : checked.Admin &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : checked.Guest
                ? "No"
                : "Yes",
            deluser:
              checked.Super_Admin || checked.Branch_Admin
                ? "Yes"
                : checked.Admin &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : checked.Guest
                ? "No"
                : "Yes",
            edituser:
              checked.Super_Admin || checked.Branch_Admin
                ? "Yes"
                : checked.Admin &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : checked.Guest
                ? "No"
                : "Yes",
            department: formattedDepartments,
          },
        };
        dispatch(createSubuser(data));
        console.log("dataaaaaaa", data);

        setToggle(true);
      } else if (selectedRole === "Super_Admin") {
        let data = {
          admin_id: userData.userid,
          hosp_id: userData.hospid,
          hosp_name: userData?.hospname,
          user_name: name.trim(),
          user_email: email.trim(),
          user_mobile: mobileNumber.trim(),
          user_desig: designation.trim(),
        };
        dispatch(createSuperAdmin(data));

        setToggle(true);
      }
    }
  };

  useEffect(() => {
    let data = {
      hospid: userData.hospid,
      userid: userData.userid,
      branchid: userBranch.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  //-------------------------------p-----------------------------------//

  useEffect(() => {
    if (addedStatus !== undefined && addedStatus?.length > 0) {
      if (addedStatus?.success && name.length > 0) {
        setOpenAddUser(true);
      }
      if (addedStatus?.success === false && name.length > 0) {
        setOpenAddUser(true);
      }
    }
  }, [addedStatus]);
  useEffect(() => {
    if (createSuperAdmins !== undefined && createSuperAdmins?.length > 0) {
      if (createSuperAdmins?.success && name.length > 0) {
        setOpenAddUser(true);
      }
      if (createSuperAdmins?.success === false && name.length > 0) {
        setOpenAddUser(true);
      }
    }
  }, [createSuperAdmins]);

  useEffect(() => {
    let data = {
      userid: userData.userid,
      hosp_id: userData.hospid,
      branchid: userBranch.id,
    };
    dispatch(getUserData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(response).length === 0) {
      setEmailError("");

      setName("");
      setMobileNumber("");
      setDesignation("");
    } else {
      if (response.role === "Owner" || response.role === "Super_Admin") {
        if (show) {
          setEmailError("User already exists");
        }
      } else if (response.role !== "Owner" || response.role !== "Super_Admin") {
        if (response.role === "Sub_User") {
          const branchIdFromLocalStorage = userBranch.id;
          const branchIdsFromResponse = response.bm_branchids;

          const branchIdMatch = branchIdsFromResponse.some(
            (id) => id === branchIdFromLocalStorage
          );

          if (branchIdMatch) {
            setEmailError("User already exists");
          } else {
            setEmailError("");

            setName(response.username);
            setMobileNumber(response.mobile || "");
            setDesignation(response.designate || "");
          }
        } else {
          setEmailError("");

          setName(response.username || "");
          setMobileNumber(response.mobile || "");
          setDesignation(response.designate || "");
        }
      } else {
        setEmailError("");
      }
    }
  }, [response, userList]);

  useEffect(() => {
    setEmailError("");
  }, []);
  const [checked, setChecked] = useState({
    Super_Admin: false,
    Branch_Admin: false,
    Admin: false,
    Guest: false,
  });
  console.log("checked", checked);
  const [adminType, setAdminType] = useState("");
  const [adminValue, setAdminValue] = useState("");

  const handleCheckboxChange = (name) => (event) => {
    setUserRollError("");
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedRole(name);
    } else {
      setSelectedRole("");
    }

    const updatedChecked = { ...checked, [name]: event.target.checked };

    if (name === "Super_Admin" && event.target.checked) {
      setChecked({
        Super_Admin: true,
        Branch_Admin: false,
        Admin: false,
        Guest: false,
      });
      setAdminType("");
    } else if (name === "Branch_Admin" && event.target.checked) {
      setChecked({
        Super_Admin: false,
        Branch_Admin: true,
        Admin: false,
        Guest: false,
      });
      setAdminType("");
    } else if (name === "Admin" && event.target.checked) {
      setChecked({
        Super_Admin: false,
        Branch_Admin: false,
        Admin: true,
        Guest: false,
      });
      setAdminType("Department Admin");
    } else if (name === "Guest" && event.target.checked) {
      setChecked({
        Super_Admin: false,
        Branch_Admin: false,
        Admin: false,
        Guest: true,
      });
      setAdminType("Guest");
    } else {
      setChecked({
        Super_Admin: false,
        Branch_Admin: false,
        Admin: false,
        Guest: false,
      });
      setAdminType("");
      setAdminValue("");
    }
  };

  console.log("seleee", selectedRole);

  const handleClick = () => {
    let isValid = true;

    if (name === "") {
      setNameError("Please fill the field");
      isValid = false;
    } else {
      setNameError("");
    }

    if (email === "") {
      setEmailError("Please fill the field");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (designation === "") {
      setDesignationError("Please fill the  field");
      isValid = false;
    } else {
      setDesignationError("");
    }

    if (mobileNumber === "") {
      setPhoneError("Please fill the  field");
      isValid = false;
    } else {
      setPhoneError("");
    }
    if (
      (selectedRole === "Admin" || selectedRole === "Guest") &&
      selectedDepartments.length === 0
    ) {
      setDepartmentError("Please select at least one department");
      isValid = false;
    } else {
      setDepartmentError("");
    }
    if (
      !checked.Super_Admin &&
      !checked.Branch_Admin &&
      !checked.Admin &&
      !checked.Guest
    ) {
      setUserRollError("Please select the user role");
      isValid = false;
    } else {
      setUserRollError("");
    }
    if (isValid) {
      const formattedDepartments =
        selectedDepartments.length === 0 &&
        (selectedRole === "Branch_Admin" || selectedRole === "Super_Admin")
          ? [
              {
                depart_id: "all",
                depart_name: "All",
              },
            ]
          : selectedDepartments.map((dept) => ({
              depart_id: dept.departid,
              depart_name: dept.departname,
            }));

      if (
        selectedRole === "Admin" ||
        selectedRole === "Branch_Admin" ||
        selectedRole === "Guest"
      ) {
        let data = {
          admin_id: userData.userid,
          hosp_id: userData.hospid,
          hosp_name: userData?.hospname,
          user_name: name.trim(),
          user_email: email.trim(),
          user_mobile: mobileNumber.trim(),
          user_role: "Sub_User",
          user_desig: designation.trim(),
          user_departs: "All",
          user_departids: "all",
          subscrb_plan: userData.subs_plan,
          subscrb_date: moment(userData.subs_date).format("DD-MM-YYYY"),
          subscrb_expdate: moment(userData.subs_expdate).format("DD-MM-YYYY"),
          hospcity: userData.city,
          dash_acc: "Yes",
          addprod: "Yes",
          deleteprod: "Yes",
          editprod: "Yes",
          adddepart: "Yes",
          deldepart: "Yes",
          editdepart: "Yes",
          adduser: "Yes",
          deleteuser: "No",
          edituser: "Yes",
          branchname: userBranch.branch,
          branchid: userBranch.id,
          branchdepart: {
            branchId: userBranch.id,
            branch: userBranch.branch,
            role: selectedRole,
            addprod:
              selectedRole === "Branch_Admin" ||
              selectedRole === "Super_Admin" ||
              selectedRole === "Admin"
                ? "Yes"
                : "No",
            delprod:
              selectedRole === "Branch_Admin" ||
              selectedRole === "Super_Admin" ||
              selectedRole === "Admin"
                ? "Yes"
                : "No",

            editprod:
              selectedRole === "Branch_Admin" ||
              selectedRole === "Super_Admin" ||
              selectedRole === "Admin"
                ? "Yes"
                : "No",
            adddepart:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            deldepart:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            editdepart:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            adduser:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            deluser:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            edituser:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            department: formattedDepartments,
          },
        };
        dispatch(createSubuser(data));
        console.log("dataaaa", data);
        setToggle(true);
      } else if (selectedRole === "Super_Admin") {
        let data = {
          admin_id: userData.userid,
          hosp_id: userData.hospid,
          hosp_name: userData?.hospname,
          user_name: name.trim(),
          user_email: email.trim(),
          user_mobile: mobileNumber.trim(),
          user_desig: designation.trim(),
        };
        dispatch(createSuperAdmin(data));
        console.log("dataaaaaaaaa", data);

        setToggle(true);
      }
    }
  };
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // minHeight: "100vh",
      }}>
      <Box1 sx={{}}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label variant="subtitle1" color="primary">
            Add User
          </Label>

          <Label variant="subtitle1" color="primary">
            {userBranch.branch}
          </Label>
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "white",
            }}>
            <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
              New User
            </Label>
          </Box>
          <Divider style={{ background: "rgba(70, 144, 255, 1)" }} />

          <UserBox>
            <Box2
              sx={{
                width: "50%",
                marginRight: "16px",
                gap: "15px",

                height: "100%",
                textAlign: "start",
              }}>
              <Label>Personal Details</Label>
              <Stack>
                <Field
                  value={email}
                  onChange={handleEmailChange}
                  label="Email"
                  variant="filled"
                  sx={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />

                <HelperText variant="caption">{emailError}</HelperText>
              </Stack>
              <Stack>
                <Field
                  value={name}
                  onChange={handleName}
                  label="User Name"
                  variant="filled"
                  sx={{ width: "100%" }}
                  disabled={
                    email.length === 0 ||
                    !validateEmail(email) ||
                    emailError !== ""
                  }
                  InputProps={{ disableUnderline: true }}
                />
                <HelperText variant="caption">{nameError}</HelperText>
              </Stack>
              <Stack>
                <Field
                  value={mobileNumber}
                  onChange={handlePhone}
                  label="Mobile Number"
                  variant="filled"
                  sx={{ width: "100%" }}
                  disabled={
                    email.length === 0 ||
                    !validateEmail(email) ||
                    emailError !== ""
                  }
                  InputProps={{ disableUnderline: true }}
                />
                <HelperText variant="caption">{phoneError}</HelperText>
              </Stack>
              <Stack>
                <Field
                  value={designation}
                  onChange={handleDesignation}
                  label="Designation"
                  variant="filled"
                  sx={{ width: "100%" }}
                  disabled={
                    email.length === 0 ||
                    !validateEmail(email) ||
                    emailError !== ""
                  }
                  InputProps={{ disableUnderline: true }}
                />
                <HelperText variant="caption">{designationError}</HelperText>
              </Stack>
            </Box2>
            <Box2
              sx={{
                width: "50%",
                height: "100%",

                gap: "15px",
                textAlign: "start",
              }}>
              <Label>User Access</Label>
              <Stack sx={{ height: "100%" }}>
                <Stack
                  sx={{
                    gap: "20px",
                  }}>
                  <FormGroup
                    sx={{
                      padding: "20px",
                      bgcolor: "#F4F6F6",
                    }}>
                    <FormControlLabel
                      disabled={
                        email.length === 0 ||
                        !validateEmail(email) ||
                        emailError !== ""
                      }
                      control={
                        <Checkbox
                          checked={checked.Super_Admin}
                          onChange={handleCheckboxChange("Super_Admin")}
                        />
                      }
                      label="Super Admin"
                    />
                    <FormControlLabel
                      disabled={
                        email.length === 0 ||
                        !validateEmail(email) ||
                        emailError !== ""
                      }
                      control={
                        <Checkbox
                          checked={checked.Branch_Admin}
                          onChange={handleCheckboxChange("Branch_Admin")}
                        />
                      }
                      label="Branch Admin"
                    />
                    <FormControlLabel
                      disabled={
                        email.length === 0 ||
                        !validateEmail(email) ||
                        emailError !== ""
                      }
                      control={
                        <Checkbox
                          checked={checked.Admin}
                          onChange={handleCheckboxChange("Admin")}
                        />
                      }
                      label="Department Admin"
                    />
                    <FormControlLabel
                      disabled={
                        email.length === 0 ||
                        !validateEmail(email) ||
                        emailError !== ""
                      }
                      control={
                        <Checkbox
                          checked={checked.Guest}
                          onChange={handleCheckboxChange("Guest")}
                        />
                      }
                      label="Guest"
                    />

                    <HelperText>{userRollError}</HelperText>
                  </FormGroup>
                  {adminType && (
                    <Stack>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={[allOption, ...departmentData]}
                        disableCloseOnSelect
                        style={{
                          textAlign: "left",
                          background: "#F4F6F6",
                        }}
                        getOptionLabel={(option) => option.departname}
                        onChange={handleChange}
                        value={selectedDepartments}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              disabled={selectAll && option.departid !== "all"}
                            />
                            {option.departname}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Field
                            placeholder="Select department"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            InputProps={{ disableUnderline: true }} // This line removes the bottom underline
                            {...params}
                          />
                        )}
                      />
                      {selectedDepartments?.length === 0 && (
                        <HelperText>Select at least one department</HelperText>
                      )}
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Box2>
          </UserBox>

          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "16px",
              }}>
              <Stack
                sx={{
                  flexShrink: 1,
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}>
                <Button
                  //   onClick={handleSubmit}
                  onClick={handleClick}
                  disabled={userAccess?.adduser !== "Yes"}
                  variant="contained"
                  sx={{
                    width: "154px",
                    borderRadius: "22px",
                    bgcolor: "rgba(70, 144, 255, 1)",
                  }}>
                  Submit
                </Button>
              </Stack>
            </Box>
          </>
        </Box>
      </Box1>

      <AddUserModal
        openAddUser={openAddUser}
        handleOpenUser={handleOpenUser}
        handleCloseUser={handleCloseUser}
        addedStatus={addedStatus}
        toggle={toggle}
      />
      <AddUserModal
        openAddUser={openAddUser}
        handleOpenUser={handleOpenUser}
        handleCloseUser={handleCloseUser}
        addedStatus={createSuperAdmins}
        toggle={toggle}
      />
    </Stack>
  );
};

export default AddUserPage;
