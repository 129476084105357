import { AppBar, Stack, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import React from 'react'

const FooterMain = () => {
  return (
    <AppBar
    position="static"
    sx={{
      top: "auto",
      bottom: 0,
      zIndex: 999,
      bgcolor: "rgba(27, 37, 53, 1)",
      px: {
        xs: '0px',  
        sm: '0px',   
        md: '30px', 
      },
    }}>
    <Toolbar>
      <Stack
        sx={{
          width: "100%",

          alignItems: "flex-end",
          flexDirection: "column",
        }}>
        <Typography variant="body1" component="div" sx={{}}>
        <Link to="https://vajra.software/contact-us/" style={{ marginRight: "20px" , textDecoration:'none' , color:'white' }}>Help</Link>
        <Link to="https://vajra.software/faqs/" style={{ marginRight: "20px" , textDecoration:'none' , color:'white' }}>FAQ</Link>
        <Link to="https://vajra.software/contact-us/" style={{ marginRight: "20px" , textDecoration:'none' , color:'white' }}>Contact</Link>
        <Link to="https://vajra.software/terms-conditions/" style={{ marginRight: "20px" , textDecoration:'none' , color:'white' }}>Terms and Conditions</Link>
        <Link to="https://vajra.software/privacy-policies/" style={{ marginRight: "20px" , textDecoration:'none' , color:'white' }}>Privacy Policy</Link>
        </Typography>
      </Stack>
    </Toolbar>
  </AppBar>
  )
}

export default FooterMain
