import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  TableContainer,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Stack,  Tooltip } from "@mui/material";
import SortSearchFilter from "../components/SortSearchFilter";


import TableRow from "@mui/material/TableRow";

import { useDispatch, useSelector } from "react-redux";

import { openService } from "../redux/nbmData/action";

import empty from "../images/empty.png";

import DialogBox from "../components/DialogBox";
import TopBox from "../components/TopBox";
import Pagination from "../components/Pagination";
import NoData from "../components/NoData";
import TableSort from "../components/TableSort";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));
const OpenServices = () => {
  const serviceData = useSelector((store) => store?.data?.openService);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [anchorElSort2, setAnchorElSort2] = React.useState(null);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const uniqueMonths = [
    ...new Set(
      serviceData
        .filter((item) => item.reqdate !== "NA")
        .map((item) => item.reqdate.split("-")[1])
    ),
  ].sort();
  const uniqueYears = [
    ...new Set(serviceData.map((item) => item.reqdate.split("-")[2])),
  ];
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      downloadCsvFile();
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.reqdate.split("-").reverse().join("-"));
      const dateB = new Date(b.reqdate.split("-").reverse().join("-"));
      return dateA - dateB;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.reqdate.split("-").reverse().join("-"));
      const dateB = new Date(b.reqdate.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const department = [...new Set(serviceData?.map((item) => item.assetdept))];

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(openService(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (serviceData && serviceData?.length > 0) {
      setData(serviceData);
    }
  }, [serviceData]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const [filterChanged, setFilterChanged] = useState(false);

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories.includes(status)
        ? prevCategories.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = data;

    // Filter by search query
    filteredProducts = filteredProducts.filter(
      (product) =>
        product.assetname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Filter by category
    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedCategories.includes(product.assetdept)
      );
    }

    if (selectedYear) {
      filteredProducts = filteredProducts.filter((service) => {
        const serviceYear = service.reqdate.split("-")[2];
        return serviceYear === selectedYear;
      });
    }
    if (selectedMonth) {
      filteredProducts = filteredProducts.filter((service) => {
        const serviceMonth = service.reqdate.split("-")[1];
        return serviceMonth === String(selectedMonth).padStart(2, "0");
      });
    }
    return filteredProducts;
  };

  const filteredProducts = filterProducts();
  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    filteredProducts.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(serviceData);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.assetname;
      case "date":
        return item.reqdate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );

  const [filteredData, setFilteredData] = useState([]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      //   justifyContent="space-between"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px"
    >
      
      <Box display="flex" flexDirection="column" gap="10px">
      <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}>
          <Label variant="h6">
           Open Service.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px">
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" }
            }}>
            <Typography>Total :</Typography>
            <Typography
              sx={{ color: "#4690FF" }}>{` ${data?.length}`}</Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="dept"
              Filter2=""
              FilterName1="Department"
              FilterName2=""
              sortDate="reqdate"
              dueDate=""
              sortPrice=""
              name="assetname"
              csvName="All Assets"
            />
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 130px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            {filteredData.length > 0 ? (
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    style={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 237, 237, 1)",
                      },
                    }}
                  >
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Asset Code</TableHeading>
                    <TableHeading>Department</TableHeading>
                    <TableHeading>Date</TableHeading>{" "}
                    <TableHeading>Time</TableHeading>
                    <TableHeading sx={{textAlign: "center",}}>Status</TableHeading>
                  </TableRow>
                </TableHead>
                {/* Table Body with Scrollbar */}
                <TableBody style={{ overflowY: "scroll" }}>
                  {filteredData.map((item, index) => (
                    <TableRow
                      key={item?.servid}
                      component={Link}
                      to={`/asset-detail/${item.assetid}`}
                      sx={{
                        "&:hover": {
                          bgcolor: "#EEF5FF",
                        },
                        textDecoration: "none",
                      }}
                    >
                      <TableCells>
                        <Tooltip title={item.assetname}>
                          {item.assetname}
                        </Tooltip>
                      </TableCells>

                      <TableCells>
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>
                      <TableCells>{item.dept}</TableCells>

                      <TableCells>{item.reqdate}</TableCells>
                      <TableCells>{item.reqtime}</TableCells>
                      
                     <TableCells  component={Link}
                      to={`/service-list/${item?.servid}`}
                      sx={{
                        textAlign: "center",

                        color: "rgba(70, 144, 255, 1)",
                      }}>  
                      {item.servstatus === "Open" ? (
                        <Button
                          disabled={userAccess?.role === "Guest"}
                          size="small"
                          sx={{
                            borderRadius: "35px",
                            textTransform: "none",
                            width: "100px",
                            height: "25px",
                            bgcolor: "rgba(255, 107, 0, 1)",
                            "&:hover": {
                              bgcolor: "rgba(255, 107, 0, 1)",
                            },
                          }}
                          variant="contained"
                        >
                          Open
                        </Button>
                      ) : (
                        <>Cleared</>
                      )}</TableCells>
                    
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                No Service found.
              </Typography>
            )}
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default OpenServices;
