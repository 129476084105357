import {
  Typography,
  Box,
  FormLabel,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  FormHelperText,
  Stack,
  Divider,
  Checkbox,
  Modal,
} from "@mui/material";
import authImage from "../images/authImage.png";
import React, { useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";


import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postAuth } from "../redux/auth/action";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";

import FooterMain from "./FooterMain";
import NavbarMain from "./NavbarMain";
import Progress from "./Progress";

const SignInput = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
}));

const CustomLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "rgba(192, 192, 192, 1)",
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  width: "300px",
}));

function SignUpPage() {
  const signup = useSelector((store) => store.auth.signUpAuth);
  const isLoading = useSelector((store) => store.auth.isLoading);
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [hospital, setHospital] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [showEmail, setShowEmail] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [hospitalError, setHospitalError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);


  const handleSaveTime = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem("savedTime", currentTime);
    setIsButtonDisabled(true);
  };

  const handleClick = () => {
    hospital === "" && setHospitalError("Please fill the field");
    email === "" && setEmailError("Please fill the field");
    name === "" && setNameError("Please fill the field");
    phone === "" && setPhoneError("Please fill the field");
    password === "" && setPasswordError("Please fill the field");

    if (
      name !== "" &&
      phone !== "" &&
      email !== "" &&
      password !== "" &&
      hospital !== "" &&
      hospitalError === "" &&
      emailError === "" &&
      nameError === "" &&
      passwordError === "" &&
      phoneError === "" &&
      isTermsChecked
    ) {
      let data = {
        full_name: name.trim(),
        phone: phone.trim(),
        email: email.trim(),
        password: password.trim(),
        hospital_name: hospital.trim(),
      };

      dispatch(postAuth(data));
      handleSaveTime();
    }
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/;
    setEmail(value.trim());
    setShowEmail(value.trim());
    if (regex.test(value.trim())) {
      setEmailError("");
    } else if (value !== "" && !regex.test(value.trim())) {
      setEmailError("Invalid email");
    } else if (value === "") {
      setEmailError("Please fill all fields");
    }
  };


  const handleName = (e) => {
    let value = e.target.value;
    const maxLength = 50;
    const regex = /^[A-Za-z\s]+$/;
    setName(value);
    if (value === "") {
      setNameError("Please fill the field");
    } else if (value.length >= maxLength) {
      setNameError("Hospital name should not exceed 50 characters");
    } else if (!regex.test(value)) {
      setNameError("Invalid characters in hospital name");
    } else {
      setNameError("");
    }
  };

  const handleHospitalName = (e) => {
    const value = e.target.value;
    const maxLength = 100;
    const regex = /^[a-zA-Z0-9 -]+$/;

    setHospital(value);

    if (value === "") {
      setHospitalError("Please fill the field");
    } else if (value.length < 6) {
      setHospitalError("Invalid hospital name (minimum 6 characters)");
    } else if (value.length >= maxLength) {
      setHospitalError("Hospital name should not exceed 100 characters");
    } else if (!regex.test(value)) {
      setHospitalError("Invalid characters in hospital name");
    } else {
      setHospitalError("");
    }
  };

  const handlePhone = (e) => {
    let value = e.target.value;
    const regex = /^\d{10}$/;
    setPhone(value.trim());
    if (value === "" || regex.test(value.trim())) {
      setPhoneError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setPhoneError("Invalid Number");
    }
    if (value === "") {
      setPhoneError("Please fill all field");
    }
  };

  const handlePassword = (e) => {
    const value = e.target.value;
    let regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

    setPassword(value);
    if (value === "" || regex.test(value)) {
      setPassword(value);

      setPasswordError("");
    }
    if (value !== "" && !regex.test(value)) {
      setPasswordError(
        "Password must have at least 8 characters,  1 lowercase letter,  uppercase letter,  number, and 1 special character (!”#$%&'()*+,-./:;<=>?@[]^_`{|}~)"
      );
    }
    if (value === "") {
      setPasswordError("Please fill all field");
    }
  };

  useEffect(() => {
    if (signup?.success) {
      setHospital("");
      setName("");
      setEmail("");
      setPassword("");
      setPhone("");
    }
  }, [signup]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };

  const handleTermsChange = (event) => {
    setIsTermsChecked(event.target.checked);
  };


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
        bgcolor: "white",
      }}>
      <NavbarMain />

      <Stack
        sx={{
          flex: 1,

          justifyContent: "center",
          alignItems: "center",
        }}>
        <Stack
          sx={{

            minHeight: "calc(100vh - 110px)",
            paddingTop: 2,
            paddingBottom: 2,
            width: { sm: "100%", lg: "60%" },
            flexDirection: "row",
            gap: "30px",
          }}>
          <Stack
            sx={{
              width: "40%",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                backgroundColor: "#1B2535",
              }}>

              <img
                src={authImage}
                alt="authImage"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />

            </Box>
          </Stack>

          <Stack
            sx={{
              width: { sm: "100%", lg: "60%" },
              boxShadow: "0px 0px 10px 0px rgba(219, 231, 229, 1)",
            }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "20px ",
                alignItems: "center",
              }}>
              <form
                style={{
                  width: { sm: "100%", lg: "60%" },

                  height: "100%",
                }}>
                <Stack
                  sx={{
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",

                    height: "100%",
                    justifyContent: "space-evenly",
                    gap: "15px",
                  }}>
                  <Typography variant="h4" sx={{ fontWeight: 400, fontWeight: 'bold' }}>
                    Create new account
                  </Typography>

                  <Stack>
                    <CustomLabel>Name</CustomLabel>
                    <SignInput
                      size="small"
                      value={name}
                      placeholder="e.g. Jhone"
                      onChange={handleName}
                      onKeyPress={handleKeyPress}
                      inputProps={{ maxLength: 50 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton disabled>
                            <PermIdentityIcon sx={{ color: "#787878" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <HelperText>{nameError}</HelperText>
                  </Stack>
                  <Stack>
                    <CustomLabel>Email</CustomLabel>
                    <SignInput
                      size="small"
                      value={email}
                      type="email"
                      onKeyPress={handleKeyPress}
                      placeholder="e.g. xyz@gmail.com"
                      onChange={handleEmail}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton disabled>
                            <EmailOutlinedIcon sx={{ color: "#787878" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <HelperText>{emailError}</HelperText>
                  </Stack>
                  <Stack>
                    <CustomLabel>Contect NO</CustomLabel>
                    <SignInput

                      size="small"
                      value={phone}
                      type="number"
                      onKeyPress={handleKeyPress}
                      placeholder="e.g. 8971580265"
                      onChange={handlePhone}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton disabled>
                            <PhoneEnabledOutlinedIcon
                              sx={{ color: "#787878" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <HelperText>{phoneError}</HelperText>
                  </Stack>
                  <Stack>
                    <CustomLabel>Hospital Name</CustomLabel>
                    <SignInput
                      size="small"
                      value={hospital}
                      placeholder="e.g. XYZ Hospital"
                      onChange={handleHospitalName}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton disabled>
                            <LocationCityOutlinedIcon
                              sx={{ color: "#787878" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <HelperText>{hospitalError}</HelperText>
                  </Stack>
                  <Stack>
                    <CustomLabel>Password</CustomLabel>
                    <SignInput
                      autoComplete="new-password"
                      size="small"
                      value={password}
                      placeholder="e.g. aBcH0sp1t@l"
                      onKeyPress={handleKeyPress}
                      type={showPassword ? "text" : "password"}
                      onChange={handlePassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ marginRight: "2px" }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <HelperText>{passwordError}</HelperText>
                  </Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Divider style={{ background: "rgba(231, 231, 231, 1)" }} />

                    <Stack
                      sx={{
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        flexDirection: "row",
                      }}>
                      <Checkbox name="saveAddress" value="yes" onChange={handleTermsChange} />
                      <Typography variant="caption">
                        Agree to all the <Link to="https://vajra.software/terms-conditions/" style={{ color: '#4690FF' }}>Terms & Conditions</Link>{" "}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Button
                      onClick={handleClick}
                      variant="contained"
                      sx={{ width: "150px", borderRadius: "22px", boxShadow: 'none' }}
                      disabled={!isTermsChecked}
                    >
                      Sign Up
                    </Button>

                    {isLoading && <Progress />}
                    {!signup?.success && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#EA232B",
                          marginTop: "20px",
                          textAlign: "center",
                        }}>
                        {signup?.message}
                      </Typography>
                    )}

                    {signup?.success && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#008D6F",
                          textAlign: "center",
                          marginTop: "20px",
                        }}>
                        An email with a verification link was just sent to your
                        email id {showEmail}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <FooterMain />
    </Box>
  );
}

export default SignUpPage;
