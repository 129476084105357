import {
    Typography,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
    Paper,
    Dialog,
  } from "@mui/material";
  
  import { useState } from "react";
  import CloseIcon from "@mui/icons-material/Close";
  import CalenderModal from "../components/CalenderModal";
  import { useEffect } from "react";
  import { Link } from "react-router-dom";
  import { useSelector } from "react-redux";
  
  const headingCellStyles = {
    color: "#1746A2",
    whiteSpace: "nowrap",
  };
  const ExpiryModalPage = ({
    dataModal,
    setDataModalOpen,
    dataModalOpen,
    text,
    color,
  }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleClose = () => {
      setDataModalOpen(false);
    };
    const handleClose2 = () => {
      setSuccessDialogOpen(false);
    };
    useEffect(() => {
      setPage(0);
    }, [dataModal]);
  
    const getUser = useSelector((store)=> store?.data)
    console.log(getUser)
  
    return (
      <Dialog
        open={dataModalOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{
         borderRadius: "0px",
          "& .MuiDialog-paper": {
           borderRadius: "0px",
          },
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
           borderRadius: "0px",
  
            backgroundColor: color,
          }}>
          <Typography
            variant="h4"
            sx={{
              color: "white",
              backgroundColor: color,
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px",
            }}>
            {text}
          </Typography>
  
          {isMobile ? (
            <Typography
              onClick={handleClose}
              variant="body2"
              sx={{
                color: "white",
                backgroundColor: color,
                height: "100%",
                cursor: "pointer",
                paddingRight: "2%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <CloseIcon />
            </Typography>
          ) : (
            <Typography
              onClick={handleClose}
              variant="body2"
              sx={{
                color: "white",
                backgroundColor: color,
                height: "100%",
                cursor: "pointer",
              }}>
              Close
            </Typography>
          )}
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow sx={{ bgcolor: "#EFF5FE" }}>
                  <TableCell
                    style={headingCellStyles}
                    sx={{ bgcolor: "#EFF5FE" }}>
                    Asset Name
                  </TableCell>
                  <TableCell style={headingCellStyles}>Asset Code</TableCell>
                  <TableCell style={headingCellStyles}>Department</TableCell>
                  <TableCell style={headingCellStyles}>Extended To</TableCell>
  
                  <TableCell style={headingCellStyles}>Completed By</TableCell>
                  {/* <TableCell style={headingCellStyles}>Duration</TableCell> */}
                  <TableCell style={headingCellStyles}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataModal
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component={Link}
                        to={`/asset-details/${item.asset_id}`}
                        sx={{ textDecoration: "none" }}>
                        {item.asset_name}
                      </TableCell>
                      <TableCell>{item.asset_code}</TableCell>
                      <TableCell>{item.department}</TableCell>
                      <TableCell>{item.extend_to}</TableCell>
                      <TableCell>{item.completed_by}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
  
          {dataModal?.length > rowsPerPage && (
            <TablePagination
              sx={{
                bgcolor: "white",
                borderRadius: "  0px 0px 25px 25px",
              }}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataModal.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>
        <CalenderModal open={successDialogOpen} onClose={handleClose2} />
      </Dialog>
    );
  };
  
  export default ExpiryModalPage;
  