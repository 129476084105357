import {
  Typography,
  Box,
  FormLabel,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  FormHelperText,
  Stack,
  Divider,
} from "@mui/material";

import React, { useState } from "react";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import authImage from "../images/authImage.png";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotAuth } from "../redux/auth/action";
import { useSelector } from "react-redux";
import signinImg from "../images/Signin_img.png"


import styled from "@emotion/styled";

import FooterMain from "./FooterMain";
import NavbarMain from "./NavbarMain";


const SignInput = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
}));

const CustomLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "rgba(192, 192, 192, 1)",

}));


const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  width: "300px",


}));

function ForgotPasswordPage() {
  const status = useSelector((store) => store.auth.forgotAuth);
  const [emailError, setEmailError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const isLoading = useSelector((store) => store.auth.isLoading);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };

  const handleClick = () => {
    email === "" && setEmailError("Please fill the field");
    if (email !== "" && emailError === "") {
      let data = {
        email: email,
      };

      dispatch(forgotAuth(data));
    }
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(value.trim());
  
    if (value === "") {
      setEmailError("Please fill all field");
    } else if (regex.test(value.trim())) {
      setEmailError("");
    } else {
      setEmailError("Invalid email");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
        bgcolor: "white",
      }}>
      <NavbarMain />

      <Stack
        sx={{
          flex: 1,

          justifyContent: "center",
          alignItems: "center",
        }}>
        <Stack
          sx={{
            // p: { sm: 0, lg: 5 },
            height: "calc(100vh - 150px)",

            width: { sm: "100%", lg: "60%" },
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
          }}>
          <Stack
            sx={{
              width: "40%",

              display: { xs: "none", sm: "none", lg: "flex" },
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                backgroundColor: "#1B2535",
              }}>
              <img
                src={signinImg}
                alt="signinImg"
                style={{
                  width: "500px",
                  height: "100%", position: 'relative',
                  left: "100px",
                  '@media (max-width: 1440px)': {
                    left: "20px",
                  },
                  '@media (max-width: 768px)': {
                    left: "20px",
                  },
                  '@media (max-width: 480px)': {
                    left: "10px",
                  },
                }}
              />
            </div>
          </Stack>

          <Stack
            sx={{
              width: { xs: "90%", sm: "100%", md: "100%", lg: "60%" },

              boxShadow: "0px 0px 10px 0px rgba(219, 231, 229, 1)",
            }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "20px ",
                alignItems: "center",
              }}>
              <Box
                style={{
                  width: { sm: "100%", lg: "60%" },

                  height: "100%",
                }}>
                <Stack
                  sx={{
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",

                    height: "100%",
                    justifyContent: "space-evenly",
                    alignItems: 'center',
                  }}>
                  <Typography variant="h4" sx={{ fontWeight: 400, fontWeight: 'bold' }}>
                    Forgot Password?
                  </Typography>
                  <Typography sx={{ width: '250px', }}>
                    Enter your email address to receive a verification code
                  </Typography>

                  <Stack>
                    <CustomLabel>Email</CustomLabel>
                    <SignInput
                      size="small"
                      value={email}
                      type="email"
                      onKeyPress={handleKeyPress}
                      placeholder="e.g. xyz@gmail.com"
                      onChange={handleEmail}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton disabled>
                            <EmailOutlinedIcon sx={{ color: "#787878" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <HelperText>{emailError}</HelperText>
                  </Stack>

                  <Stack sx={{ width: "100%" }}>
                    <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
                  </Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Button
                      onClick={handleClick}
                      variant="contained"
                      sx={{ width: "150px", borderRadius: "22px" }}>
                      Send Link
                    </Button>
                  </Stack>
                  <Typography>
                    Click Here to go back to{" "}
                    <Link
                      to="/signin"
                      style={{
                        color: "rgba(70, 144, 255, 1)",
                        // textDecoration: "none",
                      }}>
                      Sign In
                    </Link>
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <FooterMain />
    </Box>
  );
}

export default ForgotPasswordPage;
