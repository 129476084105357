import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  Button,
  styled,
  Divider,
  FormHelperText,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import "jspdf-autotable";

import { contactUs } from "../redux/data/action";
import SuccessPopUp from "../components/SuccessPopUp";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const Label2 = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 500,
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const OuterBox = styled(Stack)(({ theme }) => ({
  width: "600px",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const ContactPage = () => {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [email, setEmail] = useState(userIds.email);
  const [name, setName] = useState(userIds.username);

  const [notes, setNotes] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isNotesError, setIsNotesError] = useState("");
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    setNotes("");
  };

  const handleNotes = (e) => {
    const value = e.target.value;
    const regex = /^[^"'()<>]*$/;
    setNotes(value);
    if (regex.test(value) || value === "") {
      setNotes(value);
      setIsNotesError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsNotesError("Please avoid that special character");
    }
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    const regex = /^[\w.-]+@([\w-]+\.)+[a-zA-Z]{2,}$/;
    setEmail(value);

    if (regex.test(value)) {
      setEmailError("");
    } else if (value !== "") {
      setEmailError("Invalid email");
    } else {
      setEmailError("Please fill all fields");
    }
  };

  const handleName = (e) => {
    let value = e.target.value;
    const maxLength = 50;
    const regex = /^[A-Za-z\s]+$/;
    setName(value);
    if (value === "") {
      setNameError("Please fill the field");
      // } else if (value.length < 3) {
      //   setNameError("Invalid hospital name (minimum 3 characters)");
    } else if (value.length >= maxLength) {
      setNameError("Name should not exceed 50 characters");
    } else if (!regex.test(value)) {
      setNameError("Invalid characters in hospital name");
    } else {
      setNameError("");
    }
  };

  const handleSubmit = () => {
    if (notes === "") {
      setIsNotesError("Please fill the field");
    }
    if (name === "") {
      setIsNotesError("Please fill the field");
    }
    if (email === "") {
      setIsNotesError("Please fill the field");
    }

    if (
      notes !== "" &&
      isNotesError === "" &&
      email !== "" &&
      emailError === "" &&
      name !== "" &&
      nameError === ""
    ) {
      let data = {
        hospid: userIds.hospid,
        hospname: userIds.hospname,
        hospcity: userIds.city,
        userid: userIds.userid,
        username: name.trim(),
        useremail: email.trim(),
        usermobile: userIds.mobile,
        message: notes.trim(),
        branchid: userBranch.id,
        branchname: userBranch.branch,
      };
      dispatch(contactUs(data));
      setToggle(true);
      setOpenModal(true);
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}
    >
      <OuterBox>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}
        >
          <Label variant="subtitle1" color="primary">
            Connect with Us
          </Label>
          {/* <IconButton>
            <Calculate color="primary" />
          </IconButton> */}
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}
          >
            <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
              How can we help you?{" "}
            </Label>
          </Box>
          <Divider style={{ background: "rgba(70, 144, 255, 1)" }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
              height: "auto",
              minHeight: "170px",
              marginTop: "20px",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                marginRight: "16px",
                gap: "15px",
                marginTop: "10px",
                height: "100%",
                textAlign: "start",
              }}
            >
              <Label>User</Label>
              <Stack>
                <Field
                  value={name}
                  onChange={handleName}
                  placeholder="User Name"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <HelperText variant="caption">{nameError}</HelperText>
              </Stack>
              <Stack>
                <Field
                  value={email}
                  onChange={handleEmail}
                  placeholder="Email"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <HelperText variant="caption">{emailError}</HelperText>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                height: "100%",

                gap: "15px",
                textAlign: "start",
                marginTop: "10px",
              }}
            >
              <Label>Leave us a message</Label>
              <Stack sx={{ height: "100%" }}>
                <TextField
                  value={notes}
                  onChange={handleNotes}
                  placeholder="Enter your message..."
                  variant="outlined"
                  multiline
                  rows={5} // You can adjust the number of rows as needed
                  sx={{ width: "100%", height: "100%" }}
                />
                <HelperText variant="caption">{isNotesError}</HelperText>
              </Stack>
            </Stack>
          </Box>

          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "16px",
              }}
            >
              <Stack
                sx={{
                  flexShrink: 1,
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{
                    width: "154px",
                    borderRadius: "22px",
                    bgcolor: "rgba(70, 144, 255, 1)",
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Box>
          </>
          <>
            <Stack
              sx={{
                width: "100%",

                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Divider sx={{ marginBottom: "10px" }} />
              <Stack
                sx={{
                  width: "100%",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                  },
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Stack
                  flexDirection={"row"}
                  style={{
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <IconButton>
                    <LocationOnIcon size={15} />
                  </IconButton>
                  <Label2
                    variant="caption"
                    style={{
                      textAlign: "start",
                    }}
                  >
                    2086,9th Cross RPC Layout ,
                    <br />
                    Vijayanagar, Bangalore - 560040
                  </Label2>
                </Stack>
                <Stack
                  flexDirection={"row"}
                  style={{
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Label2 variant="caption">
                    {" "}
                    <IconButton>
                      <LocalPhoneIcon size={15} />
                    </IconButton>
                    +91 89715 80265
                  </Label2>
                </Stack>

                <Stack>
                  <Stack icon={<LocalPhoneIcon />} label="Call me" />
                </Stack>
                <Stack
                  flexDirection={"row"}
                  style={{
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Label2 variant="caption">
                    {" "}
                    <IconButton>
                      <EmailIcon size={15} />
                    </IconButton>
                    contact@vajra.software
                  </Label2>
                </Stack>
              </Stack>
            </Stack>
          </>
        </Box>
      </OuterBox>
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"Your message has been submitted successfully.  "}
        subText2={" We will get back to you soon!  "}
        toggle={toggle}
      />
    </Stack>
  );
};

export default ContactPage;
