// import React, { useRef } from "react";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import { Button, Container } from "@mui/material";
// import { PieChart } from "@mui/x-charts/PieChart";
// import { BarChart } from "@mui/x-charts/BarChart";
// import "jspdf-autotable";
// import vajraBlack from "../images/vajraBlack.png";

// const TestingComponent = () => {
//   //   const chartRef = useRef();

//   // // Example Bar Chart Data
//   // const barData = {
//   //   xAxis: ["January", "February", "March", "April", "May"],
//   //   series: [
//   //     {
//   //       id: "Sales",
//   //       data: [12, 19, 3, 5, 2],
//   //     },
//   //   ],
//   // };

//   // // Example Pie Chart Data
//   // const pieData = [
//   //   { label: "Red", value: 12 },
//   //   { label: "Blue", value: 19 },
//   //   { label: "Yellow", value: 3 },
//   // ];

//   // const formattedPieData = pieData.map((item) => ({
//   //   label: item.label,
//   //   value: item.value,
//   // }));

//   // // Example Table Data
//   // const tableData = [
//   //   ["Item 1", "Category A", "$100"],
//   //   ["Item 2", "Category B", "$200"],
//   //   ["Item 3", "Category C", "$300"],
//   //   ["Item 4", "Category D", "$400"],
//   //   ["Item 5", "Category E", "$500"],
//   // ];

//   // // Modified generatePDF function
//   // const generatePDF = () => {
//   //   const input = chartRef.current;

//   //   const logoUrl = vajraBlack; // Replace with your logo image path or base64
//   //   const headerLogoWidth = 20; // Adjust logo width
//   //   const headerLogoHeight = 10; // Adjust logo height

//   //   html2canvas(input, {
//   //     scale: 2, // Higher resolution
//   //     useCORS: true, // Handle cross-origin images
//   //     width: input.scrollWidth, // Fix width to container
//   //   }).then((canvas) => {
//   //     const imgWidth = 190; // Fixed width for PDF
//   //     const pageHeight = 290; // A4 page height
//   //     const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale image proportionally
//   //     let heightLeft = imgHeight;

//   //     const pdf = new jsPDF("p", "mm", "a4"); // Portrait mode, A4 page
//   //     let position = 30; // Position the content below the logo (30mm from the top)

//   //     // Get the width of the PDF page
//   //     const pageWidth = pdf.internal.pageSize.getWidth();

//   //     // Calculate the X position to center the logo
//   //     const logoXPosition = (pageWidth - headerLogoWidth) / 2;

//   //     // Add the first page with the centered logo
//   //     const addLogo = (pdf) => {
//   //       // Add the logo at the top center of the page
//   //       pdf.addImage(logoUrl, "PNG", logoXPosition, 10, headerLogoWidth, headerLogoHeight);
//   //     };

//   //     // Add the centered logo to the first page
//   //     addLogo(pdf);

//   //     // Add the content (charts and table)
//   //     pdf.addImage(
//   //       canvas.toDataURL("image/png"),
//   //       "PNG",
//   //       10,
//   //       position,
//   //       imgWidth,
//   //       imgHeight
//   //     );
//   //     heightLeft -= pageHeight;

//   //     // Handle additional pages with the logo on top
//   //     while (heightLeft >= 0) {
//   //       position = heightLeft - imgHeight;
//   //       pdf.addPage();
//   //       addLogo(pdf); // Add logo to new pages
//   //       pdf.addImage(
//   //         canvas.toDataURL("image/png"),
//   //         "PNG",
//   //         10,
//   //         position,
//   //         imgWidth,
//   //         imgHeight
//   //       );
//   //       heightLeft -= pageHeight;
//   //     }

//   //     pdf.save("chart_with_logo.pdf"); // Save the PDF
//   //   });
//   // };

//   // return (
//   //   <Container>
//   //     <h1>MUI Charts and PDF Export</h1>

//   //     {/* Wrapper for charts and table */}
//   //     <div ref={chartRef}>
//   //       {/* Bar Chart */}
//   //       <div>
//   //         <BarChart
//   //           width={500}
//   //           height={300}
//   //           xAxis={[{scaleType:'band', data: barData.xAxis }]}
//   //           series={[{ data: barData.series[0].data }]}
//   //         />
//   //       </div>

//   //       {/* Pie Chart */}
//   //       <div style={{ marginTop: "20px" }}>
//   //         <PieChart
//   //           series={[
//   //             {
//   //               data: formattedPieData,
//   //               innerRadius: 50,
//   //               highlightScope: { faded: "global", highlighted: "item" },
//   //               faded: {
//   //                 innerRadius: 30,
//   //                 additionalRadius: -30,
//   //                 color: "gray",
//   //               },
//   //             },
//   //           ]}
//   //           width={500}
//   //           height={200}
//   //         />
//   //       </div>

//   //       {/* Table below the charts */}
//   //       <table
//   //         style={{
//   //           width: "100%",
//   //           marginTop: "20px",
//   //           border: "1px solid black",
//   //           borderCollapse: "collapse",
//   //         }}
//   //       >
//   //         <thead>
//   //           <tr>
//   //             <th style={{ border: "1px solid black", padding: "5px" }}>
//   //               Item
//   //             </th>
//   //             <th style={{ border: "1px solid black", padding: "5px" }}>
//   //               Category
//   //             </th>
//   //             <th style={{ border: "1px solid black", padding: "5px" }}>
//   //               Price
//   //             </th>
//   //           </tr>
//   //         </thead>
//   //         <tbody>
//   //           {tableData.map((row, index) => (
//   //             <tr key={index}>
//   //               {row.map((cell, i) => (
//   //                 <td
//   //                   key={i}
//   //                   style={{ border: "1px solid black", padding: "5px" }}
//   //                 >
//   //                   {cell}
//   //                 </td>
//   //               ))}
//   //             </tr>
//   //           ))}
//   //         </tbody>
//   //       </table>
//   //     </div>

//   //     {/* Export Button */}
//   //     <Button
//   //       variant="contained"
//   //       color="primary"
//   //       onClick={generatePDF}
//   //       style={{ marginTop: "20px" }}
//   //     >
//   //       Export to PDF
//   //     </Button>
//   //   </Container>
//   // )

//   const barData = {
//     xAxis: ["January", "February", "March", "April", "May"],
//     series: [
//       {
//         id: "Sales",
//         data: [12, 19, 3, 5, 2],
//       },
//     ],
//   };

//   const pieData = [
//     { label: "Red", value: 12 },
//     { label: "Blue", value: 19 },
//     { label: "Yellow", value: 3 },
//   ];

//   const formattedPieData = pieData.map((item) => ({
//     label: item.label,
//     value: item.value,
//   }));

//   // const handleDownloadPdf = async () => {
//   //   const doc = new jsPDF({
//   //     orientation: "portrait",
//   //     unit: "pt",
//   //     format: [595, 842], // A4 size to maintain desktop layout
//   //   });
  
//   //   // Capture chart area (Assuming chartRef is the ref for the chart container)
//   //   const chartElement = document.getElementById("chartSection");
//   //   const chartCanvas = await html2canvas(chartElement,{ scale: 2 });
//   //   const chartImageData = chartCanvas.toDataURL("image/png");
  
//   //   // Add chart as image to PDF
//   //   doc.addImage(chartImageData, "PNG", 15, 40, 565, 300); // Adjust dimensions as needed
  
//   //   // Define multiple tables
//   //   const firstTableHead = [["Column A", "Column B", "Column C"]];
//   //   const firstTableBody = [
//   //     ["Row 1 Data A", "Row 1 Data B", "Row 1 Data C"],
//   //     ["Row 2 Data A", "Row 2 Data B", "Row 2 Data C"],
//   //     ["Row 3 Data A", "Row 3 Data B", "Row 3 Data C"],
//   //     ["Row 4 Data A", "Row 4 Data B", "Row 4 Data C"],
//   //     ["Row 5 Data A", "Row 5 Data B", "Row 5 Data C"],
//   //   ];
  
//   //   const secondTableHead = [["Column X", "Column Y"]];
//   //   const secondTableBody = [
//   //     ["Row 1 Data X", "Row 1 Data Y"],
//   //     ["Row 2 Data X", "Row 2 Data Y"],
//   //     ["Row 3 Data X", "Row 3 Data Y"],
//   //     ["Row 4 Data X", "Row 4 Data Y"],
//   //     ["Row 5 Data X", "Row 5 Data Y"],
//   //   ];
  
//   //   const thirdTableHead = [["Column P", "Column Q", "Column R"]];
//   //   const thirdTableBody = [
//   //     ["Row 1 Data P", "Row 1 Data Q", "Row 1 Data R"],
//   //     ["Row 2 Data P", "Row 2 Data Q", "Row 2 Data R"],
//   //     ["Row 3 Data P", "Row 3 Data Q", "Row 3 Data R"],
//   //     ["Row 1 Data P", "Row 1 Data Q", "Row 1 Data R"],
//   //     ["Row 2 Data P", "Row 2 Data Q", "Row 2 Data R"],
//   //     ["Row 3 Data P", "Row 3 Data Q", "Row 3 Data R"],
//   //     ["Row 1 Data P", "Row 1 Data Q", "Row 1 Data R"],
//   //     ["Row 2 Data P", "Row 2 Data Q", "Row 2 Data R"],
//   //     ["Row 3 Data P", "Row 3 Data Q", "Row 3 Data R"],
//   //     ["Row 1 Data P", "Row 1 Data Q", "Row 1 Data R"],
//   //     ["Row 2 Data P", "Row 2 Data Q", "Row 2 Data R"],
//   //     ["Row 3 Data P", "Row 3 Data Q", "Row 3 Data R"],
//   //   ];
  
//   //   // Adjust the startY for the first table to prevent overlap with the chart
//   //   const chartHeight = 300; // Height of the chart
//   //   const firstTableStartY = chartHeight + 50;
  
//   //   // Create the first table
//   //   doc.autoTable({
//   //     head: firstTableHead,
//   //     body: firstTableBody,
//   //     startY: firstTableStartY, // Start table below the chart
//   //     margin: { top: 20, left: 15, right: 15 },
//   //     pageBreak: "auto", // Auto page break
//   //   });
  
//   //   // Get Y position after the first table
//   //   let finalY = doc.lastAutoTable.finalY;
  
//   //   // Create the second table, ensuring it starts on a new page if necessary
//   //   doc.autoTable({
//   //     head: secondTableHead,
//   //     body: secondTableBody,
//   //     startY: finalY + 30, // Automatically handle page breaks
//   //     margin: { top: 20, left: 15, right: 15 },
//   //     pageBreak: "auto",
//   //   });
  
//   //   // Get Y position after the second table
//   //   finalY = doc.lastAutoTable.finalY;
  
//   //   // Create the third table, ensuring it starts on a new page if necessary
//   //   doc.autoTable({
//   //     head: thirdTableHead,
//   //     body: thirdTableBody,
//   //     startY: finalY + 30, // Automatically handle page breaks
//   //     margin: { top: 20, left: 15, right: 15 },
//   //     pageBreak: "auto",
//   //   });
  
//   //   // Download the PDF
//   //   doc.save("report.pdf");
//   // };
  
// //   const handleDownloadPdf = async () => {
// //     const doc = new jsPDF({
// //       orientation: "portrait",
// //       unit: "pt",
// //       format: [595, 842], // A4 size
// //     });

// //     // Capture chart area with higher resolution
// //     const chartElement = document.getElementById("chartSection");
// //     const chartCanvas = await html2canvas(chartElement, { scale: 2 });
// //     const chartImageData = chartCanvas.toDataURL("image/png");

// //     // Add chart as image to PDF with adjusted dimensions
// //     doc.addImage(chartImageData, "PNG", 15, 40, 565, 400); // Adjust dimensions as needed

// //     // Define multiple tables
// //     const firstTableHead = [["Column A", "Column B", "Column C"]];
// //     const firstTableBody = [
// //       ["Row 1 Data A", "Row 1 Data B", "Row 1 Data C"],
// //       ["Row 2 Data A", "Row 2 Data B", "Row 2 Data C"],
// //       ["Row 3 Data A", "Row 3 Data B", "Row 3 Data C"],
// //       ["Row 4 Data A", "Row 4 Data B", "Row 4 Data C"],
// //       ["Row 5 Data A", "Row 5 Data B", "Row 5 Data C"],
// //     ];

// //     const secondTableHead = [["Column X", "Column Y"]];
// //     const secondTableBody = [
// //       ["Row 1 Data X", "Row 1 Data Y"],
// //       ["Row 2 Data X", "Row 2 Data Y"],
// //       ["Row 3 Data X", "Row 3 Data Y"],
// //       ["Row 4 Data X", "Row 4 Data Y"],
// //       ["Row 5 Data X", "Row 5 Data Y"],
// //     ];

// //     const thirdTableHead = [["Column P", "Column Q", "Column R"]];
// //     const thirdTableBody = [
// //       ["Row 1 Data P", "Row 1 Data Q", "Row 1 Data R"],
// //       ["Row 2 Data P", "Row 2 Data Q", "Row 2 Data R"],
// //       ["Row 3 Data P", "Row 3 Data Q", "Row 3 Data R"],
// //       ["Row 1 Data P", "Row 1 Data Q", "Row 1 Data R"],
// //       ["Row 2 Data P", "Row 2 Data Q", "Row 2 Data R"],
// //       ["Row 3 Data P", "Row 3 Data Q", "Row 3 Data R"],
// //       ["Row 1 Data P", "Row 1 Data Q", "Row 1 Data R"],
// //       ["Row 2 Data P", "Row 2 Data Q", "Row 2 Data R"],
// //       ["Row 3 Data P", "Row 3 Data Q", "Row 3 Data R"],
// //       ["Row 1 Data P", "Row 1 Data Q", "Row 1 Data R"],
// //       ["Row 2 Data P", "Row 2 Data Q", "Row 2 Data R"],
// //       ["Row 3 Data P", "Row 3 Data Q", "Row 3 Data R"],
// //     ];

// //     // Adjust the startY for the first table to prevent overlap with the chart
// //     const chartHeight = 400; // Height of the chart
// //     const firstTableStartY = chartHeight + 50;

// //     // Create the first table
// //     doc.autoTable({
// //       head: firstTableHead,
// //       body: firstTableBody,
// //       startY: firstTableStartY,
// //       margin: { top: 20, left: 15, right: 15 },
// //       pageBreak: "auto",
// //     });

// //     // Get Y position after the first table
// //     let finalY = doc.lastAutoTable.finalY;

// //     // Create the second table
// //     doc.autoTable({
// //       head: secondTableHead,
// //       body: secondTableBody,
// //       startY: finalY + 30,
// //       margin: { top: 20, left: 15, right: 15 },
// //       pageBreak: "auto",
// //     });

// //     // Get Y position after the second table
// //     finalY = doc.lastAutoTable.finalY;

// //     // Create the third table
// //     doc.autoTable({
// //       head: thirdTableHead,
// //       body: thirdTableBody,
// //       startY: finalY + 30,
// //       margin: { top: 20, left: 15, right: 15 },
// //       pageBreak: "auto",
// //     });

// //     // Download the PDF
// //     doc.save("report.pdf");
// // };

  
// // const handleDownloadPdf = async () => {
// //   const doc = new jsPDF({
// //     orientation: "portrait",
// //     unit: "pt",
// //     format: [595, 842], // A4 size
// //   });

// //   // Title and Date Section in Table
// //   doc.setFontSize(16);
// //   doc.text("Vajra Monthly Report", 40, 40);
// //   doc.setFontSize(12);
// //   doc.text("January Month Report", 40, 60);
// //   doc.text("Date: 12/02/2024", 40, 80);
// //   doc.text("Hospital: ABC Hospital", 40, 100);
// //   doc.text("Branch: Branch Name", 40, 120);

// //   // Overview in Table
// //   const overviewTableHead = [["January Monthly rating", "Total Assets", "Working", "Not Working", "Discarded", "Total Downtime"]];
// //   const overviewTableBody = [
// //     ["4.1", "345", "290", "45", "10", "2 Days, 12h:23m:34s"],
// //   ];

// //   doc.autoTable({
// //     head: overviewTableHead,
// //     body: overviewTableBody,
// //     startY: 140,
// //     margin: { top: 20, left: 15, right: 15 },
// //     pageBreak: "auto",
// //   });

// //   const overviewAdditionalInfoHead = [["Incidents (Open)", "Incidents (Closed)", "Services (Open)", "Services (Closed)", "Budget Spent"]];
// //   const overviewAdditionalInfoBody = [["15", "12", "5", "10", "₹10,000"]];

// //   doc.autoTable({
// //     head: overviewAdditionalInfoHead,
// //     body: overviewAdditionalInfoBody,
// //     startY: doc.lastAutoTable.finalY + 10,
// //     margin: { top: 20, left: 15, right: 15 },
// //     pageBreak: "auto",
// //   });

// //   const overviewOtherInfoHead = [["Requested Service - Medpick", "Requested Service - Others", "Check-Outs", "Check-Ins"]];
// //   const overviewOtherInfoBody = [["03", "02", "10", "08"]];

// //   doc.autoTable({
// //     head: overviewOtherInfoHead,
// //     body: overviewOtherInfoBody,
// //     startY: doc.lastAutoTable.finalY + 10,
// //     margin: { top: 20, left: 15, right: 15 },
// //     pageBreak: "auto",
// //   });

// //   // Incidents Report for Blood Center
// //   doc.setFontSize(14);
// //   doc.text("Incidents Report: Blood Center", 40, doc.lastAutoTable.finalY + 30);

// //   const bloodCenterIncidentsHead = [["Name", "Code", "Status", "Incident Details", "Incident Cleared", "Downtime"]];
// //   const bloodCenterIncidentsBody = [
// //     ["Ge ECG Machine", "AG-7645698", "Closed", "Issue: Not working", "Yes", "2h:00m:00s"],
// //   ];

// //   doc.autoTable({
// //     head: bloodCenterIncidentsHead,
// //     body: bloodCenterIncidentsBody,
// //     startY: doc.lastAutoTable.finalY + 50,
// //     margin: { top: 20, left: 15, right: 15 },
// //     pageBreak: "auto",
// //   });

// //   // Incidents Report for Emergency Center
// //   doc.text("Incidents Report: Emergency Center", 40, doc.lastAutoTable.finalY + 30);

// //   const emergencyCenterIncidentsBody = [
// //     ["Ge ECG Machine", "AG-7645698", "Closed", "Issue: Not working", "Yes", "2h:00m:00s"],
// //     ["Ge ECG Machine", "AG-7645698", "Open", "Issue: Not working", "No", "4h:00m:00s"],
// //   ];

// //   doc.autoTable({
// //     head: bloodCenterIncidentsHead,
// //     body: emergencyCenterIncidentsBody,
// //     startY: doc.lastAutoTable.finalY + 50,
// //     margin: { top: 20, left: 15, right: 15 },
// //     pageBreak: "auto",
// //   });

// //   // Incidents Report for Laboratory
// //   doc.text("Incidents Report: Laboratory", 40, doc.lastAutoTable.finalY + 30);

// //   const laboratoryIncidentsBody = [
// //     ["Ge ECG Machine", "AG-7645698", "Closed", "Issue: Not working", "Yes", "2h:00m:00s"],
// //     ["Ge ECG Machine", "AG-7645698", "Closed", "Issue: Not working", "Yes", "2h:00m:00s"],
// //   ];

// //   doc.autoTable({
// //     head: bloodCenterIncidentsHead,
// //     body: laboratoryIncidentsBody,
// //     startY: doc.lastAutoTable.finalY + 50,
// //     margin: { top: 20, left: 15, right: 15 },
// //     pageBreak: "auto",
// //   });

// //   // Services Report: Laboratory
// //   doc.text("Services Report: Laboratory", 40, doc.lastAutoTable.finalY + 30);

// //   const servicesLabHead = [["Name", "Code", "Status", "Issue Details", "Incident Cleared", "Downtime"]];
// //   const servicesLabBody = [
// //     ["Blood Checkup Machine", "SB000023", "Closed", "Issue: Not working", "Yes", "2h:00m:00s"],
// //     ["Ge ECG Machine", "SB000023", "Closed", "Issue: Not working", "Yes", "2h:00m:00s"],
// //   ];

// //   doc.autoTable({
// //     head: servicesLabHead,
// //     body: servicesLabBody,
// //     startY: doc.lastAutoTable.finalY + 50,
// //     margin: { top: 20, left: 15, right: 15 },
// //     pageBreak: "auto",
// //   });

// //   // Requested Service from Medpick
// //   doc.text("Requested Service from Medpick", 40, doc.lastAutoTable.finalY + 30);

// //   const medpickServiceBody = [
// //     ["Blood Checkup Machine", "SB000023", "Closed", "Issue: Not working", "Yes", "2h:00m:00s"],
// //   ];

// //   doc.autoTable({
// //     head: servicesLabHead,
// //     body: medpickServiceBody,
// //     startY: doc.lastAutoTable.finalY + 50,
// //     margin: { top: 20, left: 15, right: 15 },
// //     pageBreak: "auto",
// //   });

// //   // Download the PDF
// //   doc.save("Vajra_Monthly_Report.pdf");
// // };

// const handleDownloadPdf = () => {
//   const doc = new jsPDF({
//     orientation: "portrait",
//     unit: "pt",
//     format: [595, 842], // A4 size
//   });

//   // Create a complex nested table structure
//   doc.setFontSize(14);
//   doc.text("Complex Nested Table Example", 40, 40);

//   const head = [
//     [
//       { content: "Main Category A", colSpan: 2, styles: { halign: 'center' } },
//       { content: "Main Category B", colSpan: 4, styles: { halign: 'center' } },
//       { content: "Main Category C", colSpan: 2, styles: { halign: 'center' } },
//     ],
//     [
//       { content: "Sub A1", styles: { halign: 'center' } },
//       { content: "Sub A2", styles: { halign: 'center' } },
//       { content: "Sub B1", colSpan: 2, styles: { halign: 'center' } },
//       { content: "Sub B2", colSpan: 2, styles: { halign: 'center' } },
//       { content: "Sub C1", styles: { halign: 'center' } },
//       { content: "Sub C2", styles: { halign: 'center' } },
//     ],
//     [
//       { content: "Row 1A", styles: { halign: 'center' } },
//       { content: "Row 2A", styles: { halign: 'center' } },
//       { content: "Row 1B1", styles: { halign: 'center' } },
//       { content: "Row 2B1", styles: { halign: 'center' } },
//       { content: "Row 1B2", styles: { halign: 'center' } },
//       { content: "Row 2B2", styles: { halign: 'center' } },
//       { content: "Row 1C", styles: { halign: 'center' } },
//       { content: "Row 2C", styles: { halign: 'center' } },
//     ],
//   ];

//   const body = [
//     [
//       { content: "Data A1", rowSpan: 2, styles: { halign: 'center' } },
//       { content: "Data A2", rowSpan: 2, styles: { halign: 'center' } },
//       { content: "Data B1-1", colSpan: 2, styles: { halign: 'center' } },
//       { content: "Data B2-1", colSpan: 2, styles: { halign: 'center' } },
//       { content: "Data C1", rowSpan: 2, styles: { halign: 'center' } },
//       { content: "Data C2", rowSpan: 2, styles: { halign: 'center' } },
//     ],
//     [
//       { content: "B1 Sub 1", styles: { halign: 'center' } },
//       { content: "B1 Sub 2", styles: { halign: 'center' } },
//       { content: "B2 Sub 1", styles: { halign: 'center' } },
//       { content: "B2 Sub 2", styles: { halign: 'center' } },
//     ],
//     [
//       { content: "Data A1-2", rowSpan: 2, styles: { halign: 'center' } },
//       { content: "Data A2-2", rowSpan: 2, styles: { halign: 'center' } },
//       { content: "Data B1-2", colSpan: 2, styles: { halign: 'center' } },
//       { content: "Data B2-2", colSpan: 2, styles: { halign: 'center' } },
//       { content: "Data C1-2", rowSpan: 2, styles: { halign: 'center' } },
//       { content: "Data C2-2", rowSpan: 2, styles: { halign: 'center' } },
//     ],
//     [
//       { content: "B1 Sub 1", styles: { halign: 'center' } },
//       { content: "B1 Sub 2", styles: { halign: 'center' } },
//       { content: "B2 Sub 1", styles: { halign: 'center' } },
//       { content: "B2 Sub 2", styles: { halign: 'center' } },
//     ],
//   ];

//   doc.autoTable({
//     head: head,
//     body: body,
//     startY: 60,
//     theme: 'grid',
//     margin: { top: 20, left: 15, right: 15 },
//     styles: { fontSize: 10 },
//   });

//   doc.save("complex_nested_table.pdf");
// };


  

//   return (
//     <div>
//       <div id="chartSection">
//         {/* Your chart components here (e.g., MUI X Charts) */}
//         <BarChart
//           width={500}
//           height={300}
//           xAxis={[{ scaleType: "band", data: barData.xAxis }]}
//           series={[{ data: barData.series[0].data }]}
//         />

//         <PieChart
//           series={[
//             {
//               data: formattedPieData,
//               innerRadius: 50,
//               highlightScope: { faded: "global", highlighted: "item" },
//               faded: {
//                 innerRadius: 30,
//                 additionalRadius: -30,
//                 color: "gray",
//               },
//             },
//           ]}
//           width={500}
//           height={200}
//         />
//       </div>
//       <div id="tableSection">
//         <table>
//           <thead>
//             <tr>
//               <th>Column 1</th>
//               <th>Column 2</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Data 1</td>
//               <td>Data 2</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <Button onClick={handleDownloadPdf}>Download as PDF</Button>
//     </div>
//   );
// };

// export default TestingComponent;


import React, { useRef } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from "recharts";
import { jsPDF } from "jspdf";
import { Canvg } from "canvg";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";


const data = [
  { name: "January", open: 40, closed: 24 },
  { name: "February", open: 30, closed: 13 },
  { name: "March", open: 20, closed: 98 },
  { name: "April", open: 28, closed: 39 },
  { name: "May", open: 18, closed: 48 },
];

const TestingComponent = () => {


  const svgRef = useRef(null);

  const downloadPDF = async () => {
    const doc = new jsPDF("landscape", "px", "a4");
    const isMobile = window.innerWidth <= 768; // Adjust this value based on your mobile breakpoint

    if (isMobile) {
      const svgElement = svgRef.current.querySelector("svg");
      const svgData = new XMLSerializer().serializeToString(svgElement);

      // Increase the canvas size to improve image clarity
      const canvas = document.createElement("canvas");
      const scale = 3; // Increase scale for better quality
      const ctx = canvas.getContext("2d");
      canvas.width = svgElement.clientWidth * scale;
      canvas.height = svgElement.clientHeight * scale;
      ctx.scale(scale, scale);

      try {
        const canvg = await Canvg.fromString(ctx, svgData);
        canvg.render();
        const imgData = canvas.toDataURL("image/png");

        doc.addImage(imgData, "PNG", 15, 40, canvas.width / 3, canvas.height / 3); // Adjust size as needed
        doc.save("chart.pdf");
      } catch (error) {
        console.error("Error rendering SVG to PNG:", error);
      }
    } else {
      // Capture the chart as an image for desktop
      const chartElement = document.getElementById("chartSection");

      const chartWidth = chartElement.clientWidth;
      const chartHeight = chartElement.clientHeight;

      const maxChartWidth = 565;
      const maxChartHeight = 400;

      const aspectRatio = chartWidth / chartHeight;
      let chartDisplayWidth = maxChartWidth;
      let chartDisplayHeight = chartDisplayWidth / aspectRatio;

      if (chartDisplayHeight > maxChartHeight) {
        chartDisplayHeight = maxChartHeight;
        chartDisplayWidth = chartDisplayHeight * aspectRatio;
      }

      const chartCanvas = await html2canvas(chartElement, { scale: 3 }); // Increase scale for better quality
      const chartImageData = chartCanvas.toDataURL("image/png");

      doc.addImage(chartImageData, "PNG", 15, 40, chartDisplayWidth, chartDisplayHeight);
      doc.save("chart.pdf");
    }
  };
  return (
    <div>
      <h2>Asset Tracking Chart</h2>

{/* This is where the chart will render */}
<div id="chartSection" ref={svgRef}>
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="open" fill="#4979d1" />
      <Bar dataKey="closed" fill="#ee7743" />
    </BarChart>
  </ResponsiveContainer>
</div>

<button onClick={downloadPDF}>Download Chart as PDF</button>
      
    </div>
  )
}

export default TestingComponent

