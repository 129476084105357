// import React from "react";
// import {
//   Box,
//   Typography,
//   IconButton,
//   TextField,
//   Stack,
//   Button,
//   Autocomplete,
//   styled,
//   Divider,
//   Modal,
// } from "@mui/material";
// import GridViewIcon from "@mui/icons-material/GridView";
// import jsQR from "jsqr";
// import { useState } from "react";
// import "jspdf-autotable";
// import { useEffect } from "react";
// import { useRef } from "react";
// import { useNavigate } from "react-router-dom";

// import { useSelector } from "react-redux";
// import CloseIcon from "@mui/icons-material/Close";

// const Label = styled(Typography)(({ theme }) => ({
//   color: "rgba(27, 37, 53, 1)",
//   fontWeight: 700,
// }));

// const MainWrapper = styled(Box)(({ theme }) => ({
//   width: "650px",
//   display: "flex",
//   borderRadius: "8px",
//   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//   gap: "10px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     flexDirection: "column",
//     width: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const ModalBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 300,
//   background: "white",
//   border: "0px solid #000",
//   boxShadow: 24,
//   p: 4,
//   height: "200px",
//   borderRadius: "6px",
// }));

// const QrCodeScanner = () => {
//   const videoRef = useRef(null);
//   const navigate = useNavigate();
//   const [scannedData, setScannedData] = useState(null);

//   useEffect(() => {
//     let stream;

//     const startScanner = async () => {
//       if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//         try {
//           stream = await navigator.mediaDevices.getUserMedia({
//             video: { facingMode: "environment" },
//           });
//           if (videoRef.current instanceof HTMLVideoElement) {
//             videoRef.current.srcObject = stream;

//             videoRef.current.onloadedmetadata = () => {
//               const canvas = document.createElement("canvas");
//               const context = canvas.getContext("2d");
//               canvas.width = videoRef.current.videoWidth;
//               canvas.height = videoRef.current.videoHeight;

//               const scanQRCode = async () => {
//                 if (videoRef.current instanceof HTMLVideoElement) {
//                   context.drawImage(
//                     videoRef.current,
//                     0,
//                     0,
//                     canvas.width,
//                     canvas.height
//                   );
//                   const imageData = context.getImageData(
//                     0,
//                     0,
//                     canvas.width,
//                     canvas.height
//                   );

//                   const code = scanQR(imageData, canvas.width, canvas.height);

//                   if (code) {
//                     setScannedData(code.data);
//                   }
//                 }

//                 requestAnimationFrame(scanQRCode);
//               };

//               scanQRCode();
//             };
//           } else {
//             console.error("videoRef.current is not a valid HTMLVideoElement.");
//           }
//         } catch (error) {
//           console.error("Error accessing camera:", error);
//         }
//       } else {
//         console.error("getUserMedia is not supported in this browser");
//       }
//     };

//     // QR code scanning function
//     const scanQR = (imageData, width, height) => {
//       const code = jsQR(imageData.data, width, height);
//       return code;
//     };

//     startScanner();

//     // Cleanup function to stop the camera stream when the component unmounts
//     return () => {
//       if (stream) {
//         stream.getTracks().forEach((track) => {
//           track.stop();
//         });
//       }
//     };
//   }, []);

//   const [openModal, setOpenModal] = React.useState(false);
//   const handleOpenModal = () => setOpenModal(true);

//   const getAssetId = useSelector((store) => store?.data?.allAssets);
//   const assetIds = getAssetId?.map((asset) => asset.assetid);
//   console.log("all data", assetIds);
//   console.log("data are", getAssetId);
//   useEffect(() => {
//     if (scannedData) {
//       navigate(`/asset-detail/${scannedData}`, {
//         state: { fromScanner: true, scannedAssetId: scannedData },
//       });
//     }
//   }, [scannedData, navigate, assetIds]);

//   const handleCloseModal = () => {
//     setOpenModal((prevOpenModal) => {
//       console.log("Previous modal state:", prevOpenModal);
//       return false;
//     });
//   };

//   return (
//     <>
//       <Stack
//         sx={{
//           justifyContent: "center",
//           alignItems: "center",
//           width: "100%",
//           // padding:'10px',
//           height: "100%",
//         }}
//       >
//         <MainWrapper
//           sx={{
//             flexDirection: "column",
//           }}
//         >
//           <Stack
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               justifyContent: "space-between",

//               bgcolor: "white",

//               padding: "20px",
//             }}
//           >
//             <Label variant="h6" color="primary">
//               Scan QR code
//             </Label>

//             <GridViewIcon size={30} style={{ color: "black" }} />
//           </Stack>

//           <Box sx={{ bgcolor: "white", padding: "20px" }}>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "10px",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <div
//                 style={{
//                   width: "250px",
//                   height: "250px",
//                   position: "relative",
//                   overflow: "hidden",
//                 }}
//               >
//                 <video
//                   ref={videoRef}
//                   autoPlay
//                   playsInline
//                   muted
//                   style={{
//                     width: "100%",
//                     height: "100%",
//                     objectFit: "cover",
//                   }}
//                 />
//                 <div
//                   style={{
//                     position: "absolute",
//                     width: "20px",
//                     height: "20px",
//                     border: "4px solid #4690FF",
//                     borderBottomWidth: "0",
//                     borderRightWidth: "0",
//                     zIndex: "1",
//                     top: "0",
//                     left: "0",
//                   }}
//                 />
//                 <div
//                   style={{
//                     position: "absolute",
//                     width: "20px",
//                     height: "20px",
//                     border: "4px solid #4690FF",
//                     borderBottomWidth: "0",
//                     borderLeftWidth: "0",
//                     zIndex: "1",
//                     top: "0",
//                     right: "0",
//                   }}
//                 />
//                 <div
//                   style={{
//                     position: "absolute",
//                     width: "20px",
//                     height: "20px",
//                     border: "4px solid #4690FF",
//                     borderTopWidth: "0",
//                     borderRightWidth: "0",
//                     zIndex: "1",
//                     bottom: "0",
//                     left: "0",
//                   }}
//                 />
//                 <div
//                   style={{
//                     position: "absolute",
//                     width: "20px",
//                     height: "20px",
//                     border: "4px solid #4690FF",
//                     borderTopWidth: "0",
//                     borderLeftWidth: "0",
//                     zIndex: "1",
//                     bottom: "0",
//                     right: "0",
//                   }}
//                 />
//               </div>

//               <Typography color="primary">Scan your QR code here.</Typography>
//               <Box sx={{ maxWidth: "85%", marginTop: "10px" }}>
//                 <Typography
//                   variant="subtitle2"
//                   sx={{ color: "rgba(181, 181, 182, 1)" }}
//                 >
//                   Please check your system settings to ensure that the camera is
//                   not locked. If the issue persists, consider adjusting your
//                   browser or device camera settings.
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </MainWrapper>
//       </Stack>

//       <Modal
//         keepMounted
//         open={openModal}
//         onClose={handleCloseModal}
//         aria-labelledby="keep-mounted-modal-title"
//         aria-describedby="keep-mounted-modal-description"
//       >
//         <ModalBox>
//           <Stack
//             sx={{
//               alignItems: "flex-end",
//               justifyContent: "end",
//               paddingRight: "10px",
//             }}
//           >
//             <IconButton onClick={handleCloseModal}>
//               <CloseIcon
//                 sx={{ fill: "#000" }}
//                 onClick={() => navigate("/home")}
//               />
//             </IconButton>
//           </Stack>
//           <Stack
//             sx={{
//               display: "flex",
//               gap: "20px",
//               flexDirection: "column",

//               textAlign: "center",
//               alignItems: "center",
//               position: "absolute",
//               top: "50px",
//               padding: "10px",
//             }}
//           >
//             <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
//               This asset is not available in your Branch.
//             </Typography>
//             <Button
//               variant="contained"
//               color="primary"
//               sx={{ width: "150px", borderRadius: "22px", fontWeight: 600 }}
//               onClick={() => navigate("/home")}
//             >
//               Okay
//             </Button>
//           </Stack>
//         </ModalBox>
//       </Modal>
//     </>
//   );
// };

// export default QrCodeScanner;






import React from "react";
import {
 Box,
 Typography,
 IconButton,
 Stack,
 Button,
 styled,
 Modal,
} from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import { useLocation, useNavigate } from 'react-router-dom';
import jsQR from "jsqr";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";


const Label = styled(Typography)(({ theme }) => ({
 color: "rgba(27, 37, 53, 1)",
 fontWeight: 700,
}));


const MainWrapper = styled(Box)(({ theme }) => ({
 width: "650px",
 display: "flex",
 borderRadius: "8px",
 boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
 gap: "10px",
 [theme.breakpoints.down("md")]: {
   flexDirection: "column",
   width: "100%",
 },
}));


const ModalBox = styled(Box)(({ theme }) => ({
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 300,
 background: "white",
 boxShadow: 24,
 p: 4,
 height: "200px",
 borderRadius: "6px",
}));


const QrCodeScanner = () => {
 const videoRef = useRef(null);
 const navigate = useNavigate();
 const [scannedData, setScannedData] = useState(null);
 const [openModal, setOpenModal] = useState(false);
 const getAssetId = useSelector((store) => store?.data?.data);
 const location = useLocation();


 useEffect(() => {
   if (location.state?.fromScan) {
     setOpenModal(true);
   }
 }, [location]);


 useEffect(() => {
   if (scannedData) {
     navigate(`/asset-detail/${scannedData}`, {
       state: { fromScanner: true, scannedAssetId: scannedData },
     });
   }
 }, [scannedData, navigate]);




 useEffect(() => {
   let stream;


   const startScanner = async () => {
     if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
       try {
         stream = await navigator.mediaDevices.getUserMedia({
           video: { facingMode: "environment" },
         });


         if (videoRef.current instanceof HTMLVideoElement) {
           videoRef.current.srcObject = stream;


           videoRef.current.onloadedmetadata = () => {
             const canvas = document.createElement("canvas");
             const context = canvas.getContext("2d");
             canvas.width = videoRef.current.videoWidth;
             canvas.height = videoRef.current.videoHeight;


             const scanQRCode = async () => {
               // Return early if the modal is open
               if (openModal) {
                 requestAnimationFrame(scanQRCode);
                 return;
               }


               if (videoRef.current instanceof HTMLVideoElement) {
                 context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                 const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                 const code = scanQR(imageData, canvas.width, canvas.height);


                 if (code) {
                   setScannedData(code.data);
                 }
               }


               requestAnimationFrame(scanQRCode);
             };


             scanQRCode();
           };
         }
       } catch (error) {
         console.error("Error accessing camera:", error);
       }
     }
   };


   const scanQR = (imageData, width, height) => {
     const code = jsQR(imageData.data, width, height);
     return code;
   };


   startScanner();


   return () => {
     if (stream) {
       stream.getTracks().forEach((track) => {
         track.stop();
       });
     }
   };
 }, [openModal]); // Include openModal in the dependency array


 const handleCloseModal = () => {
   setOpenModal(false);
 };




 return (
   <>
     <Stack sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
       <MainWrapper sx={{ flexDirection: "column" }}>
         <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", bgcolor: "white", padding: "20px" }}>
           <Label variant="h6" color="primary">Scan QR code</Label>
           <GridViewIcon size={30} style={{ color: "black" }} />
         </Stack>
         <Box sx={{ bgcolor: "white", padding: "20px" }}>
           <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center" }}>
             <div style={{ width: "250px", height: "250px", position: "relative", overflow: "hidden" }}>
               <video ref={videoRef} autoPlay playsInline muted style={{ width: "100%", height: "100%", objectFit: "cover" }} />
               {/* QR code scan area corners */}
               <div style={{ position: "absolute", width: "20px", height: "20px", border: "4px solid #4690FF", borderBottomWidth: "0", borderRightWidth: "0", zIndex: "1", top: "0", left: "0" }} />
               <div style={{ position: "absolute", width: "20px", height: "20px", border: "4px solid #4690FF", borderBottomWidth: "0", borderLeftWidth: "0", zIndex: "1", top: "0", right: "0" }} />
               <div style={{ position: "absolute", width: "20px", height: "20px", border: "4px solid #4690FF", borderTopWidth: "0", borderRightWidth: "0", zIndex: "1", bottom: "0", left: "0" }} />
               <div style={{ position: "absolute", width: "20px", height: "20px", border: "4px solid #4690FF", borderTopWidth: "0", borderLeftWidth: "0", zIndex: "1", bottom: "0", right: "0" }} />
             </div>
             <Typography color="primary">Scan your QR code here.</Typography>
             <Box sx={{ maxWidth: "85%", marginTop: "10px" }}>
               <Typography variant="subtitle2" sx={{ color: "rgba(181, 181, 182, 1)" }}>
                 Please check your system settings to ensure that the camera is
                 not locked. If the issue persists, consider adjusting your
                 browser or device camera settings.                </Typography>
             </Box>
           </Box>
         </Box>
       </MainWrapper>
     </Stack>


     <Modal
       keepMounted
       open={openModal}
       onClose={handleCloseModal}
       aria-labelledby="keep-mounted-modal-title"
       aria-describedby="keep-mounted-modal-description"
     >
       <ModalBox>
         <Stack sx={{ alignItems: "flex-end", justifyContent: "end", paddingRight: "10px" }}>
           <IconButton onClick={handleCloseModal}>
             <CloseIcon sx={{ fill: "#000" }} />
           </IconButton>
         </Stack>
         <Stack sx={{ display: "flex", gap: "20px", flexDirection: "column", textAlign: "center", alignItems: "center", position: "absolute", top: "50px", padding: "10px" }}>
           <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>This asset is not available in your Branch.</Typography>
           <Button
             variant="contained"
             color="primary"
             sx={{ width: "150px", borderRadius: "22px", fontWeight: 600 }}
             onClick={() => navigate("/home")}
           >
             Okay
           </Button>
         </Stack>
       </ModalBox>
     </Modal>
   </>
 );
};


export default QrCodeScanner;
