import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Badge,
  Button,
  Grid,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import { useEffect } from "react";
import { getIncidentDetail, profile } from "../redux/data/action";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import pdfImage from "../../src/images/pdfImage.png";

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",
  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerSet = styled(Stack)(({ theme }) => ({
  width: "750px",

  borderRadius: "8px",

  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",

  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomSet = styled(Stack)(({ theme }) => ({
  width: "750px",
  height: "70px",
  padding: "20px",
  flexDirection: "column",
  alignItems: "flex-end",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "0px",
    paddingTop: "20px",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextSet = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: "white",

  padding: "10px 20px 10px 20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "100%",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerComponent = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftComponent = styled(Box)(({ theme }) => ({
  width: "60%",
  flexDirection: "row",
  gap: "20px",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightComponent = styled(Box)(({ theme }) => ({
  width: "40%",
  flexDirection: "row",
  gap: "20px",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentDetailPage = () => {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let incidentData = useSelector((store) => store.data.incidentDetails);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: params.id,
      branchid: userBranch.id,
    };
    dispatch(getIncidentDetail(data, token));
  }, [dispatch]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.download = "downloaded_image.jpg";
    link.click();
  };

  const handlePDFDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.click();
  };

  const navigate = useNavigate();

  const [fileType, setFileType] = useState(null);
  // const fileLink = incidentData.receipt_id[0];

  const fileLink =
    Array.isArray(incidentData?.receipt_id) &&
    incidentData.receipt_id.length > 0
      ? incidentData.receipt_id[0]
      : null;

  useEffect(() => {
    if (fileLink?.endsWith(".pdf") || fileLink?.includes(".pdf")) {
      setFileType("pdf");
    } else if (
      fileLink?.endsWith(".jpg") ||
      fileLink?.endsWith(".jpeg") ||
      fileLink?.endsWith(".png") ||
      fileLink?.endsWith(".gif") ||
      fileLink?.includes("image/") ||
      fileLink?.includes(".image")
    ) {
      setFileType("image");
    } else {
      setFileType("unknown");
    }
  }, [fileLink]);

  // useEffect(() => {
  //   const determineFileType = () => {
  //     if (fileLink?.endsWith(".pdf") || fileLink?.includes('pdf')) {
  //       setFileType("pdf");
  //     } else if (fileLink?.endsWith(".jpg") || fileLink?.endsWith(".jpeg") || fileLink?.endsWith(".png") || fileLink?.endsWith(".gif")) {
  //       setFileType("image");
  //     } else {
  //       setFileType("unknown");
  //     }
  //   };

  //   determineFileType();
  // }, [fileLink]);

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
    };

    dispatch(profile(data));
  }, [dispatch]);

  const HospitalLeftData = {
    "Asset Name": incidentData.assetname,
    "Asset Code": incidentData.assetcode,
    Department: incidentData.deptname,
  };
  const HospitalLeftDataOpen = {
    "Asset Name": incidentData.assetname,
    "Asset Code": incidentData.assetcode,
    Department: incidentData.deptname,
    "Serial No.": incidentData.serialno,
  };
  const HospitalRightData = {
    "Issue Date": incidentData.start_date,
    "Clear Date": incidentData.clear_date,
    "Total Downtime": incidentData.tot_downtime,
  };

  const HospitalRightDataOpen = {
    "Issue Date": incidentData.start_date,
    "Issue Time": incidentData.start_time,
  };
  const UserLeftData = {
    Name: incidentData.person_name,
    "Contact No.": incidentData.person_phone,
    Email: incidentData.person_email,
  };

  const ExpenditureData = {
    Reason: incidentData.expenditure,
    Cost: incidentData.amt_spent,
  };

  const IssueData = {
    "Problem Identified ": incidentData?.incdt_issue,
  };
  const IssueData1 = {
    "Action Taken": incidentData?.actiontaken,
  };
  const IssueData2 = {
    ...(incidentData.feedback !== "NA" && {
      Remark: incidentData.feedback,
    }),
  };

  const isPDF = (url) => {
    const lowerCaseUrl = url.toLowerCase();
    console.log("Checking URL:", lowerCaseUrl); 
    const urlWithoutParams = lowerCaseUrl.split("?")[0];
    return urlWithoutParams.endsWith(".pdf");
  };


  return (
    <Box sx={{ padding: "10px" }}>
      {incidentData?.incid_status === "Cleared" ? (
        <Wrapper>
          <InnerSet>
            <TextSet>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Cleared Incident
              </Label>

              <Label>{incidentData?.clear_date}</Label>
            </TextSet>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <InnerComponent>
                <LeftComponent>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LeftComponent>
                <RightComponent>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </RightComponent>
              </InnerComponent>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Contact Person</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack sx={{ width: { xs: "100%", md: "60%" } }}>
                  <TableContainer
                    style={{ overflowX: "auto", width: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(UserLeftData).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells sx={{}}>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Issue Details</Label>
                <Divider />
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  {Object.entries(IssueData).map(([key, value]) => (
                    <Stack
                      sx={{
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        sx={{
                          width: { xs: "100%", sm: "100%", md: "60%" },

                          textAlign: "start",
                          minHeight: "130px",
                          background: "rgba(244, 246, 246, 1)",
                          padding: "6px",
                        }}
                      >
                        <Label sx={{ margin: "10px" }}>{key}</Label>

                        <Typography sx={{ marginLeft: "10px",wordWrap:'break-word' }}>
                          {value}
                        </Typography>
                        <Stack
                          sx={{
                            width: "20%",
                            gap: "10px",
                            display: { xs: "flex", sm: "none" },
                            flexDirection: "row",
                          }}
                        >
                          <>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row" }}
                            >
                              {incidentData?.incdt_imgs !== "NA" &&
                                incidentData?.incdt_imgs !== "" && (
                                  <>
                                    <>
                                      {incidentData?.incdt_imgs?.map(
                                        (images) => (
                                          <Badge
                                            sx={{
                                              height: "85%",
                                              width: "30%",
                                            }}
                                            badgeContent={
                                              <DownloadIcon
                                                sx={{
                                                  color: "#757575",
                                                }}
                                                size={15}
                                                style={{}}
                                                onClick={() =>
                                                  handleDownload(images)
                                                }
                                              />
                                            }
                                            color="secondary"
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "right",
                                            }}
                                          >
                                            <Stack
                                              sx={{
                                                width: "65px",

                                                height: "80px",
                                                margin: "10px",
                                              }}
                                            >
                                              <Box
                                                as="img"
                                                src={images}
                                                alt="incImage"
                                                style={{ height: "100%" }}
                                              />
                                            </Stack>
                                          </Badge>
                                        )
                                      )}
                                    </>
                                  </>
                                )}
                            </Stack>
                          </>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          width: { md: "40%" },
                          gap: "10px",
                          display: { xs: "none", sm: "flex" },
                          flexDirection: "row",
                        }}
                      >
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                          {incidentData?.incdt_imgs !== "NA" &&
                            incidentData?.incdt_imgs !== "" && (
                              <>
                                <>
                                  {incidentData?.incdt_imgs?.map((images) => (
                                    <Badge
                                      sx={{
                                        height: "70%",
                                        width: "30%",
                                      }}
                                      badgeContent={
                                        <DownloadIcon
                                          sx={{
                                            color: "#757575",
                                          }}
                                          size={15}
                                          style={{}}
                                          onClick={() => handleDownload(images)}
                                        />
                                      }
                                      color="secondary"
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                    >
                                      <Stack
                                        sx={{
                                          width: "80px",

                                          height: "80px",
                                          margin: "10px",
                                        }}
                                      >
                                        <Box
                                          as="img"
                                          src={images}
                                          alt="incImage"
                                          style={{ height: "100%" }}
                                        />
                                      </Stack>
                                    </Badge>
                                  ))}
                                </>
                              </>
                            )}
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                  {Object.entries(IssueData1).map(([key, value]) => (
                    <Stack
                      sx={{
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        sx={{
                          width: { xs: "100%", sm: "100%", md: "60%" },

                          textAlign: "start",
                          minHeight: "130px",
                          background: "rgba(244, 246, 246, 1)",
                          padding: "6px",
                        }}
                      >
                        <Label sx={{ margin: "10px" }}>{key}</Label>

                        <Typography sx={{ marginLeft: "10px",wordWrap:'break-word' }}>
                          {value}
                        </Typography>
                        <Stack
                          sx={{
                            width: "20%",
                            gap: "10px",
                            display: { xs: "flex", sm: "none" },
                            flexDirection: "row",
                          }}
                        >
                          <>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row" }}
                            >
                              {incidentData?.clear_imgs !== "NA" &&
                                incidentData?.clear_imgs !== "" && (
                                  <>
                                    {incidentData?.clear_imgs?.map((images) => (
                                      <Badge
                                        sx={{
                                          height: "85%",
                                          width: "30%",
                                        }}
                                        badgeContent={
                                          <DownloadIcon
                                            sx={{
                                              color: "#757575",
                                            }}
                                            size={15}
                                            style={{}}
                                            onClick={() =>
                                              handleDownload(images)
                                            }
                                          />
                                        }
                                        color="secondary"
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                      >
                                        <Stack
                                          sx={{
                                            width: "65px",

                                            height: "80px",
                                            margin: "10px",
                                          }}
                                        >
                                          <Box
                                            as="img"
                                            src={images}
                                            alt="incImage"
                                            style={{ height: "100%" }}
                                          />
                                        </Stack>
                                      </Badge>
                                    ))}
                                  </>
                                )}
                            </Stack>
                          </>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          width: { md: "40%" },
                          gap: "10px",
                          display: { xs: "none", sm: "flex" },
                          flexDirection: "row",
                        }}
                      >
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                          {incidentData?.clear_imgs !== "NA" &&
                            incidentData?.clear_imgs !== "" && (
                              <>
                                {incidentData?.clear_imgs?.map((images) => (
                                  <Badge
                                    sx={{
                                      height: "63%",
                                      width: "30%",
                                    }}
                                    badgeContent={
                                      <DownloadIcon
                                        sx={{
                                          color: "#757575",
                                        }}
                                        size={15}
                                        style={{}}
                                        onClick={() => handleDownload(images)}
                                      />
                                    }
                                    color="secondary"
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        width: "80px",

                                        height: "80px",
                                        margin: "10px",
                                      }}
                                    >
                                      <Box
                                        as="img"
                                        src={images}
                                        alt="incImage"
                                        style={{ height: "100%" }}
                                      />
                                    </Stack>
                                  </Badge>
                                ))}
                              </>
                            )}
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                  {Object.entries(IssueData2).map(([key, value]) => (
                    <Stack
                      sx={{
                        width: { sm: "100%", md: "60%" },

                        textAlign: "start",
                        minHeight: "130px",
                        background: "rgba(244, 246, 246, 1)",
                        padding: "20px",
                      }}
                    >
                      <Label>{key}</Label>

                      <Typography sx={{ marginTop: "10px",wordWrap:'break-word' }}>
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              {ExpenditureData?.Cost.length > 0 && (
                <Box>
                  <Stack
                    sx={{
                      textAlign: "start",

                      marginTop: "25px",
                      paddingLeft: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <Label variant="subtitle1">Expenditure Details</Label>
                    <Divider />
                  </Stack>

                  <Stack
                    flexDirection={"row"}
                    sx={{
                      gap: "20px",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack sx={{ width: "100%" }}>
                      {incidentData.expenditure.length > 0 ? (
                        incidentData.expenditure.map((expenditure, index) => (
                          <Box
                            key={index}
                            elevation={2}
                            sx={{
                              display: { sm: "grid", md: "flex,", lg: "flex" },
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Grid
                                container
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-around",
                                }}
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                    variant="body1"
                                  >
                                    Reason
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Cost
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{
                                      margin: "5px",
                                      width: "100%",
                                      fontSize: "0.75rem",
                                      wordWrap:'break-word'
                                    }}
                                    variant="body1"
                                  >
                                    {expenditure}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ width: "100%", fontSize: "0.75rem" }}
                                  >
                                    ₹ {incidentData.amt_spent[index]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>

                            {/* <Box sx={{ width: "100%" }}>
                              {incidentData.receipt_id[index] ? (
                                <>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                    variant="body1"
                                  >
                                    Receipt
                                  </Typography>
                                  <Stack
                                    sx={{
                                      width: "100%",

                                      textAlign: "start",
                                      minHeight: "130px",
                                      background: "rgba(244, 246, 246, 1)",
                                      margin: "10px",
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        position: "relative",
                                        width: "fit-content",
                                        margin: "10px",
                                      }}
                                    >
                                      {fileType === "pdf" ? (
                                        <Box
                                          component="img"
                                          src={pdfImage} // Thumbnail or placeholder for PDFs
                                          alt="PDF Thumbnail"
                                          sx={{
                                            height: "100px", // Ensure proper height
                                            width: "auto",
                                            objectFit: "cover",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={
                                            () =>
                                              handlePDFDownload(
                                                incidentData.receipt_id[index]
                                              ) // Download logic
                                          }
                                        />
                                      ) : (
                                        <Box
                                          component="img"
                                          src={incidentData.receipt_id[index]} // Correct array indexing
                                          alt="Receipt Image"
                                          sx={{
                                            height: "100px", // Ensure proper height
                                            width: "auto",
                                            objectFit: "cover",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={
                                            () =>
                                              handlePDFDownload(
                                                incidentData.receipt_id[index]
                                              ) // Download logic for images
                                          }
                                        />
                                      )}
                                    </Stack>
                                  </Stack>
                                </>
                              ) : (
                                <Typography variant="body1">
                                  No image available
                                </Typography>
                              )}
                            </Box> */}
                            <Box sx={{ width: "100%" }}>
                              {incidentData.receipt_id[index] ? (
                                <>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                    variant="body1"
                                  >
                                    Receipt
                                  </Typography>
                                  <Stack
                                    sx={{
                                      width: "100%",
                                      textAlign: "start",
                                      minHeight: "130px",
                                      background: "rgba(244, 246, 246, 1)",
                                      margin: "10px",
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        position: "relative",
                                        width: "fit-content",
                                        margin: "10px",
                                      }}
                                    >
                                      {(() => {
                                        const receiptUrl =
                                          incidentData.receipt_id[index];
                                        console.log("Receipt URL:", receiptUrl); 
                                        if (isPDF(receiptUrl)) {
                                          console.log("This is a PDF file.");
                                          return (
                                              <IconButton>
                                                {/* <PictureAsPdfIcon />{" "} */}
                                                <img src={pdfImage} style={{
                                                width: "100px",
                                                height: "100px",
                                              }} ></img>
                                              </IconButton>
                                          );
                                        } else {
                                          console.log("This is an image file.");
                                          return (
                                            <img
                                              src={receiptUrl} 
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }} 
                                              onClick={() => {
                                                const link =
                                                  document.createElement("a");
                                                link.href = receiptUrl; 
                                                link.download = `receipt_${index}.jpg`;
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                              }}
                                            />
                                          );
                                        }
                                      })()}
                                      <IconButton
                                        onClick={() => {
                                          const link =
                                            document.createElement("a");
                                          link.href =
                                            incidentData.receipt_id[index];
                                          link.download = `receipt_${index}.jpg`;
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        }}
                                        aria-label="download"
                                        sx={{
                                          position: "absolute",
                                          bottom: -6,
                                          right: -3,
                                          zIndex: 1,
                                          color: "black",
                                          padding: "0px",
                                          backgroundColor: "white",
                                          color: "#757575",
                                          padding: "5px",
                                        }}
                                      >
                                        <DownloadIcon />
                                      </IconButton>
                                    </Stack>
                                  </Stack>
                                </>
                              ) : (
                                <Typography variant="body1">
                                  No image available
                                </Typography>
                              )}
                            </Box>

                          </Box>
                        ))
                      ) : (
                        <div>No expenditures found.</div>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              )}
            </Stack>
          </InnerSet>
        </Wrapper>
      ) : (
        <Wrapper>
          <InnerSet>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "white",
                padding: "10px 20px 10px 20px",
              }}
            >
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Incident Summary
              </Label>

              <Label sx={{ color: "#FF6B00" }}>
                {incidentData.incid_status}
              </Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>

              <InnerComponent>
                <LeftComponent sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LeftComponent>
                <RightComponent sx={{ width: "40%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </RightComponent>
              </InnerComponent>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Issue
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Stack
                    sx={{
                      width: "auto",

                      textAlign: "start",
                      minHeight: "170px",
                      background: "rgba(244, 246, 246, 1)",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <Typography
                      sx={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      {incidentData?.incdt_issue}
                    </Typography>
                    {incidentData?.incdt_imgs !== "NA" &&
                      incidentData?.incdt_imgs !== "" && (
                        <Stack
                          flexDirection={"row"}
                          sx={{
                            width: "100%",
                            height: "100px",
                            // overflowY: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                            // overflowX: "auto",
                            display: { xs: "flex", sm: "none" },
                          }}
                        >
                          {incidentData?.incdt_imgs?.map((images, index) => (
                            <Box
                              sx={{ height: "80%" }}
                              key={index}
                              position="relative"
                              display="inline-block"
                              mx={1}
                            >
                              <Box
                                as="img"
                                src={images}
                                alt="incImage"
                                sx={{ width: "65px", height: "80px" }}
                              />
                              <Box
                                position="absolute"
                                bottom={-5}
                                right={0}
                                borderRadius={0}
                                sx={{ backgroundColor: "white" }}
                              >
                                <IconButton sx={{ padding: "0px" }}>
                                  <DownloadIcon
                                    onClick={() => handleDownload(images)}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          ))}
                        </Stack>
                      )}
                  </Stack>
                </Stack>
                {incidentData?.incdt_imgs !== "NA" &&
                  incidentData?.incdt_imgs !== "" && (
                    <Stack
                      flexDirection={"row"}
                      sx={{
                        width: "50%",
                        height: "100px",
                        // overflowY: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                        // overflowX: "auto",
                        display: { xs: "none", sm: "flex" },
                      }}
                    >
                      {incidentData?.incdt_imgs?.map((images, index) => (
                        <Box
                          sx={{ height: "65%" }}
                          key={index}
                          position="relative"
                          display="inline-block"
                          mx={1} // Add some margin to separate the images
                        >
                          <Box
                            as="img"
                            src={images}
                            alt="incImage"
                            sx={{ width: "80px", height: "80px" }} // Adjusted width and height
                          />
                          <Box
                            position="absolute"
                            bottom={-18}
                            right={-5}
                            borderRadius={25}
                            sx={{ backgroundColor: "white" }}
                          >
                            <IconButton
                              sx={{ padding: "0px", cursor: "pointer" }}
                            >
                              <DownloadIcon
                                onClick={() => handleDownload(images)}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                      {/* {fileUrl?.map((item, index) => (
                  <Badge
                    key={index}
                    badgeContent={
                      <CloseIcon
                        size={15}
                        style={{
                          color: " rgba(255, 58, 68, 1)",
                        }}
                        onClick={() => removePhoto(item?.imageid)}
                      />
                    }
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      as="img"
                      src={item?.image}
                      alt=""
                      sx={{ width: "110px", height: "120px" }}
                    />
                  </Badge>
                ))} */}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          </InnerSet>

          <BottomSet sx={{}}>
            <Button
              onClick={() => navigate(`/clear-incident/${params.id}`)}
              variant="contained"
              sx={{ width: "150px", borderRadius: "22px" }}
            >
              Clear Incident
            </Button>
          </BottomSet>
        </Wrapper>
      )}
    </Box>
  );
};

export default IncidentDetailPage;
