import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  MenuItem,
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Select,
  Divider,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState } from "react";
import {
  AmcCmc,
  getCalibrated,
  getDashboardRequestData,
  getDashboardRequestDataNBM,
  warrantyStatus,
} from "../redux/nbmData/action";
import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import WarrantyRequestModal from "../nbmComponents/WarrantyRequestModal";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));

const TopSet = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "stretch",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const RequestPage = ({ handleDataChange }) => {
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState("calibration");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [isCalibrationTab,setIsCalibrationTab] = useState(false)

  const calibrationList = useSelector((store) => store.nbmData.calibratedList);
  const warrantyStatusData = useSelector((store) => store.nbmData?.warrantyStatus);

  const requestDashboardData = useSelector(
    (store) => store?.nbmData?.requestDashboardDataNBM
  );

  

  const amcCmcData = useSelector((store) => store.data.AmcCmcData);
  const [getId, setGetId] = useState({});
  const [open, setopen] = useState(false);
  // const handleChange = (event) => {
  //   setSelectedOption(event.target.value);
  //   if(selectedOption === 'Calibration'){
  //     setIsCalibrationTab(true)
  //   }else{
  //     setIsCalibrationTab(false)
  //   }
  // };
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "calibration") {
      setIsCalibrationTab(true);
    } else {
      setIsCalibrationTab(false);
    }
  };

  useEffect(() => {
    setIsCalibrationTab(selectedOption === "calibration");
  }, [selectedOption]);

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    let selectedData = [];
    switch (selectedOption) {
      case "calibration":
        selectedData = calibrationList;
        break;
      case "warranty":
        selectedData = warrantyStatusData;
        break;
      case "amc_cmc":
        selectedData = amcCmcData;
        break;
      default:
        break;
    }
    setData(selectedData);
  }, [selectedOption, calibrationList, warrantyStatusData, amcCmcData]);

  useEffect(() => {
    let datas = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };

    if (selectedOption === "calibration") {
      dispatch(getCalibrated(datas));
    } else if (selectedOption === "warranty") {
      dispatch(warrantyStatus(datas));
    } else if (selectedOption === "amc_cmc") {
      dispatch(AmcCmc(datas));
    }
  }, [dispatch, selectedOption]);

  const handleClickPopper = (event, data) => {
    setGetId(data);
    setopen(true);
  };

  useEffect(() => {
    if (handleDataChange !== undefined) {
      let data2 = {
        request: filteredData?.length,
        warranty: 14,
        task: 20,
        calibration: 30,
      };

      handleDataChange(data2);
    }
  }, [filteredData]);

  useEffect(() => {
    let requestData = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: "all",
    };
  
    console.log('Request Data:', requestData); // Check the request payload
  
    dispatch(getDashboardRequestDataNBM(requestData))
      .then((response) => {
        console.log('API Response:', response); // Log the API response
      })
      .catch((error) => {
        console.error('API Error:', error); // Log any errors
      });
  }, []);
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      gap="10px"
      height="85vh"
      padding="16px"
    >
      <Stack
        justifyContent="space-between"
        flexDirection={"row"}
        alignItems={"center"}
        sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
      >
        <Label variant="h6">
          Request.
          <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
        </Label>
        {/* <IconButton>
          <AddCircleIcon
            style={{ color: "#4690FF" }}
            onClick={() => navigate("/add-asset")}
          />
        </IconButton> */}
      </Stack>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{ display: { xs: "none", sm: "none", lg: "flex" } }}
        gap="10px"
      >
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {requestDashboardData?.completed_count} /{" "}
              {requestDashboardData?.request_count}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Completed Requests</Label>
              <IconButton onClick={() => navigate("/calendar")}>
                <ArrowRightAltIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#FF6B00" }}>
              {requestDashboardData?.expiry_count}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Expiring Soon</Label>
              <IconButton onClick={() => navigate("/calendar")}>
                <ArrowRightAltIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {requestDashboardData?.task_count}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Tasks</Label>
              <IconButton onClick={() => navigate("/task")}>
                <ArrowRightAltIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap="10px">
        <TopSet>
          <Box flex="1">
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-around"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <Select
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    textAlign: "left",
                    color: "rgba(70, 144, 255, 1)",
                  }}
                  value={selectedOption}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="calibration">Calibration</MenuItem>
                  <MenuItem value="warranty">Warranty</MenuItem>
                  <MenuItem value="amc_cmc">AMC/CMC</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Box>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              Filter1="department"
              Filter2=""
              FilterName1="Department"
              FilterName2=""
               sortPrice=""
              sortDate={
                selectedOption === "calibration"
                  ? "dop"
                  : selectedOption === "warranty"
                  ? "dop"
                  : "startdate"
              }
              dueDate={
                selectedOption === "calibration"
                  ? "calibduedate"
                  : selectedOption === "warranty"
                  ? "exp_date"
                  : "duedate"
              }
              name="name"
              csvName="All Assets"
            />
          </Box>
        </TopSet>

        <Box flex="1" >
          {/* Table */}
          <TableContainer
            component={Paper}
            style={{
              height: "calc(67vh - 120px)",
              maxHeight: "calc(80vh - 120px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {filteredData.length > 0 ?  <Table stickyHeader>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableHeading>Asset Name</TableHeading>
                  <TableHeading>Asset Code</TableHeading>
                  <TableHeading>Brand</TableHeading>
                  <TableHeading>Department</TableHeading>

                  <TableHeading>Date</TableHeading>
                  <TableHeading>Due Date</TableHeading>

                  <TableHeading sx={{ textAlign: "center" }}>
                    Status
                  </TableHeading>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: "scroll" }}>
                {filteredData?.map((item, index) => (
                  <TableRow >
                    <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>
                      <Tooltip title={item.name}>{item.name}</Tooltip>
                    </TableCells>

                    <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>
                      <Tooltip title={item.assetcode}>{item.assetcode}</Tooltip>
                    </TableCells>
                    <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>
                      <Tooltip title={item.brand}>{item.brand}</Tooltip>
                    </TableCells>
                    <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>
                      <Tooltip title={item.department}>
                        {item.department}
                      </Tooltip>
                    </TableCells>

                    {selectedOption === "calibration" && (
                      <>
                        <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>{item.dop}</TableCells>

                        <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>{item.calibduedate}</TableCells>
                      </>
                    )}
                    {selectedOption === "warranty" && (
                      <>
                        <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>{item.dop}</TableCells>

                        <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>{item.exp_date}</TableCells>
                      </>
                    )}
                    {selectedOption === "amc_cmc" && (
                      <>
                        <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>{item.startdate}</TableCells>

                        <TableCells onClick={()=>navigate(`/asset-detail/${item.assetid}`)}>{item.duedate}</TableCells>
                      </>
                    )}

                    <TableCells
                      sx={{
                        textAlign: "center",
                        color: "rgba(70, 144, 255, 1)",
                      }}
                    >
                      {item?.req_status === "NA" ||
                      item?.warrantstatus === "NA" ? (
                        <Button
                          onClick={(e) => handleClickPopper(e, item)}
                          disabled={userAccess?.role === "Guest"}
                          size="small"
                          sx={{
                            borderRadius: "35px",
                            textTransform: "none",
                            width: { xs: "85px", sm: "75px", md:"75px",lg: "100px" },
                            
                            bgcolor: "rgba(255, 107, 0, 1)",
                            "&:hover": {
                              bgcolor: "rgba(255, 107, 0, 1)",
                            },
                          }}
                          variant="contained"
                        >
                          Request
                        </Button>
                      ) : (
                        <>Requested</>
                      )}
                    </TableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>:<Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70vh",
                }}
              >
                No request data found.
              </Typography>}
           
            
          </TableContainer>
          <Stack
              sx={{
                display: {
                  xs: 'none', 
                  sm: 'none',
                  md: 'flex',
                },
                flexDirection: "row",
                justifyContent: isCalibrationTab ? 'space-between':'flex-end',
                padding: "10px",
                borderTop: "1px solid #0000001c",
                background:'#fff'
                
                
              }}
            >
             {isCalibrationTab && <Box
                sx={{  fontSize: {
                  xs: "16px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                }, fontWeight: 700, cursor: "pointer" }}
                onClick={() => navigate('/calibration-not-required')}

              >
                Calibrations not required:  {requestDashboardData?.calib_not_req_count}{" "}
                <ArrowForwardIcon fontSize="30px" sx={{ margin: "-3px 0" }} />
              </Box>}
              <Box>
                <Button
                 sx={{
                  background: "rgba(70, 144, 255, 1)",
                  height: "44px",
                  color: "#fff",
                  width: "max-content",
                  borderRadius: "22px",
                  fontSize: {
                    xs: "16px",
                    sm: "20px",
                    md: "15px",
                    lg: "15px",
                  },
                  fontWeight: 700,
                  "&:hover": {
                    background: "rgba(70, 144, 255, 1)",
                    height: "44px",
                    color: "#fff",
                  }
                }}
                onClick={()=>navigate('/calendar')}>
                  See Assigned <ArrowForwardIcon sx={{ fontSize: "26px" }} />
                </Button>
              </Box>
            </Stack>
        </Box>
      </Box>
      <WarrantyRequestModal
        text={
          selectedOption === "calibration"
            ? "Calibration Request"
            : selectedOption === "warranty"
            ? "Warranty Request"
            : "AMC-CMC Request"
        }
        open={open}
        setopen={setopen}
        data={getId}
        selectedOption={selectedOption}
      />
    </Box>
  );
};

export default RequestPage;
