import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EastIcon from "@mui/icons-material/East";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  IconButton,
  Stack,
  Typography,
  styled,
  Tooltip,
  Checkbox,
  Button,
  Divider
} from "@mui/material";
import { useState } from "react";
import {
  bulkAssetDelete,
  deleteAsset,
  retrieveAllAssets,
  getNewAssets,
} from "../redux/nbmData/action";
import { useTheme } from "@emotion/react";

import { useEffect } from "react";
import TableSort from "../components/TableSort";
import { Autocomplete, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import BulkDeleteDeleteModal from "../components/BulkDeleteModal";
import BulkUploadAssetPopUp from "./BulkUploadAssetPopUp";
import SortSearchFilter from "../components/SortSearchFilter";

import dummyImage from "../images/download.png";
import vajraImage from "../images/vajraNewLogo.jpg";
import { jsPDF } from "jspdf";
import { setNavigationSource } from "../redux/data/action";
import Calculate from "@mui/icons-material/Calculate";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));

const AllAsset = () => {
  const [allCounts, setAllCounts] = useState({});
  const dataCount = useSelector((store) => store.data.allAssetsNew);
  const allAssets = useSelector((store) => store.nbmData.allAssets);
  const deletedStatus = useSelector((store) => store.data.bulkAssetDelete);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const deleted = useSelector((store) => store.data.deleteAsset);

  const [filteredData, setFilteredData] = useState([]);

  const [toggle, setToggle] = useState(false);

  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);

  const [filterValue, setFilterValue] = useState(null);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const assetLogo = useSelector((store) => store?.nbmData?.logoUrl?.logo_url);

  const handleSelectAllClick = () => {
    const allAssetIds = data?.map((item) => item.assetid);

    if (selectAll) {
      // Deselect all
      setSelectedAssetIds([]);
    } else {
      // Select all
      setSelectedAssetIds(allAssetIds);
    }

    setSelectAll(!selectAll);
  };

  const handleSelectRowClick = (assetId) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    let newSelected = [];

    if (selectedIndex === -1) {
      // Asset is not selected, add it to the array
      newSelected = [...selectedAssetIds, assetId];
    } else {
      // Asset is selected, remove it from the array
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1),
      ];
    }

    setSelectedAssetIds(newSelected);
  };
  const handleButtonClick = () => {
    navigate("/depreciation");
  };

  useEffect(() => {
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
  }, [filterValue]);

  const text = "asset";

  const handleOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };

  const handleCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(retrieveAllAssets(data, token));
    setToggle(false);
  }, [dispatch, deleted, toggle, deletedStatus]);

  // useEffect(() => {
  //   if (allAssets && allAssets?.length > 0) {
  //     setData(allAssets);
  //   }
  // }, [allAssets, deleted, deletedStatus]);

  const handleBulkDelete = () => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetids: selectedAssetIds,
      branchid: userBranch.id,
    };
    dispatch(bulkAssetDelete(data));
    setToggle(true);
    setOpenBulkDeleteModal(false);
  };

  const handleEdit = (id) => {
    navigate(`/edit-asset/${id}`);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(getNewAssets(data));
  }, []);

  useEffect(() => {
    if (Object.keys(dataCount)?.length > 0) {
      setData(dataCount?.assets);
      let data = {
        department: dataCount?.department_count || 0,
        checkout: dataCount?.checkout_count || 0,
        movements: dataCount?.movement_count || 0,
      };
      setAllCounts(data);
    }
  }, [dataCount]);

  const email = "www.vajra.software";
  const contact = "contact@vajra.software";

  const MiniQRset = styled(Box)(({ theme }) => ({
    width: "60px",
    height: "60px",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  // const handleDownloadQrCode = (item) => {
  //   const pdf = new jsPDF("p", "mm", [130, 130]);
  //   if (!assetLogo || assetLogo === "NA") {
  //     pdf.text("NA", 85, 40);
  //   } else {
  //     pdf.addImage(assetLogo, "PNG", 70, 1, 50, 25);
  //   }
  //   pdf.addImage(item?.qr_img, "JPEG", 2, 20, 60, 70);

  //   const centerX = pdf.internal.pageSize.width / 2;

  //   pdf.setFontSize(14);
  //   pdf.setFont("helvetica", "bold");

  //   const assetIdLabel = "Asset ID";
  //   const assetIdLabelWidth = pdf.getTextWidth(assetIdLabel);
  //   pdf.text(assetIdLabel, centerX - assetIdLabelWidth / 2 + 25, 55);

  //   const assetCode = item?.assetcode || "N/A";
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(assetCode, centerX - pdf.getTextWidth(assetCode) / 2 + 25, 62);

  //   pdf.setLineWidth(0.85);
  //   pdf.setDrawColor(0, 0, 0);
  //   pdf.line(65, 68, pdf.internal.pageSize.width - 5, 68);

  //   pdf.setFont("helvetica", "bold");
  //   const departmentLabel = "Department";
  //   const departmentLabelWidth = pdf.getTextWidth(departmentLabel);
  //   pdf.text(departmentLabel, centerX - departmentLabelWidth / 2 + 25, 75);

  //   const department = item?.department || "N/A";
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(department, centerX - pdf.getTextWidth(department) / 2 + 25, 82);

  //   pdf.addImage(vajraImage, "PNG", 80, 100, 50, 30);

  //   pdf.setFontSize(12);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text(email || "N/A", 5, 110);
  //   pdf.text(contact || "N/A", 5, 115);

  //   pdf.setLineWidth(0.85);
  //   pdf.setDrawColor(0, 0, 0);
  //   pdf.line(1, 100, 60, 100);

  //   pdf.save(`${item?.name || "asset-details"}.pdf`);
  // };

  const handleDownloadQrCode = (item) => {
    const qrImage = item?.qr_img;

    const link = document.createElement("a");
    link.href = qrImage;
    link.download = `${item?.name || "asset-details"}.png`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const goToDepartment = () => {
    dispatch(setNavigationSource("assets"));
    navigate("/department-list");
  };

  const uniqueDepartments = [...new Set(data.map((item) => item.department))];
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const handleDepartmentChange = (newValue) => {
    setSelectedDepartment(newValue);

    if (newValue) {
      const filtered = data.filter((item) => item.department === newValue);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };
  const isGuest = userAccess && userAccess.role === 'Guest';


  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="stretch"
        gap="10px"
        sx={{ display: { xs: "none", sm: "none", lg: "flex" } }}
      >
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {allCounts?.department}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Departments</Label>
              <IconButton onClick={goToDepartment}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {allCounts?.checkout}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Check Outs</Label>
              <IconButton onClick={() => navigate("/check-in")}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {allCounts?.movements}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label>Asset movements</Label>
              <IconButton onClick={() => navigate("/asset-history-list")}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="10px">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        >
          <Label variant="h6">
            Assets.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          {selectedAssetIds?.length > 0 && (
                  <IconButton
                  onClick={!isGuest ? handleOpenBulkDeleteModal : undefined}
                    sx={{
                      bgcolor: "#1B2535",
                      width: "27px ",
                      height: "27px",
                      "&:hover": {
                        backgroundColor: "#1B2535",
                        color: "white",
                      },
                    }}
                  >
                    <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
                  </IconButton>
                )}
          <Button
            onClick={() => navigate("/add-asset")}
            sx={{
              bgcolor: "#1B2535",
              width: "auto ",
              height: "27px",
              textDecoration: "none",
              borderRadius: "20px",
              borderRight: "20px",
              color: "white",
              "&:hover": {
                backgroundColor: "#1B2535",
                color: "white",
              },
            }}
          >
            Add Asset <AddIcon style={{ color: "white", fontSize: "20px" }} />
          </Button>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
        >
          <Stack
            flexDirection={"row"}
            height="50px"
            width="15%"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="10px"
            gap={"5px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Autocomplete
              options={uniqueDepartments}
              value={selectedDepartment}
              onChange={(event, newValue) => {
                handleDepartmentChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Department"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      paddingRight: 0,
                    },
                  }}
                  sx={{
                    bgcolor: "transparent",
                    "& .MuiInputBase-root": {
                      background: "transparent",
                      border: "none",
                    },
                    "&:before, &:after": {
                      border: "none",
                    },
                  }}
                />
              )}
              freeSolo
              sx={{
                width: "100%",
                "& .MuiAutocomplete-clearIndicator": {
                  backgroundColor: "white",
                  borderRadius: "50%",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                },
              }}
            />
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="department"
              Filter2="cmcamc"
              FilterName1="Department"
              FilterName2="AMC/CMC Status"
              sortDate="cmcamcdate"
              sortPrice="price"
              dueDate=""
              name="name"
              csvName="All Assets"
            />
          </Box>

          <Box
            sx={{
              flex: 1,
              width: "100%",
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-around"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}
            >
              {selectedAssetIds?.length > 0 && (
                <IconButton
                onClick={!isGuest ? handleOpenBulkDeleteModal : undefined}
                  sx={{
                    bgcolor: "#1B2535",
                    width: "27px ",
                    height: "27px",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                  }}
                >
                  <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
                </IconButton>
              )}
              <Button
                onClick={() => navigate("/add-asset")}
                sx={{
                  bgcolor: "#1B2535",
                  width: "100px ",
                  height: "27px",
                  textDecoration: "none",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },
                  borderRadius: "20px",
                }}
              >
                Add Asset{" "}
                <AddIcon style={{ color: "white", fontSize: "20px" }} />
              </Button>
              {/* <IconButton
              onClick={() => navigate("/add-asset")}
              sx={{
                bgcolor: "#1B2535",
                width: "27px ",
                height: "27px",
                "&:hover": {
                  backgroundColor: "#1B2535",
                  color: "white",
                },
              }}>
              <AddIcon style={{ color: "white", fontSize: "20px" }} />
            </IconButton> */}

              <BulkUploadAssetPopUp />
            </Stack>
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          {/* Table */}
          <TableContainer
            component={Paper}
            style={{
              height: "calc(80vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            {filteredData.length > 0 ? (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableHeading>
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selectedAssetIds.length > 0 &&
                          selectedAssetIds.length < data?.length
                        }
                        checked={selectedAssetIds.length === data?.length}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all assets",
                        }}
                      />
                    </TableHeading>
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Asset Code</TableHeading>
                    <TableHeading>Serial Code</TableHeading>
                    <TableHeading>Brand</TableHeading>
                    <TableHeading>Department</TableHeading>
                    <TableHeading sx={{ textAlign: "center" }}>
                      Status
                    </TableHeading>
                    <TableHeading>Warranty expiry</TableHeading>

                    <TableHeading>AMC/CMC</TableHeading>
                    <TableHeading></TableHeading>
                    <TableHeading>QR Code</TableHeading>
                  </TableRow>
                </TableHead>

                <TableBody style={{ overflowY: "scroll" }}>
                  {filteredData?.map((item, index) => (
                    <TableRow key={item?.assetid}>
                      <TableCells>
                        <Checkbox
                          checked={selectedAssetIds.includes(item.assetid)}
                          onChange={() => handleSelectRowClick(item.assetid)}
                        />
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.name}>{item.name}</Tooltip>
                      </TableCells>

                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.serialno}>{item.serialno}</Tooltip>
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.brand}>{item.brand}</Tooltip>
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.department}>
                          {item.department}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                        sx={{
                          textAlign: "center",
                          color: "rgba(70, 144, 255, 1)",
                        }}
                      >
                        {item?.status !== "Working" ? (
                          <Button
                            size="small"
                            sx={{
                              borderRadius: "35px",
                              textTransform: "none",
                              width: { xs: "85px", sm: "75px", md:"75px",lg: "84px" },
                              height: "25px",
                              bgcolor: "rgba(255, 107, 0, 1)",
                              "&:hover": {
                                bgcolor: "rgba(255, 107, 0, 1)",
                              },
                            }}
                            variant="contained"
                          >
                            {item.status}
                          </Button>
                        ) : (
                          <>{item.status}</>
                        )}
                      </TableCells>

                      <TableCells
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                        >
                          {item.warranty_expdate === "Invalid date"
                            ? "NA"
                            : item.warranty_expdate}
                        </TableCells>

                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        {item.cmcamc}
                      </TableCells>
                      <TableCells>
                        <BorderColorIcon
                          disabled={userAccess?.editprod !== "Yes"}
                          onClick={() =>
                            navigate(`/edit-asset/${item.assetid}`)
                          }
                        />
                      </TableCells>
                      <TableCells>
                        <MiniQRset
                          onClick={() => handleDownloadQrCode(item)}
                          as="img"
                          src={item?.qr_img}
                        />
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70vh",
                }}
              >
                No assets found.
              </Typography>
            )}
            
          </TableContainer>
          <Stack
              sx={{
                padding: "20px",
                bgcolor: "white",
                gap: "20px",
                display: { xs: "none", sm: "none", lg: "flex" },
              }}
            >
              <Divider />
              <Stack
                sx={{
                  bgcolor: "white",
                  alignItems: "flex-end",

                  justifyContent: "column",
                }}
              >
                <Button
                  onClick={handleButtonClick}
                  variant="contained"
                  sx={{
                    borderRadius: "22px",
                    background: "rgba(70, 144, 255, 1)",
                  }}
                >
                  Depreciation <Calculate sx={{ marginLeft: "10px" }} />
                </Button>
              </Stack>
            </Stack>
        </Box>
      </Box>
      <BulkDeleteDeleteModal
        open={openBulkDeleteModal}
        onClose={handleCloseBulkDeleteModal}
        onDelete={handleBulkDelete}
        text={
          selectedAssetIds?.length > 1
            ? `Are you sure you want to delete ${selectedAssetIds?.length} assets?`
            : `Are you sure you want to delete ${selectedAssetIds?.length} asset?`
        }
        count={selectedAssetIds?.length}
      />
    </Box>
  );
};

export default AllAsset;
