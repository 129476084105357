import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import { Link } from "react-router-dom";
  
  const CalenderExpiryComponent = ({ dataModal, taskTitle }) => {
    // const [expiryData,setExpiryData] = useState(dataModal)
    const headingCellStyles = {
      color: "#1746A2",
      whiteSpace: "nowrap",
    };
    return (
      <Box padding="10px">
        {dataModal.map((item, index) => (
          <TableContainer
            key={index}
            component={Paper}
            sx={{ boxShadow: "none", marginBottom: "10px" }}
          ><Typography
          sx={{
            textAlign: "left",
            fontSize: "17px",
            fontWeight: 700,
            marginBottom: "10px",
          }}
        >
          {taskTitle} {index + 1}
        </Typography>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Asset Name
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {item?.asset_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Asset Code
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {item?.asset_code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Department
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {item?.department}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Extended To
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {item?.extend_to}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Completed By
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      padding: "5px 0",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {item?.completed_by}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </Box>
    );
  };
  
  export default CalenderExpiryComponent;
  