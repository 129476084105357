import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
} from "@mui/material";
import { Calculate, GetApp, Close } from "@mui/icons-material";

import DownloadIcon from '@mui/icons-material/Download';
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect } from "react";
import {
  assetDetailsData,
  departmentAssetDetails,
  getDepartmentData,
} from "../redux/data/action";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const DepreciationCalculator = () => {
  const departmentData = useSelector((store) => store.data.department) || [];
  const [showDetail, setShowDetail] = useState(false);
  const departAssetData = useSelector(
    (store) => store.data.departmentAssetDetails
  );
  const detailData = useSelector((store) => store.data.assetDetails);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [usefulLife, setUsefulLife] = useState("");
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const [department, setDepartment] = useState("");
  const [asset, setAsset] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [price, setPrice] = useState("");
  const [isPriceError, setIsPriceError] = useState("");
  const [isUsefulLifeError, setIsUsefulLifeError] = useState("");
  const [yearlyData, setYearlyData] = useState([]);
  const [assetId, setAssetId] = useState("");
  const [assetValue, setAssetValue] = useState("");
  const [data, setData] = useState({});

  const [toggle, setToggle] = useState(false);

  const filteredDepartments = departmentData.filter(
    (department) => parseInt(department.totalassets) > 0
  );

  const handleUsefulLife = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setUsefulLife(value);
    if (value === "" || regex.test(value)) {
      setUsefulLife(value);
      setIsUsefulLifeError("");
      setShow(false);
      setYearlyData([]);
    }
    if (value !== "" && !regex.test(value)) {
      setIsUsefulLifeError("Enter valid number");
    }
    if (value === "") {
      setIsUsefulLifeError("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError("");
      setShow(false);
      setYearlyData([]);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(detailData)?.length > 0) {
      setData(detailData);
    }
  }, [detailData]);

  const calculateDepreciationAndExpense = () => {
    price === "NA" && setIsPriceError("Enter valid price");
    usefulLife === "NA" && setIsUsefulLifeError("Enter valid year");
    price === "" && setIsPriceError("Please fill the field");
    usefulLife === "" && setIsUsefulLifeError("Please fill the field");
    if (
      price !== "NA" &&
      usefulLife !== "NA" &&
      price !== "" &&
      usefulLife !== "" &&
      isPriceError === "" &&
      isUsefulLifeError === ""
    ) {
      let usefulLifeCount = usefulLife;
      const depreciationPercentage = (2 / usefulLifeCount) * 100;
      let remainingPrice = price;

      const data = [];
      for (let year = 1; year <= usefulLifeCount; year++) {
        const depreciationExpense = Math.floor(
          (depreciationPercentage / 100) * remainingPrice
        );
       const percentage = ((depreciationExpense/price)*100).toFixed(2)

        data.push({ year, depreciationExpense, percentage });
        remainingPrice -= depreciationExpense;
      }

      setYearlyData(data);
      setShow(true);
    }
  };

  const handleDepartment = (e, item) => {
    if (item !== null && item !== undefined) {
      setSelectedId(item.id);
      setDepartment(item.name);
      setShow(false);
      setShowDetail(false);
      setYearlyData([]);
      setAssetValue("");
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        depart_ids: userAccess?.departmentids,
      };
      dispatch(getDepartmentData(data, token));
      setData({});
    }
  };
  console.log("data", data);

  const handleAssetName = (e, item) => {
    if (item !== null && item !== undefined) {
      setAssetId(item.value);
      setToggle(true);
      setAsset(item.name);
      setAssetValue(item.label);
      setShowDetail(true);
    }
    if (item === null && item === undefined) {
      setShow(false);
    }
  };
  console.log("assetId", assetId);
  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      deptid: selectedId,
      branchid: userBranch?.id,
    };

    dispatch(departmentAssetDetails(data, token));
  }, [dispatch, selectedId]);

  useEffect(() => {
    setUsefulLife(detailData?.lifeyrs);
    setPrice(detailData?.price);
  }, [detailData]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: assetId,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
    setToggle(false);
  }, [dispatch, assetId, toggle]);

  let print = {
    name: detailData.name,
    dop: detailData?.dop,
    price: price,
    usefulLife: usefulLife,
    department: detailData.dept,
    yearlyData: yearlyData,
  };

  const downloadPDF = (asset) => {
    const pdf = new jsPDF();

    pdf.setFillColor(255, 251, 244);
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    // Title
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(16);
    pdf.text(
      "Calculated  Depreciation  Expense",
      pdf.internal.pageSize.width / 2,
      18,
      "center"
    );
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(14, 79, 205); // #0E4FCD color
    pdf.line(
      pdf.internal.pageSize.width / 2 - 90,
      25,
      pdf.internal.pageSize.width / 2 + 90,
      25
    );

    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(12);
    pdf.text(`Asset Name:`, 20, 50);
    pdf.setTextColor(0, 0, 0); // Black color for values
    pdf.text(`${asset.name}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Date of Purchase:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.dop}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Price:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.price}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Useful Life:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.usefulLife} years`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.department}`, 70, 90);

    // Additional Title and Underline above the table
    pdf.setTextColor(14, 79, 205); // #0E4FCD color
    pdf.setFontSize(16);
    pdf.text("Depreciation", pdf.internal.pageSize.width / 2, 105, "center");
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0, 0, 0);
    pdf.line(
      pdf.internal.pageSize.width / 2 - 30,
      110,
      pdf.internal.pageSize.width / 2 + 30,
      110
    );

    const data = asset.yearlyData.map((item) => [
      `Depreciation Expense for year : ${item.year}`,
      `Expense : ${item.depreciationExpense}`,
    ]);

    pdf.autoTable({
      body: data,
      startY: 120,
    });

    pdf.save("Depreciation.pdf");
  };
  const handleClear = () => {
    setSelectedId("");
    setDepartment("");
    setAssetId("");
    setAsset("");
    setAssetValue("");
    setPrice("");
    setUsefulLife("");
    setYearlyData([]);
    setData({})
  };

  useEffect(() => {
    // Cleanup function to reset all fields when the component unmounts
    return () => {
      handleClear();
    };
  }, []);

  useEffect(() => {
    console.log("Cleared data:", data);
  }, [data]);
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}>
      <Stack
        sx={{
          width:  { xs: 'auto', sm: 'auto',  md: '40%',lg: '40%',  xl: '40%',  },

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label variant="h6" color="primary">
            Calculate depreciation expense
          </Label>
          <IconButton>
            <Calculate color="primary" />
          </IconButton>
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}>
            <Label variant="h6">Depreciation / year</Label>
            <Box sx={{marginBottom:"10px" ,}}>
              {yearlyData?.length > 0 && show && (
                <IconButton
                  onClick={() => downloadPDF(print)}
                  style={{ color: "rgba(70, 144, 255, 1)", }}>
                  <DownloadIcon />
                </IconButton>
              )}

              <IconButton
                style={{ color: "rgba(70, 144, 255, 1)" }}
                onClick={handleClear}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <Stack
            flexDirection={"row"}
            gap="10px"
            alignItems={"center"}
            sx={{
              width: "100%",
              overflowY: "auto",

              padding: "5px 0",
              display:"ruby",
              height: "70px",
              marginTop: "10px",
            }}>
            {yearlyData?.map((item) => (
              <Box
                key={item.year}
                sx={{
                  minWidth: "100px",
                  marginTop:"10px"
                }}>
                <Typography variant="body2">Year {item.year}</Typography>
                <Label variant="body2" sx={{ color: "rgba(70, 144, 255, 1)" }}>
                  {item.percentage}%
                </Label>
                <Label variant="body2" sx={{ color: "rgba(70, 144, 255, 1)" }}>
                  ₹ {item?.depreciationExpense?.toLocaleString('en-IN')}
                </Label>
              </Box>
            ))}
          </Stack>

          <Box sx={{ display: "flex", flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },gap:"20px",marginTop:"10px" }}>
            <Autocomplete
              value={department}
              onChange={handleDepartment}
              placeholder="e.g. ICU Department"
              options={filteredDepartments?.map((option) => ({
                label: `${option?.departname}`,
                name: option?.departname,
                id: option?.departid,
              }))}
              renderInput={(params) => (
                <Field {...params} placeholder="Select Department" />
              )}
              sx={{
                width: "100%",
                marginRight: "16px",
                "& fieldset": { border: "none" },
              }}
            />
            <Autocomplete
              onChange={handleAssetName}
              value={assetValue}
              placeholder="e.g. Ventilator"
              options={departAssetData?.map((option) => ({
                label: `${option?.name} (${option?.assetcode})`,
                value: option?.assetid,
                name: option?.name,
              }))}
              renderInput={(params) => (
                <Field {...params} placeholder=" Select Asset" />
              )}
              sx={{
                width: "100%",

                "& fieldset": { border: "none" },
              }}
            />
          </Box>

          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                },gap:"20px",
                marginTop: "16px",
              }}>
              <Box sx={{ flexGrow: 1, marginRight: "16px", width: "100%" }}>
                <Field
                  disabled
                  value={data?.dop || ""}
                  placeholder="Purchase Date"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
              </Box>
              <Stack sx={{ flexShrink: 1, width: "100%", textAlign: "start" }}>
                <Field
                  value={price || ''}
                  onChange={handlePrice}
                  placeholder="Asset Price"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <Typography variant="caption" sx={{ color: "red" }}>
                  {isPriceError}
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                },gap:"20px",
                marginTop: "16px",
              }}>
              <Stack
                sx={{
                  flexGrow: 1,
                  marginRight: "16px",
                  width: "100%",
                  textAlign: "start",
                }}>
                <Field
                  value={usefulLife ||''}
                  placeholder="Useful Life"
                  onChange={handleUsefulLife}
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <Typography variant="caption" sx={{ color: "red" }}>
                  {isUsefulLifeError}
                </Typography>
              </Stack>

              <Stack
                sx={{
                  flexShrink: 1,
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}>
                <Button
                  onClick={calculateDepreciationAndExpense}
                  variant="contained"
                  disabled={
                    usefulLife === "" ||
                    price === "" ||
                    assetValue === "" ||
                    selectedId === ""
                  }
                  sx={{
                    width: "154px",
                    borderRadius: "22px",
                    bgcolor: "rgba(70, 144, 255, 1)",
                  }}>
                  Calculate
                </Button>
              </Stack>
            </Box>
          </>
        </Box>
      </Stack>
    </Stack>
  );
};

export default DepreciationCalculator;
