import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AssetHistory,
  departmentAlreadySaved,
  retrieveAllAssets,
} from "../redux/nbmData/action";

import SuccessPopUp from "../components/SuccessPopUp";

const SelectWrapper = styled(Autocomplete)(({ theme }) => ({
  width: "100%",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "56px",
  },
}));

const MainDiv = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // border: "1px solid red",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "2%",

  bgcolor: "white",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  width: "70%",
  justifyContent: "center",
  alignItems: "center",
  height: "330px",
  // height: '1163px',
  padding: "0px 30px 20px 30px",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {
    width: "70%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "70%",
    height: "390px",
  },
  [theme.breakpoints.down("md")]: {
    width: "70%",
    height: "390px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    // padding: "20px",
    minWidth: "340px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const TextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "#1746A2",
  marginTop: "2%",
  fontSize: "20px",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",

  // display:'flex',
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    border: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("xs")]: {
    height: "350px",
    width: "100%",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  marginTop: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FormInput = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: "50px",
  //   border: "1px solid rgba(126, 126, 126, 0.3)",
  "& fieldset": { border: "none" },
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "56px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  width: "45%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "45%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const SubmitButton = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  marginTop: "3%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
    marginTop: "10%",
    marginBottom: "3%",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "70%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {
    width: "70%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Department = () => {
  const allDepartment = useSelector((store) => store.nbmData.alreadySaved);
  const allAssets = useSelector((store) => store.nbmData.allAssets);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const response = useSelector((store) => store.nbmData.assetHistoryData);

  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("userToken");
  let ids = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);

  const [previousDepartment, setPreviousDepartment] = useState("");
  const [previousDepartmentId, setPreviousDepartmentId] = useState("");

  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);

    const filteredOptions = allAssets.filter((option) => {
      return (
        option.name.toLowerCase().includes(newInputValue.toLowerCase()) ||
        option.assetcode.toLowerCase().includes(newInputValue.toLowerCase())
      );
    });

    setOptions(filteredOptions);
  };

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);

    if (newValue) {
      setPreviousDepartment(newValue.department);

      const matchingDepartment = allDepartment.find(
        (department) => department.departname === newValue.department
      );

      if (matchingDepartment) {
        setPreviousDepartmentId(matchingDepartment.departid);
      } else {
        setPreviousDepartmentId("");
      }
    } else {
      setPreviousDepartment("");
      setPreviousDepartmentId("");
    }
  };

  const handleDepartmentChange = (event, newValue) => {
    if (newValue) {
      setSelectedDepartment(newValue.departname);
      setSelectedDepartmentId(newValue.departid);
    } else {
      setSelectedDepartment("");
      setSelectedDepartmentId("");
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState(allAssets);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const handleSubmit = () => {
    if (!selectedOption && !selectedDepartment) {
      setErrorMessage("Please select an asset name and a department.");
    } else if (!selectedOption) {
      setErrorMessage("Please select an asset name.");
    } else if (!selectedDepartment) {
      setErrorMessage("Please select a department.");
    } else if (previousDepartment === selectedDepartment) {
      setErrorMessage("Selected department must differ from the current one.");
    } else {
      setErrorMessage("");

      let data = {
        hospid: ids?.hospid,
        username: ids?.username,
        userid: ids?.userid,
        hospname: ids.hospname,
        branchid: userBranch?.id,
        assetid: selectedOption.assetid,
        assetname: selectedOption.name,
        assetcode: selectedOption.assetcode,
        p_depart: previousDepartment,
        p_departid: previousDepartmentId,
        to_depart: selectedDepartment,
        to_departid: selectedDepartmentId,
      };

      dispatch(AssetHistory(data));
      setFlag(true);
    }
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      depart_ids: userAccess?.departmentids,
      branchid: userBranch?.id
    };

    let data2 = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(departmentAlreadySaved(data2, token));
    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (allAssets && allAssets?.length > 0 && allAssets?.length > 0) {
      setOptions(allAssets);
    }
  }, [allAssets]);

  const handleCloseSuccess = () => {
    setFlag(false);

    userAccess.role !== "Guest" 
      ? navigate("/asset-history-list")
      : navigate("/");
  };
  return (
    <>
    <Stack sx={{padding:'10px'}}>

      <MainDiv>
        <TopContainer>
          <TitleHead>Asset Movement</TitleHead>
        </TopContainer>
        <InnerContainer sx={{ bgcolor: "white" }}>
          <TextContainer>
            <FormWrapper>
              <FormElement>
                <TextLabel>Asset Name / Code</TextLabel>

                <SelectWrapper
                  displayEmpty
                  sx={{
                    bgcolor: "#EFF5FE",
                    "& fieldset": { border: "none" },
                    borderRadius: "5px",
                  }}
                  id="search-autocomplete"
                  inputProps={{ "aria-label": "Without label" }}
                  options={options}
                  getOptionLabel={(option) =>
                    option.name + "-" + option.assetcode
                  }
                  inputValue={searchTerm}
                  onInputChange={handleInputChange}
                  onChange={handleOptionChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="e.g Ventilator/V-10882-BH"
                      variant="outlined"
                    />
                  )}
                />
              </FormElement>

              <FormElement>
                <TextLabel>Present Department</TextLabel>
                <FormInput
                  value={previousDepartment}
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. ICU Department"
                />
              </FormElement>
            </FormWrapper>
          </TextContainer>
          <BottomContainer>
            <FormWrapper>
              <FormElement>
                <TextLabel sx={{ marginTop: "4%" }}>
                  {" "}
                  Select Department
                </TextLabel>

                <SelectWrapper
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    bgcolor: "#EFF5FE",
                    "& fieldset": { border: "none" },
                    borderRadius: "5px",
                  }}
                  id="department-autocomplete"
                  options={allDepartment}
                  getOptionLabel={(option) => option.departname}
                  onChange={handleDepartmentChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="e.g. Blood Bank"
                      variant="outlined"
                    />
                  )}
                />
              </FormElement>
              {/* <FormElement>
                <SubmitButton sx={{}}>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={
                      userAccess?.role !== "Admin" &&
                      userAccess?.role !== "Super_Admin"
                    }
                    sx={{
                      bgcolor: "#1746A2",
                      color: "white",
                      borderRadius: "13px",
                    }}>
                    Change Department
                  </Button>
                </SubmitButton>
              </FormElement> */}
            </FormWrapper>
            {<HelperText>{errorMessage}</HelperText>}
          </BottomContainer>
        </InnerContainer>

        <SubmitButton sx={{}}>
          <Button
            onClick={() => navigate(-1)}
            variant="contained"
            disabled={
              userAccess?.role === "Guest"
            }
            sx={{
              bgcolor: "white",
              color: "#1746A2",
              border: "1px solid #1746A2",
              borderRadius: "40px",
              textTransform: "none",
              "&:hover": {
                bgcolor: "white",
                color: "#1746A2",
                border: "1px solid #1746A2",
              },
            }}>
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={
              userAccess?.role === "Guest"
            }
            sx={{
              bgcolor: "#1746A2",
              color: "white",
              borderRadius: "40px",
              textTransform: "none",
            }}>
            Change Department
          </Button>
        </SubmitButton>

        <SuccessPopUp
          open={response}
          close={handleCloseSuccess}
          flag={flag}
          text="Submitted Successfully"
        />
      </MainDiv>
    </Stack>
    </>
  );
};

export default Department;
