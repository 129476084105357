import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  IconButton,
  Box,
  Typography,
  FormHelperText,
  Stack,
  Divider,
  Badge,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import pdfImage from "../images/pdfImage.png";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuid } from "uuid";

import styled from "@emotion/styled";
import ButtonGroup from "@mui/material/ButtonGroup";

import ArticleIcon from '@mui/icons-material/Article';
import { useParams } from "react-router-dom";
import {
  AddAssetReport,
  assetDeleteReport,
  assetDetailsData,
  updateReport,
} from "../redux/nbmData/action";
import { useDispatch, useSelector } from "react-redux";
const ImageSet = styled(Box)(({ theme }) => ({
  width: "183px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MenuList = styled(MenuItem)(({ theme }) => ({
  fontSize: "14px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const UpdateReport = ({ assetId }) => {
  const detailData = useSelector((store) => store.nbmData.assetDetails);
  let userAccess =
  JSON.parse(sessionStorage.getItem("user_access")) ||
  JSON.parse(localStorage.getItem("user_access"));
  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const params = useParams();
  const [assetReport, setAssetReport] = useState([]);
  const dispatch = useDispatch();
  const [reportImage, setReportImage] = useState([]);
  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data));
  }, [dispatch]);

  const initialRow = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    imageid: "",
    image: "",
    report_name: "select_report",
  };
  const [reportData, setReportData] = useState([initialRow]);
  const [openReport, setOpenReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [fileError, setFileError] = useState("");

  const [dataToSend, setDataToSend] = useState([initialRow]);
  const [newRow, setNewRow] = useState({ ...initialRow });

  const handleOpenReport = () => {
    setOpenReport(true);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
    const filteredData = dataToSend.filter((item) => item.image !== "");
    setAssetReport(filteredData);
    setFileError("");
  };

  const handleAddRow = () => {
    setReportData([...reportData, { ...newRow }]);
    setDataToSend([...reportData, { ...newRow }]);
    setNewRow({ ...initialRow });
  };
  const handleRemoveRow = (index) => {
    const updatedData = [...reportData];
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };
    dispatch(assetDeleteReport(dataDelete));
    updatedData.splice(index, 1);
    setReportData(updatedData);
    setDataToSend(updatedData);
    setFileError("");
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    const updatedData = [...reportData];


    if (file) {
      const allowedTypes = ["pdf", "jpg", "jpeg", "png"];
      const fileType = file.type.split("/")[1];

      const allowedSize = fileType === "pdf" ? 6 * 1024 * 1024 : 4 * 1024 * 1024;


      if (allowedTypes.includes(fileType)) {
        if (file.size > allowedSize) {
          setFileError(fileType === "pdf"
            ? "PDF file size cannot exceed 6 MB."
            : "Image file size cannot exceed 4 MB."
          );
          return;
        }
        const splitId = uuid().substring(0, 6);
        const imageid = assetId.substring(0, 6);
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          updatedData[index].imageid = `${imageid}_${splitId}.${fileType}`;
          updatedData[index].image = dataUrl;
          setReportData(updatedData);
          setDataToSend(updatedData);
          let assetRep = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: updatedData[index].imageid,
            image: updatedData[index].image,
            branchid: userBranch?.id,
          };

          dispatch(AddAssetReport(assetRep));
          setFileError("");
        };
        reader.readAsDataURL(file);
      } else {
        setFileError("Please upload pdf, jpg, jpeg or png file");
      }
    } else {
      updatedData[index].imageid = "";
      updatedData[index].image = "";
      setReportData(updatedData);
      setDataToSend(updatedData);
    }
  };

  const handleClearImage = (index) => {
    const updatedData = [...reportData];
    updatedData[index].image = "";
    setReportData(updatedData);
    setDataToSend(updatedData);
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };

    dispatch(assetDeleteReport(dataDelete));
  };
  const handleReportName = (event, index) => {
    const updatedData = [...reportData];
    const selectedReportName = event.target.value;
    updatedData[index].report_name = selectedReportName;
    setReportData(updatedData);
    setDataToSend(updatedData);

    let assetRep = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      image: updatedData[index].image,
      branchid: userBranch?.id,
    };

    dispatch(AddAssetReport(assetRep));
    setSelectedReport(selectedReportName);
    setFileError("");
  };

  const handleUpload = () => {
    const filteredData = dataToSend.filter((item) => item.image !== "");

    const hasInvalidReport = filteredData.some(
      (item) => item.report_name === "select_report"
    );

    if (hasInvalidReport) {
      setFileError("Please select a valid report before submitting.");
      return;
    }

    const hasEmptyField = dataToSend.some(
      (item) =>
        Object.values(item).some((value) => value === "") ||
        Object.values(item).some((value) => value === undefined)
    );

    if (hasEmptyField) {
      setFileError("Please fill in all fields before submitting.");
      return;
    }
    setFileError("");
    handleCloseReport();

    const data = {
      assetid: assetId,
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      username: userIds.username,

      report_names:
        filteredData?.length === 0
          ? []
          : filteredData?.map((item) => {
              return item.report_name;
            }),
      report_ids:
        filteredData?.length === 0
          ? []
          : filteredData?.map((item) => {
              return item.imageid;
            }),
    };


    dispatch(updateReport(data))
      .then(() => {
        let data = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          assetid: params.id,
          branchid: userBranch?.id,
        };
        dispatch(assetDetailsData(data));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setReportData(
      detailData?.reportimgs === "NA" || detailData?.reportimgs === ""
        ? []
        : detailData?.reportimgs?.map((item, index) => {
            return {
              imageid: item.imgid,
              image: item.imgurl,
              report_name: detailData?.reportnames[index],
            };
          })
    );
  }, [detailData]);
  const handleOpenReportAndAddRow = () => {
    handleOpenReport();
    handleAddRow();
  };

  useEffect(() => {
    if (detailData?.reportimgs) {
      setReportImage(detailData?.reportimgs);
    }

    // setReportImageName
  }, [detailData]);

  const isGuest = userAccess && userAccess.role === 'Guest';

  return (
    <div
    style={{
      display: "flex",
      marginRight: "2%",
      alignItems:'center',
      justifyContent:'center'
    }}>
    <Button
      onClick={
        reportData?.length === 0
          ? handleOpenReportAndAddRow
          : handleOpenReport
      }
      disabled={userAccess?.role === "Guest"}
      variant="contained"
      sx={{
        borderRadius: "22px ",
        width: "auto",
        fontSize: "medium",
        color: "#fff",
        border: "1px solid",
        background: "#FF6B00",
        "&:hover": {
          background: "#FF6B00",
          color: "#fff",
        },
      }}>
      Add Report
    </Button>

    <Dialog
      open={openReport}
    
    >
      <DialogContent>
        <Stack sx={{ padding: "10px", gap: "15px" }}>
          <Stack
            sx={{
              width: "100%",

              flexDirection: "row",
              justifyContent: "space-between",

              gap: "10px",
            }}>
            <Typography>Add new Report</Typography>
            <CloseIcon onClick={handleCloseReport} />
          </Stack>
          <Divider sx={{ width: "100%" }} />
          {reportData?.map((row, index) => (
              <Stack sx={{ display: "flex",flexDirection: { xs: "column", sm: "column",md:"row" }, gap:"10px" }} key={index}>
              <Select
                sx={{
                  height: "45px",
                  background: "rgba(244, 246, 246, 1)",
                  width: "200px",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                value={row.report_name}
                onChange={(event) => handleReportName(event, index)}>
                <MenuList value="select_report" disabled>
                  Select Report
                </MenuList>
                <MenuList value="Service Report">Service Report</MenuList>
                <MenuList value="Installation Report">
                  Installation Report
                </MenuList>
                <MenuList value="Warranty Document">
                  Warranty Document
                </MenuList>
                <MenuList value="Calibration Document">
                  Calibration Document
                </MenuList>
                <MenuList value="Service Invoice">Service Invoice</MenuList>
                <MenuList value="Sales Invoice">Sales Invoice</MenuList>
                <MenuList value="Equipment Invoice">Equipment Invoice</MenuList>
                <MenuList value="Purchase Order">Purchase Order</MenuList>
                <MenuList value="Others">Others</MenuList>
              </Select>
              <Stack sx={{display: { xs: "flex", sm: "flex", },flexDirection: { xs: "row", sm: "row", },justifyContent:"space-between"}}>

              {row.image ? (
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "rgba(244, 246, 246, 1)",

                    width: "130px",
                  }}>
                  <Badge
                    key={index}
                    badgeContent={
                      <CloseIcon
                        size={15}
                        style={{
                          color: " rgba(255, 58, 68, 1)",
                        }}
                        onClick={() => handleClearImage(index)}
                      />
                    }
                    sx={{
                      // height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <Box
                      width={"35px"}
                      height={"35px"}
                      as="img"
                      src={`${
                        row.imageid?.split(".")?.pop() === "pdf"
                          ? pdfImage
                          : row.image
                      }`}
                      alt="Selected"
                    />
                  </Badge>
                </Stack>
              ) : (
                <>
                  <input
                    type="file"
                    accept=".pdf,.jpg,.jpeg,.png"
                    onChange={(event) => handleImageUpload(event, index)}
                    style={{ display: "none" }}
                    id="file-upload"
                  />

                  <label htmlFor="file-upload">
                    <Stack
                      sx={{
                        height: "45px",
                        width: "130px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(244, 246, 246, 1)",
                        flexDirection:"row",
                        gap:"10px"
                      }}
                      aria-label="split button">
                      <FileUploadIcon size={20} sx={{ color: "black" }} /> Img/pdf
                    </Stack>
                  </label>
                </>
              )}

              <IconButton onClick={() => handleRemoveRow(index)}>
                <DeleteIcon />
              </IconButton>
              </Stack>
            </Stack>
          ))}
          <FormHelperText>{fileError}</FormHelperText>

          <Stack
            sx={{
              display: "flex",
              width: "100%",

              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Stack sx={{ gap: "20px", alignItems: "flex-end", width: "95%" }}>
              <AddCircleIcon
                size={25}
                style={{ color: "#4690FF" }}
                onClick={handleAddRow}
              />
            </Stack>
            <Divider sx={{ width: "100%" }} />
            <Stack sx={{}}>
              <Button
                variant="contained"
                disabled={isGuest}
                sx={{
                  textTransform: "none",
                  width: "150px",
                  borderRadius: "22px",
                }}
                onClick={handleUpload}>
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  </div>
  );
};

export default UpdateReport;
