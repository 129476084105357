import styled from "@emotion/styled";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Grid,
  Typography,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';



import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { v4 as uuid } from "uuid";

import { useDispatch } from "react-redux";
import {
  assetDetailsData,
  clearService,
  clearServiceImgDel,
  clearServiceReceipt,
  clearServiceReceiptDelete,
  getDepartmentData,
  serviceImageUpload,
  serviceIssue,
  clearIncidentImageRemove,
  serviceExpenditureImageUpload,
  clearServiceExpenditureImageRemove,
  // clearServiceReceiptImageUpload,clearServiceReceiptImageUpload
} from "../redux/data/action";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import SuccessPopUp from "../components/SuccessPopUp";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const BlueStepper = styled(Stepper)(({ theme }) => ({
  ".MuiStepConnector-active": {
    "& > .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
      width: "700px",
    },
  },
  "& .MuiStepConnector-line": {
    borderLeftWidth: "558px", // Adjust the width as needed
  },
}));

const ClearService = () => {
  const issueData = useSelector((store) => store.data.serviceIssue);
  const detailData = useSelector((store) => store.data.assetDetails);
  const response = useSelector((store) => store?.data?.clearService);

  const [expenditures, setExpenditures] = useState([]);
  const [newExpenditure, setNewExpenditure] = useState({
    description: '',
    totalAmountSpent: '',
    expImage: [],
  });


  let assetId = localStorage.getItem("assetId") || "";
  let idSplit = assetId.substring(0, 4);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));





  const [department, setDepartment] = useState("");
  const [accessory, setAccessory] = useState("");
  const [isAccessoryError, setIsAccessoryError] = useState("");


  const [accessoryType, setAccessoryType] = useState("");
  const [isAccessoryTypeError, setIsAccessoryTypeError] = useState("");


  const [isWarrantyError, setIsWarrantyError] = useState(null);
  const [isCalibrationDateError, setIsCalibrationDateError] = useState(null);




  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const servid = localStorage.getItem("serviceId");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));





  const [fileUrl, setFileUrl] = useState([]);
  const [fileUrlAccessory, setFileUrlAccessory] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const [isAccessoryPhotoError, setIsAccessoryPhotoError] = useState("");
  let ids = JSON.parse(sessionStorage.getItem("userData")) || JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const [nameError, setNameError] = useState("");
  const [isMobileNumberError, setIsMobileNumberError] = useState("");
  const [isCompanyError, setIsCompanyError] = useState("");
  const [isIssueError, setIsIssueError] = useState("");
  const [isActionError, setIsActionError] = useState("");
  const [isStatusError, setIsStatusError] = useState("");

  const [amccmError, setAmccmcError] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [software, setSoftware] = useState("");
  const [company, setCompany] = useState("");
  const [amcCmc, setAmcCmc] = useState("");

  const [status, setStatus] = useState("");
  const [issue, setIssue] = useState("");
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const [isRemarkError, setIsRemarkError] = useState("");


  const dispatch = useDispatch();
  const params = useParams();
  const [isPaid, setIsPaid] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState("");
  const [showError, setShowError] = useState(false);
  const [toggle, setToggle] = useState(false);


  const [unitPrice, setUnitPrice] = useState("");
  const [isUnitPriceError, setIsUnitPriceError] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isQuantityError, setIsQuantityError] = useState("");
  const [totalAmountSpent, setTotalAmountSpent] = useState("");
  const [isTotalAmountSpentError, setIsTotalAmountSpentError] = useState("");
  const [description, setDescription] = useState("");
  const [isDescriptionError, setIsDescriptionError] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openModal, setOpenModal] = React.useState(false);

  const [receipt, setReceipt] = useState("");

  const handlePaidChange = (event) => {
    if (event.target.value === "no") {
      setAmount("");
      setFile("");
    }
    setIsPaid(event.target.value);
    setShowError(false);
  };

  // const handleStatusChange = (event) => {
  //   setStatus(event.target.value);
  //   setIsStatusError(false);
  //   setStatus(value);
  // };
  const handleStatusChange = (value) => {
    setStatus(value);
    setIsStatusError(false);
  };




  const handleAmountChange = (event) => {
    const newValue = event.target.value;

    // Validate the input (you can customize this part)
    if (newValue.match(/^\d*$/) || newValue === "") {
      setAmount(newValue);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const uploadedImageName = fileUrl?.map((el) => el?.imageid);


  const handleAccessoryPhotoUpload = (e, index) => {
    const singlefile = e.target.files[0];
    if (!singlefile) return; // No file selected

    const fileType = singlefile.type;
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
    const allowedSize = 2 * 1024 * 1024; // 2 MB

    if (!allowedTypes.includes(fileType)) {
      setIsAccessoryPhotoError(`Can't upload ${fileType.split("/")[1]} file.`);
      return;
    }

    if (singlefile.size > allowedSize) {
      compressImage(singlefile);
    } else {
      setIsAccessoryPhotoError(null);
      const reader = new FileReader();

      reader.onloadend = () => {
        let sendData = {
          hospid: ids?.hospid,
          userid: ids?.userid,
          imageid: `${params.id}-${uuid()}.${fileType.split("/")[1]}`,
          image: reader.result,
          branchid: userBranch.id,
          fileType: fileType,
        };

        let edit = [...expenditures];
        edit[index].expImage = [sendData];
        setExpenditures([...edit]);

        // Uncomment if you want to dispatch an action for uploading
        // dispatch(serviceImageUpload(sendData, token));
      };

      reader.readAsDataURL(singlefile);
    }
  };











  const compressImage = (file, index) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const compressedFile = new File([blob], `${params.id}-${uuid()}.jpg`, {
            type: "image/jpeg",
          });

          const reader = new FileReader();
          reader.onloadend = () => {
            const sendData = {
              imageid: `${params.id}-${uuid()}.jpg`,
              image: reader.result,
              fileType: "image/jpeg",
            };

            const updatedExpenditures = [...expenditures];
            updatedExpenditures[index].expImage = [sendData];
            setExpenditures(updatedExpenditures);
          };
          reader.readAsDataURL(compressedFile);
        }, "image/jpeg", 0.7);
      };
    };
    reader.readAsDataURL(file);
  };


  const handleReceiptUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize = 3 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImageReceipt(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: params.id + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFile(file);
          setReceipt(sendData);
          dispatch(clearServiceReceipt(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };
  // const handleReceiptUploadImage = (e) => {
  //   const singlefile = e.target.files[0];

  //   const newFiles = Array.from(e.target.files).filter((file) => {
  //     const fileType = file.type.split("/")[1];
  //     const allowedTypes = [
  //       "image/jpeg",
  //       "image/jpg",
  //       "image/png",
  //       "application/pdf",
  //     ];
  //     const allowedSize = 3 * 1024 * 1024; // 2 MB

  //     if (allowedTypes.indexOf(file.type) === -1) {
  //       setIsPhotoError(`Can't upload ${fileType} file.`);
  //       return false;
  //     }

  //     if (file.size > allowedSize) {
  //       compressImageReceiptUploadImage(file);
  //     } else {
  //       setIsPhotoError(null);
  //       const reader = new FileReader();

  //       reader.onloadend = () => {
  //         let sendData = {
  //           hospid: ids?.hospid,
  //           userid: ids?.userid,
  //           imageid: params.id + uuid() + `.${fileType}`,
  //           image: reader.result,
  //           branchid: userBranch.id,
  //         };
  //         setFile(file);
  //         setReceipt(sendData);
  //         dispatch(clearServiceReceipt(sendData, token));
  //       };
  //       reader.readAsDataURL(singlefile);
  //     }
  //   });
  // };

  const compressImageReceipt = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              params.id + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: params.id + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch.id,
              };
              setFile(file);
              setReceipt(sendData);

              dispatch(clearServiceReceipt(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  // const handleFileUpload = (event, index) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const fileType = file.type.split("/")[0];
  //       const payload = {
  //         hospid: ids?.hospid,
  //         userid: ids?.userid,
  //         imageid: `${params.id}-${uuid()}.${fileType}`,
  //         image: reader.result,
  //         branchid: userBranch.id,
  //       };

  //       // Dispatch the serviceImageUpload action
  //       dispatch(serviceExpenditureImageUpload(payload));

  //       const newFile = {
  //         id: uuid(),
  //         name: file.name,
  //         type: file.type,
  //         url: URL.createObjectURL(file),
  //       };

  //       // Create a copy of fileUrlAccessory for this specific expenditure
  //       const updatedFiles = [...fileUrlAccessory];
  //       updatedFiles[index] = [...(updatedFiles[index] || []), newFile]; // Add the new file for the specific expenditure index
  //       setFileUrlAccessory(updatedFiles);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  //   event.target.value = null;
  // };

  // const handleFileUpload = (event, index) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const fileType = file.type.includes('pdf') ? 'pdf' : 'png'; // Determine file type
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       // Generate a unique image ID and include it in the file object
  //       const newFile = {
  //         id: uuid(), // Unique id for the file
  //         name: file.name,
  //         type: file.type,
  //         url: URL.createObjectURL(file), // Create a blob URL for immediate display
  //         imageid: `${params.id}-${uuid()}.${fileType}`, // Generate unique imageid
  //       };

  //       // Prepare the payload for dispatch
  //       const payload = {
  //         hospid: ids?.hospid,
  //         userid: ids?.userid,
  //         imageid: newFile.imageid, // Use the generated imageid
  //         image: reader.result, // Base64-encoded file data
  //         branchid: userBranch.id,
  //       };

  //       // Dispatch the action to upload the image
  //       dispatch(serviceExpenditureImageUpload(payload))
  //         .then((response) => {
  //           // Assume the response contains the URL or ID for the uploaded image
  //           const uploadedImage = {
  //             ...newFile,
  //             url: response?.payload?.url || newFile.url, // Update URL with server response
  //           };

  //           // Update expenditures array, replace the previous image
  //           const updatedExpenditures = [...expenditures];
  //           updatedExpenditures[index].expImage = [uploadedImage]; // Only one image allowed

  //           // Update the expenditures state
  //           setExpenditures(updatedExpenditures);

  //           // Update fileUrlAccessory for display
  //           const updatedFileUrls = [...fileUrlAccessory];
  //           updatedFileUrls[index] = [uploadedImage]; // Replace with the uploaded image

  //           setFileUrlAccessory(updatedFileUrls); // Update the fileUrlAccessory state
  //         })
  //         .catch((error) => {
  //           console.error("File upload error:", error);
  //         });
  //     };

  //     reader.readAsDataURL(file); // Read file data as base64 string for the payload
  //   }

  //   // Clear the input value after the file is uploaded
  //   event.target.value = null;
  // };

  const handleFileUpload = (event, index) => {
    const file = event.target.files[0];
  
    if (file) {
      const allowedImageSize = 4 * 1024 * 1024; // 4 MB
      const allowedPdfSize = 6 * 1024 * 1024; // 6 MB
      const isPdf = file.type === 'application/pdf';
      const isImage = file.type.includes('image');
      
      // Check for file size restrictions
      if (isImage && file.size > allowedImageSize) {
        setIsAccessoryPhotoError("Image files must be less than 4MB.");
        return;
      }
  
      if (isPdf && file.size > allowedPdfSize) {
        setIsAccessoryPhotoError("PDF files must be less than 6MB.");
        return;
      }
  setIsAccessoryPhotoError(null)
      const fileType = isPdf ? 'pdf' : 'png';
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const newFile = {
          id: uuid(), // Unique id for the file
          name: file.name,
          type: file.type,
          url: URL.createObjectURL(file), // Create a blob URL for immediate display
          imageid: `${params.id}-${uuid()}.${fileType}`, // Generate unique imageid
        };
  
        const payload = {
          hospid: ids?.hospid,
          userid: ids?.userid,
          imageid: newFile.imageid,
          image: reader.result,
          branchid: userBranch.id,
        };
  
        // Dispatching the action for file upload
        dispatch(serviceExpenditureImageUpload(payload))
          .then((response) => {
            const uploadedImage = {
              ...newFile,
              url: response?.payload?.url || newFile.url,
            };
  
            const updatedExpenditures = [...expenditures];
            updatedExpenditures[index].expImage = [uploadedImage];
  
            setExpenditures(updatedExpenditures);
  
            const updatedFileUrls = [...fileUrlAccessory];
            updatedFileUrls[index] = [uploadedImage]; 
  
            setFileUrlAccessory(updatedFileUrls);
          })
          .catch((error) => {
            console.error("File upload error:", error);
          });
      };
  
      // Read file as Data URL
      reader.readAsDataURL(file);
    }
  
    event.target.value = null;
  };

  const handleRemarkChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;:"", -]+$/;

    if (value === "" || regex.test(value)) {
      setRemark(value);
      setIsRemarkError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsRemarkError("Invalid character");
    }
    if (value === "") {
      setIsRemarkError("Please fill the field");
    }
  };
  const handleDeletingAccessory = async (id, index) => {
    console.log("Deleting image with id:", id, "at index:", index);

    const data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };

    try {
      await dispatch(clearIncidentImageRemove(data));

      // Update expenditures state by removing the deleted image and its id
      const updatedExpenditures = expenditures.map((exp, idx) => {
        if (idx === index) {
          return {
            ...exp,
            expImage: exp.expImage.filter(img => img.imageid !== id),
          };
        }
        return exp;
      });
      setExpenditures(updatedExpenditures);

      // Filter the fileUrlAccessory to remove the deleted image
      const updatedFiles = fileUrlAccessory.filter((el) => el.id !== id);
      console.log("Updated fileUrlAccessory after deletion:", updatedFiles);
      setFileUrlAccessory(updatedFiles);
    } catch (error) {
      console.error("Error deleting the image:", error);
    }
  };
  // const compressImageReceiptUploadImage = (file) => {
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     if (file.type === "application/pdf") {
  //       const sendData = {
  //         hospid: ids?.hospid,
  //         userid: ids?.userid,
  //         imageid: params.id + uuid() + ".pdf",
  //         image: e.target.result,
  //         branchid: userBranch.id,
  //       };
  //       setFile(file);
  //       setReceipt(sendData);

  //       dispatch(clearServiceReceiptImageUpload(sendData));
  //     } else {
  //       const img = new Image();
  //       img.src = e.target.result;

  //       img.onload = () => {
  //         const canvas = document.createElement("canvas");
  //         const maxWidth = 800;
  //         const maxHeight = 600;
  //         let width = img.width;
  //         let height = img.height;

  //         if (width > height) {
  //           if (width > maxWidth) {
  //             height *= maxWidth / width;
  //             width = maxWidth;
  //           }
  //         } else {
  //           if (height > maxHeight) {
  //             width *= maxHeight / height;
  //             height = maxHeight;
  //           }
  //         }

  //         canvas.width = width;
  //         canvas.height = height;
  //         const ctx = canvas.getContext("2d");
  //         ctx.drawImage(img, 0, 0, width, height);

  //         canvas.toBlob(
  //           (blob) => {
  //             const compressedFile = new File(
  //               [blob],
  //               params.id + uuid() + ".jpg",
  //               {
  //                 type: "image/jpeg",
  //               }
  //             );

  //             const reader = new FileReader();
  //             reader.onloadend = () => {
  //               const sendData = {
  //                 hospid: ids?.hospid,
  //                 userid: ids?.userid,
  //                 imageid: params.id + uuid() + ".jpg",
  //                 image: reader.result,
  //                 branchid: userBranch.id,
  //               };
  //               setFile(file);
  //               setReceipt(sendData);

  //               dispatch(clearServiceReceiptImageUpload(sendData, token));
  //             };
  //             reader.readAsDataURL(compressedFile);
  //           },
  //           "image/jpeg",
  //           0.7
  //         );
  //       };
  //     }
  //   };

  //   // Read the file as a data URL
  //   reader.readAsDataURL(file);
  // };

  const handleImageDelete = (imageId, expenditureIndex) => {
    setExpenditures(prevExpenditures => {
      return prevExpenditures.map((exp, index) => {
        if (index === expenditureIndex) {
          return {
            ...exp,
            expImage: exp.expImage.filter(img => img.imageid !== imageId)
          };
        }
        return exp;
      });
    });
  };





  const handleDelete = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    dispatch(clearServiceImgDel(data));
    setFileUrl(fileUrl?.filter((el) => el?.imageid !== id));
  };

  const handleDeleteReceipt = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    dispatch(clearServiceReceiptDelete(data));
    setReceipt(receipt.imageid !== id);
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;

    if (value === "") {
      setName(value);
      setNameError("Please enter a valid Name");
    } else if (value.trim() === "") {
      setName(value);
      setNameError("Invalid name");
    } else if (regex.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid Name");
    }
  };

  const handleCompany = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.-]+$/i;

    if (value === "") {
      setCompany(value);
      setIsCompanyError("Please enter a valid company");
    } else if (value.trim() === "") {
      setCompany(value);
      setIsCompanyError("Invalid company");
    } else if (regex.test(value)) {
      setCompany(value);
      setIsCompanyError("");
    } else {
      setIsCompanyError("Invalid company");
    }
  };

  const handleNumber = (e) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/\s+/g, "");
    const regex = /^\+?[0-9]{10}$/;

    if (cleanedValue === "") {
      setMobile(value);
      setIsMobileNumberError("Please enter a valid mobile number");
    }
    else if (regex.test(cleanedValue)) {
      setMobile(cleanedValue);
      setIsMobileNumberError("");
    }
    else {
      setMobile(value);
      setIsMobileNumberError("Invalid mobile number");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    // const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    if (value === "") {
      setEmail(value);
      setIsEmailError("Please enter a valid email");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setEmail(value);
      setIsEmailError(null);
    } else {
      setIsEmailError("Enter valid email");
    }
    setEmail(value);
  };

  const handleIssue = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;:"", -]+$/i;

    if (value === "") {
      setIssue(value);
      setIsIssueError("Please enter a valid issue");
    } else if (value.trim() === "") {
      setIssue(value);
      setIsIssueError("Invalid issue");
    } else if (regex.test(value)) {
      setIssue(value);
      setIsIssueError("");
    } else {
      setIsIssueError("Invalid issue");
    }
  };
  const handleAction = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;:"", -]*$/;

    if (value === "") {
      setAction(value);
      setIsActionError("Please enter a valid action");
    } else if (value.trim() === "") {
      setAction(value);
      setIsActionError("Invalid action");
    } else if (regex.test(value)) {
      setAction(value);
      setIsActionError("");
    } else {
      setIsActionError("Invalid action");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      servid: params.id2,
      branchid: userBranch.id,
    };
    dispatch(serviceIssue(data, token));
  }, [dispatch]);

  // const handleClicks = () => {
  //   const trimmedName = name.trim();
  //   const trimmedMobile = mobile.trim();
  //   const trimmedCompany = company.trim();
  //   const trimmedEmail = email.trim();
  //   const trimmedAction = action.trim();
  //   const trimmedIssue = issue.trim();
  //   const trimmedStatus = status.trim();

  //   if (
  //     trimmedName === "" ||
  //     trimmedMobile === "" ||
  //     trimmedCompany === "" ||
  //     trimmedEmail === "" ||
  //     trimmedAction === "" ||
  //     trimmedIssue === "" ||
  //     trimmedStatus === ""
  //   ) {
  //     setNameError(trimmedName === "" ? "Please enter a valid Name" : null);
  //     setIsMobileNumberError(
  //       trimmedMobile === "" ? "Please enter a valid mobile number" : null
  //     );
  //     setIsCompanyError(
  //       trimmedCompany === "" ? "Please enter a valid company" : null
  //     );
  //     setIsEmailError(
  //       trimmedEmail === "" ? "Please enter a valid email" : null
  //     );
  //     setIsIssueError(
  //       trimmedIssue === "" ? "Please enter a valid issue" : null
  //     );
  //     setIsStatusError(
  //       trimmedStatus === "" ? "Please enter a valid status" : null
  //     );
  //     setIsActionError(
  //       trimmedAction === "" ? "Please enter a valid action" : null
  //     );
  //     setShowError(true);

  //     setSnackbarMessage("Please fill all the mandatory fields.");
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //   // if (
  //   //   !isPaid ||
  //   //   (isPaid === "yes" && (!amount || receipt === "" || receipt === false))
  //   // ) {
  //   //   setShowError(true);
  //   //   return;
  //   // }
  //   if (
  //     name !== "" &&
  //     mobile !== "" &&
  //     company !== "" &&
  //     issue !== "" &&
  //     action !== "" &&
  //     status !== ""
  //   ) {
  //     let datas = {
  //       assetid: params.id,
  //       hospid: userIds.hospid,
  //       serviceid: params.id2,
  //       userid: userIds.userid,
  //       username: userIds.username,
  //       swversion: software === "" ? "NA" : software.trim(),
  //       paidservice: isPaid,
  //       asset_name: detailData.name,
  //       serial_no: detailData.serialno,
  //       model_no: detailData.modelno,
  //       department: detailData.dept,
  //       req_date: issueData.reqdate,
  //       req_time: issueData.reqtime,
  //       branchid: userBranch?.id,
  //       servicecost: amount?.length > 0 ? amount : "NA",
  //       "amc/camc": amcCmc === "" ? "NA" : amcCmc,
  //       fixstatus: status,
  //       probidentify: trimmedIssue,
  //       action_taken: trimmedAction,
  //       servicer_name: trimmedName,
  //       company: trimmedCompany,
  //       phone: trimmedMobile,
  //       email: trimmedEmail,
  //       feedback: remark === "" ? "NA" : remark.trim(),
  //       clearimages: uploadedImageName?.length > 0 ? uploadedImageName : "NA",
  //       // receipt_id: receipt ? receipt.imageid : "NA",
  //       assetcode: detailData.code,
  //       expenditure: expenditures?.map((item) => {
  //         return item.description;
  //       }),
  //       amt_spent: expenditures?.map((item) => { return Number(item.totalAmountSpent) }),
  //       receipt_id: expenditures?.flatMap(item => item?.expImage?.map(img => img?.imageid || [])),

  //     };
  //     setToggle(true);
  //     handleOpenModal()
  //     dispatch(clearService(datas, token));
  //   }
  //   // console.log(Data);
  // };


  const handleClicks = () => {
    const trimmedName = name.trim();
    const trimmedMobile = mobile.trim();
    const trimmedCompany = company.trim();
    const trimmedEmail = email.trim();
    const trimmedAction = action.trim();
    const trimmedIssue = issue.trim();
    const trimmedStatus = status.trim();
  
    // Validate mandatory fields
    if (
      trimmedName === "" ||
      trimmedMobile === "" ||
      trimmedCompany === "" ||
      trimmedEmail === "" ||
      trimmedAction === "" ||
      trimmedIssue === "" ||
      trimmedStatus === ""
    ) {
      setNameError(trimmedName === "" ? "Please enter a valid Name" : null);
      setIsMobileNumberError(trimmedMobile === "" ? "Please enter a valid mobile number" : null);
      setIsCompanyError(trimmedCompany === "" ? "Please enter a valid company" : null);
      setIsEmailError(trimmedEmail === "" ? "Please enter a valid email" : null);
      setIsIssueError(trimmedIssue === "" ? "Please enter a valid issue" : null);
      setIsStatusError(trimmedStatus === "" ? "Please enter a valid status" : null);
      setIsActionError(trimmedAction === "" ? "Please enter a valid action" : null);
      setShowError(true);
  
      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }
    

    // Validate AMC/CMC and Status fields
  if (!amcCmc || !status) {
    setSnackbarMessage("Please fill both AMC/CMC and Status fields.");
    setSnackbarOpen(true);
    setShowError(true); // Make sure to set the error flag
    return;
  }
  
    // Validate expenditures
    let hasIncompleteExpenditure = false;
    if (expenditures.length > 0) {
      hasIncompleteExpenditure = expenditures.some((item, index) => {
        return (
          !item.description || 
          !item.totalAmountSpent || 
          !item.expImage || 
          item.expImage.length === 0
        );
      });
    }
  
    // If any expenditures are incomplete, show an error message
    if (hasIncompleteExpenditure) {
      setSnackbarMessage("Please fill all mandatory expenditure fields.");
      setSnackbarOpen(true);
      return;
    }
  
    // If all validations pass, proceed with submission
    if (
      trimmedName !== "" &&
      trimmedMobile !== "" &&
      trimmedCompany !== "" &&
      trimmedIssue !== "" &&
      trimmedAction !== "" &&
      trimmedStatus !== ""
    ) {
      let datas = {
        assetid: params.id,
        hospid: userIds.hospid,
        serviceid: params.id2,
        userid: userIds.userid,
        username: userIds.username,
        swversion: software === "" ? "NA" : software.trim(),
        paidservice: isPaid,
        asset_name: detailData.name,
        serial_no: detailData.serialno,
        model_no: detailData.modelno,
        department: detailData.dept,
        req_date: issueData.reqdate,
        req_time: issueData.reqtime,
        branchid: userBranch?.id,
        servicecost: amount?.length > 0 ? amount : "NA",
        "amc/camc": amcCmc === "" ? "NA" : amcCmc,
        fixstatus: status,
        probidentify: trimmedIssue,
        action_taken: trimmedAction,
        servicer_name: trimmedName,
        company: trimmedCompany,
        phone: trimmedMobile,
        email: trimmedEmail,
        feedback: remark === "" ? "NA" : remark.trim(),
        clearimages: uploadedImageName?.length > 0 ? uploadedImageName : "NA",
        assetcode: detailData.code,
        expenditure: expenditures?.map((item) => item.description),
        amt_spent: expenditures?.map((item) => Number(item.totalAmountSpent)),
        receipt_id: expenditures?.flatMap(item => item?.expImage?.map(img => img?.imageid || [])) || [],
      };
  
      setToggle(true);
      handleOpenModal();
      dispatch(clearService(datas, token));
    }
  };
  
  useEffect(() => {
    if (response === "Updated" && name !== "") {

    }
  }, [response]);
  console.log("response", response)

  useEffect(() => { }, [snackbarOpen, snackbarMessage]);
  console.log("receipt", file);









  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);


  const calibrationValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsCalibrationDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsCalibrationDateError(null);
      setIsCalibrationDateError(null);
    }
  };

  const warrantyValidDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsWarrantyError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsWarrantyError(null);
      // setIsCmcEndDateError(null);
    }
  };

  useEffect(() => {
    let Id = uuid();
    localStorage.setItem("assetId", Id);
  }, []);



  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (isMobile) {
        return Math.min(prevActiveStep + 1, 2);
      } else {
        return Math.min(prevActiveStep + 1, 1);
      }
    });
  };


  const handleAccessory = (e, item) => {
    if (item !== null && item !== undefined) {
      // setSelectedId(item.id);
      setAccessoryType(item.name);
      // setShow(false);
      // setShowDetail(false);
      // setYearlyData([]);
      // setAssetValue("");
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        depart_ids: userAccess?.departmentids,
      };
      // dispatch(getDepartmentData(data, token));
      // setData({});
    }
  };


  const handleDeleteAccessory = async (id, index) => {
    console.log("Deleting image with id:", id, "at index:", index);

    const data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };

    try {
      await dispatch(clearServiceExpenditureImageRemove(data));

      // Update expenditures state
      const updatedExpenditures = expenditures.map((exp, idx) => {
        if (idx === index) {
          // If multiple images exist, remove only the one with the matching id
          const updatedExpImages = exp.expImage.filter(img => img.id !== id);
          return { ...exp, expImage: updatedExpImages };
        }
        return exp;
      });
      setExpenditures(updatedExpenditures);

      // Update the correct index of fileUrlAccessory for the expenditure
      const updatedFiles = [...fileUrlAccessory];
      updatedFiles[index] = updatedFiles[index].filter((file) => file.id !== id); // Filter out the deleted image
      console.log("Updated fileUrlAccessory after deletion:", updatedFiles);
      setFileUrlAccessory(updatedFiles);

    } catch (error) {
      console.error("Error deleting the image:", error);
    }
  };





  const handleDescription = (e) => {
    const value = e.target.value.trim();
    const regex = /^[a-zA-Z0-9.,'"\s-]*$/;
    if (value === "") {
      setDescription(value);
      setIsDescriptionError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setDescription(value);
      setIsDescriptionError(null);
    } else {
      setIsDescriptionError("Enter a valid description");
    }
  };



  const handleTotalAmountSpent = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError(null);
    } else {
      setIsTotalAmountSpentError("Enter a valid amount");
    }
  };





  const handleAddExpenditure = () => {
    setExpenditures([...expenditures, { ...newExpenditure }]);
    setNewExpenditure({
      description: '',
      totalAmountSpent: '',
      expImage: []
    });
  };


  const handleRemoveExpenditure = (index) => {
    const updatedExpenditures = expenditures.filter((_, i) => i !== index);
    setExpenditures(updatedExpenditures);

    const updatedFileUrlAccessory = fileUrlAccessory.filter((_, i) => i !== index);
    setFileUrlAccessory(updatedFileUrlAccessory);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewExpenditure({ ...newExpenditure, [name]: value });
  };





  const handleTotalAmountSpentObject = (e, index) => {
    const value = e.target.value;
    // Allow only numbers, decimals, and empty values
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(value)) {
      let updatedExpenditures = [...expenditures];
      updatedExpenditures[index].totalAmountSpent = value;
      setExpenditures(updatedExpenditures);
    }
  };

  const handleDescriptionObject = (e, index) => {
    const value = e.target.value;
    // Disallow numbers in the reason description
    const regex = /^[a-zA-Z0-9.,"\s-]*$/;

    if (regex.test(value)) {
      let updatedExpenditures = [...expenditures];
      updatedExpenditures[index].description = value;
      setExpenditures(updatedExpenditures);
    }
  };


  const handleClick = (value) => {
    setAmcCmc(value);
  };


  const validateExpenses = (expenditures) => {
    const errors = expenditures.map((expense, index) => {
      let error = {};

      if (!expense.description || expense.description.trim() === "") {
        error.error = `Please fill description`;
      }

      if (!expense.totalAmountSpent || expense.totalAmountSpent.trim() === "") {
        error.error = `Please fill total amount spent`;
      }

      if (
        !expense.expImage ||
        expense.expImage.length === 0 ||
        !expense.expImage[0].image ||
        expense.expImage[0].image.trim() === ""
      ) {
        error.error = `Please add image`;
      }

      return Object.keys(error).length ? error : null;
    });

    return errors.filter((error) => error !== null);
  };

  let expend = validateExpenses(expenditures)?.length;
  let expError = validateExpenses(expenditures);



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isLaptopView = useMediaQuery('(min-width:1024px)');

  const isStepComplete = (step) => {
    switch (step) {
      case 0:
        return name && email && company && mobile && !nameError && !isEmailError && !isCompanyError && !isMobileNumberError;
      default:
        return false;
    }
  };


  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 4 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        // compressImage(file);
        setIsPhotoError(`Can't upload image more than 4MB.`)
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: params.id + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(serviceImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const isGuest = userAccess && userAccess.role === 'Guest';
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ bgcolor: "white" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}>
              <Stack
                sx={{
                  width: "50%",
                  height: "100%",
                  gap: "15px",
                  textAlign: "start",
                  '@media (max-width: 600px)': {
                    width: "100%",
                  },
                }}>
                <Stack>
                  <Typography
                    sx={{
                      marginBottom: "20px",
                      color: "rgba(70, 144, 255, 1)",
                      fontWeight: "700",
                    }}>
                    Representative
                  </Typography>
                  <Field
                    value={name}
                    label="Name "
                    onChange={handleName}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    required
                  />

                  <HelperText variant="caption">{nameError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    value={email}
                    label="Email ID"
                    onChange={handleEmail}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    required
                  />

                  <HelperText variant="caption">{isEmailError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    value={company}
                    label="Company"
                    onChange={handleCompany}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    required
                  />

                  <HelperText variant="caption">{isCompanyError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    value={mobile}
                    label="Mobile No."
                    onChange={handleNumber}
                    variant="filled"
                    
                    InputProps={{ disableUnderline: true,
                      onKeyPress: (e) => {
                        if (!/^[0-9+]*$/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                     }}
                    required
                  />

                  <HelperText variant="caption">
                    {isMobileNumberError}
                  </HelperText>
                </Stack>
                <Stack>
                  <Field
                    label="Software Version"
                    value={software}
                    onChange={(e) => setSoftware(e.target.value)}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Box>
        );
      case 1:
        return (
          <Stack sx={{ bgcolor: "white", gap: "15px" }}>
            <h3 style={{ textAlign: 'left' }}>Issues</h3>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
              }}>

              <Stack
                sx={{
                  width: "100%",

                  gap: "15px",

                  height: "100%",
                  textAlign: "start",
                }}>

                <Stack sx={{ gap: "10px" }}>
                  <Label>Problem Identified</Label>
                  <hr style={{ border: "1px solid #ccc", margin: "0", padding: "0" }} />
                  <Field
                    multiline
                    rows={3}
                    // label="Problem Identified"
                    onChange={handleIssue}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    sx={{ width: "100%" }}
                    value={issue}
                    inputProps={{ maxLength: 120 }}
                    placeholder="Problem Identified (0/120 characters)"
                  />
                  <HelperText>{isIssueError}</HelperText>
                </Stack>

                <Stack sx={{ gap: "10px" }}>
                  <Label>Action Taken</Label>
                  <hr style={{ border: "1px solid #ccc", margin: "0", padding: "0" }} />
                  <Field
                    multiline
                    rows={3}
                    value={action}
                    // label="Action Taken"
                    onChange={handleAction}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    sx={{ width: "100%" }}
                    inputProps={{ maxLength: 120 }}
                    placeholder="Action Taken (0/120 characters)"
                  />
                  <HelperText>{isActionError}</HelperText>
                </Stack>

              </Stack>

              <Stack
                sx={{
                  width: "100%",
                  gap: "3px",
                  height: "100%",
                  textAlign: "start",
                  display: isMobile ? 'none' : 'flex',
                }}
              >
                <Label>Services</Label>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center' }}>
                      AMC/CMC
                      <hr style={{ border: '1px solid #ccc', margin: '0', padding: '0' }} />
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" justifyContent="center" spacing={2}>
                      {isMobile ? (
                        <>
                          <FormControlLabel
                            control={<Checkbox checked={amcCmc === 'yes'} onChange={() => handleClick('yes')} />}
                            label="Yes"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={amcCmc === 'no'} onChange={() => handleClick('no')} />}
                            label="No"
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            style={{ borderRadius: '20px' }}
                            variant={amcCmc === 'yes' ? 'contained' : 'outlined'}
                            onClick={() => handleClick('yes')}
                          >
                            Yes
                          </Button>
                          <Button
                            style={{ borderRadius: '20px' }}
                            variant={amcCmc === 'no' ? 'contained' : 'outlined'}
                            onClick={() => handleClick('no')}
                          >
                            No
                          </Button>
                        </>
                      )}
                    </Stack>
                    {amccmError && <HelperText>{amccmError}</HelperText>}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center' }}>
                      Status
                      <hr style={{ border: '1px solid #ccc', margin: '0', padding: '0' }} />
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" justifyContent="center" spacing={2}>
                      {isMobile ? (
                        <>
                          <FormControlLabel
                            control={<Checkbox checked={status === 'Fixed'} onChange={() => handleStatusChange('Fixed')} />}
                            label="Fixed"
                          />
                          <FormControlLabel
                            style={{ marginLeft: '0' }}
                            control={<Checkbox checked={status === 'UnFixed'} onChange={() => handleStatusChange('UnFixed')} />}
                            label="UnFixed"
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            style={{ borderRadius: '20px' }}
                            variant={status === 'Fixed' ? 'contained' : 'outlined'}
                            onClick={() => handleStatusChange('Fixed')}
                          >
                            Fixed
                          </Button>
                          <Button
                            style={{ borderRadius: '20px' }}
                            variant={status === 'UnFixed' ? 'contained' : 'outlined'}
                            onClick={() => handleStatusChange('UnFixed')}
                          >
                            UnFixed
                          </Button>
                        </>
                      )}
                    </Stack>
                    {isStatusError && <HelperText>{isStatusError}</HelperText>}
                  </Grid>
                </Grid>

                {showError && (!amcCmc || !status) && (
    <HelperText>Please fill both AMC/CMC and Status fields</HelperText>
  )}
              </Stack>

            </Stack>
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  textAlign: "start",
                }}
              >
                <Stack sx={{ gap: "10px" }}>
                  <Label>Remarks</Label>
                  <hr style={{ border: "1px solid #ccc", margin: "0", padding: "0" }} />
                  <Field
                    multiline
                    rows={3}
                    onChange={handleRemarkChange}
                    // onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    sx={{ width: "100%" }}
                    inputProps={{ maxLength: 120 }}
                    placeholder="Action Taken (0/120 characters)"
                  />
                </Stack>
                {<HelperText>{isRemarkError}</HelperText>}
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  textAlign: "start",
                }}
              >
                <Stack sx={{ gap: "3px", height: "100%" }}>
                  <Stack
                    sx={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Label>Upload Images</Label>
                    {uploadedImageName?.length < 3 && (
                      <>
                        <label htmlFor="img">
                          <FileUploadIcon
                            size={20}
                            style={{
                              color: "rgba(27, 37, 53, 1)",
                              cursor: "pointer",
                            }}
                          />
                        </label>
                        <input
                          type="file"
                          name="uploadfile"
                          id="img"
                          style={{ display: "none" }}
                          onChange={handlePhotoUpload}
                        />
                      </>
                    )}
                  </Stack>
                  <hr style={{ border: "1px solid #ccc", marginTop: "0", padding: "0" }} />
                  <Stack
                    sx={{
                      height: "93px",
                      flexDirection: "row",
                      overflowX: "auto",
                      overflowY: "hidden",
                      gap: "20px",
                      alignItems: "center",
                      background: "rgba(244, 246, 246, 1)",
                      paddingLeft: "20px",
                    }}
                  >
                    {fileUrl?.map((item, index) => (
                      <Badge
                        key={index}
                        badgeContent={
                          <CloseIcon
                            size={15}
                            style={{
                              color: " rgba(255, 58, 68, 1)",
                            }}
                            onClick={() => handleDelete(item?.imageid)}
                          />
                        }
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10px",
                          padding: "5px",
                        }}
                      >
                        <Box
                          as="img"
                          src={item?.image}
                          alt=""
                          sx={{ width: "50px", height: "50px" }}
                        />
                      </Badge>
                    ))}
                  </Stack>
                  {isPhotoError && (
                    <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
                  )}
                </Stack>
              </Grid>
            </Grid>


            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                paddingTop: "10px",
                paddingBottom: "10px",
                alignItems: "center",
                justifyContent: "space-between",
                display: isMobile ? 'none' : 'flex',
              }}>
              <Label style={{ color: "#4690FF", textAlign: "left", fontWeight: 'bold' }}>
                Expenditure
              </Label>

              <Button
                style={{}}
                onClick={handleAddExpenditure}
                variant="outlined"
                sx={{
                  color: 'white',
                  borderRadius: "25px",
                  background: 'rgb(70 144 255)',
                  '&:hover': {
                    borderColor: "rgb(70 144 255)",
                    color: 'rgb(70 144 255)',
                  },
                }}
              >
                Add Expenditure
              </Button>


            </Stack>
            <hr style={{ border: "1px solid #ccc", marginTop: "0", padding: '0' }} />


            {expenditures.map((item, index) => (
              <Stack key={index} sx={{ display: "flex", gap: "30px" }}>
                <Stack sx={{
                  justifyContent: "space-between", flexDirection: "row", gap: "30px", display: {
                    xs: "grid",
                    sm: "grid",
                    md: "flex",
                  },
                }}>
                  <Stack sx={{ gap: "10px", width: "50%", textAlign: "start" }}>
                    <Field
                      value={item.description || ""} // Make sure value is tied to item.description
                      placeholder="Reason description"
                      onChange={(e) => handleDescriptionObject(e, index)}
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                     <HelperText>
                      {item.description
                        ? ""
                        : "Please fill in the reason description."}
                    </HelperText>
                  </Stack>

                  <Stack sx={{ gap: "10px", width: "50%", textAlign: "start" }}>
                    <Field
                      value={item.totalAmountSpent || ""} // Make sure value is tied to item.totalAmountSpent
                      placeholder="Total amount spent"
                      onChange={(e) => handleTotalAmountSpentObject(e, index)}
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                    <HelperText>
                      {item.totalAmountSpent
                        ? ""
                        : "Please fill in the Total amount spent."}
                    </HelperText>
                  </Stack>
                </Stack>

                <Stack sx={{ justifyContent: "space-between", flexDirection: "row", gap: "30px" }}>
                  <Stack sx={{
                    width: {
                      xs: '100%',
                      sm: '100%', md: '50%', lg: '50%', xl: '50%',
                    }, gap: "3px", height: "100%", textAlign: "start"
                  }}>
                    <Stack sx={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                      <Label>Upload Receipt (Png, jpeg or pdf)</Label>
                      {/* {fileUrlAccessory.length < 3 && ( */}
                      <>
                        <label htmlFor={`img-${index}`}>
                          <FileUploadIcon
                            size={20}
                            style={{ color: "rgba(27, 37, 53, 1)", cursor: "pointer" }}
                          />
                        </label>
                        <input
                          type="file"
                          id={`img-${index}`}
                          style={{ display: "none", width: "42px", height: "42px" }}
                          accept="image/*,.pdf"
                          onChange={(e) => handleFileUpload(e, index)}
                        />
                      </>
                      {/* )} */}
                    </Stack>

                    <Stack
                      sx={{
                        height: "93px",
                        width: {
                          xs: '100%',
                          sm: '100%', md: '100%', lg: '100%', xl: '100%',
                        },
                        flexDirection: "row",
                        overflowX: "auto",
                        overflowY: "hidden",
                        gap: "20px",
                        alignItems: "center",
                        background: "rgba(244, 246, 246, 1)",
                        // paddingLeft: "20px",
                      }}
                    >
                      {fileUrlAccessory[index]?.map((file, fileIndex) => (
                        <Badge
                          key={file.id} // Ensure this is unique
                          badgeContent={
                            <CloseIcon
                              size={15}
                              style={{ color: "rgba(255, 58, 68, 1)" }}
                              onClick={() => handleDeleteAccessory(file.id, index, fileIndex)}
                            />
                          }
                          sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px", padding: "5px" }}
                        >
                          {file.type.startsWith('image') ? (
                            <Box
                              as="img"
                              src={file.url}
                              alt="uploaded-file"
                              sx={{ width: "50px", height: "50px" }}
                            />
                          ) : (
                            <Box
                              sx={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center", background: "gray" }}
                            >
                              <span>PDF</span>
                            </Box>
                          )}
                        </Badge>
                      ))}

                    </Stack>
                    {(!fileUrlAccessory[index] || fileUrlAccessory[index].length === 0) && (
                      <HelperText sx={{ color: "red" }}>
                        Please upload an image
                      </HelperText>
                    )}

                    {isAccessoryPhotoError && (
                      <HelperText sx={{ color: "red" }}>{isAccessoryPhotoError}</HelperText>
                    )}
                  </Stack>
                </Stack>

                <Stack sx={{ justifyContent: "end", flexDirection: "row", gap: "30px" }}>
                  <Button onClick={() => handleRemoveExpenditure(index)} variant="outlined" sx={{ borderRadius: '25px' }}>Delete Expenditure</Button>
                </Stack>

                <Divider />
              </Stack>
            ))}
          </Stack>

        );
      case 2:
        if (isMobile) {
          return (
            <Stack sx={{ bgcolor: "white", gap: "15px", padding: "10px" }}>
              <Typography variant="h6" sx={{ color: "black", fontWeight: "700", justifyContent: 'start', display: 'flex' }}>
                Services
              </Typography>


              <Stack sx={{ gap: "10px", marginLeft: '20px' }}>
                {/* <Label>Services</Label> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ backgroundColor: '#f4f6f6', marginBottom: '10px', }}>
                    <FormLabel sx={{ width: '100%', fontWeight: 'bold', justifyContent: 'start', display: 'flex' }}>
                      AMC/CMC
                    </FormLabel>
                    <Stack direction="row" justifyContent="space-around" spacing={2}>
                      <FormControlLabel
                        control={<Checkbox checked={amcCmc === 'yes'} onChange={() => handleClick('yes')} />}
                        label="AMC"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={amcCmc === 'no'} onChange={() => handleClick('no')} />}
                        label="CMC"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} style={{ backgroundColor: '#f4f6f6' }}>
                    <FormLabel sx={{ width: '100%', fontWeight: 'bold', justifyContent: 'start', display: 'flex' }}>
                      Status
                    </FormLabel>
                    <Stack direction="row" justifyContent="space-around" spacing={2}>
                      <FormControlLabel
                        control={<Checkbox checked={status === 'Fixed'} onChange={() => handleStatusChange('Fixed')} />}
                        label="Fixed"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={status === 'UnFixed'} onChange={() => handleStatusChange('UnFixed')} />}
                        label="UnFixed"
                      />
                    </Stack>
                    {showError && (!amcCmc || !status) && (
    <HelperText>Please fill both AMC/CMC and Status fields</HelperText>
  )}
                  </Grid>
                </Grid>
              </Stack>


              <Stack sx={{ gap: "10px", }}>
                <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <h3 style={{ color: "black", fontWeight: "700" }}>
                    Expenditure
                  </h3>
                  <Button
                    onClick={handleAddExpenditure}
                    variant="outlined"
                    fullWidth
                    style={{ width: 'auto' }}
                    sx={{
                      color: 'white',
                      borderRadius: "25px",
                      background: 'rgb(70 144 255)',
                      '&:hover': {
                        borderColor: "rgb(70 144 255)",
                        color: 'rgb(70 144 255)',
                        width: 'auto'
                      },
                    }}
                  >
                    Add
                  </Button>
                </Stack>



                {expenditures.map((item, index) => (
                  <Stack key={index} sx={{ display: "flex", gap: "30px" }}>
                    <Stack sx={{ justifyContent: "space-between", flexDirection: "row", gap: "30px" }}>
                      <Stack sx={{ gap: "10px", width: "50%", textAlign: "start" }}>
                        <Field
                          value={item.description || ""} // Make sure value is tied to item.description
                          placeholder="Reason description"
                          onChange={(e) => handleDescriptionObject(e, index)}
                          variant="outlined"
                        />
                        <HelperText>{isDescriptionError}</HelperText>
                      </Stack>

                      <Stack sx={{ gap: "10px", width: "50%", textAlign: "start" }}>
                        <Field
                          value={item.totalAmountSpent || ""} // Make sure value is tied to item.totalAmountSpent
                          placeholder="Total amount spent"
                          onChange={(e) => handleTotalAmountSpentObject(e, index)}
                          variant="outlined"
                        />
                        <HelperText>{isTotalAmountSpentError}</HelperText>
                      </Stack>
                    </Stack>

                    <Stack sx={{ justifyContent: "space-between", flexDirection: "row", gap: "30px" }}>
                      <Stack sx={{
                        width: {
                          xs: '100%',
                          sm: '100%', md: '50%', lg: '50%', xl: '50%',
                        }, gap: "3px", height: "100%", textAlign: "start"
                      }}>
                        <Stack sx={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                          <Label>Upload Receipt (Png, jpeg or pdf)</Label>
                          {/* {fileUrlAccessory.length < 3 && ( */}
                          <>
                            <label htmlFor={`img-${index}`}>
                              <FileUploadIcon
                                size={20}
                                style={{ color: "rgba(27, 37, 53, 1)", cursor: "pointer" }}
                              />
                            </label>
                            <input
                              type="file"
                              id={`img-${index}`}
                              style={{ display: "none", width: "42px", height: "42px" }}
                              accept="image/*,.pdf"
                              onChange={(e) => handleFileUpload(e, index)}
                            />
                          </>
                          {/* )} */}
                        </Stack>

                        <Stack
                          sx={{
                            height: "93px",
                            width: {
                              xs: '100%',
                              sm: '100%', md: '100%', lg: '100%', xl: '100%',
                            },
                            flexDirection: "row",
                            overflowX: "auto",
                            overflowY: "hidden",
                            gap: "20px",
                            alignItems: "center",
                            background: "rgba(244, 246, 246, 1)",
                            // paddingLeft: "20px",
                          }}
                        >
                          {fileUrlAccessory[index]?.map((file, fileIndex) => (
                            <Badge
                              key={file.id} // Ensure this is unique
                              badgeContent={
                                <CloseIcon
                                  size={15}
                                  style={{ color: "rgba(255, 58, 68, 1)" }}
                                  onClick={() => handleDeleteAccessory(file.id, index, fileIndex)}
                                />
                              }
                              sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px", padding: "5px" }}
                            >
                              {file.type.startsWith('image') ? (
                                <Box
                                  as="img"
                                  src={file.url}
                                  alt="uploaded-file"
                                  sx={{ width: "50px", height: "50px" }}
                                />
                              ) : (
                                <Box
                                  sx={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center", background: "gray" }}
                                >
                                  <span>PDF</span>
                                </Box>
                              )}
                            </Badge>
                          ))}

                        </Stack>
                        {(!fileUrlAccessory[index] || fileUrlAccessory[index].length === 0) && (
                          <HelperText sx={{ color: "red" }}>
                            Please upload an image
                          </HelperText>
                        )}

                        {isAccessoryPhotoError && (
                          <HelperText sx={{ color: "red" }}>{isAccessoryPhotoError}</HelperText>
                        )}
                      </Stack>
                    </Stack>

                    <Stack sx={{ justifyContent: "end", flexDirection: "row", gap: "30px" }}>
                      <Button onClick={() => handleRemoveExpenditure(index)} variant="outlined" sx={{ borderRadius: '25px' }}>Delete Expenditure</Button>
                    </Stack>

                    <Divider />
                  </Stack>
                ))}

                {/* {expenditures.length === 0 && <Typography>No expenditures added</Typography>} */}
              </Stack>
            </Stack>
          );
        }
      default:
        return null;
    }
  };



  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    navigate('/service-list')
  }


  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "auto",

        height: "100%",
      }}>
      <Stack
        sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '80%',
            lg: '60%',
            xl: '50%',
          },

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack

          sx={{
            display: "flex",

            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label variant="subtitle1" color="primary">
            Clear Service
          </Label>
          {/* <IconButton>
            <Calculate color="primary" />
          </IconButton> */}
        </Stack>

        <Stack
          sx={{
            width: "100%",
            // maxWidth: 800,
            margin: "auto",
            bgcolor: "white",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <BlueStepper
            activeStep={activeStep}
            nonLinear
            style={{
              height: "70px",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Step>
              <StepLabel>Step 1</StepLabel>
            </Step>
            <Step>
              <StepLabel>Step 2</StepLabel>
            </Step>
            {isMobile && (
              <Step>
                <StepLabel>Step 3</StepLabel>
              </Step>
            )}
          </BlueStepper>

          <Box sx={{ marginTop: 2, width: "90%" }}>
            {getStepContent(activeStep)}

            <>
              <Stack
                sx={{
                  flexShrink: 1,
                  width: "100%",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  justifyContent: "end",
                  margin: "10px",
                  marginBottom: '10px',
                  gap: "10px",
                }}
              >
                {activeStep > 0 && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "154px",
                      borderRadius: "22px",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                  >
                    Back
                  </Button>
                )}

                {(activeStep === 2 && isMobile) || (activeStep === 1 && !isMobile) ? (
                  <Button
                  disabled={isGuest}
                    onClick={handleClicks}
                    variant="contained"
                    sx={{
                      width: "154px",
                      borderRadius: "22px",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                  // disabled={!isStepComplete(activeStep) || expend > 0 || expenditures.length === 0}
                  >
                    Clear Service
                  </Button>
                ) : (
                  <Button
                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                    variant="contained"
                    sx={{
                      width: "154px",
                      borderRadius: "22px",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                    disabled={isLaptopView ? !isStepComplete(activeStep) : false}
                  >
                    Next
                  </Button>
                )}
              </Stack>
            </>
          </Box>
        </Stack>
      </Stack>
      <SuccessPopUp
        openModal={open}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful!"}
        subText1={"Service cleared."}
        subText2={"  "}
      />
    </Stack>
  );
};

export default ClearService;
