import * as React from "react";
import { Box, Button, Divider, Stack, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserListPage from "./UserListPage";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
export default function UserMobile() {
  const navigate = useNavigate();


  const [userPageCount, setUserPageCount] = React.useState({
    user:0,
    completed:0,
    calender:0,
    expiring:0
  })


  const handleDataChange = (childData) => {
    setUserPageCount({
      user:childData.user,
      completed:childData.completed,
      calender:childData.calender,
      expiring:childData.expiring
    });
  };





  return (
    <>
    <Stack
      sx={{
        width: "100%",
        // height: "100vh",
        marginTop:'-11px',
       
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        bgcolor: "#f5f5f5",
        gap: "30px",
        paddingBottom:'11px',
      }}>
      <Stack
        sx={{
         
          height: "130px",
          bgcolor: "#1B2535",
          width: "100%",
          color: "white",
        }}>
        <Stack
          justifyContent={"space-between"}
          sx={{ flexDirection: "row", p: 2, alignItems: "end", height: "90%" }}>
          <Label variant="h4">Users.</Label>
          <Label variant="h6"  onClick={() => navigate("/calendar")}>Assign</Label>
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "80%",
          height: "45%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          gap: "10px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/user-mobile")}
            sx={{
              cursor: "pointer",
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {userPageCount?.user}
            </Label>
            <Label variant="h6">Users</Label>
          </Stack>
          <Stack
            onClick={() => navigate("/user-mobile")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {userPageCount?.completed}
            </Label>
            <Label variant="h6">Completed</Label>
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/calendar")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {userPageCount?.calender}
            </Label>
            <Label variant="h6">Calendar</Label>
          </Stack>
          <Stack
            onClick={() => navigate("/calendar")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {userPageCount?.expiring} 
            </Label>
            <Label variant="h6">Expiring</Label>
          </Stack>
        </Box>
      </Box>
      <Divider sx={{ width: "90%" }} />
      <Button
        variant="contained"
        size="large"
        sx={{ borderRadius: "22px", width: "80%" }}
        onClick={() => navigate("/calendar")}>
       Calendar
      </Button>
    </Stack>

    <UserListPage handleDataChange={handleDataChange}  />
    </>
  );
}
