import {
  Box,
  Button,
  Table,
  TableCell,
  TableRow,
  Typography,
  styled,
  Stack,
  IconButton,
  Divider,
  TableBody,
  TableContainer,
  Badge,
} from "@mui/material";

import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DownloadPDF, checkInOutDetail } from "../redux/data/action";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

const MainDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  // border:"1px solid green",
  width: "1030px",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Download = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "35px",
  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    height: "40px",
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "40px",
  },
  [theme.breakpoints.down("xs")]: {
    height: "40px",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  // border: "2px solid green",
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "70%",
  justifyContent: "center",
  alignItems: "center",
  // height: '1163px',
  // padding: "20px",
  borderRadius: "30px",
  gap: "10px",
  paddingBottom: "20px",

  [theme.breakpoints.down("xl")]: {
    width: "70%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // padding: "2px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  // border: "2px solid black",

  width: "90%",
  marginBottom: "10px",
  marginTop: "2%",
  // display:'flex',
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const TableDivider = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  color: "#1746A2",
  fontSize: "26px",
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "22px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const TableElement = styled(TableCell)(({ theme }) => ({
  textTransform: "none",
  height: "1px",
  fontSize: "18px",
  padding: "6px",
  textAlign: "left",
  width: "50%",
  borderBottom: "0",

  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    // padding: "3px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const DivideRow = styled(TableDivider)(({ theme }) => ({
  textTransform: "none",
  height: "1px",
  fontSize: "22px",
  padding: "2px",
  paddingRight: "5px",
  // margginTop:'20px',
  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const MainTable = styled(Table)(({ theme }) => ({
  // border: "1px solid red",
  textTransform: "none",
  height: "170px",
  position: "static",
  paddingBottom: "10px",

  borderBottom: "0",
}));

function CheckinOutDetailPage() {
  const userData = useSelector((store) => store.data.checkInOutDataDetail);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const dispatch = useDispatch();
  const params = useParams();
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
      checkid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(checkInOutDetail(data));
  }, [dispatch]);



  const TableCells = styled(TableCell)(({ theme }) => ({
    textDecoration: "none",
    borderBottom: "none",
    padding: "10px",
    fontWeight: 500,
    whiteSpace: "wrap",
    wordWrap: "break-word",
    maxWidth: "60px",
  }));
  const Label = styled(Typography)(({ theme }) => ({
    color: "rgba(27, 37, 53, 1)",
    fontWeight: 700,
  }));


  const Wrapper = styled(Stack)(({ theme }) => ({
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",


    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const InnerSet = styled(Stack)(({ theme }) => ({
    width: "750px",

    borderRadius: "8px",

    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",

    gap: "10px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "100%",
      // flexDirection:'column',
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const BottomSet = styled(Stack)(({ theme }) => ({
    width: "750px",
    height: "70px",
    padding: "20px",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0px",
      paddingTop: "20px",
      // flexDirection:'column',
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));



  const TextSet = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    backgroundColor: "white",

    padding: "10px 20px 10px 20px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      // width: "100%",
      // flexDirection:'column',
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const InnerComponent = styled(Stack)(({ theme }) => ({

    width: "100%",
    flexDirection: 'row',
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: 'column',
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const LeftComponent = styled(Box)(({ theme }) => ({
    width: "60%",
    flexDirection: "row",
    gap: "20px",

    justifyContent: "space-between",


    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: 'column',
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const RightComponent = styled(Box)(({ theme }) => ({
    width: "40%",
    flexDirection: "row",
    gap: "20px",

    justifyContent: "space-between",


    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: 'column',
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  return (
    <Box sx={{ padding: '10px' }}>
      <Stack>
        <Wrapper>
          <InnerSet>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "white",
                padding: "10px 20px 10px 20px",
              }}>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}>
                Checked In
              </Label>

              <Label sx={{ color: "#FF6B00" }}>
              </Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <LeftComponent sx={{ width: "60%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}>
                  <Table sx={{ maxWidth: "100%" }}>
                    <TableBody>
                      {/* {Object.entries(HospitalLeftDataOpen).map(
                        ([key, value]) => ( */}
                      <TableRow>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{
                            color: "rgba(27, 37, 53, 1)",
                            fontWeight: 700,
                          }}>
                          Asset Name
                        </TableCells>
                        <p>{userData?.assetname}</p>
                        
                      </TableRow>
                      <TableRow>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{
                            color: "rgba(27, 37, 53, 1)",
                            fontWeight: 700,
                          }}>
                          Asset Code
                        </TableCells>
                        <p>{userData?.assetcode}</p>
                      </TableRow>
                      <TableRow>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{
                            color: "rgba(27, 37, 53, 1)",
                            fontWeight: 700,
                          }}>
                          Department
                        </TableCells>
                        <p>{userData?.depart}</p>
                      </TableRow>
                    </TableBody>

                  </Table>
                </TableContainer>
              </LeftComponent>

              <Stack
                sx={{
                  textAlign: "start",
                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Check out Details</Label>
                <Divider />
              </Stack>
              <InnerComponent>
                <LeftComponent sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        <TableRow>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 700,
                            }}>
                            Contact No.
                          </TableCells>
                          <p>{userData?.tomobile}</p>
                        </TableRow>
                        <TableRow>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 700,
                            }}>
                            Email ID
                          </TableCells>
                          <p>{userData?.toemail}</p>
                        </TableRow>
                        <TableRow>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 700,
                            }}>
                            Checked out by
                          </TableCells>
                          <p>{userData?.tousername
                          }</p>
                        </TableRow>
                        <TableRow>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 700,
                            }}>
                            Checked out on
                          </TableCells>
                          <p>{userData?.checkoutdate}
                           <br /> @ {userData?.checkouttime}</p>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LeftComponent>
                <RightComponent sx={{ width: "40%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        <TableRow>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 700,
                            }}>
                            Destination
                          </TableCells>
                          <p>{userData?.toplace}</p>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </RightComponent>
              </InnerComponent>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Check In Details</Label>
                <Divider />
              </Stack>
              <InnerComponent>
                <LeftComponent sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        <TableRow>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 700,
                            }}>
                            Checked In by
                          </TableCells>
                          <p>{userData?.receiver}</p>
                        </TableRow>
                        <TableRow>
                          <TableCells
                            component="th"
                            scope="row"
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              fontWeight: 700,
                            }}>
                            Checked In on
                          </TableCells>
                          <p>{userData?.checkindate}
                           <br /> @ {userData?.checkintime}</p>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </TableContainer>
                </LeftComponent>
              </InnerComponent>
            </Stack>

          </InnerSet>
          <Stack alignItems={"end"} sx={{
            width: {
              xs: "100%",
              sm: "50%",
            },
          }} >
            {userData?.receiver !== "NA" && (
              <Box sx={{ marginTop: "2%" }}>
                <Download
                  sx={{
                    bgcolor: "#4690FF",
                    color: "white",
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "#3c52b2",
                    },
                  }}
                  onClick={() =>
                    dispatch(
                      DownloadPDF(userData?.report_url, userData?.assetname)
                    )
                  }>
                  <FileDownloadIcon /> Download &nbsp;
                </Download>
              </Box>
            )}
          </Stack>



        </Wrapper>

      </Stack>


    </Box>

    // <MainDiv>
    //   <TopContainer>
    //     <TitleHead></TitleHead>
    //   </TopContainer>
    //   <InnerContainer sx={{ bgcolor: "white" }}>
    //     <Typography
    //       variant="h5"
    //       sx={{
    //         bgcolor: "#2E58AA;",
    //         borderRadius: " 15px 15px 0px 0px",
    //         width: "100%",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         color: "white",
    //         height: "50px",
    //       }}>
    //       Asset Information
    //     </Typography>

    //     <TextContainer>
    //       <MainTable sx={{ position: "static" }}>
    //         <TableRow sx={{ height: "70px" }}>
    //           <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //             Asset Name
    //           </TableElement>
    //           <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //           <TableElement
    //             component="th"
    //             scope="row"
    //             style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
    //             {userData?.assetname}
    //           </TableElement>
    //         </TableRow>
    //         <TableRow sx={{ height: "70px" }}>
    //           <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //             Asset code
    //           </TableElement>
    //           <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //           <TableElement
    //             component="th"
    //             scope="row"
    //             style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
    //             {userData?.assetcode}
    //           </TableElement>
    //         </TableRow>
    //         <TableRow sx={{ height: "70px" }}>
    //           <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //             Department
    //           </TableElement>
    //           <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //           <TableElement>{userData?.depart}</TableElement>
    //         </TableRow>

    //         <TableRow sx={{ height: "70px" }}>
    //           <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //             Product Destination
    //           </TableElement>
    //           <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //           <TableElement>{userData?.toplace}</TableElement>
    //         </TableRow>

    //         <TableRow sx={{ height: "70px" }}>
    //           <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //             Contact
    //           </TableElement>
    //           <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //           <TableElement>{userData?.tomobile}</TableElement>
    //         </TableRow>
    //         <TableRow sx={{ height: "70px" }}>
    //           <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //             Email
    //           </TableElement>
    //           <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //           <TableElement>{userData?.toemail}</TableElement>
    //         </TableRow>
    //         <TableRow sx={{ height: "70px" }}>
    //           <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //             Checked out user
    //           </TableElement>
    //           <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //           <TableElement
    //             style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
    //             {userData?.sender}
    //           </TableElement>
    //         </TableRow>
    //         <TableRow sx={{ height: "70px" }}>
    //           <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //             Checked out date
    //           </TableElement>
    //           <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //           <TableElement>
    //             {userData?.checkoutdate},{userData?.checkouttime}
    //           </TableElement>
    //         </TableRow>

    //         {userData?.receiver !== "NA" && (
    //           <>
    //             <TableRow sx={{ height: "70px" }}>
    //               <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //                 Checked in user
    //               </TableElement>
    //               <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //               <TableElement>{userData?.receiver} </TableElement>
    //             </TableRow>
    //             <TableRow sx={{ height: "70px" }}>
    //               <TableElement sx={{ color: "#1746A2", whiteSpace: "nowrap" }}>
    //                 Checked in date
    //               </TableElement>
    //               <DivideRow sx={{ color: "#1746A2" }}>:</DivideRow>
    //               <TableElement
    //                 style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
    //                 {userData?.checkindate},{userData?.checkintime}
    //               </TableElement>
    //             </TableRow>
    //           </>
    //         )}
    //       </MainTable>
    //       {userData?.receiver !== "NA" && (
    //         <Box sx={{ marginTop: "2%" }}>
    //           <Download
    //             sx={{
    //               bgcolor: "#1746A2",
    //               color: "white",
    //               "&:hover": {
    //                 color: "#fff",
    //                 backgroundColor: "#3c52b2",
    //               },
    //             }}
    //             onClick={() =>
    //               dispatch(
    //                 DownloadPDF(userData?.report_url, userData?.assetname)
    //               )
    //             }>
    //             Download Pdf &nbsp; <FileDownloadIcon />
    //           </Download>
    //         </Box>
    //       )}
    //     </TextContainer>
    //     <Box
    //       sx={{
    //         width: "90%",
    //         display: "flex",
    //         justifyContent: "end",
    //         alignItems: "end",
    //       }}>
    //       <Button
    //         sx={{ color: "#FF731D", textTransform: "none" }}
    //         onClick={() => navigate(-1)}>
    //         Close
    //       </Button>
    //     </Box>
    //   </InnerContainer>
    // </MainDiv>
  );
}

export default CheckinOutDetailPage;
