import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { Link, } from "react-router-dom";
import { Stack, Typography, styled, Tooltip ,  Button,} from "@mui/material";
import { useState } from "react";
import {
    assetsdiscarded,
} from "../redux/data/action";
import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));

const Discarded = () => {
    const discardedassets = useSelector((store) => store.data.discardedassets);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);


  useEffect(() => {
    const fetchData = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(assetsdiscarded(fetchData, token));
  }, [dispatch]);

  // Updating data when API data changes
  useEffect(() => {
    if (discardedassets?.length > 0) {
      setData(discardedassets);
    }
  }, [discardedassets]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      //   justifyContent="space-between"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px">
      <Box display="flex" flexDirection="column" gap="10px">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "none", lg: "none" } }}
        >
          <Typography variant="h6">
          Discarded
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Typography>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px">
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              display: { xs: "none", sm: "flex", lg: "flex" },
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Typography>Total :</Typography>
            <Typography
              sx={{ color: "#4690FF" }}>{` ${data?.length}`}</Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={""}
              Filter1="department"
              Filter2=""
              FilterName1="Department"
              FilterName2=""
              sortDate=""
              dueDate=""
              sortPrice=""
              name="name"
              csvName="Not Working Assets"
            />
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Table stickyHeader>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                
                  <TableHeading>Asset Name</TableHeading>
                  <TableHeading>Asset Code</TableHeading>
                  <TableHeading>Brand</TableHeading>
                  <TableHeading>Department</TableHeading>
                  <TableHeading sx={{ textAlign: "center" }}>
                    Status
                  </TableHeading>
                  <TableHeading>Date</TableHeading>

                  <TableHeading>AMC/CMC</TableHeading>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: "scroll" }}>
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <TableRow key={item.assetid}>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.name}>{item.name}</Tooltip>
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.brand}>{item.brand}</Tooltip>
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.department}>
                          {item.department}
                        </Tooltip>
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                        sx={{
                          textAlign: "center",
                          color: "rgba(70, 144, 255, 1)",
                        }}
                      >
                        {item?.status !== "Working" ? (
                          <Button
                            size="small"
                            sx={{
                              borderRadius: "35px",
                              textTransform: "none",
                              width: "100px",
                              height: "25px",
                              bgcolor: "rgba(255, 107, 0, 1)",
                              "&:hover": {
                                bgcolor: "rgba(255, 107, 0, 1)",
                              },
                            }}
                            variant="contained"
                          >
                            {item.status}
                          </Button>
                        ) : (
                          <>{item.status}</>
                        )}
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        {item.cmcamcdate}
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        {item.cmcamc}
                      </TableCells>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      No Discarded Assets
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Discarded;
