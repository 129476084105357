import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AddIcon from "@mui/icons-material/Add";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { v4 as uuid } from "uuid";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import SuccessModal from "../components/SuccessModal";
import {
  assetDetailsData,
  createService,
  createServiceImageUpload,
  serviceRequestImageRemove,
} from "../redux/nbmData/action";

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const NotesInput = styled(OutlinedInput)(({ theme }) => ({
  border: "1px solid rgba(126, 126, 126, 0.3)",
  width: "100%",
  height: "170px",
  borderRadius: "20px",
  bgcolor: "#EFF5FE",
  [theme.breakpoints.down("xl")]: {
    height: "170px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "330px",
    height: "200px",
  },
  [theme.breakpoints.down("sm")]: {
    // width: "330px",
    height: "200px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  justifyItems: "center",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: "100%",
  // border: "3px solid brown",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(1,1fr)",
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
    // border: "3px solid red",
    fontSize: "16px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1,1fr)",
    width: "100%",
    // border: "3px solid blue",
  },
  [theme.breakpoints.down("xs")]: {
    gridTemplateColumns: "repeat(1,1fr)",
    width: "100%",
    // border: "3px solid yellow",
  },
}));

const Main = styled(Box)(({ theme }) => ({
  // border: "2px solid red",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  bgcolor: "white",
  padding: "20px",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  borderRadius: "30px",
  marginTop: "1%",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "20px",
  },
}));

const NotesTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",

  marginTop: "30px",
  color: "#FF731D",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  // marginTop: "2%",
  textAlign: "left",
  // border: "1px solid blue",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    // border: "3px solid blue",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const BottomElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // marginTop: "4%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const OuterBox = styled(Box)(({ theme }) => ({
  // border: "6px solid green",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
  width: "80%",
  // padding: "20px",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    height: "auto",
    width: "90%",
    padding: "5px",
  },
}));
const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  textTransform: "none",
  bgcolor: "#ADC2EC",

  color: "#1746A2",
  height: "60px",
  "&:hover": {
    backgroundColor: "#ADC2EC",
    color: "#1746A2",
  },
  [theme.breakpoints.down("xl")]: {
    height: "45px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingBottom: "40px",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadImageBox = styled(Box)(({ theme }) => ({
  // border: "4px solid blue",

  padding: "10px",
  display: "flex",
  alignItems: "center",

  borderRadius: "8px",
  boxShadow: "0px 0px 2px #EAF1FF",
  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    // border: "4px solid blue",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  marginLeft: "8px",
  width: "40px",
  height: "40px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    marginLeft: "4px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const DotedBox = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ImageOuterBox = styled(Box)(({ theme }) => ({
  //

  height: "120px",
  boxShadow: "0px 0px 2px #EAF1FF",
  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const MainContainer = styled(Box)(({ theme }) => ({
  //
  paddingTop: "20px",
  // paddingBottom: "40px",
  width: "100%",
  bgcolor: "#F4F6F6",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
}));
const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "80%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Photo = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));
const BtnWrapper2 = styled(Stack)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "2%",
  marginBottom: "2%",
  borderRadius: "15px",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",

  //
  [theme.breakpoints.down("xl")]: {
    width: "100%",

    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "150px",

    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "150px",

    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column-reverse",
    padding: "2%",
    alignItems: "center",
    height: "100px",
    marginTop: "45px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));
const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // border: "3px solid red",
  width: "100%",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: { width: "100%" },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    //justifyContent: "center",
  },
}));

const Boxes = styled(Box)(({ theme }) => ({
  display: "flex",
  // border: "2px solid red",
  marginTop: "2%",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // border: "1px solid orange",
    width: "100%",
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
    width: "100%",
    // border: "1px solid black",
  },
  [theme.breakpoints.down("xs")]: {},
}));

function ServiceMedpick() {
  const detailData = useSelector((store) => store?.nbmData?.assetDetails);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const response = useSelector((store) => store.nbmData.createService);

  const token = localStorage.getItem("userToken");

  const params = useParams();
  const [fileUrl, setFileUrl] = useState([]);
  const [description, setDescription] = useState("");
  const [isPhotoError, setIsPhotoError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const text = "Service requested";
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    navigate(-1);
  };
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);

  const navigate = useNavigate();
  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 2048 * 2048; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        //setIsPhotoError("Greater than 2 MB can't be uploaded");

        // Compress the image if size is greater than 2MB
        compressImage(file);

        //return false;
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: `${"assetId_"}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(createServiceImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${"assetId_"}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: `${"assetId_"}` + uuid() + ".jpg",
                image: reader.result,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(createServiceImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    if (!e.target.value) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError("");
    }
  };

  const handleSubmit = () => {
    if (!description || description?.length < 3) {
      setDescriptionError("Please enter a valid description");
    } else {
      setDescriptionError("");
    }
    if (description !== "" && description?.length >= 3) {
      let data = {
        hospid: ids?.hospid,
        hospname: ids?.hospname,
        userid: ids?.userid,
        username: ids?.username,
        branchid: userBranch?.id,
        usermail: ids?.email,
        userphone: ids?.mobile,
        assetid: params?.id,
        assetname: detailData?.name,
        brand: detailData?.brand,
        departname: detailData?.dept,
        serialno: detailData?.serialno,
        modelno: detailData?.modelno,
        issuedetail: description,
        issuedetail_img: uploadedImageName,
        request_email: "contact@vajra.software",
        assetcode: detailData.code,
        departid: detailData?.departid,
      };

      setOpenModal(true);
      setToggle(true);
      dispatch(createService(data, token));
    }
  };
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const handleDelete = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
    };
    dispatch(serviceRequestImageRemove(data));
    setFileUrl(fileUrl?.filter((el) => el?.imageid !== id));
  };

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      assetid: params?.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (response === "Inserted" && description !== "") {
      setOpenModal(true);
    }
  }, [response]);
  return (
    <>
      <MainContainer sx={{}}>
        <TopContainer>
          <TitleHead>Service Request</TitleHead>
        </TopContainer>

        <OuterBox sx={{}}>
          <Main sx={{ bgcolor: "white" }}>
            <FormWrapper>
              <FormElement sx={{}}>
                <Boxes
                  sx={{
                    display: "flex",
                  }}>
                  <Box
                    sx={{
                      minWidth: "120px",
                      width: { sm: "40px" },
                    }}>
                    <Typography variant="body2" sx={{ color: "#1746A2" }}>
                      Name
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ textAlign: "left" }}>
                    :&nbsp;{detailData?.name}
                  </Typography>
                </Boxes>
              </FormElement>
              <FormElement>
                <Boxes sx={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      minWidth: "120px",
                      width: { sm: "10px" },
                      color: "#1746A2",
                    }}>
                    Model no
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: "left" }}>
                    :&nbsp;{detailData?.modelno}
                  </Typography>
                </Boxes>
              </FormElement>
              <FormElement>
                <Boxes sx={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      minWidth: "120px",
                      width: { sm: "40px" },
                      color: "#1746A2",
                    }}>
                    Serial no
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: "left" }}>
                    :&nbsp;{detailData?.serialno}
                  </Typography>
                </Boxes>
              </FormElement>
              <FormElement>
                <Boxes sx={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      minWidth: "120px",
                      width: { sm: "40px" },
                      color: "#1746A2",
                    }}>
                    Brand
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: "left" }}>
                    :&nbsp;{detailData?.brand}
                  </Typography>
                </Boxes>
              </FormElement>
              <FormElement>
                <Boxes sx={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      minWidth: "120px",
                      width: { sm: "40px" },
                      color: "#1746A2",
                    }}>
                    Asset code
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: "left" }}>
                    :&nbsp;{detailData?.code}
                  </Typography>
                </Boxes>
              </FormElement>
            </FormWrapper>
            <BottomElement sx={{}}>
              <FormElement sx={{}}>
                <NotesTextLabel sx={{ typography: "body1" }}>
                  Describe the issue shortly
                </NotesTextLabel>
                <NotesInput
                  placeholder="Describe the issue"
                  value={description}
                  multiline
                  maxRows={10}
                  onChange={handleDescription}
                  sx={{ bgcolor: "#EFF5FE", "& fieldset": { border: "none" } }}
                />

                <FormHelperText sx={{ color: "red" }}>
                  {descriptionError}
                </FormHelperText>
              </FormElement>
            </BottomElement>
          </Main>

          <UploadContainer sx={{}}>
            <>
              <ButtonWrapper sx={{}}>
                <>
                  <UploadButton
                    onClick={() => setPhotoFlag(!photoFlag)}
                    sx={{
                      bgcolor: "#ADC2EC",
                      fontWeight: "500",
                    }}>
                    <AddPhotoAlternateIcon
                      sx={{ color: "#1746A2", fontSize: "40px" }}
                    />
                    Upload Photos
                  </UploadButton>
                </>
              </ButtonWrapper>
              {photoFlag && (
                <>
                  <ButtonWrapper
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <ImageOuterBox
                      sx={{
                        textAlign: "start",
                        color: "#1746A2",
                      }}>
                      Upload photos
                      <UploadImageBox
                        sx={{ border: " 1px solid rgba(23, 70, 162, 0.2)" }}>
                        {uploadedImageName?.length < 3 && (
                          <>
                            <DotedBox>
                              <label for="img">
                                {" "}
                                <AddIcon
                                  sx={{ marginTop: "2%", cursor: "pointer" }}
                                />
                              </label>
                            </DotedBox>
                            <input
                              type="file"
                              name="uploadfile"
                              id="img"
                              style={{
                                display: "none",

                                width: "42px",
                                height: "42px",
                              }}
                              onChange={handlePhotoUpload}
                            />
                          </>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          {fileUrl?.map((item, index) => (
                            <ImageBox key={index} className="image-item">
                              <Badge
                                sx={{ color: "rgba(255, 115, 29, 0.5)" }}
                                badgeContent={
                                  <RemoveCircleIcon
                                    sx={{
                                      width: "10px",
                                      height: "10px",
                                      color: "red",
                                    }}
                                    onClick={() => handleDelete(item?.imageid)}
                                  />
                                }>
                                <Photo as="img" src={item?.image} alt={""} />
                              </Badge>
                            </ImageBox>
                          ))}
                        </Box>
                      </UploadImageBox>
                      <Box style={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        <>
                          {photoFlag && (
                            <FormHelperText sx={{ color: "red" }}>
                              format should be either in jpg, jpeg or png
                            </FormHelperText>
                          )}
                        </>
                        {isPhotoError && (
                          <FormHelperText sx={{ color: "red" }}>
                            {isPhotoError}
                          </FormHelperText>
                        )}
                      </Box>
                    </ImageOuterBox>
                  </ButtonWrapper>
                </>
              )}

              <BtnWrapper2 sx={{}}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "35px",
                    bgcolor: "white",
                    textTransform: "none",
                    color: "#3c52b2",
                    border: "1px solid #1746A2",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#3c52b2",
                    },
                  }}
                  onClick={() => navigate(-1)}
                  size="large">
                  Cancel
                </Button>

                <Button
                  onClick={handleSubmit}
                  sx={{
                    borderRadius: "35px",
                    textTransform: "none",
                    bgcolor: "#1746A2",
                  }}
                  variant="contained"
                  size="large">
                  Submit
                </Button>
              </BtnWrapper2>
            </>
          </UploadContainer>
        </OuterBox>
        <SuccessModal
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          response={response}
          text={text}
          toggle={toggle}
        />
      </MainContainer>
    </>
  );
}

export default ServiceMedpick;
