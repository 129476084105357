// import {
//   Autocomplete,
//   Box,
//   Button,
//   Card,
//   CardActions,
//   CardContent,
//   CardMedia,
//   Checkbox,
//   Container,
//   FormControlLabel,
//   FormGroup,
//   IconButton,
//   InputAdornment,
//   Menu,
//   MenuItem,
//   OutlinedInput,
//   Paper,
//   TextField,
//   Typography,
//   styled,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";

// import TableRow from "@mui/material/TableRow";
// import emp from "../images/emp.png";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

// import { useDispatch, useSelector } from "react-redux";
// import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
// import SwapVertIcon from '@mui/icons-material/SwapVert';
// import {
//   bulkDepartmentUpdate,
//   deleteDepart,
//   departmentAssetDetails,
//   getDepartmentData,
// } from "../redux/data/action";
// import TableContainer from "@mui/material/TableContainer";

// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

// import Pagination from "../components/Pagination";
// import SuccessModal from "../components/SuccessModal";
// import AssetMovementModal from "../components/AssetMovementModal";
// import SimpleModal from "../components/SimpleModal";

// const Wrapper = styled(Box)(({ theme }) => ({
//   width: "100%",

//   display: "flex",
//   minHeight: "400px",

//   padding: "10px",
//   flexDirection: "column",
//   alignItems: "center",
//   background: "#F4F6F6",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const TableCells = styled(TableCell)(({ theme }) => ({
//   maxWidth: "11ch",
//   overflow: "hidden",
//   textOverflow: "ellipsis",
//   whiteSpace: "nowrap",
//   textDecoration: "none",
//   borderBottom: "none",
//   cursor: "pointer",
//   padding: "10px",
//   fontWeight: 500,
// }));

// const TableHeading = styled(TableCell)(({ theme }) => ({
//   color: "rgba(27, 37, 53, 1)",
//   fontWeight: 700,
//   padding: "10px",
//   whiteSpace: "nowrap",
// }));


// const BoxWrapper = styled(Box)(({ theme }) => ({
//   marginTop: "10px",

//   width: "100%",
//   borderRadius: "15px",

//   background: "white",
//   [theme.breakpoints.down("xl")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "800px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "500px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "370px",
//   },
//   [theme.breakpoints.down("xs")]: {
//     width: "280px",
//   },
// }));

// // const TableHeading = styled(TableCell)(({ theme }) => ({
// //   color: "black",
// //   fontSize: "14px",
// //   fontWeight: 600,
// //   textAlign: "start",
// //   bgcolor: "rgba(40, 98, 212, 0.1)",
// //   // ,
// //   [theme.breakpoints.down("xl")]: {
// //     fontSize: "16px",
// //   },
// //   [theme.breakpoints.down("lg")]: {},
// //   [theme.breakpoints.down("md")]: {},
// //   [theme.breakpoints.down("sm")]: {
// //     fontSize: "14px",
// //   },
// //   [theme.breakpoints.down("xs")]: {},
// // }));

// const TableRows = styled(TableCell)(({ theme }) => ({
//   "&.MuiTableCell-root": {
//     fontSize: "18px",
//     fontWeight: "400",
//     color: "#212427",
//     textAlign: "start",
//     maxWidth: 130,
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     transition: "all 0.3s ease",
//     "&:hover": {
//       maxWidth: "none",
//       whiteSpace: "normal",
//       overflow: "visible",
//     },
//     [theme.breakpoints.down("xl")]: {
//       fontSize: "16px",
//     },
//     [theme.breakpoints.down("lg")]: {},
//     [theme.breakpoints.down("md")]: {},
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "12px",
//     },
//     [theme.breakpoints.down("xs")]: {},
//   },
// }));

// const TopContainer = styled(Box)(({ theme }) => ({
//   //
//   width: "100%",
//   display: "flex",
//   background:'white',
//   justifyContent: "space-between",
//   alignItems: "center",
//   padding:'10px',

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {
//     // width: "800px",
//   },
//   [theme.breakpoints.down("md")]: {
//     flexDirection: "column",
//     // width: "500px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("xs")]: {
//     width: "100%",
//   },
// }));
// const TitleHead = styled(Typography)(({ theme }) => ({
//   fontSize: "24px",
//   fonteight: "400",
//   color: "black",
//   [theme.breakpoints.down("xl")]: {
//     fonteight: "400",
//     fontSize: "22px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     fonteight: "400",
//     fontSize: "22px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("md")]: {
//     fonteight: "500",
//     fontSize: "18px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     fonteight: "500",
//     fontSize: "16px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));
// const SortFilterContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   justifyContent:'space-between',
//   gap: "10px",
//   // padding: "10px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {
//     gap: "10px",
//   },
//   [theme.breakpoints.down("md")]: {
//     // flexDirection: "row",
//     width: "98%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     flexDirection: "column",
//     width: "98%",
//     justifyContent: "end",
//     alignItems: "end",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const SFContainer = styled(Box)(({ theme }) => ({
//   display: "flex",

//   alignItems: "end",
//   gap: "10px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     flexDirection: "row",
//     width: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     flexDirection: "row",
//     width: "100%",
//     justifyContent: "end",
//     alignItems: "end",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const ColumnSet = styled(Box)(({ theme }) => ({
//   display: "flex",

//   alignItems: "start",
//   gap: "10px",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {
//     justifyContent: "left",
//     alignItems: "start",
//   },
//   [theme.breakpoints.down("md")]: {
//     // flexDirection: "row",

//     width: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     // flexDirection: "row",
//     width: "100%",
//     justifyContent: "end",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const Search = styled(OutlinedInput)(({ theme }) => ({
//   width: 230,
//   height: "40px",
//   borderRadius: "36px",
//   border: "1px solid rgba(23, 70, 162, 0.5)",
//   bgcolor: "white",
//   "& fieldset": { border: "none" },

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "140px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "150px",
//     height: "35px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterInnerContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: "10px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterMain = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: "10px",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterText = styled(Typography)(({ theme }) => ({
//   fontSize: "18px",
//   color: "#1746A2",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FormWrapper = styled(FormGroup)(({ theme }) => ({
//   display: "grid",
//   gridTemplateColumns: "repeat(2,1fr)",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));
// const Cards = styled(Card)(({ theme }) => ({
//   width: "688px",
//   height: "351px",
//   fontFamily: "Poppins",
//   fontStyle: "normal",
//   fontWeight: "500",
//   fontSize: "18px",
//   boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {
//     width: "80%",
//     height: "400px",
//     fontSize: "16px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "80%",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "80%",
//     // border: "1px solid orange",
//     marginTop: "2%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     marginTop: "2%",

//     fontSize: "18px",

//     width: "80%",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));
// const TitleBox = styled(Typography)(({ theme }) => ({
//   width:"100px",
//   height:'100%',
//   display: "flex",
//   flexDirection: "column",
//   justifyContent:'center',
//   alignItems: "center",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
    
//   },
//   [theme.breakpoints.down("sm")]: {
  
//   },
//   [theme.breakpoints.down("xs")]: {
   
//   },
// }));

// const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
//   width: "230px",
//   height: "40px",
//   borderRadius: "36px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));
// const Boxes = styled(Box)(({ theme }) => ({
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     display: "flex",
//     flexDirection: "row-reverse",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));
// const DepartmentAssetDelete = () => {
//   const departAssetData = useSelector(
//     (store) => store.data.departmentAssetDetails
//   );
//   let userAccess =
//     JSON.parse(sessionStorage.getItem("user_access")) ||
//     JSON.parse(localStorage.getItem("user_access"));
//   const deleted = useSelector((store) => store.data.deleteAsset);
//   const token = localStorage.getItem("userToken");
//   let userIds = JSON.parse(localStorage.getItem("userData"));
//   const bulkDepartmentStatus = useSelector(
//     (store) => store.data.bulkDepartmentUpdate
//   );
//   const departmentData = useSelector((store) => store.data.department) || [];
//   const departmentAsset = localStorage.getItem("selectedDepartname");
//   const bulkChange = localStorage.getItem("bulkChange");
//   let userBranch =
//     JSON.parse(sessionStorage.getItem("userBranch")) ||
//     JSON.parse(localStorage.getItem("userBranch"));
//   const [selectAll, setSelectAll] = useState(false);
//   const [show, setShow] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const { id } = useParams();
//   let simpleText =
//     "Choose Department and select assets, then move it for a seamless asset relocation.";

//   const [filterdDepartment, setFilterdDepartment] = useState([]);
//   const dispatch = useDispatch();
//   const [data, setData] = useState([]);
//   const [selectedRow, setSelectedRow] = useState(0);
//   const navigate = useNavigate();

//   const [selectedData, setSelectedData] = useState({});
//   const [assetData, setAssetData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedCategories, setSelectedCategories] = useState([]);
//   const [selectedStatus, setSelectedStatus] = useState([]);
//   const [anchorElSort, setAnchorElSort] = React.useState(null);
//   const [anchorElFilter, setAnchorElFilter] = React.useState(null);
//   const [filterChanged, setFilterChanged] = useState(false);
//   const [question, setQuestion] = useState(false);
//   const [toggleDelete, setToggleDelete] = useState(false);
//   const openSort = Boolean(anchorElSort);
//   const openFilter = Boolean(anchorElFilter);
//   const [selectedAssetIds, setSelectedAssetIds] = useState([]);
//   const [selectedAssetName, setSelectedAssetName] = useState([]);
//   const [selectedAssetCode, setSelectedAssetCode] = useState([]);
//   const [successToggle, setSuccessToggle] = useState(false);
//   const [filteredDepartment, setFilteredDepartment] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(7);

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const amccmc = [...new Set(departAssetData?.map((item) => item.cmcamc))];

//   const status = [...new Set(departAssetData?.map((item) => item.status))];

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//     setPage(0);
//     setFilterChanged(true);
//   };
//   const handleChangePage = (event, newPage) => {
//     if (filterChanged) {
//       setPage(0);
//       setFilterChanged(false);
//     } else {
//       const filteredIncidents = filterProducts();
//       const newPageIndexStart = newPage * rowsPerPage;
//       const newPageIndexEnd = newPageIndexStart + rowsPerPage;
//       const currentPageIncidents = filteredIncidents.slice(
//         newPageIndexStart,
//         newPageIndexEnd
//       );

//       const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
//       if (newPage >= newPageCount) {
//         newPage = newPageCount - 1;
//       }

//       setPage(newPage);
//     }
//   };
//   const handleCategoryChange = (e) => {
//     const category = e.target.value;
//     setSelectedCategories((prevCategories) =>
//       prevCategories?.includes(category)
//         ? prevCategories?.filter((c) => c !== category)
//         : [...prevCategories, category]
//     );
//     setPage(0);
//     setFilterChanged(true);
//   };
//   const handleStatusChange = (e) => {
//     const status = e.target.value;
//     setSelectedStatus((prevCategories) =>
//       prevCategories?.includes(status)
//         ? prevCategories?.filter((c) => c !== status)
//         : [...prevCategories, status]
//     );
//     setPage(0);
//     setFilterChanged(true);
//   };

//   const filterProducts = () => {
//     let filteredProducts = departAssetData;

//     filteredProducts = filteredProducts?.filter(
//       (product) =>
//         product?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
//     );

//     if (selectedCategories?.length > 0) {
//       filteredProducts = filteredProducts?.filter((product) =>
//         selectedCategories?.includes(product.cmcamc)
//       );
//     }

//     if (selectedStatus?.length > 0) {
//       filteredProducts = filteredProducts?.filter((product) =>
//         selectedStatus?.includes(product.status)
//       );
//     }
//     return filteredProducts;
//   };

//   const filteredProducts = filterProducts();

//   const handleFilter = (event) => {
//     setAnchorElFilter(event.currentTarget);
//   };

//   const handleCloseSort = () => {
//     setAnchorElSort(null);
//   };

//   const handleSort = (event) => {
//     setAnchorElSort(event.currentTarget);
//   };
//   const handleCloseFilter = () => {
//     setAnchorElFilter(null);
//   };

//   const sortHighToLow = () => {
//     let sorted = assetData.sort((a, b) => {
//       if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
//         const dateA = new Date(a.cmcamcdate?.split("-").reverse().join("-"));
//         const dateB = new Date(b.cmcamcdate?.split("-").reverse().join("-"));
//         return dateA - dateB;
//       } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
//         return 1;
//       } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
//         return -1;
//       } else {
//         return 0;
//       }
//     });

//     setAssetData(sorted);
//     handleCloseSort();
//   };
//   const sortLowToHigh = () => {
//     let sorted = assetData.sort((a, b) => {
//       if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
//         const dateA = new Date(a.cmcamcdate?.split("-").reverse().join("-"));
//         const dateB = new Date(b.cmcamcdate?.split("-").reverse().join("-"));
//         return dateB - dateA;
//       } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
//         return 1;
//       } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
//         return -1;
//       } else {
//         return 0;
//       }
//     });

//     setAssetData(sorted);
//     handleCloseSort();
//   };

//   const handleSelectRow = (rowIndex) => {
//     setSelectedRow(rowIndex);
//   };

//   useEffect(() => {
//     let data = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       deptid: id,
//       branchid: userBranch.id,
//     };

//     dispatch(departmentAssetDetails(data, token));
//   }, [dispatch, deleted, successToggle]);

//   useEffect(() => {
//     if (departAssetData && departAssetData?.length > 0) {
//       setData(departAssetData[0].department);
//       setAssetData(departAssetData);
//     }
//   }, [departAssetData, successToggle]);

//   useEffect(() => {
//     return () => {
//       localStorage.removeItem("selectedDepartname");
//     };
//   }, []);

//   useEffect(() => {
//     return () => {
//       localStorage.removeItem("bulkChange");
//     };
//   }, []);

//   useEffect(() => {
//     let data = {
//       hospid: userIds?.hospid,
//       userid: userIds?.userid,
//       branchid: userBranch.id,
//       depart_ids: userAccess.departmentids,
//     };
//     dispatch(getDepartmentData(data, token));
//   }, [dispatch, successToggle]);

//   useEffect(() => {
//     let filtered = departmentData?.filter((department) => {
//       return department?.departid !== id;
//     });

//     setFilteredDepartment([...filtered]);
//   }, []);

//   const sortDataByKey = (key, ascending) => {
//     let sorted = assetData.sort((a, b) => {
//       const valueA = a[key].trim().toLowerCase();
//       const valueB = b[key].trim().toLowerCase();
//       return ascending
//         ? valueA.localeCompare(valueB)
//         : valueB.localeCompare(valueA);
//     });
//     setAssetData(sorted);
//     handleCloseSort();
//   };

//   const handleDepartment = (event, value) => {
//     if (value !== null && value !== undefined) {
//       setSelectedData(value);
//     }
//     if (value === null) {
//       setSelectedData({});
//     }
//   };

//   const handleSelectRowClick = (assetId, assetName, assetCode) => {
//     const selectedIndex = selectedAssetIds.indexOf(assetId);
//     const selectedIndexName = selectedAssetName.indexOf(assetName);
//     const selectedIndexCode = selectedAssetCode.indexOf(assetCode);

//     let newSelected = [];
//     let newNameSelected = [];
//     let newCodeSelected = [];
//     if (
//       selectedIndex === -1 ||
//       newNameSelected === -1 ||
//       newCodeSelected === -1
//     ) {
//       // Asset is not selected, add it to the array
//       newSelected = [...selectedAssetIds, assetId];
//       newNameSelected = [...selectedAssetName, assetName];
//       newCodeSelected = [...selectedAssetCode, assetCode];
//     } else {
//       // Asset is selected, remove it from the array
//       newSelected = [
//         ...selectedAssetIds.slice(0, selectedIndex),
//         ...selectedAssetIds.slice(selectedIndex + 1),
//       ];
//       newNameSelected = [
//         ...selectedAssetName.slice(0, selectedIndexName),
//         ...selectedAssetName.slice(selectedIndexName + 1),
//       ];
//       newCodeSelected = [
//         ...selectedAssetCode.slice(0, selectedIndexCode),
//         ...selectedAssetCode.slice(selectedIndexCode + 1),
//       ];

//       if (newSelected?.length === 0) {
//         setSelectedData({});
//       }
//     }

//     setSelectedAssetIds(newSelected);
//     setSelectedAssetName(newNameSelected);
//     setSelectedAssetCode(newCodeSelected);
//   };

//   const handleSelectAllClick = () => {
//     const allAssetIds = assetData?.map((item) => item.assetid);
//     const allAssetName = assetData?.map((item) => item.name);
//     const allAssetCode = assetData?.map((item) => item.assetcode);

//     if (selectAll) {
//       setSelectedAssetIds([]);
//       setSelectedAssetName([]);
//       setSelectedAssetCode([]);
//       setSelectedData({});
//     } else {
//       setSelectedAssetIds(allAssetIds);
//       setSelectedAssetName(allAssetName);
//       setSelectedAssetCode(allAssetCode);
//     }

//     setSelectAll(!selectAll);
//   };

//   const handleMove = () => {
//     setQuestion(true);

//     // setToggleDelete(true)
//   };

//   const handleCloseSuccessModal = () => {
//     setSuccessToggle(false);
//   };

//   const handleMoveBulk = () => {
//     let data = {
//       hospid: userIds.hospid,
//       hospname: userIds.hospname,
//       userid: userIds.userid,
//       username: userIds.username,
//       assetids: selectedAssetIds,
//       assetnames: selectedAssetName,
//       assetcodes: selectedAssetCode,
//       p_depart: departmentAsset,
//       p_departid: id,
//       to_depart: selectedData.name,
//       to_departid: selectedData.id,
//       branchid: userBranch.id,
//     };

//     dispatch(bulkDepartmentUpdate(data));

//     setQuestion(false);
//     setSuccessToggle(true);
//     setSelectedData({});
//     setShow(false);
//     setSelectedAssetIds([]);
//     setSelectedAssetName([]);
//     setSelectedAssetCode([]);
//   };

//   const handleCloseQuestion = () => {
//     setQuestion(false);
//   };

//   const handleButton = () => {
//     setShow(true);
//     setShowModal(true);
//   };

//   const handleCloseSimple = () => {
//     // setShow(false)
//     setShowModal(false);
//   };

//   return (
//     <Wrapper>
//       <TopContainer>
//         <TitleBox>
         
//           {departAssetData?.length > 0 && (
//             <Typography
//               sx={{
//                 color: "black",
//               }}>{`Total : ${departAssetData?.length}`}</Typography>
//           )}
//         </TitleBox>

//         {departAssetData?.length > 0 && (
//           <>
//             <SortFilterContainer>
//               <SFContainer>
//                 {bulkChange && (
//                   <>
//                     <ColumnSet>
//                       {!show && (
//                         <Boxes>
//                           <Button
//                             variant="contained"
//                             disabled={selectedAssetIds?.length == 0}
//                             onClick={handleButton}
//                             sx={{
//                               // bgcolor: "#1746A2",
//                               textTransform: "none",
//                               width: "180px",
//                             }}>
//                             Select Department
//                           </Button>
//                         </Boxes>
//                       )}

//                       {show && selectedAssetIds?.length > 0 && (
//                         <CustomAutoComplete
//                           sx={{
//                             bgcolor: "transparent",
//                             "& .MuiOutlinedInput-root": {
//                               padding: "0px",
//                             },
//                           }}
//                           options={departmentData
//                             ?.filter((department) => {
//                               return department?.departid !== id;
//                             })
//                             ?.map((option) => ({
//                               name: option.departname,
//                               id: option?.departid,
//                             }))}
//                           // value={{ name: department, id:departmentId }}
//                           clearOnBlur={true}
//                           clearOnEscape={true}
//                           getOptionLabel={(option) => option.name}
//                           onChange={handleDepartment}
//                           renderInput={(params) => (
//                             <TextField
//                               sx={{
//                                 bgcolor: "transparent", // Add other styles as needed
//                               }}
//                               placeholder="e.g. Emergency Department"
//                               {...params}
//                             />
//                           )}
//                         />
//                       )}
//                     </ColumnSet>
//                   </>
//                 )}

//                 {show && selectedAssetIds?.length > 0 && (
//                   <Button
//                     disabled={Object.keys(selectedData)?.length === 0}
//                     sx={{
//                       borderRadius: "15px",
                     
//                       height: "40px",
//                     }}
//                     onClick={handleMove}
//                     variant="contained">
//                     Submit
//                   </Button>
//                 )}
//               </SFContainer>

//               <SFContainer>
//                 <Search
//                   type="search"
//                   placeholder="Search"
//                   value={searchQuery}
//                   onChange={handleSearchChange}
//                   startAdornment={
//                     <InputAdornment position="start">
//                       <IconButton
//                         placeholder="Search"
//                         aria-label="toggle password visibility"
//                         edge="start">
//                         <SearchOutlinedIcon />
//                       </IconButton>
//                     </InputAdornment>
//                   }
//                 />

//                 <div>
//                   <IconButton
//                     id="basic-button"
//                     aria-controls={openSort ? "basic-menu" : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={openSort ? "true" : undefined}
//                     onClick={handleSort}>
//                     <SwapVertIcon sx={{ color: "black" }} />
//                   </IconButton>
//                   <Menu
//                     id="basic-menu"
//                     anchorEl={anchorElSort}
//                     open={openSort}
//                     onClose={handleCloseSort}
//                     MenuListProps={{
//                       "aria-labelledby": "basic-button",
//                     }}>
//                     <MenuItem sx={{ color: "black" }}>Sort by Name</MenuItem>
//                     <MenuItem onClick={() => sortDataByKey("name", true)}>
//                       A-Z
//                     </MenuItem>
//                     <MenuItem onClick={() => sortDataByKey("name", false)}>
//                       Z-A
//                     </MenuItem>
//                     <MenuItem sx={{ color: "black" }}>Date Sort</MenuItem>
//                     <MenuItem onClick={sortLowToHigh}>
//                       Newest to oldest
//                     </MenuItem>
//                     <MenuItem onClick={sortHighToLow}>
//                       Oldest to newest
//                     </MenuItem>
//                   </Menu>
//                 </div>

//                 <div>
//                   <IconButton
//                     id="basic-button"
//                     aria-controls={openFilter ? "basic-menu" : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={openFilter ? "true" : undefined}
//                     onClick={handleFilter}>
//                     <FilterAltOutlinedIcon sx={{ color: "black" }} />
//                   </IconButton>
//                   <Menu
//                     id="basic-menu"
//                     anchorEl={anchorElFilter}
//                     open={openFilter}
//                     onClose={handleCloseFilter}
//                     MenuListProps={{
//                       "aria-labelledby": "basic-button",
//                     }}>
//                     <MenuItem>
//                       <FilterMain>
//                         <FilterText display="block">Department</FilterText>

//                         <FilterInnerContainer>
//                           <FormWrapper>
//                             {amccmc?.map((item) => (
//                               <FormControlLabel
//                                 control={
//                                   <Checkbox
//                                     color="warning"
//                                     type="checkbox"
//                                     value={item}
//                                     checked={selectedCategories.includes(item)}
//                                     onChange={handleCategoryChange}
//                                     name="gilad"
//                                   />
//                                 }
//                                 label={item}
//                               />
//                             ))}
//                           </FormWrapper>
//                         </FilterInnerContainer>
//                       </FilterMain>
//                     </MenuItem>
//                     <MenuItem>
//                       <FilterMain>
//                         <FilterText display="block">Working Status</FilterText>
//                         <FilterInnerContainer>
//                           <FormWrapper>
//                             {status?.map((item) => (
//                               <FormControlLabel
//                                 control={
//                                   <Checkbox
//                                     color="warning"
//                                     type="checkbox"
//                                     value={item}
//                                     checked={selectedStatus.includes(item)}
//                                     onChange={handleStatusChange}
//                                     name="gilad"
//                                   />
//                                 }
//                                 label={item}
//                               />
//                             ))}
//                           </FormWrapper>
//                         </FilterInnerContainer>
//                       </FilterMain>
//                     </MenuItem>
//                   </Menu>
//                 </div>
//               </SFContainer>
//             </SortFilterContainer>
//           </>
//         )}
//       </TopContainer>

//       {assetData?.length === 0 || filteredProducts?.length === 0 ? (
//         <BoxWrapper
//           style={{ overflowX: "auto" }}
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             // borderRadius: "15px ",
//             justifyContent: "center",
//             alignItems: "center",
//             minHeight: "500px",
//           }}>
//           <Cards>
//             <CardMedia
//               component="img"
//               sx={{ width: 151 }}
//               image={emp}
//               alt="green iguana"
//             />
//             <CardContent>
//               <Typography
//                 sx={{ color: " #212427" }}
//                 color="text.secondary"
//                 gutterBottom>
//                 There is no assets in this department. Please add asset.
//               </Typography>
//             </CardContent>
//             <CardActions>
//               <Button
//                 onClick={() => navigate("/add-asset")}
//                 sx={{
//                   borderRadius: "35px",
//                   textTransform: "none",
//                   // bgcolor: "#1746A2",
//                 }}
//                 variant="contained">
//                 Add Asset
//               </Button>
//             </CardActions>
//           </Cards>
//         </BoxWrapper>
//       ) : (
//         <>
//           <BoxWrapper style={{ overflowX: "auto", borderRadius:'0px' }}>
//             <Box sx={{ overflow: "auto" }}>
//               <TableContainer
//               component={Paper}
//               style={{
//                 height: "calc(90vh - 200px)",
//                 boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
//                 borderRadius:'0px'
//               }}
                
//                 >
//                 <Table
//                   stickyHeader
//                   sx={{
//                     borderRadius: "0px",
//                     bgcolor: "white",
//                     // border: "6px solid green",
//                     whiteSpace: "nowrap",
//                   }}>
//                   <TableHead
//                     // style={{ borderRadius: "30px" }}
//                     sx={{
//                       // borderRadius: "15px 15px 0px 0px",
//                     }}>
//                     <TableRow
//                       selected={selectedRow === 0}
//                       onClick={() => handleSelectRow(0)}
//                       sx={{
//                         // borderRadius: "15px 15px 0px 0px",
//                         bgcolor: "rgba(146, 25, 118, 0.1)",
//                       }}>
//                       {bulkChange && (
//                         <TableCell>
//                           <Checkbox
//                             color="primary"
//                             indeterminate={
//                               selectedAssetIds?.length > 0 &&
//                               selectedAssetIds?.length < assetData?.length
//                             }
//                             checked={
//                               selectedAssetIds?.length === assetData?.length
//                             }
//                             onChange={handleSelectAllClick}
//                             inputProps={{
//                               "aria-label": "select all assets",
//                             }}
//                           />
//                         </TableCell>
//                       )}

//                       <TableHeading
//                         sx={{
//                           // borderRadius: "15px 0px 0px 0px",
//                         }}>
//                         Asset Name
//                       </TableHeading>
//                       <TableHeading sx={{}}>Brand</TableHeading>
//                       <TableHeading sx={{}}>Asset Code</TableHeading>
//                       {/* <TableHeading sx={{}}>Department</TableHeading> */}
//                       <TableHeading sx={{ textAlign: "center" }}>
//                         Status
//                       </TableHeading>
//                       <TableHeading sx={{ textAlign: "center" }}>
//                         AMC/CMC
//                       </TableHeading>
//                       <TableHeading sx={{textAlign: "center"}}>Date</TableHeading>

//                       <TableHeading
//                         sx={{
//                           // borderRadius: "0px 15px 0px 0px",
//                         }}></TableHeading>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody sx={{ textDecoration: "none" }}>
//                     {filteredProducts?.map((item, index) => (
//                         <TableRow
//                           key={item.assetid}
//                           sx={{
//                             textDecoration: "none",
//                             "&:hover": {
//                               backgroundColor: "#E8ECF6",
//                             },
                           
//                           }}
//                           onClick={() => handleSelectRow(0)}>
//                           {bulkChange && (
//                             <TableCells  sx={{ textDecoration: "none" }} >
//                               <Checkbox
//                                 checked={selectedAssetIds.includes(
//                                   item.assetid
//                                 )}
//                                 onChange={() =>
//                                   handleSelectRowClick(
//                                     item.assetid,
//                                     item.name,
//                                     item.assetcode
//                                   )
//                                 }
//                               />
//                             </TableCells>
//                           )}

//                           <TableCells
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {" "}
//                             <Link
//                               style={{ textDecoration: "none", color: "black" }}
//                               sx={{
//                                 fontSize: {
//                                   xl: "18px",
//                                   lg: "16px",
//                                   md: "14px",
//                                   sm: "12px",
//                                   xs: "10px",
//                                 },
//                               }}
//                               component="button"
//                               variant="body2"
//                               // onClick={() => {
//                               //   console.info("I'm a button.");
//                               // }}

//                               to={`/asset-detail/${item?.assetid}`}>
//                               {item.name}
//                             </Link>
//                           </TableCells>
//                           <TableCells
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {item.brand}
//                           </TableCells>
//                           <TableCells
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {item.assetcode}
//                           </TableCells>

//                           {/* <TableCells sx={{}}>{item.department}</TableCells> */}
//                           <TableCells
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textAlign: "center", textDecoration: "none" }}
//                             style={{ whiteSpace: "nowrap" }}>
//                            {item?.status !== "Working" ? (
//                         <Button
//                           size="small"
//                           sx={{
//                             borderRadius: "35px",
//                             textTransform: "none",
//                             width: "90px",
//                             height: "25px",
//                             bgcolor: "rgba(255, 107, 0, 1)",
//                             "&:hover": {
//                               bgcolor: "rgba(255, 107, 0, 1)",
//                             },
//                           }}
//                           variant="contained">
//                           {item.status}
//                         </Button>
//                       ) : (
//                         <>{item.status}</>
//                       )}
//                           </TableCells>
//                           <TableCells
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textAlign: "center", textDecoration: "none" }}>
//                             {item.cmcamc}
//                           </TableCells>
//                           <TableCells
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textAlign: "center", textDecoration: "none" }}>
//                             {" "}
//                             {item.cmcamcdate}
//                           </TableCells>
//                           <TableCells
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {/* <cccccccccccc? */}
//                           </TableCells>
//                         </TableRow>
//                       ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//               {/* <Pagination
//                 filteredProducts={filteredProducts}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 handleChangePage={handleChangePage}
//                 handleChangeRowsPerPage={handleChangeRowsPerPage}
//               /> */}
//             </Box>
//           </BoxWrapper>
//         </>
//       )}

//       <SuccessModal
//         openModal={bulkDepartmentStatus}
//         handleCloseModal={handleCloseSuccessModal}
//         text={"Asset moved"}
//         toggle={successToggle}
//       />

//       <AssetMovementModal
//         open={question}
//         onClose={handleCloseQuestion}
//         onDelete={handleMoveBulk}
//         count={selectedAssetIds.length}
//         prevDep={departmentAsset}
//         current={selectedData.name}
//       />

//       {/* <SimpleModal openModal={showModal}  text={simpleText} closeModal={handleCloseSimple}   /> */}
//     </Wrapper>
//   );
// };

// export default DepartmentAssetDelete;

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  styled,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import SearchIcon from "@mui/icons-material/Search";

import TableRow from "@mui/material/TableRow";
import emp from "../images/emp.png";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { useDispatch, useSelector } from "react-redux";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  bulkDepartmentUpdate,
  deleteDepart,
  departmentAssetDetails,
  getDepartmentData,
} from "../redux/data/action";
import TableContainer from "@mui/material/TableContainer";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import Pagination from "../components/Pagination";
import SuccessModal from "../components/SuccessModal";
import AssetMovementModal from "../components/AssetMovementModal";
import SimpleModal from "../components/SimpleModal";
import SortSearchFilter from "../components/SortSearchFilter";

const Wrapper = styled(Box)(({ theme }) => ({
  // width: "100%",

  display: "flex",
  minHeight: "400px",

  padding: "10px",
  flexDirection: "column",
  alignItems: "center",
  background: "#F4F6F6",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  whiteSpace: "nowrap",
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "10px",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

// const TableHeading = styled(TableCell)(({ theme }) => ({
//   color: "black",
//   fontSize: "14px",
//   fontWeight: 600,
//   textAlign: "start",
//   bgcolor: "rgba(40, 98, 212, 0.1)",
//   // ,
//   [theme.breakpoints.down("xl")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",
  background: "white",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    // width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    // width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));
const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "black",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "16px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "10px",
  // padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    gap: "10px",
  },
  [theme.breakpoints.down("md")]: {
    // flexDirection: "row",
    width: "98%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "98%",
    justifyContent: "end",
    alignItems: "end",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SFContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "end",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "end",
    alignItems: "end",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ColumnSet = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "start",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    justifyContent: "left",
    alignItems: "start",
  },
  [theme.breakpoints.down("md")]: {
    // flexDirection: "row",

    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    // flexDirection: "row",
    width: "100%",
    justifyContent: "end",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  borderRadius: "36px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",
  "& fieldset": { border: "none" },

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
    height: "35px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
}));
const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  width: "230px",
  height: "40px",
  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Boxes = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const DepartmentAssetDelete = () => {
  const departAssetData = useSelector(
    (store) => store.data.departmentAssetDetails
  );
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const deleted = useSelector((store) => store.data.deleteAsset);
  const token = localStorage.getItem("userToken");
  let userIds = JSON.parse(localStorage.getItem("userData"));
  const bulkDepartmentStatus = useSelector(
    (store) => store.data.bulkDepartmentUpdate
  );
  const departmentData = useSelector((store) => store.data.department) || [];
  const departmentAsset = localStorage.getItem("bulkChange");
  const bulkChange = localStorage.getItem("bulkChange");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [selectAll, setSelectAll] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  let simpleText =
    "Choose Department and select assets, then move it for a seamless asset relocation.";

  const [filterdDepartment, setFilterdDepartment] = useState([]);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);

  const [selectedData, setSelectedData] = useState({});
  const [assetData, setAssetData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const [filterChanged, setFilterChanged] = useState(false);
  const [question, setQuestion] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const openSort = Boolean(anchorElSort);
  const openFilter = Boolean(anchorElFilter);
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectedAssetName, setSelectedAssetName] = useState([]);
  const [selectedAssetCode, setSelectedAssetCode] = useState([]);
  const [successToggle, setSuccessToggle] = useState(false);
  const [filteredDepartment, setFilteredDepartment] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const amccmc = [...new Set(departAssetData?.map((item) => item.cmcamc))];

  const status = [...new Set(departAssetData?.map((item) => item.status))];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories?.includes(category)
        ? prevCategories?.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories?.includes(status)
        ? prevCategories?.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = departAssetData;

    filteredProducts = filteredProducts?.filter(
      (product) =>
        product?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedCategories?.includes(product.cmcamc)
      );
    }

    if (selectedStatus?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedStatus?.includes(product.status)
      );
    }
    return filteredProducts;
  };
  const [searchTerm, setSearchTerm] = useState("");

  const filteredProducts = assetData.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.assetcode.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const sortHighToLow = () => {
    let sorted = assetData.sort((a, b) => {
      if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
        const dateA = new Date(a.cmcamcdate?.split("-").reverse().join("-"));
        const dateB = new Date(b.cmcamcdate?.split("-").reverse().join("-"));
        return dateA - dateB;
      } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
        return 1;
      } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setAssetData(sorted);
    handleCloseSort();
  };
  const sortLowToHigh = () => {
    let sorted = assetData.sort((a, b) => {
      if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
        const dateA = new Date(a.cmcamcdate?.split("-").reverse().join("-"));
        const dateB = new Date(b.cmcamcdate?.split("-").reverse().join("-"));
        return dateB - dateA;
      } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
        return 1;
      } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setAssetData(sorted);
    handleCloseSort();
  };

  const handleSelectRow = (rowIndex) => {
    setSelectedRow(rowIndex);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      deptid: id,
      branchid: userBranch.id,
    };

    dispatch(departmentAssetDetails(data, token));
  }, [dispatch, deleted, successToggle]);

  useEffect(() => {
    if (departAssetData && departAssetData?.length > 0) {
      setData(departAssetData[0].department);
      setAssetData(departAssetData);
    }
  }, [departAssetData, successToggle]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("selectedDepartname");
    };
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem("bulkChange");
    };
  }, []);

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch, successToggle]);

  useEffect(() => {
    let filtered = departmentData?.filter((department) => {
      return department?.departid !== id;
    });

    setFilteredDepartment([...filtered]);
  }, []);

  const sortDataByKey = (key, ascending) => {
    let sorted = assetData.sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setAssetData(sorted);
    handleCloseSort();
  };

  const handleDepartment = (event, value) => {
    if (value !== null && value !== undefined) {
      setSelectedData(value);
    }
    if (value === null) {
      setSelectedData({});
    }
  };

  const handleSelectRowClick = (assetId, assetName, assetCode) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    const selectedIndexName = selectedAssetName.indexOf(assetName);
    const selectedIndexCode = selectedAssetCode.indexOf(assetCode);

    let newSelected = [];
    let newNameSelected = [];
    let newCodeSelected = [];
    if (
      selectedIndex === -1 ||
      newNameSelected === -1 ||
      newCodeSelected === -1
    ) {
      // Asset is not selected, add it to the array
      newSelected = [...selectedAssetIds, assetId];
      newNameSelected = [...selectedAssetName, assetName];
      newCodeSelected = [...selectedAssetCode, assetCode];
    } else {
      // Asset is selected, remove it from the array
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1),
      ];
      newNameSelected = [
        ...selectedAssetName.slice(0, selectedIndexName),
        ...selectedAssetName.slice(selectedIndexName + 1),
      ];
      newCodeSelected = [
        ...selectedAssetCode.slice(0, selectedIndexCode),
        ...selectedAssetCode.slice(selectedIndexCode + 1),
      ];

      if (newSelected?.length === 0) {
        setSelectedData({});
      }
    }

    setSelectedAssetIds(newSelected);
    setSelectedAssetName(newNameSelected);
    setSelectedAssetCode(newCodeSelected);
  };

  const handleSelectAllClick = () => {
    const allAssetIds = assetData?.map((item) => item.assetid);
    const allAssetName = assetData?.map((item) => item.name);
    const allAssetCode = assetData?.map((item) => item.assetcode);

    if (selectAll) {
      setSelectedAssetIds([]);
      setSelectedAssetName([]);
      setSelectedAssetCode([]);
      setSelectedData({});
    } else {
      setSelectedAssetIds(allAssetIds);
      setSelectedAssetName(allAssetName);
      setSelectedAssetCode(allAssetCode);
    }

    // setSelectAll(!selectAll);
    setSelectAll(!selectAll);
  };

  const handleMove = () => {
    setQuestion(true);

    // setToggleDelete(true)
  };

  const handleCloseSuccessModal = () => {
    setSuccessToggle(false);
  };

  const handleMoveBulk = () => {
    const data = {
      hospid: userIds.hospid,
      hospname: userIds.hospname,
      userid: userIds.userid,
      username: userIds.username,
      assetids: selectedAssetIds,
      assetnames: selectedAssetName,
      assetcodes: selectedAssetCode,
      p_depart: departmentAsset,
      p_departid: id,
      to_depart: selectedData.name,
      to_departid: selectedData.id,
      branchid: userBranch.id,
    };

    dispatch(bulkDepartmentUpdate(data)); // Dispatch the action to move assets

    // Show the success modal after dispatching
    setOpenModal(true); // Open the modal

    // Resetting states
    setQuestion(false);
    setSelectedData({});
    setShow(false);
    setSelectedAssetIds([]);
    setSelectedAssetName([]);
    setSelectedAssetCode([]);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseQuestion = () => {
    setQuestion(false);
  };

  const handleButton = () => {
    setShow(true);
    setShowModal(true);
  };

  const handleCloseSimple = () => {
    // setShow(false)
    setShowModal(false);
  };
  const CustomSortSearchFilter = styled(SortSearchFilter)`
    & .MuiOutlinedInput-root {
      box-shadow: none !important;
    }
  `;

  const [openModal, setOpenModal] = useState(false);

  return (
    <Wrapper>
      <Box display="flex" sx={{ gap: "10px", width: "100%" }}>
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        >
          <Label variant="h6">
            Department.
            <span style={{ color: "#4690FF" }}>
              {" "}
              {`${departAssetData?.length}`}
            </span>
          </Label>
        </Stack>

        <Stack
          flexDirection={"row"}
          height="50px"
          justifyContent={"space-between"}
          alignItems={"center"}
          padding="16px"
          gap={"10px"}
          sx={{
            bgcolor: "white",
            boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            display: { xs: "none", sm: "none", lg: "flex" },
            width: "100px",
          }}
        >
          <Typography>{`Total : ${departAssetData?.length}`}</Typography>
        </Stack>
        <Stack
          flexDirection={"row"}
          height="50px"
          justifyContent={"space-between"}
          alignItems={"center"}
          padding="16px"
          gap={"10px"}
          sx={{
            bgcolor: "white",
            boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            display: { xs: "none", sm: "none", lg: "flex" },
            width: "100%",
          }}
        >
          {!show && (
            <Boxes>
              <Button
                variant="contained"
                disabled={selectedAssetIds?.length === 0}
                onClick={handleButton}
                sx={{
                  textTransform: "none",
                  width: "180px",
                }}
              >
                Select Department
              </Button>
            </Boxes>
          )}

          {departAssetData?.length > 0 && (
            <>
              <>
                <ColumnSet>
                  {show && selectedAssetIds?.length > 0 && (
                    <CustomAutoComplete
                      sx={{
                        bgcolor: "transparent",
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                        },
                      }}
                      options={departmentData
                        ?.filter((department) => department?.departid !== id)
                        ?.map((option) => ({
                          name: option.departname,
                          id: option?.departid,
                        }))}
                      clearOnBlur={true}
                      clearOnEscape={true}
                      getOptionLabel={(option) => option.name}
                      onChange={handleDepartment}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            bgcolor: "transparent",
                          }}
                          placeholder="e.g. Emergency Department"
                          {...params}
                        />
                      )}
                    />
                  )}
                </ColumnSet>
              </>

              <TextField
                placeholder="Search"
                variant="outlined"
                padding="0px"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                  backgroundColor: "transparent",
                  marginBottom: "10px",
                  width: "300px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Remove the default bordder
                    },
                    "&:hover fieldset": {
                      border: "none", // Keep it without border on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "none", // Remove border when focused
                    },
                  },
                  "& .MuiInputBase-root": {
                    borderBottom: "2px solid #d1d1d1", // Add underline style
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {show && selectedAssetIds?.length > 0 && (
                <Button
                  disabled={Object.keys(selectedData)?.length === 0}
                  sx={{
                    borderRadius: "15px",
                    height: "40px",
                  }}
                  onClick={handleMove}
                  variant="contained"
                >
                  Submit
                </Button>
              )}
            </>
          )}
        </Stack>
      </Box>
      <Box
        sx={{ display: { sm: "block", md: "none", lg: "none" }, width: "100%" }}
      >
        <Box sx={{ marginTop: "10px" }}>
          <TextField
            placeholder="Search"
            variant="outlined"
            padding="0px"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              backgroundColor: "transparent",
              marginBottom: "10px",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none", // Remove the default border
                },
                "&:hover fieldset": {
                  border: "none", // Keep it without border on hover
                },
                "&.Mui-focused fieldset": {
                  border: "none", // Remove border when focused
                },
              },
              "& .MuiInputBase-root": {
                borderBottom: "2px solid #d1d1d1", // Add underline style
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              marginBottom: "10px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            <Boxes
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {show && selectedAssetIds?.length > 0 && (
                <Button
                  disabled={Object.keys(selectedData)?.length === 0}
                  sx={{
                    borderRadius: "15px",
                    height: "40px",
                  }}
                  onClick={handleMove}
                  variant="contained"
                >
                  Submit
                </Button>
              )}
              <Button
                variant="contained"
                disabled={selectedAssetIds?.length === 0}
                onClick={handleButton}
                sx={{
                  textTransform: "none",
                  width: "180px",
                  marginRight: "15px",
                }}
              >
                Select Department
              </Button>
            </Boxes>
          </Box>
          <Box sx={{ width: "100%" }}>
            {departAssetData?.length > 0 && (
              <>
                <>
                  <ColumnSet sx={{ width: "100%" }}>
                    {show && selectedAssetIds?.length > 0 && (
                      <CustomAutoComplete
                        sx={{
                          bgcolor: "transparent",
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                          },
                        }}
                        options={departmentData
                          ?.filter((department) => department?.departid !== id)
                          ?.map((option) => ({
                            name: option.departname,
                            id: option?.departid,
                          }))}
                        clearOnBlur={true}
                        clearOnEscape={true}
                        getOptionLabel={(option) => option.name}
                        onChange={handleDepartment}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              bgcolor: "transparent",
                            }}
                            placeholder="e.g. Emergency Department"
                            {...params}
                          />
                        )}
                      />
                    )}
                  </ColumnSet>
                </>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {assetData?.length === 0 || filteredProducts?.length === 0 ? (
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            // borderRadius: "15px ",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <Cards>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={emp}
              alt="green iguana"
            />
            <CardContent>
              <Typography
                sx={{ color: " #212427" }}
                color="text.secondary"
                gutterBottom
              >
                There is no assets in this department. Please add asset.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => navigate("/add-asset")}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  // bgcolor: "#1746A2",
                }}
                variant="contained"
              >
                Add Asset
              </Button>
            </CardActions>
          </Cards>
        </BoxWrapper>
      ) : (
        <>
          <BoxWrapper style={{ overflowX: "auto", borderRadius: "0px" }}>
            <Box sx={{ overflow: "auto" }}>
              <TableContainer
                component={Paper}
                style={{
                  height: "calc(90vh - 200px)",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  borderRadius: "0px",
                }}
              >
                <Table
                  stickyHeader
                  sx={{
                    borderRadius: "0px",
                    bgcolor: "white",
                    // border: "6px solid green",
                    whiteSpace: "nowrap",
                  }}
                >
                  <TableHead
                    // style={{ borderRadius: "30px" }}
                    sx={
                      {
                        // borderRadius: "15px 15px 0px 0px",
                      }
                    }
                  >
                    <TableRow
                      selected={selectedRow === 0}
                      onClick={() => handleSelectRow(0)}
                      sx={{
                        // borderRadius: "15px 15px 0px 0px",
                        bgcolor: "rgba(146, 25, 118, 0.1)",
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedAssetIds?.length > 0 &&
                            selectedAssetIds?.length < assetData?.length
                          }
                          checked={
                            selectedAssetIds?.length === assetData?.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all assets",
                          }}
                        />
                      </TableCell>

                      <TableHeading
                        sx={
                          {
                            // borderRadius: "15px 0px 0px 0px",
                          }
                        }
                      >
                        Asset Name
                      </TableHeading>
                      <TableHeading sx={{}}>Brand</TableHeading>
                      <TableHeading sx={{}}>Asset Code</TableHeading>
                      {/* <TableHeading sx={{}}>Department</TableHeading> */}
                      <TableHeading sx={{ textAlign: "center" }}>
                        Status
                      </TableHeading>
                      <TableHeading sx={{ textAlign: "center" }}>
                        AMC/CMC
                      </TableHeading>
                      <TableHeading sx={{ textAlign: "center" }}>
                        Date
                      </TableHeading>

                      <TableHeading
                        sx={
                          {
                            // borderRadius: "0px 15px 0px 0px",
                          }
                        }
                      ></TableHeading>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ textDecoration: "none" }}>
                    {filteredProducts?.map((item, index) => (
                      <TableRow
                        key={item.assetid}
                        sx={{
                          textDecoration: "none",
                          "&:hover": {
                            backgroundColor: "#E8ECF6",
                          },
                        }}
                        onClick={() => handleSelectRow(0)}
                      >
                        <TableCells sx={{ textDecoration: "none" }}>
                          <Checkbox
                            checked={selectedAssetIds.includes(item.assetid)}
                            onChange={() =>
                              handleSelectRowClick(
                                item.assetid,
                                item.name,
                                item.assetcode
                              )
                            }
                          />
                        </TableCells>

                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          sx={{ textDecoration: "none" }}
                        >
                          {" "}
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            sx={{
                              fontSize: {
                                xl: "18px",
                                lg: "16px",
                                md: "14px",
                                sm: "12px",
                                xs: "10px",
                              },
                            }}
                            component="button"
                            variant="body2"
                            // onClick={() => {
                            //   console.info("I'm a button.");
                            // }}

                            to={`/asset-detail/${item?.assetid}`}
                          >
                            {item.name}
                          </Link>
                        </TableCells>
                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          sx={{ textDecoration: "none" }}
                        >
                          {item.brand}
                        </TableCells>
                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          sx={{ textDecoration: "none" }}
                        >
                          {item.assetcode}
                        </TableCells>

                        {/* <TableCells sx={{}}>{item.department}</TableCells> */}
                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          sx={{ textAlign: "center", textDecoration: "none" }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {item?.status !== "Working" ? (
                            <Button
                              size="small"
                              sx={{
                                borderRadius: "35px",
                                textTransform: "none",
                                width: "90px",
                                height: "25px",
                                bgcolor: "rgba(255, 107, 0, 1)",
                                "&:hover": {
                                  bgcolor: "rgba(255, 107, 0, 1)",
                                },
                              }}
                              variant="contained"
                            >
                              {item.status}
                            </Button>
                          ) : (
                            <>{item.status}</>
                          )}
                        </TableCells>
                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          sx={{ textAlign: "center", textDecoration: "none" }}
                        >
                          {item.cmcamc}
                        </TableCells>
                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          sx={{ textAlign: "center", textDecoration: "none" }}
                        >
                          {" "}
                          {item.cmcamcdate}
                        </TableCells>
                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          sx={{ textDecoration: "none" }}
                        >
                          {/* <cccccccccccc? */}
                        </TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <Pagination
                filteredProducts={filteredProducts}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              /> */}
            </Box>
          </BoxWrapper>
        </>
      )}

      <SuccessModal
        openModal={bulkDepartmentStatus}
        handleCloseModal={handleCloseSuccessModal}
        text={"Asset moved"}
        toggle={successToggle}
      />

      <AssetMovementModal
        open={question}
        onClose={handleCloseQuestion}
        onDelete={handleMoveBulk}
        count={selectedAssetIds.length}
        prevDep={departmentAsset}
        current={selectedData.name}
      />

      {/* <SimpleModal openModal={showModal}  text={simpleText} closeModal={handleCloseSimple}   /> */}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="asset-moved-modal-title"
        aria-describedby="asset-moved-modal-description"
      >
        <ModalBox
          sx={{
            backgroundColor: "white",
            padding: "15px",
            borderRadius: "10px",
            width: { xs: "80%", sm: "80%", md: "25%", lg: "25%" },
          }}
        >
          <Stack alignItems="flex-end">
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" spacing={2}>
            <DoneIcon style={{ fontSize: 60, color: "rgba(0, 201, 32, 1)" }} />
            <Typography variant="h6" align="center">
              Assets Moved Successfully!
            </Typography>
            <Typography variant="body2" align="center">
              The selected assets have been moved to the new department.
            </Typography>
            <Button
              variant="contained"
              //  onClick={handleCloseModal}
              onClick={() => navigate("/department-list")}
            >
              Close
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </Wrapper>
  );
};

export default DepartmentAssetDelete;

